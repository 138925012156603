<template>
  <aside class="fixed right-[2vw] bottom-[2vw] max-lg:hidden z-10">
    <button type="button" @click="scrollToTop">
      <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="44" height="44" rx="9.5" fill="white" stroke="#0C3388"/>
        <path d="M24.0927 17.7916V32.5384H21.6005V17.7916L14.9163 24.2903L13.1543 22.5772L22.8466 13.1538L32.5389 22.5772L30.7769 24.2903L24.0927 17.7916Z" fill="#0C3388"/>
      </svg>      
    </button>
    <a href="https://pf.kakao.com/_YqHmu/chat" target="_blank">
      <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="45" height="45" rx="10" fill="#FFF100"/>
        <path d="M22.845 11.5176C29.2186 11.5176 34.3845 15.8192 34.3845 21.127C34.3845 26.4335 29.2186 30.7351 22.8461 30.7351C22.2115 30.7355 21.5776 30.6923 20.9483 30.606L16.1043 33.9907C15.5538 34.3018 15.3593 34.2678 15.5856 33.5058L16.5659 29.1878C13.401 27.4737 11.3076 24.5034 11.3076 21.127C11.3076 15.8204 16.4725 11.5176 22.8461 11.5176H22.845Z" fill="#1B1D21"/>
      </svg> 
    </a>
  </aside>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style scoped>
  button, a {
    filter: drop-shadow(0px 3px 3px #00000020);
  }
</style>