<script setup>
import { useAppProductRequest } from '@/stores/appProductRequest';
import {onMounted, ref} from "vue";
import address from "@/assets/js/address";
import {numberWithCommas} from "@/assets/js/util";
import router from "@/router";
const store = useAppProductRequest();

const checkoutInfo = ref([]);   // 추가 결제 시작
const gopaymethod = ref("Card");
const mobileGopaymethod = ref("CARD");
const token = ref("");
// 결제시 필요 한 정보

const submitForm = async () => {
}

const cancelForm = () => {
  store.addPaymentInfo = {
    orderName: "",
    orderPhone: "",
    orderEmail: "",
    postcode: "",
    address: "",
    detailAddress: "",
  };
  store.addPaymentItemInfo = {};
  store.displayAddPayment = false;
}
// 결제 방법 선택(PC, mobile)
const setPayMethod = () => {
  let mobileMethod;
  if( gopaymethod.value === 'Card' ){
    mobileMethod = "CARD";
  }
  else if( gopaymethod.value === 'DirectBank' ){
    mobileMethod = "BANK";
  }
  else if( gopaymethod.value === 'VBank' ){
    mobileMethod = "VBANK ";
  }
  mobileGopaymethod.value = mobileMethod;
};

const pcPaySubmit = async () => {

  // 유효성 검사
  if( store.addPaymentInfo.orderName === "" ){
    alert("주문자 이름을 입력해 주세요.");
    return;
  }
  if( store.addPaymentInfo.orderPhone === "" ){
    alert("주문자 연락처를 입력해 주세요.");
    return;
  }
  if( store.addPaymentInfo.orderEmail === "" ){
    alert("주문자 이메일을 입력해 주세요.");
    return;
  }
  /*if( store.addPaymentInfo.shippingName === "" ){
    alert("배송지 이름을 입력해 주세요.");
    return;
  }*/
  if( store.addPaymentInfo.shippingPhone === "" ){
    alert("배송지 연락처를 입력해 주세요.");
    return;
  }
  if( store.addPaymentInfo.postcode === "" ){
    alert("배송지 주소를 입력해 주세요.");
    return;
  }
  if( store.addPaymentInfo.address === "" ){
    alert("배송지 주소를 입력해 주세요.");
    return;
  }
  if( store.addPaymentInfo.detailAddress === "" ){
    alert("배송지 상세주소를 입력해 주세요.");
    return;
  }

  try {
    const localStorageToken = localStorage.getItem('authorization');

    if (localStorageToken) {
      store.paymentToken = localStorageToken

      //token 값을 가져온 이후 실행되도록 처리
      await new Promise((resolve) => {
        document.querySelector('#PayForm').style.display = 'block';
        // window.open('', 'fPayPopForm', 'width=900,height=700');
        setTimeout(resolve, 500);
      });

      document.pcPayForm.submit();
    } else {
      console.error("토큰 값이 일치하지 않습니다.");
      alert("잘못된 요청입니다.");
      window.location.reload();
    }
  } catch (error) {
    console.error(error);
  }
};


// 결제 요청 - mobile
const mPaySubmit = async () => {
  try {
    const localStorageToken = localStorage.getItem('authorization');

    if (localStorageToken) {
      store.paymentToken = localStorageToken

      //token 값을 가져온 이후 실행되도록 처리
      await new Promise((resolve) => {
        document.querySelector('#PayForm').style.display = 'block';
        // window.open('', 'fPayPopForm', 'width=900,height=700');
        setTimeout(resolve, 500);
      });

      // setDeliveryRequestMessageJson();

      setTimeout(() => {
        console.log(document.mPayForm);
        document.querySelector('#mPayForm').submit();
      }, 500);
      // document.querySelector('#mPayForm').submit();
      // document.mPayForm.submit();
    } else {
      console.error("토큰 값이 일치하지 않습니다.");
      alert("잘못된 요청입니다.");
      window.location.reload();
    }
  } catch (error) {
    console.error(error);
  }
};


// 주소 찾기 열기
const openAddressSearch = () => {
  address.execDaumPostcode(setAddress);
};
const setAddress = (data) => {
  store.addPaymentInfo.postcode = data.postcode;
  store.addPaymentInfo.address = data.address;
};
// 메세지 감지 - iframe으로부터의 메시지 수신
window.addEventListener('message', (event) => {
  // console.log('message', event.data);
  // console.log(typeof event.data);

  if( typeof event.data === 'string' ){
    const eventData = JSON.parse(event.data);
    if( eventData.mod === 'close' ){ // 이니시스 닫힘
      closeIFrame();
    } else if (eventData.mod === 'exception') { // 실패
      alert(eventData.returnMsg);
      document.location.reload();
    }
  }
});

const closeIFrame = () => {
  alert('사용자 요청에 의해 결제가 취소 되었습니다.');
  // document.location.reload();
  router.go(0);
}

onMounted(() => {
  console.log(store.addPaymentInfo);
});
</script>

<template>
  <div class="modal_container">

    <div class="max-w-[600px] w-[600px] mx-auto p-6 bg-white rounded-md shadow-md">
      <h2 class="text-2xl font-bold mb-4"> 추가 결제 진행 </h2>
      <div>
        <div class="mb-4 flex justify-center gap-3">
          <div class="flex w-1/2">
            <div class="w-1/3"> - 결제 제목 </div>
            <div>{{ (store.addPaymentItemInfo.goodName)? store.addPaymentItemInfo.goodName : '추가결제' }}</div>
          </div>
          <div class="flex w-1/2">
            <div class="w-1/3"> - 결제 금액</div>
            <div>{{ numberWithCommas(store.addPaymentItemInfo.paidAmount) }}</div>
          </div>
        </div>

        <div class="w-full border my-3"></div>

        <div class="mb-4 flex flex-col justify-center gap-3">

          <div>
            <label for="orderName" class="block text-sm font-semibold mb-2"> 주문자 이름 </label>
            <input type="text" id="orderName" v-model="store.addPaymentInfo.orderName" class="form_style w-full" />
          </div>

          <div>
            <label for="orderPhone" class="block text-sm font-semibold mb-2"> 주문자 연락처 </label>
            <input type="text" id="orderPhone" v-model="store.addPaymentInfo.orderPhone" class="form_style w-full" />
          </div>

          <div>
            <label for="orderPhone" class="block text-sm font-semibold mb-2"> 배송지 연락처 </label>
            <input type="text" id="orderPhone" v-model="store.addPaymentInfo.shippingPhone" class="form_style w-full" />
          </div>

          <div>
            <label for="orderPhone" class="block text-sm font-semibold mb-2"> 주문자 이메일 </label>
            <input type="text" id="orderPhone" v-model="store.addPaymentInfo.orderEmail" class="form_style w-full" />
          </div>

          <div>
            <label for="orderPhone" class="block text-sm font-semibold mb-2">주소 </label>
            <div class="flex gap-2 mb-2">
              <input type="text" id="orderPhone" v-model="store.addPaymentInfo.postcode" class="form_style w-1/6" />
              <button type="button" @click="openAddressSearch" class="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200">주소찾기</button>
            </div>
            <div class="flex gap-2">
              <input type="text" id="orderPhone" v-model="store.addPaymentInfo.address" class="form_style w-1/2" />
              <input type="text" id="orderPhone" v-model="store.addPaymentInfo.detailAddress" class="form_style w-1/2" />
            </div>
          </div>

        </div>

        <div class="mb-4 flex justify-center gap-3">
          <div>
            <button type="button" class="cancel_btn" @click="cancelForm"> 취소 </button>
          </div>
          <div>
            <button @click="pcPaySubmit" class="max-md:hidden button_style">
              결제하기
            </button>

            <!-- mobile -->
            <button @click="mPaySubmit" class="md:hidden button_style">
              결제하기
            </button>
          </div>
        </div>

        <div>
          <!-- pc -->
          <div v-if="checkoutInfo">
            <form method="post" action="https://nodeblack.net/api/mall/order/extra/pc" target="PayForm" name="pcPayForm">
              <input type="hidden" id="buyername" name="buyername" v-model="store.addPaymentInfo.orderName" />
              <input type="hidden" id="buyertel" name="buyertel" v-model="store.addPaymentInfo.orderPhone" />
              <input type="hidden" id="buyeremail" name="buyeremail" v-model="store.addPaymentInfo.orderEmail" />
              <input type="hidden" id="buyerAddress" name="buyerAddress" v-model="store.addPaymentInfo.address" />
              <input type="hidden" id="buyerDetailedAddress" name="buyerDetailedAddress" v-model="store.addPaymentInfo.detailAddress" />
              <input type="hidden" id="buyerZipCode" name="buyerZipCode" v-model="store.addPaymentInfo.postcode" />
              <input type="hidden" id="gopaymethod" name="gopaymethod" v-model="gopaymethod" />
              <input type="hidden" id="token" name="token" v-model="store.paymentToken" />
              <input type="hidden" id="orderExtraOid" name="orderExtraOid" v-model="store.addPaymentInfo.orderExtraOid" />
              <!-- 확인 해야 할 정보 -->
              <!--
              <input type="hidden" id="cartIdSet" name="cartIdSet" :value="cartItemList" />
              <input type="hidden" id="deliveryRequestMessageJson" name="deliveryRequestMessageJson" v-model="deliveryRequestMessageJson" />
              -->
            </form>

            <!-- mobile -->
            <form method="post" action="https://nodeblack.net/api/mall/order/extra/mob" id="mPayForm" name="mPayForm">
              <input type="hidden" id="P_UNAME" name="P_UNAME" v-model="store.addPaymentInfo.orderName" />
              <input type="hidden" id="P_MOBILE" name="P_MOBILE" v-model="store.addPaymentInfo.orderPhone" />
              <input type="hidden" id="P_EMAIL" name="P_EMAIL" v-model="store.addPaymentInfo.orderEmail" />
              <input type="hidden" id="P_buyerAddress" name="buyerAddress" v-model="store.addPaymentInfo.address" />
              <input type="hidden" id="P_buyerDetailedAddress" name="buyerDetailedAddress" v-model="store.addPaymentInfo.detailAddress" />
              <input type="hidden" id="P_buyerZipCode" name="buyerZipCode" v-model="store.addPaymentInfo.postcode" />
              <input type="hidden" id="P_INI_PAYMENT" name="P_INI_PAYMENT" v-model="mobileGopaymethod" />
              <input type="hidden" id="P_token" name="token" v-model="store.paymentToken" />
              <input type="hidden" id="P_orderExtraOid" name="orderExtraOid" v-model="store.addPaymentInfo.orderExtraOid" />
              <!-- 확인 해야 할 정보 -->
              <!--
              <input type="hidden" id="P_cartIdSet" name="cartIdSet" :value="cartItemList" />
              <input type="hidden" id="P_deliveryRequestMessageJson" name="deliveryRequestMessageJson" v-model="deliveryRequestMessageJson" />
              -->
            </form>
          </div>

          <iframe
            id="PayForm"
            name="PayForm"
            src="about:blank"
            style="display:none; width:100vw; height: 100vh;position: fixed; top: 0; left: 0; z-index: 9999;"
          />

        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
.form_style {
  @apply border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500;
}
.submit_btn {
  @apply bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200;
}
.cancel_btn {
  @apply border py-2 px-4 rounded-md hover:bg-gray-300 transition duration-200;
}
.modal_container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.button_style {
  @apply bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200;
}
</style>