<template>
  <div class="lg:w-[1000px] p-5 mx-auto lg:mt-10">
    <!-- 회원정보 -->
    <div class="my-5 w-full">
      <h4 class="font-medium mb-2">회원정보</h4>
      <div class="signup_form field_input mb-5">

        <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0">
          <label class="inline-block w-1/5">이름<span></span></label>
          <input type="text" v-model="state.myProfile.name" class="px-4 py-2 border">
        </div>

        <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0">
          <label class="inline-block w-1/5">아이디<span></span></label>
          <input type="text" v-model="state.myProfile.uid" disabled class="px-4 py-2 border">
        </div>

        <div v-if="state.passwordChange === true">
          <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0">
            <label class="inline-block w-1/5">현재 비밀번호<span></span></label>
            <input type="password" v-model="state.myProfile.prePassword" class="px-4 py-2 border" v-show="state.togglePassword === true">
            <button type="button" class="sec_blk_btn lg:px-5 p-2 ml-2" @click="updatePasswordOpen" v-show="state.togglePassword === false"> 비밀번호 변경 </button>
          </div>

          <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0" v-show="state.togglePassword === true">
            <label class="inline-block w-1/5"> 새 비밀번호<span></span></label>
            <input type="password" v-model="state.myProfile.newPassword" class="px-4 py-2 border">
            <span class="ml-3"> 비밀번호 변경시 아래 '비밀번호 확인'과 함께 입력해 주세요. </span>
          </div>

          <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0" v-show="state.togglePassword === true">
            <label class="inline-block w-1/5"> 새 비밀번호 확인<span></span></label>
            <input type="password" v-model="state.myProfile.newPasswordRe" class="px-4 py-2 border">
            <button type="button" class="sec_blk_btn lg:px-5 p-2 ml-2" @click="updatePasswordCancel" v-show="state.togglePassword === true"> 취소 </button>
          </div>
        </div>


        <div
            v-if="state.appleLogin===false"
          class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0"
        >
          <label class="inline-block w-1/5">연락처<span></span></label>
          <input type="text" v-model="state.myProfile.phone" class="px-4 py-2 border">
          <span class="md:ml-3"> "-" 없이 숫자만 입력 해 주세요. </span>
        </div>

        <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0">
          <label class="inline-block w-1/5">이메일<span></span></label>
          <input type="text" v-model="state.myProfile.email" disabled class="px-4 py-2 border">
        </div>

        <!-- <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0">
          <label class="inline-block w-1/5">교회명<span></span></label>
          <input type="text" class="px-4 py-2 border">
        </div> -->
      </div>
      <div class="flex justify-between">
        <div>
          <button type="button" class="sec_blk_btn lg:px-5 p-2" @click="updateProfile">정보수정</button>
        </div>
        <div>
          <router-link to="/withdrawal" class="sec_blk_btn lg:px-5 p-2">회원탈퇴</router-link>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {onMounted, reactive} from "vue"
import axios from "axios"

export default {
  name: "EduRegisterPage",


  setup () {

    const state = reactive({
      myProfile: [],
      togglePassword: false,
      passwordChange: false,
      appleLogin: false,
    })

    onMounted(() => {
      axios.get('/api/member/me').then(response => {
        console.log(response.data.contents);
        state.myProfile = response.data.contents
        state.passwordChange = (state.myProfile.joinType.includes('normal'));
        state.appleLogin = (state.myProfile.joinType.includes('apple'));
      }).catch(error => {
        console.error(error)
      })
    })

    const updateProfile = () => {
      if( state.myProfile.name == '' ){
        alert('이름을 입력해주세요.')
        return
      }

      // 비밀번호 입력 체크
      if( state.togglePassword === true ) {
        if( state.myProfile.prePassword == '' ){
          alert('비밀번호를 입력해 주세요.');
          return
        }

        // 비밀번호 체크
        if( state.myProfile.newPassword != '' && state.myProfile.newPasswordRe != '' ){
          // // 비밀번호 공잭 제거
          // state.myProfile.upw = state.myProfile.new_upw.replace(/ /gi, "");
          // 비밀번호 길이 체크
          if( state.myProfile.newPassword.length < 8 ){
            alert('비밀번호는 8자 이상 입력해주세요.')
            return
          }
          if( state.myProfile.newPassword != state.myProfile.newPassword ){
            alert('비밀번호가 일치하지 않습니다.')
            return
          }
        }
      }

      // 핸드폰번호 체크
      if( state.myProfile.phone == '' ){
        alert('핸드폰번호를 입력해주세요.')
        return
      }
      else {
        const regExp = /[^0-9]/g;
        state.myProfile.phone = state.myProfile.phone.replace(regExp, '');
      }


      // 이메일 체크
      if( state.myProfile.email == '' ){
        alert('이메일을 입력해주세요.')
        return
      }

      axios.put('/api/member/update', state.myProfile).then(response => {
        if( response.data.err_code == 0 ){
          alert('회원정보가 수정되었습니다.');
          document.location.reload();
        }
        else {
          alert(response.data.err_msg);
          return false;
        }
      }).catch(error => {
        console.error(error)
      })
    }

    const updatePasswordOpen = () => {
      state.togglePassword = !state.togglePassword
    }
    const updatePasswordCancel = () => {
      state.myProfile.prePassword = null;
      state.myProfile.newPassword = null;
      state.myProfile.newPasswordRe = null;
      state.togglePassword = !state.togglePassword
    }

    return { state, updateProfile, updatePasswordOpen, updatePasswordCancel }
  },


}



</script>
