<template>
  <div class="lg:flex flex-1">
    <LNavComponent v-if="pageName !== 'eduRegisterApp' && pageName !== 'eduRegister2App' " />
    <appNavigator v-if="pageName === 'eduRegisterApp' || pageName === 'eduRegister2App'" :page="pageName" />
    <EduRegisterPage />
  </div>
</template>

<script>
import LNavComponent from "@/components/LNavComponent.vue";
import EduRegisterPage from "@/pages/EduRegisterPage.vue";
import AppNavigator from "@/components/appNavigator.vue";

export default {
  name: "EduRegisterView",
  components: {AppNavigator, EduRegisterPage, LNavComponent},
  data() {
    return {
      pageName: this.$route.name
    }
  },
}
</script>

<style scoped>

</style>
