<template>
  <section class="flex-1">
    <div class="text-center px-5">
      <h1 class="font-medium mb-3 pt-20">회원정보 입력</h1>
      <p class="font-light text-neutral-400 break-keep">총회교육개발원 홈페이지에 오신 것을 환영합니다</p>
    </div>


    <div class="max-w-[860px] mx-auto mt-10 flex justify-end px-5">
      <label class="require"> <span></span> 필수 입력 항목 입니다. </label>
    </div>
    <div class="signup_form field_inputs max-w-[860px] mx-auto mb-10 px-5">

      <!-- 아이디 -->
      <div class="flex items-center">
        <label class="inline-block w-1/5 require">아이디<span></span></label>
        <div class="flex">
          <input type="text" placeholder="아이디" class="regi_inputs w-2/3" v-model="state.form.uid"/>
          <button class="ml-2 sec_blk_btn w-32 p-2" @click="checkId()">중복확인</button>
        </div>
      </div>

      <!-- 이름 -->
      <div class="flex items-center">
        <label class="inline-block w-1/5 require">이름<span></span></label>
        <input type="text" placeholder="이름" class="regi_inputs w-4/5" v-model="state.form.name"/>
      </div>

      <!-- 비밀번호 -->
      <div class="flex items-center" v-show="state.form.sns_type == ''">
        <label class="inline-block w-1/5 require">비밀번호<span></span></label>
        <input type="password" placeholder="비밀번호" class="regi_inputs w-4/5" v-model="state.form.upw"/>
      </div>

      <!-- 비밀번호 확인 -->
      <div class="flex items-center" v-show="state.form.sns_type == ''">
        <label class="inline-block w-1/5 require">비밀번호 확인<span></span></label>
        <input type="password" placeholder="비밀번호 확인" class="regi_inputs w-4/5" v-model="state.pw2"/>
      </div>

      
<!--      <div
        v-if="state.appleJoin === true"
        class="flex items-center"
      >
        <label class="inline-block w-1/5 require">
          전화번호(선택)
        </label>
        <div class="flex gap-2 mont font-medium">
            <select name="" class="w-24 regi_inputs" v-model="state.ph1">
              <option value="">선택</option>
              <option selected>010</option>
            </select>
            <input type="text" maxlength="4" class="w-24 regi_inputs" v-model="state.ph2" />
            <input type="text" maxlength="4" class="w-24 regi_inputs" v-model="state.ph3" />
        </div>
      </div>-->
      
      <!-- 전화번호 -->
      <div class="flex items-center" v-if="state.appleJoin === false">
        <label class="inline-block w-1/5 require">
          전화번호 <span v-if="state.pageName === 'signup'"></span>
        </label>
        <div class="flex gap-2 mont font-medium">
            <select name="" class="w-24 regi_inputs" v-model="state.ph1" :disabled="state.chk_passCode!=''">
                <option value="">선택</option>
                <option selected>010</option>
            </select>
            <input type="text" maxlength="4" class="w-24 regi_inputs" v-model="state.ph2" :disabled="state.chk_passCode!=''"/>
            <input type="text" maxlength="4" class="w-24 regi_inputs" v-model="state.ph3" :disabled="state.chk_passCode!=''"/>
        </div> <button class="ml-2 sec_blk_btn w-32 p-2" @click="sendPassCode()" v-if="state.chk_passCode==''">인증번호발송</button><span v-if="state.chk_passCode"> <input type="text" v-model="state.form.passCode" placeholder="인증번호 입력" class="regi_inputs w-4/5"/></span>
      </div>
      
      

      <!-- 이메일 -->
      <div class="flex items-center !border-black">
        <label class="inline-block w-1/5 require">이메일 <span></span></label>
        <div class="flex items-center gap-2">
            <input type="text" placeholder="이메일" class="w-32 regi_inputs" v-model="state.email1"/>@
            <input type="text" class="w-32 regi_inputs" v-model="state.email2"/>
            <select name="" class="w-32 regi_inputs" v-model="state.email2">
                <option value="">직접입력</option>
                <option>naver.com</option>
                <option>daum.net</option>
            </select>
        </div>
      </div>

      <div class="!border-0">
        <div class="flex mt-4">
          <span class="check_btn check_blk_btn">
            <input id="policy" type="checkbox" name="policy" v-model="state.agreeCheck"/>
            <label for="policy">개인정보활용 동의</label>
          </span>
          <a href="/policy/info" target="_blank" class="sec_blk_btn ml-2 text-sm px-2 py-1">약관보기</a>
        </div>
      </div>

      <section class="w-3/4 mx-auto my-10 pb-10 text-center !border-0">
        <div class="sec_filled-blk_btn block p-4 cursor-pointer" @click="submit()">가입하기</div>
      </section>
    </div>
  </section>
</template>

<script>
import {onMounted, reactive} from "vue";
import axios from "axios";
import router from "@/router";

export default {
  name: "SignupPage",
  setup () {
    const state = reactive ({
      pw2: "",
      ph1: "010",
      ph2: "",
      ph3: "",
      email1: "", email2: "",
      agreeCheck: false,
      form: {
        uid: "",
        name: "",
        upw: "",
        phone: "",
        email: "",
        is_agree: "N",
        sns_type: "",
        sns_id: "",
        passCode : ""
      },
      chk_passCode : "",
      timer : 300,
      pageName: router.currentRoute.value.name,
      applePhoneOption: true,
      appleJoin: false
    })
    const load = () => {}

    load()
    const submit = () => {

      if (!state.form.uid) {
        alert("아이디를 입력하세요.")
        return
      }
      if (!state.form.name) {
        alert("이름을 입력하세요.")
        return;
      }
      if( state.form.sns_type == '' ) {
        if (!state.form.upw) {
          alert("비밀번호를 입력하세요.")
          return;
        }
        if (!state.pw2) {
          alert("비밀번호 확인을 입력하세요.")
          return
        }
        if (state.form.upw !== state.pw2) {
          alert("비밀번호가 일치하지 않습니다.")
          return
        }
      }
      // 애플 로그인이 아닐때만
      if( state.appleJoin === false ){
        if (!state.ph1 || !state.ph2 || !state.ph3) {
          alert("전화번호를 입력하세요.")
          return;
        }

        if(!state.chk_passCode){
          alert("휴대전화 인증을 해주세요");
          return
        }

        if(!state.form.passCode){
          alert("인증코드를 입력해주세요");
          return
        }

        if(state.chk_passCode != state.form.passCode){
          alert("인증코드가 일치하지 않습니다.");
          state.form.passCode = "";
          return
        }

        state.form.phone = state.ph1 + state.ph2 + state.ph3
      }
      /*if( state.applePhoneOption === true ) {
        if (!state.ph1 || !state.ph2 || !state.ph3) {
          alert("전화번호를 입력하세요.")
          return;
        }

        state.form.phone = state.ph1 + state.ph2 + state.ph3
      }*/
          /*
      else {
        if( state.ph1 && state.ph2 && state.ph3 ){
          const checkPhone = state.ph1 + state.ph2 + state.ph3;
          const phoneReg = /^0[0-9]{2}[0-9]{4}[0-9]{4}$/;
          if (!phoneReg.test(checkPhone)) {
            alert("올바른 핸드폰 번호를 입력해주세요.")
            return
          }
          state.form.phone = checkPhone;
        }
        else {
          state.form.phone = '';
        }
      }
      */

      if (!state.email1 || !state.email2) {
        alert("이메일을 입력하세요")
        return;
      }
      let reg_email2 = /([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
      if (!reg_email2.test(state.email2)) {
        alert("이메일 형식을 확인하세요.")
        return
      }
      state.form.email = state.email1 + "@" + state.email2
      if (!state.agreeCheck) {
        alert("개인정보활용을 동의해주세요.")
        return
      }
      state.form.is_agree = "Y"

      axios.post("/api/member", state.form).then(({data}) => {
        console.log(data);
        if (data.err_code) {
          alert(data.err_msg)
          return
        }

        // 1. sns 로 가입된 정보가 있을때
        localStorage.removeItem('sns_type');
        localStorage.removeItem('sns_id');
        localStorage.removeItem('uid');
        localStorage.removeItem('email');
        localStorage.removeItem('name');
        localStorage.removeItem('phone');

        if( state.pageName === 'signup_app' ){
          // TODO: APP 로그인 페이지로 이동
          alert('회원 가입이 완료되었습니다. 로그인해 주세요.');
          getData.postMessage('login');
        }
        else {
          if( state.form.sns_type != '' ){
            alert('회원 가입이 완료되었습니다. SNS 버튼으로 다시 로그인해 주세요. ');
          }
          else {
            alert('회원 가입이 완료되었습니다.')
          }
          // router.push({path: "/login"})
          document.location.href = "/";
        }
      })

    }

    const checkId = () => {
      if (!state.form.uid) {
        alert("아이디를 입력하세요.")
        return
      }
      axios.get(`/api/check-id?uid=${state.form.uid}`).then(({data}) => {
        if (data.contents) {
          alert("존재하는 아이디입니다.")
        } else {
          alert("사용가능한 아이디입니다.")
        }
      })
    }

    const sendPassCode = () => {
      if (!state.ph1 || !state.ph2 || !state.ph3) {
        alert("전화번호를 입력하세요.")
        return;
      }else{
        state.form.phone = state.ph1 + state.ph2 + state.ph3
        axios.post("/api/join/passcode", state.form).then(({data}) => {
          console.log(data);


          if (data.err_code == 0) {
            state.chk_passCode = data.passCode;
            alert("인증번호가 발송되었습니다.");
          }
          else if (data.err_code == "403") {
            // 이미 가입된 번호 입니다.
            // TODO : 가입된 번호가 있을때 처리
            const joinType = data.joinType
            if( joinType.includes('normal') && joinType.includes('kakao') ){
              alert('\'카카오(간편가입)\'와 \'일반회원가입\'으로 가입된 계정 정보가 있습니다.\n\n\'일반회원가입\' 계정정보가 기억나지 않으신다면 핸드폰 번호로\n\'아이디/비밀번호 찾기\'를 이용 해 주세요.');
            }
            // 일반, 네이버
            else if( joinType.includes('normal') && joinType.includes('naver') ){
              alert('\'네이버(간편가입)\'와 \'일반회원가입\'으로 가입된 계정 정보가 있습니다.\n\n\'일반회원가입\' 계정정보가 기억나지 않으신다면 핸드폰 번호로\n\'아이디/비밀번호 찾기\'를 이용 해 주세요.');
            }
            // 일반, 네이버
            else if( joinType.includes('normal') && joinType.includes('apple') ){
              alert('\'애플ID(간편가입)\'와 \'일반회원가입\'으로 가입된 계정 정보가 있습니다.\n\n\'일반회원가입\' 계정정보가 기억나지 않으신다면 핸드폰 번호로\n\'아이디/비밀번호 찾기\'를 이용 해 주세요.');
            }
            // 일반
            else if( joinType.includes('normal') ){
              alert('\'일반회원가입\'으로 가입된 계정 정보가 있습니다.\n\n계정정보가 기억나지 않으신다면 핸드폰 번호로\n\'아이디/비밀번호 찾기\'를 이용 해 주세요.');
            }
            // 카카오
            else if( joinType.includes('kakao') ){
              alert('\'카카오(간편가입)\'로 가입된 계정 정보가 있습니다.');
            }
            // 네이버
            else if( joinType.includes('naver') ){
              alert('\'네이버(간편가입)\'로 가입된 계정 정보가 있습니다.');
            }
            // 애플
            else if( joinType.includes('apple') ){
              alert('\'애플ID(간편가입)\'로 가입된 계정 정보가 있습니다.');
            }
            else {
              alert('이미 가입된 번호입니다.');
            }



            return false;
          }
          else{
            state.chk_passCode = "";
            alert("인증코드 오류");
            console.log(data.err_msg);
          }
        });
      }
    }

    onMounted(() => {
      if( localStorage.getItem('sns_type') ){ state.form.sns_type = localStorage.getItem('sns_type') }
      if( localStorage.getItem('sns_id') ){ state.form.sns_id = localStorage.getItem('sns_id') }
      if( localStorage.getItem('uid') ){ state.form.uid = localStorage.getItem('uid') }
      if( localStorage.getItem('email') ) {
        let email = localStorage.getItem('email');
        let reg_email = /([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
        if (reg_email.test(email)) {
          email = email.split('@');
          state.email1 = email[0];
          state.email2 = email[1];
        }
      }
      if( localStorage.getItem('name') ){
        const getNames = localStorage.getItem('name');
        if( getNames != '' && getNames != undefined ){
          state.form.name = localStorage.getItem('name');
        }
      }
      if( localStorage.getItem('phone') ){
        let phone = localStorage.getItem('phone');
        let reg_phone = /^\d{10,11}$/;
        if (reg_phone.test(phone)) {
            //console.log('핸드폰번호 정규식 통과')
          state.ph1 = phone.substr(0, 3);
          state.ph2 = phone.substr(3, 4);
          state.ph3 = phone.substr(7, 4);
        }
      }

      if( state.form.sns_type === 'apple' && state.pageName === 'signup_app' ){
        state.appleJoin = true;
      }
    })

    return { state, load, submit, checkId, sendPassCode }
  }
}
</script>

<style scoped>

</style>
