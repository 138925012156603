<template>
  <div class="w-full border-b border-neutral-200">
    <nav id="nav" class="max-w-[1700px] mx-auto">
      <div class="brand">
        <router-link to="/">
          <img src="@/assets/img/logo.png" alt="총회교육개발원">
        </router-link>
      </div>
      <div class="link">
        <router-link to="/profile" v-if="$store.state.uid.id"><b>{{$store.state.uid.id}}</b> 마이페이지</router-link>
        <router-link to="/management" v-if="$store.state.level.id === 10 ">관리자페이지</router-link>

        <router-link to="/login" v-if="!$store.state.account.id">로그인</router-link>
        <a @click="logout()" v-else class="cursor-pointer">로그아웃</a>

        <router-link to="/signup" v-if="!$store.state.account.id">회원가입</router-link>
      </div>
    </nav>
  </div>

</template>

<script>
import store from "@/store";
// import axios from "axios";
import router from "@/router";
export default {
  name: "NavComponent",
  setup () {
    const logout = () => {
      localStorage.removeItem('authorization')
      store.commit('setAccount', 0)
      store.commit('setLevel', 0)
      store.commit('setUid', '')
      router.push({path: '/'});
    }

    return { logout }
  }
}
</script>

<style scoped>
.link a:hover {
  text-decoration: underline;
}

</style>
