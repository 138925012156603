<template>

  <section class="bg-white dark:bg-gray-900">
    <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
      <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white"> 문의하기 </h2>
      <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
        결제관련 및 기타 문의사항을 남겨주시면<br />빠른 시간내에 답변드리겠습니다.
      </p>

      <p v-if="loginCheck === false" class="mb-8 lg:mb-16 font-light text-center dark:text-gray-400 sm:text-xl text-red-500">
        ※ 로그인 후 이용해 주세요.
        <div class="lg:hidden"> 로그인은 상단 오른쪽 메뉴 버튼을 눌러주세요. </div>
      </p>

      <form action="#" class="space-y-8">
        <div>
          <label for="conference_idx" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">컨퍼런스</label>
          <select id="conference_idx" name="conference_idx"
                  v-model="qna.conference_idx"
                  class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  ref="conference_idx"
          >
              <option value="0">컨퍼런스 선택</option>
              <option :value="item.idx" v-for="item in conferenceList">{{item.title}}</option>
          </select>


        </div>
        <div>
          <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">제목</label>
          <input type="text" id="title" class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                 v-model="qna.title"
                 placeholder="제목을 입력해 주세요."
                 ref="title"
                 required>
        </div>
        <div class="sm:col-span-2">
          <label for="contents" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">내용</label>
          <textarea id="contents" rows="6" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    v-model="qna.contents"
                    ref="contents"
                    placeholder="문의에 남길 내용을 입력해 주세요."></textarea>
        </div>
        <div class="flex justify-center" v-if="loginCheck === true">
          <button type="button"
                  class="py-3 px-20 text-lg font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  @click="submit"> 문의등록 </button>
        </div>
      </form>
    </div>
  </section>

</template>

<script>
import axios from "axios";

export default {
    name: "qnaComponent",

    data() {
        return {
            qna: {
                conference_idx: 0,
                title: '',
                contents: '',
            },
            loginCheck: false,
            conferenceList: [],
        }
    },

    methods: {
        async submit() {
            // 컨퍼런스 유효성 검사
            if (this.qna.conference_idx === 0) {
                alert('컨퍼런스를 선택해 주세요.');
                this.$refs.conference_idx.focus();
                return;
            }
            // 제목 유효성 검사
            if (this.qna.title === '') {
                alert('제목을 입력해 주세요.');
                this.$refs.title.focus();
                return;
            }
            // 내용 유효성 검사
            if (this.qna.contents === '') {
                alert('내용을 입력해 주세요.');
                this.$refs.contents.focus();
                return;
            }
            // 문의 등록
            const url = "/api/qna";
            const body = {
                conference_idx: this.qna.conference_idx,
                title: this.qna.title,
                contents: this.qna.contents,
            };
            const { data } = await axios.post(url, body);
            if( data.err_code === 0 ){
                alert('문의가 등록되었습니다.');
                document.location.href = '/';
            }
            else {
                alert(data.err_msg);
            }
        },

        getConferenceList() {
          const url = "/api/conference/title/list";
          try {
            axios.get(url).then((res) => {
              const data = res.data;
              console.log(data);
              if( data.err_code === 0 ){
                this.conferenceList = data.contents;
              }
            });
          }
          catch (e) {
            console.log(e);
          }
        }
    },
    mounted() {
        this.getConferenceList();
        this.loginCheck = (localStorage.getItem('authorization') !== null);
    }
}
</script>

<style scoped>

</style>