<template>
  <main class="max-w-[1200px] w-full mx-auto mb-20 px-5">
    <h1 class="font-bold text-center my-8">내 콘텐츠</h1>

    <div class="w-full overflow-auto lg:my-4 py-4">
      <div class="flex w-fit gap-x-2">
        <!-- <router-link to="" 
          class="sec_btn py-2 px-4 rounded-full whitespace-nowrap max-lg:text-sm">
          온라인 강의실
        </router-link> -->
        <router-link to="/mypage/contentsBox" active-class="pri_btn"
          class="sec_btn py-2 px-4 rounded-full whitespace-nowrap max-lg:text-sm">
          콘텐츠 보관함
        </router-link>
        <router-link to="/mypage/downloadHistory" active-class="pri_btn"
          class="sec_btn py-2 px-4 rounded-full whitespace-nowrap max-lg:text-sm">
          콘텐츠 다운로드 내역
        </router-link>
        <router-link to="/mypage/cart" active-class="pri_btn"
          class="sec_btn py-2 px-4 rounded-full whitespace-nowrap max-lg:text-sm">
          콘텐츠 장바구니
        </router-link>
      </div>
    </div>

    <div class="text-sm">
      <div class="bg-neutral-100 border border-neutral-200 p-4 mt-2">
        <div class="lg:flex items-center">
          <span class="w-20">기간조회</span>
          <VDatePicker v-model.range="range" locale="ko" :max-date='new Date()'>
            <template #default="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center">
                <input type="text" class="regi_inputs w-full" :value="inputValue.start" v-on="inputEvents.start" />
                <span class="mx-2">~</span>
                <input type="text" class="regi_inputs w-full" :value="inputValue.end" v-on="inputEvents.end" />
                <button class="ml-2 sec_filled-blk_btn p-2 flex-1" type="button" @click="getMyContentsList(1)">
                  조회
                </button>
              </div>
            </template>
          </VDatePicker>
        </div>
      </div>
    </div>

    <div class="mt-10">
      <div class="table_grid_container">
        <table class="w-full" v-if="myContentsList.length != 0">
          <thead class="max-lg:hidden">
            <tr class="border-b border-gray-[#dee0e3] text-gray-400">
              <th class="p-4 text-left text-sm font-normal">콘텐츠 정보</th>
              <th class="p-4 text-left text-sm font-normal">기한</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr class="table_grid_body max-lg:grid max-lg:pb-6 mb-6" v-for="item in myContentsList" :key="item.orderContentId">
              <td class="align-top p-4">
                <div class="flex max-lg:items-center max-lg:justify-center gap-4 max-lg:flex-col">
                  <div class="product_thumb !w-40">
                    <img
                      v-if="item.thumbnailLink"
                      :src="'https://eduwind-api.nodeblack.net/' + item.thumbnailLink"
                      :alt="item.title"
                    />
                    <img
                      v-else
                      src="@/assets/img/null_img.jpg"
                      :alt="item.title"
                    />
                  </div>
                  <div>
                    <div class="font-semibold">{{item.orderContentTitle}}</div>
                    <div class="mt-1">
                      {{ 
                        item.contentType === 'IMAGE' ? '이미지' :
                        item.contentType === 'FILE' ? '파일' :
                        item.contentType === 'VIDEO' ? '영상' :
                        item.contentType === 'MUSIC' ? '음원' :
                        ''
                      }}
                    </div>
                    <div class="text-sm mt-3 text-gray-500">
                      <b v-if="item.orderContentStatus === 'NOT_PAID'">결제 대기 중</b>
                    </div>
                  </div>
                </div>
              </td>
              <td class="align-top p-4 text-sm">
                <span v-if="new Date(item.downloadExpiration) < base">무제한</span>
                <span v-else
                :class="{'text-red-500': isExpired(item.downloadExpiration)}">
                  <p class="lg:hidden">기한</p>
                  {{ formDownloadDate(item.downloadExpiration) }} 까지
                  <p v-if="isExpired(item.downloadExpiration)" class="text-red-500">기한만료</p>
                </span>
              </td>
              <td class="align-top p-4">
                <div class="flex flex-col gap-2">
                  <router-link
                    v-if="item.orderContentStatus != 'DOWNLOAD_FIN'
                    && new Date(item.downloadExpiration) < base
                    || new Date(item.downloadExpiration) >= today"
                    :to="'/mypage/contentsBox/' + item.orderContentId"
                    class="flex justify-center items-center sec_filled-blk_btn px-2 py-2 text-sm max-lg:py-3">
                    상세보기
                    <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.74999 15C6.74952 14.9015 6.76874 14.8038 6.80652 14.7128C6.8443 14.6218 6.89988 14.5392 6.96999 14.47L11.44 9.99999L6.99999 5.52999C6.9086 5.38904 6.86718 5.22148 6.88237 5.05418C6.89755 4.88687 6.96847 4.72951 7.08375 4.60731C7.19903 4.48512 7.35199 4.40516 7.51813 4.38026C7.68427 4.35536 7.85395 4.38696 7.99999 4.46999L13 9.47C13.1404 9.61062 13.2193 9.80124 13.2193 9.99999C13.2193 10.1987 13.1404 10.3894 13 10.53L7.99999 15.53C7.85936 15.6704 7.66874 15.7493 7.46999 15.7493C7.27124 15.7493 7.08061 15.6704 6.93999 15.53C6.8093 15.3853 6.74101 15.1948 6.74999 15Z" fill="white"/>
                    </svg>
                  </router-link>
                  <div v-else-if="item.orderContentStatus == 'CREATE_FAIL'">
                    <p class="text-red-500 text-sm">시스템 오류입니다.<br/>고객센터로 문의 바랍니다.</p>
                  </div>
                  <button v-if="item.reviewed === false" 
                    @click="openModal(item)" 
                    class="sec_btn px-2 py-2 max-lg:py-3 max-lg:w-full text-sm rounded">
                      리뷰 작성
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-else class="text-center py-10">
          보관함이 비었습니다.
        </div>
        
        <pagination v-if="pageInfo.page"
          :pageInfo="pageInfo"
          :page="page"
          :size="size"
          @updatePage="updatePage"
        />

      </div>
    </div>
  </main>

  <ReviewWriteComponent v-if="modalVisible" @close="modalVisible = false" :content="modalData" />
</template>

<script setup>
  import axios from 'axios';
  import { onMounted, ref, computed } from "vue";
  import Pagination from '@/components/Pagination.vue';
  import ReviewWriteComponent from "@/components/ReviewWriteComponent.vue";

  import { useRoute } from 'vue-router';
  const route = useRoute();

  const formatDate = (date) => date.toISOString().split('T')[0];

  const start = new Date();
  const end = new Date();
  start.setDate(end.getDate() - 14);

  const range = ref({ start, end });

  const formStartDate = computed(() => formatDate(range.value.start));
  const formEndDate = computed(() => formatDate(range.value.end));

  const myContentsList = ref({});

  const pageInfo = ref({});

  const page = ref(1);
  const size = ref(10);

  const getMyContentsList = async (num) => {
    try {
      const pageValue = num !== undefined ? 1 : page.value;

      const { data } = await axios.get(
        `/new_api/customer/order/content/list?page=${pageValue}&size=${size.value}&startDate=${formStartDate.value}&endDate=${formEndDate.value}`
      );
      myContentsList.value = data.data.orderContentList;
      pageInfo.value = data.data.pageInfo;
    } catch (err) {
      console.error(err);
    }
  }

  onMounted(async () => {
    await getMyContentsList();
  });

  // 기한 만료 체크
  const today = new Date();
  const base = new Date('2000-01-01');

  const isExpired = (date) => {
    const expirationDate = new Date(date);
    return expirationDate < today;
  }

  const formDownloadDate = (d) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const date = new Date(d);
    return date.toLocaleDateString('ko-KR', options).replace(/(\d+)\D(\d+)\D(\d+)\D(\d+)\D(\d+)/, '$1.$2.$3 $4:$5:$6');
  };

  const updatePage = (num) => {
    page.value = num;
    getMyContentsList();
  };

  
  const modalVisible = ref(false);
  const modalData = ref(null);

  const openModal = (content) => {
    modalData.value = content;
    modalVisible.value = true;
  };
</script>