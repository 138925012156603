<template>
  <main class="max-w-[1200px] min-w-[60vw] max-md:min-w-[88vw] mx-auto mb-20 flex-1">
    <h1 class="font-bold text-center my-8">마이페이지</h1>
    <!-- <h3 class="nanum-square max-md:px-4"><b>{{$store.state.uid.id}}</b>님 안녕하세요.</h3> -->
    <div class="max-lg:px-5">
      <div class="flex flex-col justify-between bg-neutral-100 border border-neutral-200 p-4 mt-4 max-md:flex-col max-md:gap-4 max-md:items-center">
        <!-- <div class="flex gap-[5vw] max-md:grid max-md:w-full">
          <div>
            <p>캐시</p>
            <div class="flex items-center">
              <b class="text-[#FF4A4A] font-bold text-3xl">0</b>
              <button type="button" class="ml-2 border border-black rounded px-1 text-sm">충전</button>
            </div>
          </div>
          <div>
            <p>포인트</p>
            <b class="text-[#FF4A4A] font-bold text-3xl">0</b>
          </div>
          <div>
            <p>쿠폰</p>
            <b class="text-[#FF4A4A] font-bold text-3xl">0</b>
          </div>
        </div> -->
        <h3 class="nanum-square max-md:px-4"><b>{{$store.state.uid.id}}</b>님 안녕하세요.</h3>
        <div class="flex gap-2 items-center place-self-end">
          <VDialog no-scroll :classes="{ bg: 'bg-black-alpha' }">
            <template #toggle="{ bind, on }">
              <button v-bind="bind" v-on="on"  class="inline-flex h-fit gap-1 bg-white border border-black p-2 items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path d="M12.0808 4.10666C12.3083 3.87916 12.3083 3.49999 12.0808 3.28416L10.7158 1.91916C10.5 1.69166 10.1208 1.69166 9.89333 1.91916L8.82 2.98666L11.0075 5.17416M1.75 10.0625V12.25H3.9375L10.3892 5.79249L8.20167 3.60499L1.75 10.0625Z" fill="#1B1D21"/>
                </svg>
                개인정보 수정
              </button>
            </template>
        
            <template #default="{ close }">
              <div class="bg-black bg-opacity-25 w-screen h-screen flex justify-center items-center">
                <div class="min-w-[25vw] flex flex-col bg-white w-fit rounded-3xl">
                  <button
                    aria-label="close"
                    class="my-dialog__close w-fit mt-3 mr-4 self-end"
                    @click="close"
                  >
                    <h4 class="text-2xl"><font-awesome-icon icon="fa-solid fa-circle-xmark" /></h4>
                  </button>
                  <MemberProfilePage/>
                  
                </div>
              </div>
            </template>
          </VDialog>

          <button type="button" class="inline-flex h-fit gap-1 bg-white border text-neutral-400 p-2 items-center text-sm" @click="logout()">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path d="M9.91699 4.08333L9.09449 4.90583L10.5995 6.41667H4.66699V7.58333H10.5995L9.09449 9.08833L9.91699 9.91667L12.8337 7M2.33366 2.91667H7.00033V1.75H2.33366C1.69199 1.75 1.16699 2.275 1.16699 2.91667V11.0833C1.16699 11.725 1.69199 12.25 2.33366 12.25H7.00033V11.0833H2.33366V2.91667Z" fill="#898C92"/>
            </svg>
            로그아웃
          </button>
        </div>
      </div>
    </div>
    
    <div class="w-full flex justify-between gap-[1.6vw] my-7 max-md:flex-col max-md:px-5">
      <section class="w-full border border-neutral-200 rounded-lg p-[1.6vw] max-md:p-5">
        <h3 class="mb-7">접수·결제 정보</h3>
        <div class="flex flex-col gap-1 text-neutral-600">
          <router-link to="/profile/confList" target="_blank">컨퍼런스 신청내역</router-link>
          <router-link to="/mypage/list">주문내역</router-link>
          <router-link to="/mypage/paymentList">결제내역</router-link>
        </div>
      </section>
      <section class="w-full border border-neutral-200 rounded-lg p-[1.6vw] max-md:p-5">
        <h3 class="mb-7">내 콘텐츠</h3>
        <div class="flex flex-col gap-1 text-neutral-600">
          <!-- <router-link to="">온라인 강의실</router-link> -->
          <router-link to="/mypage/contentsBox">콘텐츠 보관함</router-link>
          <router-link to="/mypage/downloadHistory">콘텐츠 다운로드 내역</router-link>
          <router-link to="/mypage/cart">콘텐츠 장바구니</router-link>
        </div>
      </section>
      <section class="w-full border border-neutral-200 rounded-lg p-[1.6vw] max-md:p-5">
        <h3 class="mb-7">작성한 글</h3>
        <div class="flex flex-col gap-1 text-neutral-600">
          <router-link to="/mypage/post">내가 작성한 글</router-link>
          <!-- <router-link to="">나의 문의내역</router-link> -->
          <router-link to="/mypage/review">나의 후기</router-link>
        </div>
      </section>
    </div>

    <!-- <div class="flex flex-col mt-20 max-lg:px-5 max-lg:mt-12">
      <h3 class="font-bold">나의 최근 결제 내역</h3>
      <div class="table_grid_container">
        <table class="table_grid_wrapper">
          <tr class="table_grid_header">
            <th>구분</th>
            <th>주문번호</th>
            <th>주문명</th>
            <th>주문일자</th>
            <th>결제금액</th>
            <th>결제수단</th>
            <th>결제상태</th>
            <th>영수증</th>
          </tr>
          <tr class="table_grid_body">
            <td>콘텐츠</td>
            <td>230808101615317854</td>
            <td>{{ truncateText() }}</td>
            <td>23.06.14 15:41</td>
            <td>34,000원</td>
            <td>무통장 입금</td>
            <td>결제 완료</td>
            <td>
              <button type="button">영수증 출력</button>
            </td>
          </tr>
        </table>
      </div>
      
      <button class="w-1/6 max-lg:w-1/3 border border-neutral-300 text-neutral-400 p-2 text-sm mx-auto">
        더보기
      </button>
    </div> -->
  </main>
</template>

<script setup>
import store from "@/store"
import router from "@/router"
import { VDialog } from 'vuetensils/src/components';

import MemberProfilePage from "@/pages/MemberProfilePage.vue"

const orderTitle = '하나바이블 3과정 1학기 2022 총회 구역예배 인도자 강의';
const maxLength = 25;

const truncateText = () => {
  return orderTitle.length > maxLength ? orderTitle.substring(0, maxLength) + '...' : orderTitle;
};

const logout = () => {
  localStorage.removeItem('authorization');
  store.commit('setAccount', 0);
  store.commit('setLevel', 0);
  store.commit('setUid', '');
  router.push({ path: '/' });
};
</script>
