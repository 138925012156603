<template>
  <div class="lg:flex flex-1" >
    <LNavComponent />
    <myCancelList />
  </div>
</template>

<script>
import LNavComponent from "@/components/LNavComponent.vue"
import myCancelList from "@/pages/myCancelListPage.vue"
//import EduListRegisterPage2 from "@/pages/EduListRegisterPage2.vue"

export default {
  name: "cancelListView",
  components: {myCancelList, LNavComponent}
}
</script>

<style scoped>

</style>