import { createStore } from 'vuex'

export default createStore({
  state: {
    account: {
      int: 0
    },
    level: {
      int: 0
    },
    uid: {
      String: ''
    }
  },
  getters: {
  },
  mutations: {
    setAccount(state, payload) {
      state.account.id = payload
    },
    setLevel(state, level) {
      state.level.id = level
    },
    setUid(state, uid) {
      state.uid.id = uid
    }
  },
  actions: {
  },
  modules: {
  }
})
