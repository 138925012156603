<template>
  <section class="bg-white dark:bg-gray-900">
    <div class="lg:py-16 px-4 mx-auto max-w-screen-md text-center">
      <img src="@/assets/img/logo.png" alt="총회교육개발원" class="mx-auto mb-4"/>
      <p class="font-medium mb-2 text-[4vw] break-keep">{{ conf_detail.title }}</p>
      <p class="font-medium mb-2 text-[4vw] break-keep"> {{ conferenceInfo.title }} - {{dept}} </p>
      <p class="mb-10 text-[4vw] break-keep" v-if="roomName"> 배정강의실 : <span class="font-medium">{{roomName}}</span> </p>

      <vue3-barcode :value="theValue" :height="50" v-if="is_reg" class="flex justify-center"/>

      <div>
        <div class="mt-8">

          <div v-if="conferenceInfo.number === 4 || conferenceInfo.number === 6" class="mb-8">
            <a href="/order.pdf" class="text-4xl text-red-500 font-bold" target="_blank"> [ 순서지 PDF 다운로드 ] </a>
          </div>

          <div class="conf_info text-left">
            <div class="conf_info_tt">장소</div>
            <p>{{ conferenceInfo.address }}</p>
            <p class="font-medium"> {{ conferenceInfo.place }} </p>
          </div>
          <div class="conf_info text-left">
            <div class="conf_info_tt">일시</div>
            <p>{{ conferenceInfo.date }}</p>
          </div>
          <div class="conf_info text-left">
            <div class="conf_info_tt">시간표</div>
            <p class="mb-2" v-for="schedule in conferenceInfo.schedule">
              {{ schedule[0] }}
              <span class="ml-2">{{ schedule[1] }}</span>
            </p>
          </div>
          <div class="conf_info text-left">
            <div class="conf_info_tt">강의실 안내</div>
            <p class="mb-2" v-for="room in conferenceInfo.room">
              {{ room[0] }} - <b>{{ room[1] }}</b>
            </p>
          </div>
        </div>
      </div>

      <div class="conf_info text-left" v-if="conferenceInfo.number === 4 || conferenceInfo.number === 6">
        <div class="conf_info_tt">주차안내</div>
        <div >
          <ul class="conf_parking">
            <li>
              1. 주차는 <b>지하 3층</b>에 해주시고<br />
              <b>신용산교회</b> 이름이 쓰여진 <b>엘리베이터룸</b>을<br />
              이용하여 <b>지하 1층</b>으로 올라와 주시기 바랍니다.
            </li>
            <li>
              2. 주차는 <b>8시간까지 가능</b>합니다.<br />
              꼭 지하 1층 로비에서 <b>주차 기록 양식에 작성</b>하셔야<br />
              <b>무료 주차 혜택</b>을 받으실 수 있습니다.<br />
              <b>(등록시 꼭 기록해 주시기 바랍니다)</b>
            </li>
            <li>
              3. 자세한 주차 안내는<br />
              신용산교회 홈페이지(www.syschurch.org)<br />
              '<b>교회소개 - 오시는 길</b>'<br />
              하단 주차 안내 영상을 참고해 주시기 바랍니다.<br />
              * 유튜브 영상 링크 : <a href="https://youtu.be/l7jHn7hDUuc" target="_blank">https://youtu.be/l7jHn7hDUuc</a>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import axios from "axios";
import { useRoute, useRouter } from 'vue-router'
import Vue3Barcode from 'vue3-barcode'


export default {
    name: "barcodeComponent",

    components: {
        Vue3Barcode
    },
    data() {
        return {
          theValue : "",
          is_reg : false,
          conf_detail : {},
          local: "",
          number: 0,
          dept: "",
          roomName: "",
          conf_idx : 0,

          conferenceInfo: {},
          conferenceInfoList: [
            /*
            {
              number: 1,
              title: "1차 대전",
              date: "24년 5월 13일(월) 오후 7시",
              place: "판암장로교회 본당",
              address: "대전광역시 동구 옥천로176번길 35",
              schedule: [
                ["18:00-19:00", "접수"],
                ["19:00-19:30", "예배"],
                ["19:30-19:40", "주제 찬양 배우기"],
                ["19:40-20:10", "총주제 해설 강의"],
                ["20:10-20:20", "휴식 및 강의실 이동"],
                ["20:20-21:50", "부서별 강의"]
              ],
              room: [
                ["영유아부", "홀리랜드(지하 1층)"],
                ["유치부", "본당(3층)"],
                ["유년부", "비전홀(선교비전센터 지하 1층)"],
                ["초등부", "중등부실(선교비전센터 4층)"],
                ["청소년부", "고등부실(선교비전센터 5층)"],
                ["찬양율동", "꿈땅홀(선교비전센터 지하1층)"]
              ]
            },
            {
              number: 2,
              title: "2차 광주",
              date: "24년 5월 14일(화) 오후 7시",
              place: "광주동산교회 본당(2층)",
              address: "광주광역시 북구 동문대로 167번길 45",
              schedule: [
                ["18:00-19:00", "접수"],
                ["19:00-19:30", "예배"],
                ["19:30-19:40", "주제 찬양 배우기"],
                ["19:40-20:10", "총주제 해설 강의"],
                ["20:10-20:20", "휴식 및 강의실 이동"],
                ["20:20-21:50", "부서별 강의"]
              ],
              room: [
                ["영유아부", "4층 새싹홀"],
                ["유치부", "4층 지혜홀"],
                ["유년부", "1층 그레이스홀"],
                ["초등부", "2층 글로리홀(본당)"],
                ["청소년부", "지하 식당"],
                ["찬양율동", "2층 찬양대 연습실"]
              ]
            },
            {
              number: 3,
              title: "3차 대구",
              date: "24년 5월 16일(목) 오후 7시",
              place: "대구동신교회 비전관 3층 생명홀",
              address: "대구광역시 수성구 교학로4길 39",
              schedule: [
                ["18:00-19:00", "접수"],
                ["19:00-19:30", "예배"],
                ["19:30-19:40", "주제 찬양 배우기"],
                ["19:40-20:10", "총주제 해설 강의"],
                ["20:10-20:20", "휴식 및 강의실 이동"],
                ["20:20-21:50", "부서별 강의"],
              ],
              room: [
                ["영유아부", "비전관 1층 기쁨홀"],
                ["유치부", "비전관 4층 소망홀"],
                ["유년부", "비전관 2층 사랑홀"],
                ["초등부", "비전관  2층 믿음홀"],
                ["청소년부", "비전관 3층 305호"],
                ["찬양율동", "본관 1층 다니엘홀"],
              ]
            },
            {
              number: 4,
              title: "4차 서울",
              date: "24년 5월 18일(토) 오전 10시",
              place: "신용산교회 은혜채플(지하 1층)",
              address: "서울특별시 용산구 서빙고로 17",
              schedule: [
                ["09:00-10:00", "접수"],
                ["10:00-10:30", "예배"],
                ["10:30-10:40", "주제 찬양 배우기"],
                ["10:40-11:10", "총주제 해설 강의"],
                ["11:10-11:20", "휴식 및 강의실 이동"],
                ["11:20-12:50", "부서별 강의"],
              ],
              room: [
                ["영유아부", "예사랑채플(4층, 영아부실)"],
                ["유치부", "은혜채플(B1)"],
                ["유년부", "비전채플(2층)"],
                ["초등부", "JOY채플(3층, 초등부실)"],
                ["청소년부", "예삶채플(3층, 유년부실)"],
                ["찬양율동", "예꿈채플(3층, 유치부실)"],
              ]
            },
            {
              number: 5,
              title: "5차 서울",
              date: "24년 5월 25일(토) 오전 10시",
              place: "충현교회 갈릴리홀(본당 지하 1층)",
              address: "서울특별시 강남구 테헤란로27길 40 (역삼동)",
              schedule: [
                ["09:00-10:00", "접수"],
                ["10:00-10:30", "예배"],
                ["10:30-10:40", "주제 찬양 배우기"],
                ["10:40-11:10", "총주제 해설 강의"],
                ["11:10-11:20", "휴식 및 강의실 이동"],
                ["11:20-12:50", "부서별 강의"],
              ],
              room: [
                ["영유아부", "1교육관 4층 401호"],
                ["유치부", "1교육관 3층 304호"],
                ["유년부", "1교육관 3층 301호"],
                ["초등부", "1교육관 4층 504호"],
                ["청소년부", "1교육관 5층 501호"],
                ["찬양율동", "갈릴리홀(본관 지하)"],
              ]
            },
            {
              number: 6,
              title: "추가 서울",
              date: "24년 5월 18일(토) 오후 2시",
              place: "신용산교회 은혜채플(지하 1층)",
              address: "서울특별시 용산구 서빙고로 17",
              schedule: [
                ["13:30-14:00", "접수"],
                ["14:00-14:30", "총주제 해설 강의"],
                ["14:30-16:00", "부서별 강의"],
              ],
              room: [
                ["영유아부", "비전채플(2층)"],
                ["유치부", "은혜채플(B1)"],
                ["유년부", "예꿈채플(3층, 유치부실)"],
                ["초등부", "JOY채플(3층, 초등부실)"],
              ]
            },
            */
            {
              number: 1,
              title: "하나님 나라 백성의 열매",
              date: "24년 6월 3일(월) 오후 7시",
              place: "삼일교회 B관 1층 소예배실",
              address: "서울시 용산구 청파로 304",
              schedule: [
                ["18:00-19:00", "접수"],
                ["19:00-19:30", "예배"],
                ["19:30-19:40", "홍보 영상 및 찬양 배우기"],
                ["19:40-20:10", "부서별 사례 발표"],
                ["20:10-21:10", "4-2학기 교육과정특강(박재은교수)"],
                ["21:10-21:20", "광고 및 이동"],
                ["21:20-22:00", "부서별 강의"],
              ],
              room: [
                ["영유아부", "402호"],
                ["유치부", "405호"],
                ["유년부", "소예배실"],
                ["초등부", "503호"],
                ["청소년부", "701호"],
                ["대학청년부", "703호"],
              ]
            },
          ]
        }
    },

    methods: {
        init(){
            axios.get('/api/register/conference/barcode/' + this.theValue).then(response => {
                const data = response.data;
                if(data.err_code==0 || data.err_code==100){
                    console.log(data.conferenceDetail);
                    this.is_reg = true;
                    this.conf_detail = data.conferenceDetail.conference;
                    this.local = data.conferenceDetail.local;
                    this.number = data.conferenceDetail.number;
                    console.log(this.number);

                    this.dept = data.conferenceDetail.dept;
                    this.roomName = data.registerConference.roomname;
                    this.conf_idx = data.registerConference.conferenceIdx;

                    this.conferenceInfo = this.conferenceInfoList.find(item => item.number === this.number);
                }else{
                    alert(data.err_msg);
                }
                
            }).catch(error => {
                console.error(error)
            })
        },
    },
    mounted() {
        const route = useRoute()
        this.theValue = route.params.idx;
        this.init();
        // console.log(this.theValue);
    }
}
</script>

<style scoped>
.conf_info {
  margin-bottom: 2.5rem;
}
.conf_info p {
  font-size: 4vw;
  padding: 0 0.5rem;
}
.conf_info_tt {
  background: #f2faff;
  border: 1px solid #039dff;
  color: #039dff;
  text-align: center;
  font-size: 4.25vw;
  font-weight: 500;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
}

b {
  font-weight: 500;
}

.conf_parking li {
  margin-bottom: 0.5rem;
}
.conf_parking a {
  color: #039dff;
}
.conf_parking b {
  font-weight: 700;
}
</style>