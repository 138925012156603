<template>
    <div class="text-sm my-4">
        <h2 class="font-medium text-center">개인정보취급방침</h2>
        <p class="font-light mt-10 px-4">
            총회교육개발원(이하 "회사" 또는 "총회교육개발원 서비스"라 함)는 정보통신망이용촉진및정보보호등에관한법률(이하 “정보통신망법”) 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.
        </p>
        <div class="p-4">
            <h6 class="font-medium mt-5">제 1장. 수집하는 개인정보의 항목 및 수집방법</h6>
            <p class="font-light my-2">회사는 회원 가입, 서비스제공, 기타상담 등을 위해 개인정보를 수집하고 있으며, 또한 설문조사나 이벤트 시에 집단적인 통계분석을 위해서나 경품발송을 위한 목적으로도 개인정보 기재를 요청할 수 있습니다. 그러나, 회사는 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록 등)는 가급적 수집하지 않으며 부득이하게 수집해야 할 경우 이용자들의 사전동의를 반드시 구합니다. 이때에도 기입하신 정보는 해당서비스 제공이나 회원님께 사전에 밝힌 목적 이외의 다른 어떠한 목적으로도 사용되지 않음을 알려드립니다.
                개인정보의 수집 내용은 아래와 같습니다.</p>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                - 웹(홈페이지) 필수항목
                이름, 로그인ID, 로그인PASSWORD,이메일주소,휴대폰번호

                - 모바일앱(더하나바이블앱) 필수항목
                이름, 로그인ID, 로그인PASSWORD,이메일주소

                - 웹(홈페이지) 선택항목
                주소,교회명

                - 모바일앱(더하나바이블앱) 선택항목
                휴대폰번호,주소,교회명

                - 개인정보 수집방법
                웹(홈페이지), 모바일앱(하나바이블앱)
            </pre>

            <h6 class="font-medium mt-5">제 2장. 개인정보의 수집 및 이용목적</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                회사는 아래와 같은 목적으로 개인정보를 수집합니다.

                - 서비스 제공에 관한 계약이행 및 서비스제공에 따른 요금정산
                 콘텐츠 제공, 물품배송, 구매 및 요금결제
                
                - 회원관리
                회원제 서비스 이용에 따른 본인 확인, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용방지, 가입 의사 확인 연령 확인, 만 14세 미만 아동의 개인정보 수집 시 법정 대리인 동의여부 확인, 불만처리 등 민원처리, 고지사항 전달.

                - 서비스 안내등 마케팅 및 광고
                신규 서비스 개발 및 특화, 인구통계학적 특성에 따른 서비스 제공 및 광고게재, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계, 이벤트 등 광고성 정보 전달
            </pre>

            <h6 class="font-medium mt-5">제 3장. 개인정보 수집에 대한 동의</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                회사는 회원님의 개인정보 수집에 대하여 동의를 받고 있으며, 회사 내의 회원가입 절차 중 이용약관 및 개인정보취급방침에 개인정보 수집 동의절차를 마련해 두고 있습니다.
                회원님께서[개인정보활용 동의]에 체크하시면 개인정보 수집에 대해 동의한 것으로 봅니다.
            </pre>

            <h6 class="font-medium mt-5">제 4장. 개인정보의 보유 및 이용 기간</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                서비스 이용자가 당사의 회원으로서 서비스를 계속 이용하는 동안 당사는 이용자의 개인정보를 계속 보유하며 서비스 제공 등을 위해 이용합니다.
                서비스 이용자의 개인정보는 그 수집 및 이용 목적(설문조사, 이벤트 등 일시적인 목적을 포함)이 달성되거나 이용자가 직접 삭제 또는 회원 탈퇴한 경우에 재생할 수 없는 방법으로 파기됩니다.
                당사는 이용자의 권리 남용, 악용 방지, 권리침해/명예훼손 분쟁 및 수사협조 등의 요청이 있었을 경우에는 이의 재발에 대비하여 회원의 이용계약 해지 시로부터 1년 동안 회원의 개인정보를 보관할 수 있습니다.
                상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 당사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 당사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
                
                가. 회사 내부 방침에 의한 정보보유 사유
                회원탈퇴 시 개인정보 보존기간은 아래와 같습니다.
                보존근거 : 불량 이용자의 재가입 방지, 명예훼손 등 권리침해 분쟁 및 수사협조
                보존기간 : 회원탈퇴 후 1년
                
                나. 관련법령에 의한 정보보유 사유
                상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
                
                - 계약 또는 청약철회 등에 관한 기록
                보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
                보존 기간 : 5년

                - 대금결제 및 재화 등의 공급에 관한 기록
                보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
                보존 기간 : 5년

                - 소비자의 불만 또는 분쟁처리에 관한 기록
                보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
                보존 기간 : 3년

                - 본인확인에 관한 기록
                보존 이유 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률
                보존 기간 : 6개월

                - 방문에 관한 기록
                보존 이유 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률
                보존 기간 : 3개월
            </pre>

            <h6 class="font-medium mt-5">제 5장. 개인정보의 파기절차 및 방법</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                회원님은 언제든지 등록되어 있는 회원님의 개인정보를 열람하거나 정정하실 수 있으며, 회원 탈퇴를 요청 하실 수 있습니다. 회원님의 개인정보 열람 및 수정은 사이트 내의 회원정보변경을 통해 로그인(Log-in) 후 가능하며, 아이디를 제외한 사항은 수정 가능합니다.
                회원 탈퇴를 원하실 경우 사이트 내에서 탈퇴가 가능하며, 등록된 모든 정보를 파기함으로 탈퇴 여부를 신중히 고려 하셔야 합니다. 또한 탈퇴 후 재가입에 제약이 따를 수 도 있습니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
                
                - 파기절차
                이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.
                동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
                
                - 개인정보 데이터 파기방법
                종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 완전히 삭제합니다.
            </pre>

            <h6 class="font-medium mt-5">제 6장. 이용자 및 법정대리인의 권리와 그 행사방법</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                이용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수 도 있습니다.
                이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 ‘회원정보수정’(또는 ‘회원정보변경’ 등)을 가입해지를 위해서는 ‘회원탈퇴’ 를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
                혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
                이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리 결과를 제 3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
                회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 “개인정보의 보유 및 이용기간” 에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
            </pre>

            <h6 class="font-medium mt-5">제 7장. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                쿠키(cookie)는 HTTP 서버에서 사용자 브라우저에게 보내는 조그마한 데이터 파일로써 회원님의 컴퓨터에 저장됩니다.
                회사는 이러한 쿠키(cookie)를 이용하여 회원님의 브라우저 형식이나 서비스 이용 형태를 얻게 되며, 이러한 쿠키 정보를 바탕으로 회원님께 유용하고 보다 편리한 맞춤서비스를 제공하는데 사용하게 됩니다.
                회원님은 쿠키에 대한 선택권을 가지고 있습니다. 회원님의 웹브라우저에서 옵션을 선택함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
                단, 쿠키의 저장을 거부하실 경우 로그인 (Log-in) 이 필요한 회사의 모든 서비스는 이용하실 수 없게 됩니다.
            </pre>

            <h6 class="font-medium mt-5">제 8장. 개인정보보호를 위한 기술적, 관리적 대책</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                회원님의 개인정보는 비밀번호에 의해 보호되고 있습니다.
                회원님 계정의 비밀번호는 오직 본인만이 알 수 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 따라서 회원님의 비밀번호는 누구에게도 알려주면 안됩니다.
                또한 작업을 마치신 후에는 로그아웃(log-out)하시고 웹브라우저를 종료하는 것이 바람직합니다. 특히 다른 사람과 컴퓨터를 공유하여 사용하거나 공공장소에서 이용한 경우 개인정보가 다른 사람에게 알려지는 것을 막기 위해서 이와 같은 절차가 더욱 필요합니다.
                이용자 부주의나 인터넷상의 문제로 인한 개인정보 유출에 대해서 회사는 책임을 지지 않습니다.
                회사는 개인정보 취급 직원을 최소화하고 담당 직원의 수시교육을 통해 개인정보 보호 정책의 준수를 강조하고 있습니다.
                회사는 개인정보보호를 위한 기술적 관리적 대책을 다음과 같이 시행하고 있습니다.

                - 해킹 방지를 위한 방화벽과 보안 시스템에 만전을 기하고 있습니다.
                - 개인정보 송수신시 SSL 보안서버 인증서를 설치하여 정보를 보호합니다.
                - 개인정보에의 접근은 해당 업무 수행자, 업무 수행 시 개인정보 취급이 불가피한 자로 제한하여 그 이외의 직원이 접근하지 못하도록 하고 있습니다.
            </pre>

            <h6 class="font-medium mt-5">제 9장. 아동의 개인정보</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                회사는 원칙적으로 14세 미만 아동의 개인정보를 수집하지 않습니다. 다만 특별한 경우에 법정대리인의 동의를 얻어 아동의 개인정보를 수집할 수 있습니다.
                회사는 회원님의 개인정보를 보호하기 위하여 최선을 다하고 있습니다. 그러나 이러한 노력 이외에 회원님 스스로도 제 3 자에게 비밀번호 등이 노출되지 않도록 주의하셔야 합니다. 특히 비밀번호 등이 공공장소에 설치한 PC를 통해 유출되지 않도록 항상 유의하시기 바랍니다.
                또한 회사는 어떠한 경우에도 전화나 E-mail을 통해 사용자의 비밀번호를 묻지 않습니다. 그러므로 본인의 ID와 비밀번호는 꼭 본인만 사용하시고, 비밀번호를 자주 바꿔주시는 것이 좋습니다.
                회사는 개인정보보호에 최선을 다하지만, 회원님 개인 실수나 회사의 과실에 기인하지 않는 개인 정보 유출에 대해서는 책임을 지지 않습니다.
            </pre>

            <h6 class="font-medium mt-5">제 10장. 개인정보 관리 책임자</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                회사 개인정보취급방침과 관련하여 의견이 있을 경우 메일을 주시면 접수 즉시 조치하고 처리결과를 알려드리겠습니다. 개인정보관리책임자와 담당자는 아래와 같습니다.

                개인정보 관리 책임자
                - 이름 : 문성광
                - 소속 : 총회교육개발원
                - 직위 : 목사
                - 전화 : 02)559-5643
                - E-mail : eduwind.org@gmail.com
            </pre>

            <h6 class="font-medium mt-5">제 11장. 고객센터 안내</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                회사는 민원 처리를 위해 고객센터를 아래와 같이 운영하고 있습니다.

                - 근무시간 : 평일 09:30~18:00 (토, 일요일, 공휴일 휴무)
                - 전화번호 : 02-559-5643
                - 주소 : 서울특별시 강남구 영동대로 330 (대치동) 총회회관 6층 총회교육개발원 개인정보취급 담당자 앞
            </pre>

            <h6 class="font-medium mt-5">&#60;부칙&#62;</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                기존 개인정보보호정책은 개인정보취급방침으로 개정합니다.
                <p class="mt-3">개인정보취급방침 시행(변경)일자 : 2024년 04월 17일</p>
            </pre>
        </div>
    </div>
</template>

<script>
</script>