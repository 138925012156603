<template>
  <div class="w-full mx-auto">
    <h1 class="font-bold text-center mt-8 mb-3"> 주문서 작성 </h1>
  </div>

  <main class="w-full cartBackground">

    <div class="w-full max-w-[1200px] mx-auto mb-[70px]">

      <!-- 상품 -->
      <div id="goodsCart" class="max-lg:px-5 mt-5" v-if="productCount > 0">
        <h3 class="mb-2 pl-2 cursor-pointer" @click="productListToggle">구매 할 상품 - <b>{{ productCount }}</b> 건</h3>

        <div v-if="productListToggleSwitch" class="cartDivisionBox" v-for="(warehouse, wareKey) in productCartInfo.warehouses">
          <div class="flex justify-between items-center">
            <div class="flex items-center">
              <span class="w-fit check_btn font-bold text-2xl">&nbsp;&nbsp;&nbsp;{{ warehouse.name ?? "출고지" }}</span>
              <span class="text-gray-600 text-sm ml-2" v-if="warehouse.deliveryFreeOption>0">
                ( {{ warehouse.deliveryFreeOption.toLocaleString('ko-kr') }}원 이상 무료 배송 )
              </span>
            </div>
          </div>

          <div class="cart_grid_container">

            <!-- 상품 목록 -->
            <div class="cart_grid_wrapper mt-2">
              <div class="cart_grid_header2">
                <!-- 선택 체크/체크해제 저장되도록 수정 필요 -->
                <div></div>
                <div>상품정보</div>
                <div>옵션</div>
                <!-- <div>수량</div> -->
                <div>주문 금액</div>
              </div>

              <div class="cart_grid_body2" v-for="product in warehouse.products" :key="product.itemId">
                <div class="w-20 h-14 flex items-center justify-center border border-neutral-200 rounded-xl overflow-hidden">
                  <router-link :to="'/mall/view/' + product.itemId" class="flex justify-center items-center w-full h-full">
                    <img v-if="product.thumbnailLink" :src="'https://eduwind-api.nodeblack.net/' + product.thumbnailLink" class="w-full h-full object-cover"/>
                    <img v-else src="@/assets/img/null_img.jpg"/>
                  </router-link>
                </div>
                <div>
                  <div class="text-left">
                    <!-- <p class="text-sky-500 max-lg:text-sm">2023 겨울성경학교 청소년부</p> -->
                    <router-link :to="'/mall/view/' + product.itemId"  class="text-xl max-lg:text-sm">{{product.title}}</router-link>
                  </div>
                </div>
                <div>
                  <div class="justify-center">
                    <!-- 옵션 -->
                    <div v-if="product.cartOptionList.length > 0">
                      <ul>
                        <li v-for="option in product.cartOptionList" class="productOptionList">
                          <div>
                            <span v-for=" ( pathName, pathIndex ) in option.optionPathNames ">
                              <span> {{ pathName }} </span>
                              <span v-if="pathIndex < (option.optionPathNames.length-1)"> > </span>
                            </span>
                          </div>
                          <span class="px-2"> / </span>
                          <div> {{ option.quantity }} </div>
                          <span class="px-2"> / </span>
                          <div> {{ option.price }}원 </div>
                        </li>
                      </ul>
                    </div>
                    <div v-else>
                      수량 : {{ product.productStock }}
                    </div>
                  </div>
                </div>

                <div class="product_price flex-col">
                  <div v-if="product.isFree === true">
                    무료
                  </div>
                  <div v-else>
                    {{product.purchasePrice.toLocaleString('ko-kr')}}원
                  </div>
                </div>
              </div>
            </div>


            <div class="p-5 bg-neutral-100">
              <div class="productPriceInfo">
                <div class="productPriceItem">
                  <div class="itemTitle"> 총 상품 금액 </div>
                  <div class="itemPrice">
                    <!-- TODO: B013 - 주무서 목록 페이지 : 총 상품 금액 확인  -->
                    <b>{{ warehousePirceInfo[wareKey].warehouseProductPrice.toLocaleString('ko-kr') }}</b>  원
                  </div>
                </div>

                <div class="productPriceItem">
                  <div class="itemTitle"> 할인 금액 </div>
                  <div class="itemPrice">
                    <b>{{ warehousePirceInfo[wareKey].warehouseDiscountAmount.toLocaleString('ko-kr') }}</b> 원
                  </div>
                </div>

                <!--
                <div class="productPriceItem">
                  <div class="itemTitle"> 구매가 </div>
                  <div class="itemPrice">
                    {{ warehouse.productOrderPurchasePrice.toLocaleString('ko-kr') }} 원
                  </div>
                </div>
                -->

                <div class="productPriceItem">
                  <div class="itemTitle"> 배송비 </div>
                  <div class="itemPrice">
                    <b>{{ warehousePirceInfo[wareKey].deliveryFee.toLocaleString('ko-kr') }}</b> 원
                  </div>
                </div>

                <div> | </div>

                <div class="w-[200px] flex justify-end items-center">
                  <div class="text-sm mr-3"> 결제금액 </div>
                  <div class="text-xl">
<!--                    <b class="text-2xl text-red-500"> {{ warehouse.productPaidAmount.toLocaleString('ko-kr') }} </b> 원-->
                    <b class="text-2xl text-red-500"> {{warehousePirceInfo[wareKey].warehouseTotalPrice.toLocaleString('ko-kr') }} </b> 원
                  </div>
                </div>

<!--                <div>
                  총 주문금액
                  <h4 class="ml-2 font-semibold text-red-500">{{ warehouse.productPaidAmount.toLocaleString('ko-kr') }}</h4>
                  <b class="ml-1">원</b>
                </div>-->
              </div>
            </div>

            <div>
              <div class="flex items-center justify-center">
                <input
                  type="text"
                  class="w-full border border-neutral-200 rounded-lg p-2 mt-2"
                  placeholder="촐고지별 배송 메시지"
                  v-model="warehouse.requestMessage"
                />
              </div>
            </div>
          </div>
        </div>
      </div >

      <!-- 콘텐츠 -->
      <div id="contentsCart" class="max-lg:px-5 mt-10" v-if="contentCount > 0">
        <h3 class="mb-2 pl-2 cursor-pointer" @click="contentListToggle">구매 할 콘텐츠 - <b>{{ contentCount }}</b> 건</h3>

        <div v-if="contentListToggleSwitch" class="cartDivisionBox">

          <div class="flex justify-between items-center mt-4">
          </div>

          <div class="cart_grid_container">
            <div class="cart_grid_wrapper mt-2">
              <div class="cart_grid_header2">
                <!-- 선택 체크/체크해제 저장되도록 수정 필요 -->
                <div></div>
                <div>상품정보</div>
                <div>유형</div>
                <!-- <div>수량</div> -->
                <div>주문 금액</div>
              </div>
              <div class="cart_grid_body2" v-for="item in contentAvailableCartList" :key="item.cartId">
                <div class="w-20 h-14 flex items-center justify-center border border-neutral-200 rounded-xl overflow-hidden">
                  <router-link :to="'/mall/view/' + item.itemId" class="flex justify-center items-center w-full h-full">
                    <img v-if="item.thumbnailLink" :src="'https://eduwind-api.nodeblack.net/' + item.thumbnailLink" class="w-full h-full object-cover"/>
                    <img v-else src="@/assets/img/null_img.jpg"/>
                  </router-link>
                </div>
                <div>
                  <div class="text-left">
                    <!-- <p class="text-sky-500 max-lg:text-sm">2023 겨울성경학교 청소년부</p> -->
                    <router-link :to="'/mall/view/' + item.itemId"  class="text-xl max-lg:text-sm">{{item.title}}</router-link>
                  </div>
                </div>
                <div>
                  <div class="product_tags justify-center">
                    <span>{{item.itemKind}}</span>
                  </div>
                </div>
                <!-- <div>
                  <div class="flex items-center justify-center">
                    <span  class="w-6"> {{item.quantity }} </span>
                    <button @click="increaseQuantity(item)" class="mr-1">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="white" stroke="#DEE0E3"/>
                        <path d="M9.00024 3.85718V14.1429" stroke="#1B1D21" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M3.85742 9L14.1431 9" stroke="#1B1D21" stroke-width="1.5" stroke-linecap="round"/>
                      </svg>
                    </button>
                    <button @click="decreaseQuantity(item)">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="white" stroke="#DEE0E3"/>
                        <path d="M3.85718 9H14.1429" stroke="#898C92" stroke-width="1.5" stroke-linecap="round"/>
                      </svg>
                    </button>
                  </div>
                </div> -->

                <div class="product_price flex-col">
                  <div v-if="item.isFree === true">
                    무료
                  </div>
                  <div v-else>
                    {{item.purchasePrice.toLocaleString('ko-kr')}}원
                  </div>
                </div>

              </div>
            </div>
            <div class="p-5 bg-neutral-100">

              <div class="productPriceInfo">
                <div class="productPriceItem">
                  <div class="itemTitle"> 총 상품 금액 </div>
                  <div class="itemPrice">
                    <b>{{ contentPriceInfo.contentPurchasePrice.toLocaleString('ko-kr') }}</b>  원
                  </div>
                </div>

                <div class="productPriceItem">
                  <div class="itemTitle"> 할인 금액 </div>
                  <div class="itemPrice">
                    <b>{{ contentPriceInfo.contentDiscountAmount.toLocaleString('ko-kr') }}</b> 원
                  </div>
                </div>

                <!--
                <div class="productPriceItem">
                  <div class="itemTitle"> 구매가 </div>
                  <div class="itemPrice">
                    {{ contentCartInfo.productOrderPurchasePrice.toLocaleString('ko-kr') }} 원
                  </div>
                </div>
                -->

                <!--
                <div class="productPriceItem">
                  <div class="itemTitle"> 배송비 </div>
                  <div class="itemPrice">
                    <b>{{ contentCartInfo.contentOrderDeliveryFee.toLocaleString('ko-kr') }}</b> 원
                  </div>
                </div>
                -->

                <div> | </div>

                <div class="w-[200px] flex justify-end items-center">
                  <div class="text-sm mr-3"> 결제금액 </div>
                  <div class="text-xl">
                    <b class="text-2xl text-red-500"> {{ contentPriceInfo.contentPurchasePrice.toLocaleString('ko-kr') }} </b> 원
                  </div>
                </div>

<!--                <div>
                  총 주문금액
                  <h4 class="ml-2 font-semibold text-red-500">{{ warehouse.productPaidAmount.toLocaleString('ko-kr') }}</h4>
                  <b class="ml-1">원</b>
                </div>-->
              </div>

              <!--
              <div class="flex items-center justify-end">
                총 주문금액
                <h4 class="ml-2 font-semibold text-red-500">{{ getTotalPrice.toLocaleString('ko-kr') }}</h4>
                <b class="ml-1">원</b>
              </div>
              -->
            </div>
          </div>
        </div>


      </div>

      <div v-if="productCount <= 0 && contentCount <= 0">
        <div class="flex justify-center items-center h-96">
          <p class="text-2xl">
            장바구니가 비어있습니다.
          </p>
        </div>
      </div>

      <!-- 주문 버튼 -->
      <!--
      <div class="flex gap-2 justify-end max-lg:justify-center mt-5" v-if="productCount > 0 || contentCount > 0">

      </div>
      -->

      <div>

        <h3 class="mb-2 pl-2">주문자 정보</h3>
<!--        <div class="bg-neutral-100 p-6">-->
        <div class="cartDivisionBox">
          <div class="flex">
            <div class="flex items-center w-1/3">
              <b class="w-20">이름</b>
              <input type="text" class="regi_inputs" v-model="orderInfo.orderName" />
            </div>

            <div class="flex items-center w-1/3">
              <b class="w-20">연락처</b>
              <input type="text" class="regi_inputs" v-model="orderInfo.orderPhone" />
            </div>

            <div class="flex items-center w-1/3">
              <b class="w-20">이메일</b>
              <input type="text" class="regi_inputs" v-model="orderInfo.orderEmail" />
            </div>
          </div>
        </div>

      </div>
      <div>

        <!-- 실제 상품일 경우 -->
        <h3 class="mb-2 pl-2">
          배송지 정보
          <label class="text-base ml-5 inline-block items-center">
            <input type="checkbox" @change="sameOrderInfo">
            주문자 정보와 같습니다.
          </label>
        </h3>
<!--        <div class="bg-neutral-100 p-6">-->
        <div class="cartDivisionBox">
          <div class="flex">
            <div class="flex items-center mb-2 w-1/2">
              <b class="w-20">이름</b>
              <input type="text" class="regi_inputs" v-model="orderInfo.shippingName" />
            </div>

            <div class="flex items-center mb-2 w-1/2">
              <b class="w-20">연락처</b>
              <input type="text" class="regi_inputs" v-model="orderInfo.shippingPhone" />
            </div>
          </div>

          <div class="flex items-baseline">
            <b class="w-20">주소</b>
            <div class="addressAlign">
              <div>
                <input type="text" class="regi_inputs w-[6rem] text-center" placeholder="우편번호" readonly v-model="orderInfo.postcode" />
                <button class="sec_filled-blk_btn lg:px-5 px-2 py-2 mx-2" @click="openAddressSearch">주소찾기</button>
              </div>
              <div class="flex items-start">
                <span>
                  <input type="text" class="regi_inputs w-[20rem] mr-2" placeholder="기본주소" v-model="orderInfo.address" />
                </span>
                <span>
                  <input type="text" placeholder="상세주소를 입력해주세요" class="regi_inputs my-2 w-[20rem]" v-model="orderInfo.detailAddress" />
                </span>
              </div>
<!--              <label class="check_btn gap-1">
                <input type="checkbox"/>
                기본 배송지로 설정
              </label>-->
            </div>
          </div>

          <!--
          <div class="flex items-center mb-2">
            <b class="w-20">배송정보</b>
            <input type="text" class="regi_inputs w-[41rem]" v-model="orderInfo.shippingMemo" />
          </div>
          -->

        </div>

      </div>
      <div>

        <div v-if="orderInfo.totalPaidAmount > 0">
          <h3 class="mb-2 pl-2">결제 정보</h3>
          <div class="cartDivisionBox">
            <div class="flex">
              <div class="flex gap-4 py-3 w-1/2">
                <b>주문금액</b>
                <p v-if="orderInfo.totalPaidAmount">{{orderInfo.totalPaidAmount.toLocaleString('ko-kr')}}원</p>
              </div>
              <div class="flex gap-4 py-3 w-1/2">
                <b>결제수단</b>
                <select v-model="gopaymethod" @change="setPayMethod">
                  <option value="Card">신용카드</option>
                  <option value="DirectBank">실시간계좌이체</option>
                  <option value="VBank">가상계좌</option>
                </select>
              </div>
            </div>
          </div>
        </div>

      </div>


      <div>

        <div class="flex gap-2 justify-center max-lg:justify-center mt-5">
          <!-- /***************/ -->
          <!-- confirm 모달로 변경 ; 주문을 취소하시겠습니까? 작성된 내용은 저장되지 않습니다. -->
          <!-- /***************/ -->
          <router-link to="/mypage/cart" class="sec_btn !border-black p-4 w-1/6 max-lg:w-1/2">
            취소
          </router-link>

          <button @click="pcPaySubmit" class="max-md:hidden pri_btn pri_btn_bg p-4 w-1/6 max-lg:w-1/2">
            <span v-if="orderInfo.totalPaidAmount > 0">결제하기</span>
            <span v-else>주문하기</span>
          </button>

          <!-- mobile -->
          <button @click="mPaySubmit" class="md:hidden pri_btn pri_btn_bg p-4 w-1/6 max-lg:w-1/2">
            <span v-if="orderInfo.totalPaidAmount > 0">결제하기</span>
            <span v-else>주문하기</span>
          </button>
        </div>

      </div>

    </div>

  </main>



  <!-- pc -->
  <div v-if="checkoutInfo">
    <form method="post" action="https://nodeblack.net/api/pay/mall/v2/pc" target="PayForm" name="pcPayForm">
      <input type="hidden" id="buyername" name="buyername" v-model="orderInfo.orderName" />
      <input type="hidden" id="buyertel" name="buyertel" v-model="orderInfo.orderPhone" />
      <input type="hidden" id="buyeremail" name="buyeremail" v-model="orderInfo.orderEmail" />
      <input type="hidden" id="buyerAddress" name="buyerAddress" v-model="orderInfo.address" />
      <input type="hidden" id="buyerDetailedAddress" name="buyerDetailedAddress" v-model="orderInfo.detailAddress" />
      <input type="hidden" id="buyerZipCode" name="buyerZipCode" v-model="orderInfo.postcode" />
<!--      <input id="orderItemJson" name="orderItemJson" type="text" :value="JSON.stringify(orderItemJson)"/>-->
      <input type="hidden" id="cartIdSet" name="cartIdSet" :value="cartItemList" />
      <input type="hidden" id="gopaymethod" name="gopaymethod" v-model="gopaymethod" />
      <!-- token 은 submit 할 때 localstorage auth 값 받아서 보내기 -->
      <input type="hidden" id="token" name="token" v-model="token"/>
      <input type="hidden" id="deliveryRequestMessageJson" name="deliveryRequestMessageJson" v-model="deliveryRequestMessageJson" />
    </form>
  
    <!-- mobile -->
    <form method="post" action="https://nodeblack.net/api/pay/mall/v2/mobile" id="mPayForm" name="mPayForm">
      <input type="hidden" id="P_UNAME" name="P_UNAME" v-model="orderInfo.orderName" />
      <input type="hidden" id="P_MOBILE" name="P_MOBILE" v-model="orderInfo.orderPhone" />
      <input type="hidden" id="P_EMAIL" name="P_EMAIL" v-model="orderInfo.orderEmail" />
      <input type="hidden" id="P_buyerAddress" name="buyerAddress" v-model="orderInfo.address" />
      <input type="hidden" id="P_buyerDetailedAddress" name="buyerDetailedAddress" v-model="orderInfo.detailAddress" />
      <input type="hidden" id="P_buyerZipCode" name="buyerZipCode" v-model="orderInfo.postcode" />
<!--      <input type="hidden" id="orderItemJson" name="orderItemJson" :value="JSON.stringify(orderItemJson)"/>-->
      <input type="hidden" id="P_cartIdSet" name="cartIdSet" :value="cartItemList" />
      <input type="hidden" id="P_INI_PAYMENT" name="P_INI_PAYMENT" v-model="mobileGopaymethod" />
      <!-- token 은 submit 할 때 localstorage auth 값 받아서 보내기 -->
      <input type="hidden" id="P_token" name="token" v-model="token" />
      <input type="hidden" id="P_deliveryRequestMessageJson" name="deliveryRequestMessageJson" v-model="deliveryRequestMessageJson" />
    </form>
  </div>

  <iframe
    id="PayForm"
    name="PayForm"
    src="about:blank"
    style="display:none; width:100vw; height: 100vh;position: fixed; top: 0; left: 0; z-index: 9999;"
  />

<!--
src="about:blank"
style="display:none; width:100vw; height: 100vh;position: fixed; top: 0; left: 0; z-index: 9999;"
-->

<!--  <span @click="closeIFrame"> 호출 </span>-->

</template>

<script setup>
import axios from 'axios';
import {computed, onMounted, ref} from 'vue';
import router from "@/router";

import address from '@/assets/js/address.js';
// import { useStore } from 'vuex';
// const store = useStore();


const checkoutList = ref([]);
const checkoutInfo = ref([]);

let orderItemJson = [];
const gopaymethod = ref("Card");
const mobileGopaymethod = ref("Card");
const token = ref("");


const payForm = ref(null);


const orderInfo = ref({});           // 장바구니 가격 정보
const productCartInfo = ref([]);    // 상품 장바구니
const productCount = ref(0);        // 상품 장바구니 수량
const warehousePirceInfo = ref([]); // 출고지별 금액 정보
const contentCartInfo = ref([]);    // 콘텐츠 장바구니
const contentCount = ref(0);        // 콘텐츠 장바구니 수량
const contentPriceInfo = ref({})    // 콘텐츠 금액 정보


// 판매중지 상품 필터
const contentAvailableCartList = ref([]);
// const contentUnavailableCartList = ref([]);
const deliveryRequestMessageJson = ref([]); // 출고지별 배송 메시지


const closeIFrame = () => {
  alert('사용자 요청에 의해 결제가 취소 되었습니다.');
  // document.location.reload();
  router.go(0);
}

const freeContent = (data) => {
  try {
    if (data.moid) {
      const moid = data.moid;
      router.push(`/mypage/order_done/${moid}`);
    }
  } catch (error) {
    console.error(error);
  }
}

// 메세지 감지 - iframe으로부터의 메시지 수신
window.addEventListener('message', (event) => {
  // console.log('message', event.data);
  // console.log(typeof event.data);
  if( typeof event.data === 'string' ){
    const eventData = JSON.parse(event.data);
    if( eventData.mod === 'close' ){ // 이니시스 닫힘
      closeIFrame();
    } else if (eventData.mod === 'free') { // 무료결제
      freeContent(eventData);
    } else if (eventData.mod === 'exception') { // 실패
      alert(eventData.returnMsg);
      document.location.reload();
    }
  }
});


// 주문 할 상품, 콘텐츠 정보
const getOrderItemInfo = async () => {
  try {
    const { cartList } = JSON.parse(localStorage.getItem('cartIdSet')) || {cartIdSet: []};
    const url = "/new_api/customer/order/v2/form";
    const { data, status } = await axios.post(url, {cartIdSet: cartList});
    if( status === 200 ){
      if( data.code === '200' ){

        warehousePirceInfo.value = Array.from({ length: data.data.productInfo.warehouses.length }, () => ({}));
        // 상품 장바구니 체크ㅐㅜ
        orderInfo.value = {
          totalOrderRegularPrice: data.data.totalOrderRegularPrice,
          totalOrderDiscountAmount: data.data.totalOrderDiscountAmount,
          totalOrderPurchasePrice: data.data.totalOrderPurchasePrice,
          totalOrderDeliveryFee: data.data.totalOrderDeliveryFee,
          totalPaidAmount: data.data.totalPaidAmount,
          orderName: data.data.orderName,
          orderPhone: data.data.orderPhone,
          orderEmail: data.data.orderEmail,
          orderAddress: data.data.orderAddress,
          orderDetailedAddress: data.data.orderDetailedAddress,
          orderZipCode: data.data.orderZipCode,

          shippingName: "",
          shippingPhone: "",
          postcode: "",
          address: "",
          detailAddress: "",
          shippingMemo: "",


        };
        ({productInfo: productCartInfo.value, contentInfo: contentCartInfo.value} = data.data);   // 상품, 콘텐츠 장바구니
        productCount.value = productCartInfo.value.warehouses.flatMap(warehouse => warehouse.products).length;  // 상품 장바구니 수량
        contentCount.value = contentCartInfo.value.contents.length;     // 콘텐츠 장바구니 수량

        productCartInfo.value.warehouses.forEach(warehouse => {
          let productPrice = 0;
          warehouse.products.forEach(products => {
            products.cartOptionList.forEach(option => {
              productPrice += option.price * option.quantity;
            });
            products.sellingPrice = productPrice;
          });
          warehouse.requestMessage = null;
        });

        contentAvailableCartList.value = contentCartInfo.value.contents.filter(item => item.itemStatus !== "STOP");
        // contentUnavailableCartList.value = contentCartInfo.value.contents.filter(item => item.itemStatus === "STOP");



      }
      else {
        alert('장바구니를 불러오는데 실패했습니다.\n\n새로고침 후 다시 시도해 주세요.');
        // document.location.reload();
      }
    }
    else {
      alert('장바구니를 불러오는데 실패했습니다.\n\n새로고침 후 다시 시도해 주세요.');
      // document.location.reload();
    }
    /*cartList.value = res.data.data.cartList;
    console.log(cartList.value);*/
  } catch (err) {
    console.error(err);
  }
};

// 출고지 별 가격
const warehouseProductPrice = (wareKey) => {
  const productPrice = productCartInfo.value.warehouses[wareKey].products
      .filter((item) => item.isSelected)
      .reduce((total, item) => total + item.sellingPrice, 0);
  const productOrderDeliveryFee = productCartInfo.value.warehouses[wareKey].productOrderDeliveryFee;

  return productPrice + productOrderDeliveryFee;
};
const productPrice = computed(() => {
  if( productCartInfo.value.warehouses !== undefined ) {
    return productCartInfo.value.warehouses.flatMap(warehouse => warehouse.products).filter((item) => item.isSelected).reduce((total, item) => total + item.sellingPrice, 0);
  }
});
// 콘텐츠 가격
const contentPrice = computed(() => {
  return contentAvailableCartList.value
      .filter((item) => item.isSelected)
      .reduce((total, item) => total + item.purchasePrice, 0);
});

// 선택 상품 갯수
const selectedProductCount = computed(() => {
  if( productCartInfo.value.warehouses !== undefined ){
    return productCartInfo.value.warehouses.flatMap(warehouse => warehouse.products).filter((item) => item.isSelected).length;
  }
});
// 선택 콘텐츠 개수
const selectedContentCount = computed(() => {
  return contentAvailableCartList.value.filter((item) => item.isSelected).length;
});
// 선택 상품 + 선택 콘텐츠 가격
const selectedTotalPrice = computed(() => {
  return productPrice.value + contentPrice.value;
});

// 주문자 정보와 같음
const sameOrderInfo = ($event) => {
  if( $event.target.checked ){
    orderInfo.value.shippingName = orderInfo.value.orderName;
    orderInfo.value.shippingPhone = orderInfo.value.orderPhone;
  }
  else {
    orderInfo.value.shippingName = "";
    orderInfo.value.shippingPhone = "";
  }
};

const openAddressSearch = () => {
  address.execDaumPostcode(setAddress);
};
const setAddress = (data) => {
  orderInfo.value.postcode = data.postcode;
  orderInfo.value.address = data.address;
};
// 주문 목록
const cartItemList = ref("");


onMounted(async () => {
  await getOrderItemInfo();

  priceCalc();


  // 상품과 콘텐츠의 cartId 만
  const productCartIdList = productCartInfo.value.warehouses.flatMap(warehouse => warehouse.products).map(item => item.cartId);
  const contentCartIdList = contentAvailableCartList.value.map(item => item.cartId);
  // 결제 요청을 위한 장바구니 목록 만들기
  let cartIdList = contentCartIdList.concat(productCartIdList);
  console.log('cartIdList: ', cartIdList);
  cartItemList.value = cartIdList.join(',');


  // orderItemJson = checkoutList.value.map(item => ({itemId: item.itemId, quantity: item.quantity}));
  // console.log(orderItemJson);
});

// Price 계산
const priceCalc = () => {
  // 상품 - 출고지별 금액 계산
  productCartInfo.value.warehouses.forEach((warehouse, index) => {

    // 출고지별 선택된 상품의 총 상품 금액 계산
    const warehouseProductPrice = warehouse.products
    .filter((item) => item.isSelected)
    .reduce((total, item) => total + item.purchasePrice, 0);

    // 출고지별 선택된 상품의 할인 금액 계산
    const warehouseDiscountAmount = warehouse.products
    .filter((item) => item.isSelected)
    .reduce((total, item) => total + item.discountAmount, 0);

    const selectedProductCount = warehouse.products
    .filter((item) => item.isSelected).length

    // 출고지별 배송비 계산
    const deliveryFee = warehouseProductPrice >= warehouse.deliveryFreeOption || selectedProductCount == 0 ? 0 : warehouse.deliveryFee;  

    // 출고지별 선택된 상품의 결제금액 계산
    const warehouseTotalPrice = warehouseProductPrice + deliveryFee;

    warehousePirceInfo.value[index] = {
      warehouseProductPrice : warehouseProductPrice,
      warehouseDiscountAmount : warehouseDiscountAmount,
      deliveryFee : deliveryFee,
      warehouseTotalPrice : warehouseTotalPrice
      }
  })

  // 콘텐츠 - 금액 계산
  // 콘텐츠 총 상품 금액
  const contentPurchasePrice = contentCartInfo.value.contents
    .filter((item) => item.isSelected)
    .reduce((total, item) => total + item.purchasePrice, 0);
  
  // 콘텐츠 할인 금액 
  const contentDiscountAmount = contentCartInfo.value.contents
    .filter((item) => item.isSelected)
    .reduce((total, item) => total + item.discountAmount, 0);

  contentPriceInfo.value = {
    contentPurchasePrice: contentPurchasePrice,
    contentDiscountAmount: contentDiscountAmount
  }

}


// 결제 요청 - pc
const pcPaySubmit = async () => {

  // 유효성 검사
  if( orderInfo.value.orderName === "" ){
    alert("주문자 이름을 입력해 주세요.");
    return;
  }
  if( orderInfo.value.orderPhone === "" ){
    alert("주문자 연락처를 입력해 주세요.");
    return;
  }
  if( orderInfo.value.orderEmail === "" ){
    alert("주문자 이메일을 입력해 주세요.");
    return;
  }

  if( orderInfo.value.shippingName === "" ){
    alert("배송지 이름을 입력해 주세요.");
    return;
  }
  if( orderInfo.value.shippingPhone === "" ){
    alert("배송지 연락처를 입력해 주세요.");
    return;
  }
  if( orderInfo.value.postcode === "" ){
    alert("배송지 주소를 입력해 주세요.");
    return;
  }
  if( orderInfo.value.address === "" ){
    alert("배송지 주소를 입력해 주세요.");
    return;
  }
  if( orderInfo.value.detailAddress === "" ){
    alert("배송지 상세주소를 입력해 주세요.");
    return;
  }

  try {
    const localStorageToken = localStorage.getItem('authorization');

    if (localStorageToken) {
      token.value = localStorageToken;

      //token 값을 가져온 이후 실행되도록 처리
      await new Promise((resolve) => {
        document.querySelector('#PayForm').style.display = 'block';
        // window.open('', 'fPayPopForm', 'width=900,height=700');
        setTimeout(resolve, 500);
      });

      document.pcPayForm.submit();
    } else {
      console.error("토큰 값이 일치하지 않습니다.");
      alert("잘못된 요청입니다.");
      window.location.reload();
    }
  } catch (error) {
    console.error(error);
  }
};

const setPayMethod = () => {
  let mobileMethod;
  if( gopaymethod.value === 'Card' ){
    mobileMethod = "CARD";
  }
  else if( gopaymethod.value === 'DirectBank' ){
    mobileMethod = "BANK";
  }
  else if( gopaymethod.value === 'VBank' ){
    mobileMethod = "VBANK ";
  }
  mobileGopaymethod.value = mobileMethod;
};

const setDeliveryRequestMessageJson = () => {
  const requestMessageJson = [];
  productCartInfo.value.warehouses.forEach(warehouse => {
    const warehouseRequestMessage = {
      warehouseId: warehouse.warehouseId,
      requestMessage: warehouse.requestMessage
    };
    requestMessageJson.push(warehouseRequestMessage);
  });
  deliveryRequestMessageJson.value = JSON.stringify(requestMessageJson);
}

// 결제 요청 - mobile
const mPaySubmit = async () => {
  try {
    const localStorageToken = localStorage.getItem('authorization');

    if (localStorageToken) {
      token.value = localStorageToken;

      //token 값을 가져온 이후 실행되도록 처리
      await new Promise((resolve) => {
        document.querySelector('#PayForm').style.display = 'block';
        // window.open('', 'fPayPopForm', 'width=900,height=700');
        setTimeout(resolve, 500);
      });

      setDeliveryRequestMessageJson();

      setTimeout(() => {
        console.log(document.mPayForm);
        document.querySelector('#mPayForm').submit();
      }, 500);
      // document.querySelector('#mPayForm').submit();
      // document.mPayForm.submit();
    } else {
      console.error("토큰 값이 일치하지 않습니다.");
      alert("잘못된 요청입니다.");
      window.location.reload();
    }
  } catch (error) {
    console.error(error);
  }
};

const productListToggleSwitch = ref(true);
const productListToggle = () => {
  productListToggleSwitch.value = !productListToggleSwitch.value;
};
const contentListToggleSwitch = ref(true);
const contentListToggle = () => {
  contentListToggleSwitch.value = !contentListToggleSwitch.value;
};


</script>

<style scoped>
.cartBackground {
  background-color: #f9f9f9;
  @apply flex flex-col w-full;
}

.cartDivisionBox {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  padding: 1rem;
  border-radius: 16px;
  margin-bottom: 2rem;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.09);
}


.productOptionList {
  display: flex;
  align-items: baseline;
  padding: 0.5rem 0;
}

.productPriceInfo {
  @apply flex items-center justify-end gap-5;
}

.productPriceInfo .productPriceItem {
  @apply w-[100px];
}

.productPriceInfo .productPriceItem > .itemTitle {
  @apply text-sm;
}

.productPriceInfo .productPriceItem > .itemPrice {
  @apply text-xl;
}

.addressAlign {
  @apply flex items-center;
}
.addressAlign div {
  @apply items-center;
}
</style>