import {defineStore} from "pinia";
import axios from "axios";

export const useAppProductRequest = defineStore("appProductRequest", {
	state: () => {
    return {
      dialog: false,
      loading: false,
	    switchProductRequest: false,

	    requestOID: 0,
      requestOrderId: 0,
	    deliveryList: [],
      orderProductList: [],
	    productRequestList: [],

	    addPaymentOid: null,    // 추가결제 주분번호
	    displayAddPayment: false,

	    // 추가 결제 정보
	    addPaymentInfo: {
				orderName: "",
			  orderPhone: "",
			  orderEmail: "",
		    shippingPhone: "",
			  address: "",
			  detailAddress: "",
			  postcode: "",
		    orderExtraOid: ""
	    },
	    // 추가 결제 상품(항목) 정보
	    addPaymentItemInfo: {},
	    paymentToken: null,
    }
  },

	// computed
  getters: {
		/*
		setTodayContents: (state) => (content) => {
      state.todayContentIdx = content.todayContentIdx;
      state.wdate = content.wdate;
      state.title = content.title;
      state.contents = content.contents;
      state.questions = content.questions;
      state.explanation = content.explanation;
      state.thumbnail = `http://118.67.134.110:18080/${content.thumbnailImageLink}`
    },
		 */

	  setDeliveryList: (state) => (deliveryList) => {
			state.deliveryList = deliveryList;
		},
	  setSwitchProductRequest: (state) => (switchProductRequest) => {
			state.switchProductRequest = switchProductRequest;
	  },
	  setProductRequestList: (state) => (productRequestList) => {
			if( productRequestList !== undefined ){
				productRequestList.forEach((item) => {
					item.requestDateTime = item.requestDateTime.replace("T", " ");
				});

				state.productRequestList = productRequestList;
			}
	  },
	  setDisplayAddPayment: (state) => (displayAddPayment) => {
			state.displayAddPayment = displayAddPayment;
	  }


  },

	actions: {
		/*
		async getTodayContentsList() {
      // const url = `/api/app/today-content/list?page=1&size=10&startDate=2024-01-01&endDate=2024-01-03`;
      const url = `/api/app/today-content/list?page=${this.page}&size=${this.size}`;
      const { data, status } = await axios.get(url);
      if (status === 200) {
        if (data.code === "200") {
          this.setTodayContentsList(data.data.todayContentList);
        } else {
          return {
            code: res.data.code,
            message: res.data.message
          };
        }
      }
    },
		 */

		async getOrderItemList(){
			const url = `/new_api/customer/order/v2/order-result/${this.requestOID}`;
			const { data, status } = await axios.get(url);
      if (status === 200) {
        if (data.code === "200") {
	        console.log(data.data.deliveryList);
					this.setDeliveryList(data.data.deliveryList);
					//
					// this.orderProductList = data.data.deliveryList.orderProductList;
          // return data.data.deliveryList;
        } else {
          return {
            code: data.code,
            message: data.message
          };
        }
      }
    },

		async getProductRequestList(){
			// const url = `/new_api/customer/refund/list?oid=${this.requestOID}`;
			const url = `/new_api/order/v2/sales/detail/${this.requestOID}`;
			console.log(url);
			const { data, status } = await axios.get(url);
			console.log(data);
			console.log(status);
			if (status === 200) {
				if (data.code === "200") {
					console.log(data.data.refundList);
					this.setProductRequestList(data.data.refundList);
				} else {
					return {
						code: data.code,
						message: data.message
					};
				}
			}
		}
		//  /api/customer/refund/list?oid=OID_XXX_YYY_ZZZ

	}
})