<template>
  <div class="flex-1">
    <div class="eduRegi_wrapper h-full mt-20 max-w-[1000px] mx-auto text-center">
      <section class="eduRegi_header">
        <div class="flex font-medium">
          <div class="flex items-center">
            <h4>신청 확인</h4>
          </div>
        </div>
      </section>

      <form action="https://nodeblack.net/api/pay/pc" target="fPayPopForm" method="post" name="pcPayForm">
        <input type="hidden" name="idx" v-model="state.register.idx">
        <div class="w-full text-left lg:my-10 lg:px-10 mx-auto">
          <section class="eduRegi_conts">
              <div class="flex items-center">
                <label class="inline-block w-1/5">컨퍼런스 정보</label>
                <h5>{{ state.register.title }}</h5>
                <input type="hidden" v-model="state.register.title" name="goodname">
              </div>
              <div class="flex items-center">
                <label class="inline-block w-1/5">신청자 이름</label>
                <h4>{{ state.register.name }}</h4>
                <input type="hidden" v-model="state.register.name" name="buyername">
              </div>
              <div class="flex items-center">
                <label class="inline-block w-1/5">신청자 연락처</label>
                <h4>{{ state.register.phone }}</h4>
                <input type="hidden" v-model="state.register.phone" name="buyertel">
              </div>
              <div class="flex items-center">
                <label class="inline-block w-1/5">신청자 이메일</label>
                <h4>{{ state.register.email }}</h4>
                <input type="hidden" v-model="state.register.email" name="buyeremail">
              </div>
              <div class="flex items-center">
                <label class="inline-block w-1/5">결제 금액</label>
                <h4 class="font-bold"> {{ state.register.total_price ? state.register.total_price.toLocaleString() : '' }} 원</h4>
                <input type="hidden" v-model="state.register.total_price" name="price">
              </div>

<!--              <div class="flex items-center">-->
<!--                <label class="inline-block w-1/5">신청자 이메일</label>-->
<!--                <h4>{{ state.register.email }}</h4>-->
<!--                <input type="hidden" v-model="state.register.email" name="buyeremail">-->
<!--              </div>-->

              <!-- pc -->
              <div class="max-[768px]:!hidden flex items-center">
                <label class="inline-block w-1/5">결제 수단</label>
                <div class="flex gap-3">
                  <div>
                    <input type="radio" name="gopaymethod" id="Card" v-model="gopaymethod" value="Card" checked>
                    <label for="Card">신용카드</label>
                  </div>
                  <div>
                    <input type="radio" name="gopaymethod" id="DirectBank" v-model="gopaymethod" value="DirectBank">
                    <label for="DirectBank">실시간계좌이체</label>
                  </div>
<!--                  <div>
                    <input type="radio" name="gopaymethod" id="VBank" v-model="gopaymethod" value="VBank">
                    <label for="VBank">가상계좌(무통장입금)</label>
                  </div>-->
                </div>
              </div>

              <!-- mobile -->
              

              <!--
              <div class="border-l border-neutral-300 text-neutral-400" style="margin-top: -0.75rem">
                <div class="flex items-center">
                  <label class="inline-block w-1/5 pl-5">등록비</label>
                  <div>15,000원</div>
                </div>
                <div class="flex items-center">
                  <label class="inline-block w-1/5 pl-5">추가 인원</label>
                  <div>+ 0원 ( {{ state.register.memberList.length - 1 }} 명)</div>
                </div>
                <div class="flex items-center">
                  <label class="inline-block w-1/5 pl-5">얼리버드</label>
                  <div>- 0원</div>
                </div>
              </div>
              -->
          </section>
        </div>
      </form>
  
      <form action="https://nodeblack.net/api/pay/mobile" method="POST" name="mPayForm" class="max-[768px]:!flex !hidden eduRegi_conts text-left">
        <input type="hidden" name="idx" v-model="state.register.idx">
        <input type="hidden" name="goodname" v-model="state.register.title">
        <input type="hidden" name="buyername" v-model="state.register.name">
        <input type="hidden" name="price" v-model="state.register.total_price">
        <div class="max-[768px]:!block hidden items-center">
          <label class="inline-block w-1/5">결제 수단</label>
          <div class="flex flex-col gap-3">
            <div>
              <input type="radio" name="gopaymethod" id="CARD" v-model="gopaymethod" value="CARD" checked>
              <label for="CARD" class="py-4">신용카드</label>
            </div>
            <div>
              <input type="radio" name="gopaymethod" id="BANK" v-model="gopaymethod" value="BANK">
              <label for="BANK" class="py-4">실시간계좌이체</label>
            </div>
<!--            <div>
              <input type="radio" name="gopaymethod" id="VBANK" v-model="gopaymethod" value="VBANK">
              <label for="VBANK" class="py-4">가상계좌(무통장입금)</label>
            </div>-->
          </div>
        </div>
      </form>
      
<!--      <div v-show="is_show" class="modal">-->
<!--        <iframe name="fPayForm" style="display: block; width: 100vw; height: 100vh; position: fixed; left: 0; top: 0;"></iframe>-->
<!--      </div>-->

      <div class="mt-10" style="border-top: 1px solid var(--gray-cd);">
        <!-- pc -->
        <section class="max-[768px]:!hidden w-1/2 mx-auto my-10 pb-20 text-center !border-0">
          <a @click="pcPaySubmit" class="sec_blue_btn block p-4 cursor-pointer">결제하기</a>
        </section>
  
        <!-- mobile -->
        <section class="max-[768px]:!block hidden w-1/2 mx-auto my-10 pb-20 text-center !border-0">
          <a @click="mPaySubmit" class="sec_blue_btn block p-4 cursor-pointer">결제하기</a>
        </section>
      </div>
    </div>

  </div>
<!--  <button @click="limitCheck">t</button>-->
</template>

<script>
import { useRoute } from 'vue-router'
import { reactive } from 'vue'
import axios from 'axios'
import router from "@/router";

export default {
  name: "EduListRegisterPage",
  data: () => {
    return {
      is_show: false,
      gopaymethod: ''
    }
  },

  setup () {
    const route = useRoute()

    const state = reactive({
      register: [{
        memberList: [{

        }]
      }],

    })

    const idx = route.params.idx
    
    const load = () => {
      axios.get('/api/registered/conference/pay/' + idx).then(response => {
        if (response.data.err_code === 0) {
          state.register = response.data.contents
          if( state.register.conf_detail_idx === 57 ){
            state.register.title = '겨울성경학교.수련회 온라인 컨퍼런스 1차 (2023/12/11 19:00)';
          }
          else if( state.register.conf_detail_idx === 58 ){
            state.register.title = '겨울성경학교.수련회 온라인 컨퍼런스 2차 (2024/01/06 10:00)';
          }
        } else {
          router.push({path: '/'})
          alert(response.data.err_msg)
        }
        
      }).catch(error => {
        console.error(error)
      })
    }
    
    load()
    
    return { state }
  },
  methods: {
    async pcPaySubmit() {
      // window.open('','fPayPopForm','width=900,height=700');
      // document.pcPayForm.submit();
      const url = `/api/payment/check-limit/${this.state.register.conf_detail_idx}?headcount=${this.state.register.headcount}`;
      const { status, data } = await axios.get(url);
      if( status === 200 ){
        if( data.err_code === 0 ){
          window.open('','fPayPopForm','width=900,height=700');
          document.pcPayForm.submit();
        }
        else if( data.err_code === 403 ){
          alert(data.err_msg);
          document.location.reload();
        }
        else {
          alert('새로고침 후 다시 시도 해 주세요.');
          document.location.reload();
        }
      }
      else {
        alert('새로고침 후 다시 시도 해 주세요.');
        document.location.reload();
      }

      /*
      if( response.data.err_code === 0 ){
        // window.open('','fPayPopForm','width=900,height=700');
        // document.pcPayForm.submit();
      }
      else {
        alert(response.data.err_msg);
        return false;
      }
      */
    },
    async mPaySubmit() {
      // document.mPayForm.submit();
      const url = `/api/payment/check-limit/${this.state.register.conf_detail_idx}?headcount=${this.state.register.headcount}`;
      const { status, data } = await axios.get(url);
      if( status === 200 ){
        if( data.err_code === 0 ){
          document.mPayForm.submit();
        }
        else if( data.err_code === 403 ){
          // alert(data.err_msg);
          alert('신청 인원 다 차서 신청이 마감 되었습니다. 감사합니다.');
          document.location.reload();
        }
        else {
          alert('새로고침 후 다시 시도 해 주세요.');
          document.location.reload();
        }
      }
      else {
        alert('새로고침 후 다시 시도 해 주세요.');
        document.location.reload();
      }
      /*
      if( response.data.err_code === 0 ){
          document.mPayForm.submit();
      }
      else {
        alert(response.data.err_msg);
        return false;
      }
       */
    },
    async limitCheck() {
      const url = `/api/payment/check-limit/${this.state.register.conf_detail_idx}?headcount=${this.state.register.headcount}`;
      const response = await axios.get(url);
      console.log(response);
      console.log(response.data);
    }
  }
}
</script>
