<template>
  <section class="flex-1" style="height: 140vh;">
    <div class="text-center px-5">
      <h1 class="font-medium mb-3 pt-20">
<!--        <span v-if="state.findType=='ID'"> 아이디 </span>-->
<!--        <span v-if="state.findType=='PW'"> 비밀번호 </span>-->
        아이디 / 비밀번호
        찾기
      </h1>
<!--      <p class="font-light text-neutral-400 break-keep">총회교육개발원 홈페이지에 오신 것을 환영합니다</p>-->
    </div>

    <div class="signup_form field_inputs max-w-[860px] mx-auto mb-10 px-5 flex gap-1 justify-center">
      <button type="button" class="sec_blk_btn block p-4 cursor-pointer" :class="{'sec_filled-blk_btn':state.findType=='ID'}" @click="state.findType='ID'"> 아이디 찾기 </button>
      <button type="button" class="sec_blk_btn block p-4 cursor-pointer" :class="{'sec_filled-blk_btn':state.findType=='PW'}" @click="state.findType='PW'"> 비밀번호 찾기 </button>
    </div>

    <div class="signup_form field_inputs max-w-[860px] mx-auto mb-10 px-5" v-if="state.findType=='ID'">
      <!-- 이름 -->
      <div class="flex items-center">
        <label class="inline-block w-1/5 require">이름<span></span></label>
        <input type="text" placeholder="이름" class="regi_inputs w-4/5" v-model="state.id.name"/>
      </div>

      <!-- 전화번호 -->
      <div class="flex items-center">
        <label class="inline-block w-1/5 require">전화번호<span></span></label>
        <div class="flex gap-2 mont font-medium">
            <select name="" class="w-24 regi_inputs" v-model="state.id.ph1" :disabled="state.id.chk_passCode==true">
                <option value="">선택</option>
                <option selected>010</option>
            </select>
            <input type="text" maxlength="4" class="w-24 regi_inputs" v-model="state.id.ph2" :disabled="state.id.chk_passCode==true"/>
            <input type="text" maxlength="4" class="w-24 regi_inputs" v-model="state.id.ph3" :disabled="state.id.chk_passCode==true"/>
        </div>
          <button class="ml-2 sec_blk_btn w-32 p-2" @click="sendPassCode('id')" v-if="state.id.chk_passCode==false">인증번호발송</button>
          <span v-if="state.id.chk_passCode==true" class="ml-2">
            <input type="text" v-model="state.id.passCode" placeholder="인증번호 입력" class="regi_inputs w-4/5"/>
          </span>
      </div>

      <section class="w-3/4 mx-auto my-5 text-center !border-0">
        <div class="sec_filled-blk_btn block p-4 cursor-pointer" @click="findSubmit('id')"> 아이디 찾기 </div>
      </section>

      <div v-if="state.findId !== null" class="text-center">
        <p> 고객님의 정보와 일치하는 아이디입니다. </p>
        <div class="text-2xl mb-5">
          {{ state.findId }}
        </div>

        <div class="mb-5" v-if="state.joinTypeText">
          <p>이 외 간편가입(SNS가입)으로 가입된 계정이 확인 됩니다.</p>
          <div class="text-xl mb-5">
            {{ state.joinTypeText }}
          </div>
        </div>

        <div class="flex gap-3 justify-center mb-5">
          <button class="w-1/4 sec_filled-blk_btn block p-4 cursor-pointer" @click="moveLogin()"> 로그인 </button>
          <button class="w-1/4 sec_filled-blk_btn block p-4 cursor-pointer" @click="$router.go(0);"> 비밀번호 찾기 </button>
        </div>
      </div>
    </div>


    <div class="signup_form field_inputs max-w-[860px] mx-auto mb-10 px-5" v-if="state.findType=='PW'">
      <!-- 아이디 -->
      <div class="flex items-center">
        <label class="inline-block w-1/5 require">아이디<span></span></label>
        <input type="text" placeholder="아이디" class="regi_inputs w-4/5" v-model="state.pass.uid"/>
      </div>

        <!-- 이름 -->
      <div class="flex items-center">
        <label class="inline-block w-1/5 require">이름<span></span></label>
        <input type="text" placeholder="이름" class="regi_inputs w-4/5" v-model="state.pass.name"/>
      </div>

      <!-- 전화번호 -->
      <div class="flex items-center">
        <label class="inline-block w-1/5 require">전화번호<span></span></label>
        <div class="flex gap-2 mont font-medium">
          <select name="" class="w-24 regi_inputs" v-model="state.pass.ph1" :disabled="state.pass.chk_passCode==true">
            <option value="">선택</option>
            <option selected>010</option>
          </select>
          <input type="text" maxlength="4" class="w-24 regi_inputs" v-model="state.pass.ph2" :disabled="state.pass.chk_passCode==true"/>
          <input type="text" maxlength="4" class="w-24 regi_inputs" v-model="state.pass.ph3" :disabled="state.pass.chk_passCode==true"/>
        </div>
        <button class="ml-2 sec_blk_btn w-32 p-2" @click="sendPassCode('pass')" v-if="state.pass.chk_passCode==false">인증번호발송</button>
        <span v-if="state.pass.chk_passCode==true">
          <input type="text" v-model="state.pass.passCode" placeholder="인증번호 입력" class="regi_inputs w-4/5"/>
        </span>
      </div>

      <section class="w-3/4 mx-auto my-5 text-center !border-0">
        <div class="sec_filled-blk_btn block p-4 cursor-pointer" @click="findSubmit('pass')"> 비밀번호 찾기 </div>
      </section>
    </div>

    <div class="w-2/3 mx-auto my-10 pb-10 text-center !border-0">
      <div class="sec_filled p-4 cursor-pointer w-full" @click="moveLogin"> 로그인 페이지로 이동 </div>
    </div>

  </section>
</template>

<script>
import {onMounted, reactive} from "vue";
import axios from "axios";
import router from "@/router";

export default {
  name: "helpPage",
  setup () {
    const state = reactive ({
      findType: 'ID',
      id: {
        name: "",
        ph1: "010",
        ph2: "",
        ph3: "",
        phone: "",
        chk_passCode: false,
        passCode: ""
      },
      pass: {
        uid: "",
        name: "",
        ph1: "010",
        ph2: "",
        ph3: "",
        phone: "",
        chk_passCode: false,
        passCode: ""
      },
      tempToken: null,
      findId: null,
      joinType: null,
      joinTypeText: null
    })

    // 찾기 버튼
    const findSubmit = (type) => {
      if( type == 'pass' ){
        if( state.pass.uid == "" ){
          alert("아이디를 입력하세요.");
          return false;
        }
      }
      //  이름 유요성 검사
      if( state[type].name == "" ){
        alert("이름을 입력하세요.");
        return false;
      }
      // 아이디 찾기 - 전화번호 유요성 검사
      if( state[type].phone == "" ){
        alert("전화번호를 입력하세요.");
        return false;
      }


      const url = `/new_api/account/lost/confirm`;
      const params = {code: state[type].passCode};
      console.log(state.tempToken);
      axios.post(url, params, {
        headers: {
          'Authorization': state.tempToken
        }
      }).then(({data}) => {

        if( data.code == "200" ){
          if( type == 'id' ){
            state.joinType = data.data.joinType;
            state.joinTypeText = data.data.joinType.filter( (item) => item != 'normal' ).join(', ');
            state.joinTypeText = state.joinTypeText.replace('kakao', '카카오').replace('naver', '네이버').replace('apple', '애플');

            if( state.joinType.includes('normal') ){
              state.findId = data.data.uid;
            }
            else {
              alert(`'간편가입(SNS가입)'으로 가입된 계정으로 확인 됩니다.(${state.joinTypeText})`);
              return false;
              /*
              if( state.joinType.includes('kakao') && state.joinType.includes('naver') ) {
                alert('\'간편가입(SNS가입)\'으로 가입된 계정으로 확인 됩니다.(네이버, 카카오)');
              }
              else if( state.joinType.includes('kakao') ){
                alert('\'간편가입(SNS가입)\'으로 가입된 계정으로 확인 됩니다.(카카오)');
              }
              else if( state.joinType.includes('naver') ){
                alert('\'간편가입(SNS가입)\'으로 가입된 계정으로 확인 됩니다.(네이버)');
              }
              return false;
              */
            }

          }
          else {
            alert("비밀번호 찾기가 완료되었습니다.\n\n임시 비밀번호가 알림톡으로 전송 되었습니다.");
            // TODO: APP 로그인 페이지로 이동
            getData.postMessage('login');
            // router.push({path: "/"});
          }
        }
        else {
          if( data.code == "031" ){
            alert("인증코드가 유효하지 않습니다.");
            return false;
          }
          else {
            alert("인증코드가 유효하지 않습니다.");
            return false;
          }
        }
      });
    }

    const sendPassCode = (type) => {
      if (!state[type].ph1 || !state[type].ph2 || !state[type].ph3) {
        alert("전화번호를 입력하세요.")
        return false;
      }
      else {
        state[type].phone = state[type].ph1 + state[type].ph2 + state[type].ph3

        const url = `/new_api/account/lost/auth`;
        const params = {
          memberName: state[type].name,
          phoneNumber: state[type].phone
        }
        if( type == 'pass' ){
          params.uid = state.pass.uid;
        }
        axios.post(url, params).then(({data}) => {
          if (data.code == "200" || data.code == 200) {
            state[type].chk_passCode = true;
            state.tempToken = data.data.token;
            alert("인증번호가 발송되었습니다.");
          }
          else {
            state[type].chk_passCode = false;
            state.tempToken = null;
            if( data.code == "012" ){
              alert('등록된 회원정보가 없습니다.');
            }
            else if( data.code == "031" ){
              const bizMMessage = data.data.message;
              const bizMCode = bizMMessage.split(":")[0];
              if( bizMCode == "K101" ){
                alert("알림톡 수신불가 번호 입니다. 다시 확인해 주세요.");
              }
              else if( bizMCode == "K101" ) {
                alert('알림톡 전화번호 오류, 다시 확인해 주세요.');
              }
              else {
                alert("고객센터로 문의해 주세요.");
              }
            }
            else {
              alert("입력한 정보를 다시 확인 하거나\n\n고객센터로 문의 주세요.");
            }
          }
        });
      }
    }

    const moveLogin = () => {
      // 라우터 현재 페이지 이름
      const thisPageName = router.currentRoute.value.name;
      if( thisPageName === 'helpPageApp' || window.location.pathname === '/app/help_find' ){
        // getData.postMessage("login");
        getData.postMessage('login');
        /*var data = {
          message: "message",
          additionalData: {
            type: "login"
          }
        };
        window.dataChannel.postMessage(data);*/
        // var data = { message: 'login', };
        // getData.postMessage(JSON.stringify(data));

      }
      else {
        router.push({path: "/"});
      }
    }

    onMounted(() => {
    })

    return { state, sendPassCode, findSubmit, moveLogin }
  }
}
</script>

<style scoped>

</style>
