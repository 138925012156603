<template>
  <div class="flex justify-center items-center h-screen w-screen" v-if="pageLoadComplete === false">
    <span class="loader"></span>
  </div>

  <div v-else id="edu-list" class="flex-1 py-20 px-5">
    <div class="max-w-[1000px] lg:px-5 mx-auto mb-14">
      <!-- 컨퍼런스 타이틀 -->
      <h2 class="font-medium text-center">{{state.conference.title}}</h2>

      <!-- 2024 여름 컨퍼런스 -->
      <div v-if="state.conference.idx == 9">
        <div class="mt-6">
          <img src="@/assets/img/2024_confInfo_m1.jpg" alt="2024 컨퍼런스 대표 이미지" class="rounded-lg">
        </div>
        <div>
          <img src="@/assets/img/2024_confInfo_m2.jpg" alt="2024 컨퍼런스 설명 이미지" class="rounded-lg">
        </div>
        <div>
          <img src="@/assets/img/2024_confInfo_m3.jpg" alt="2024 컨퍼런스 추가 이미지" class="rounded-lg">
        </div>
      </div>

      <!-- 하나바이블 컨퍼런스 -->
      <div v-if="state.conference.idx == 11">
        <div class="mt-6">
          <img src="@/assets/img/240503_hana.jpg" alt="2024 하나바이블 지도자 컨퍼런스" class="rounded-lg">
        </div>
      </div>

      <div class="list">
        <template v-for="(confList, index) in state.conference.conferenceDetailList" :key="confList.idx">
          <div class="w-full" v-if="confList.number !== state.conference.conferenceDetailList[index - 1]?.number && confList.view !== false">
            <div class="item" @click="toggleItemSubWr(confList.idx)" :id="'conference_'+confList.number">
              <b class="mont" v-if="confList.number===6">추가</b>
              <b class="mont" v-else>{{confList.number}}차</b>
              <div class="title flex items-baseline">
                <h5 class="font-medium">{{confList.local}} {{confList.place}}</h5>
                <span class="bar"></span>
                <h5 class="font-medium">{{ formatDate(confList.schedule) }}</h5>
                <h6 class="ml-2" v-if="state.conference.idx===9 || state.conference.idx===11">( 등록 마감 <b>{{ tempFormatDate(confList.redatetime) }} 밤 12시</b>)</h6>
                <h6 class="ml-2" v-else>( 등록 마감 <b>{{ formatDate(confList.redatetime) }}</b>)</h6>
              </div>
              <button>
                <font-awesome-icon :icon="isSubVisible[confList.idx] ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'"/>
              </button>
            </div>
            <div class="item_sub_wr flex flex-wrap justify-between py-4" :class="{ hidden: isSubVisible[confList.idx] }">
              <div class="item_sub" v-for="filteredConfList in state.conference.conferenceDetailList.filter(cl => cl.number === confList.number)" :key="filteredConfList.idx">
                <div v-if=" filteredConfList.idx !== 57 && filteredConfList.idx !== 58 " class="item_sub">
                  <div class="flex items-center gap-2">
                    <h5 class="font-medium">{{filteredConfList.dept}}</h5>
                    <p>
<!--                      (인원: <b>{{( filteredConfList.registered_personnel >= filteredConfList.limit_personnel )?"마감":(filteredConfList.registered_personnel === 0)?"무제한":filteredConfList.registered_personnel}}</b>-->
                      <span v-if="state.conference.idx != 11">
                        (인원: <b> {{ ( filteredConfList.limit_personnel === 0 ) ? "무제한" : ( filteredConfList.registered_personnel >= filteredConfList.limit_personnel )?"마감":filteredConfList.registered_personnel }} </b>
                        <span v-if="filteredConfList.limit_personnel > 0"> / {{ filteredConfList.limit_personnel }}</span>)
                      </span>
                    </p>
                  </div>
                  <!-- 접수 기간 내에만 노출 -->
                  <div v-if="isAvailable(filteredConfList.rsdatetime, filteredConfList.redatetime)">
                    <!-- 로그인 유무 -->
                    <a
                      @click="alertLogin()"
                      class="block p-4"
                      :class="{ 'sec_close_btn': filteredConfList.registered_personnel >= filteredConfList.limit_personnel && filteredConfList.limit_personnel != 0 }"
                      v-if="!$store.state.account.id"
                    >
                      <span v-if="filteredConfList.registered_personnel >= filteredConfList.limit_personnel && filteredConfList.limit_personnel != 0">마감</span>
                      <span v-else>신청</span>
                    </a>
                    <!-- 인원 마감 시 -->
                    <a @click="alertExceed()" class="sec_close_btn block p-4" v-else-if="filteredConfList.registered_personnel >= filteredConfList.limit_personnel && filteredConfList.limit_personnel != 0"> 마감 </a>
  <!--                  <router-link :to="'/register/'+filteredConfList.idx" class="sec_rose_btn block p-4" v-else>신청</router-link>-->
                    <a href="javascript:void(0)" @click="goApply(filteredConfList.idx)" v-else>신청</a>
                  </div>
                  <a @click="alertAvailable()" class="sec_close_btn block p-4" v-else>접수전</a>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

    </div>
    <div v-if="thisPageName==='conferenceListApp'">
<!--      토큰 : {{ tokenTest }}-->
<!--      페이지 : {{ thisPageName }}-->
    </div>
  </div>

</template>

<script setup>
import {reactive, onMounted, ref} from 'vue'
import axios from 'axios'
import {useRouter} from "vue-router";
import router from "@/router";

const { currentRoute } = useRouter();

const state = reactive({
  conference: {},
})



/*axios.get('/api/conferences').then(response => {
  state.conferences = response.data.contents
}).catch(error => {
  console.error(error)
})*/

//접수기간 체크
const isAvailable = (rsdatetime, redatetime) => {
  const today = new Date()
  const startDate = new Date(Date.parse(rsdatetime))
  const endDate = new Date(Date.parse(redatetime))

  // console.log(today)
  // console.log(startDate)
  // console.log(endDate)
  return today >= startDate && today <= endDate
}

const formatDate = (date) => {
  date = new Date(date);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', hour12: true };
  return date.toLocaleString('ko-KR', options).replace(',', '');
}

const tempFormatDate = (date) => {
  date = new Date(date);
  // const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', hour12: true };
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleString('ko-KR', options).replace(',', '');
}

const isSubVisible = reactive([])

const toggleItemSubWr = (idx) => {
  isSubVisible[idx] = !isSubVisible[idx]
}

const alertLogin = () => {
  alert('로그인 후 이용해주세요.')
  return
 }

const alertAvailable = () => {
  alert('접수 기간이 아닙니다.')
  return
}

const alertExceed = () => {
  alert('접수가 마감되었습니다.')
  return
}

const goApply = (idx) => {
  if( thisPageName === 'conferenceListApp' ){
    // document.location.href = `/app/register/${idx}`;
    router.push({path: `/app/register/${idx}`});
  }
  else {
    // document.location.href = `/register/${idx}`;
    router.push({path: `/register/${idx}`});
  }

}

const getConferenceList = () => {
  let idx = currentRoute._value.params.idx ?? '9';
  if( location.pathname === '/hana' ){
    idx = '2';
  }
  axios.get(`/api/conference/${idx}`).then(response => {
    state.conference = response.data.contents;
    if( idx === '1' ){
      state.conference.conferenceDetailList.map((conferenceDetailList) => {
        conferenceDetailList.view = viewCheck(conferenceDetailList.number);
      })
    }

    pageLoadComplete.value = true;
  })
  .catch(error => {
    console.error(error)
  })
}

// getConferenceList();

const viewCheck = (number) => {
  if( number === 5 ){
    //23년 5월 12일 오후 5시 이후부터 보여지도록
    const date = new Date();
    const targetDate = new Date("2023-05-12T17:00:00");
    return date > targetDate;
  }
  else {
      return true;
  }
}


const thisPageName = currentRoute._value.name;
console.log(thisPageName);

const tokenTest = ref('');

const removeStorageItem = () => {
  if( localStorage.getItem('sns_type') ){ localStorage.removeItem('sns_type') }
  if( localStorage.getItem('sns_id') ){ localStorage.removeItem('sns_id') }
  if( localStorage.getItem('uid') ){ localStorage.removeItem('uid') }
  if( localStorage.getItem('email') ){ localStorage.removeItem('email') }
  if( localStorage.getItem('name') ){ localStorage.removeItem('name') }
  if( localStorage.getItem('phone') ){ localStorage.removeItem('phone') }
}

const pageLoadComplete = ref(false);
onMounted(() => {
  tokenTest.value = localStorage.getItem('authorization');
  getConferenceList();

  // sns 로그인시 사용되는 스토리지 삭제
  removeStorageItem();
})
</script>

<style scoped>
.sec_close_btn {
  background-color: #f2f2f2 !important;
  color: #999 !important;
  cursor: not-allowed !important;
}
</style>

