<template>
  <div>
    <div class="adm_box_wrapper table_wrapper">
      <section class="flex justify-between items-baseline p-5 border-b border-gray-200">
        <h3 class="font-medium">컨퍼런스 목록</h3>
        <AdminFilterComponent/>
      </section>

      <div>
        <table class="w-full table-auto table_wrapper">
          <thead class="text-left">
            <tr>
              <th>컨퍼런스 제목</th>
              <th>접수 시작</th>
              <th>접수 종료</th>
              <th>신청 취소가능일</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody class="text-left" v-for="conference in formData.conference" :key="conference.idx">
            <tr>
              <td><h5>{{ conference.title }}</h5></td>
              <td>{{ formattedDate(conference.rsdatetime) }}</td>
              <td>{{ formattedDate(conference.redatetime) }}</td>
              <td>종료일로부터 <b>{{ conference.cancel_day }}</b>일 전까지</td>
              <td colspan="2" class="text-center">
                <VDialog :classes="{ bg: 'bg-black/40' }">
                  <template #default="{ close }">
                    <!-- 참석자 목록 -->
                    <div class="color-black bg-white min-w-[500px] max-h-[80vh] overflow-y-auto">
                      <section class="flex justify-between p-4 border-b border-gray-200">
                        <h5>컨퍼런스 편집</h5>
                        <button @click="close">
                          <h4><font-awesome-icon icon="fa-solid fa-circle-xmark" /></h4>
                        </button>
                      </section>
                      <EduModifyComponent :conference="conference"/>
                    </div>
                  </template>

                  <template #toggle="{ bind, on }">
                    <button v-bind="bind" v-on="on">[편집]</button>
                  </template>
                </VDialog>
              </td>
            </tr>

            <tr class="adm_subTable adm_subTable_tt" @click="toggleSiblingsClass($event)">
              <th>
                <font-awesome-icon icon="fa-solid fa-chevron-up" />
                차수, 장소, 부서
              </th>
              <th colspan="2">컨퍼런스 일시</th>
              <th>가격</th>
              <th colspan="3">인원(등록수/최대수)</th>
            </tr>
            <tr v-for="(confList, index) in conference.conferenceDetailList" :key="index" :class="'adm_subTable hidden'">
              <td>{{confList.number}}차 {{confList.local}}[{{confList.place}}] <b>{{confList.dept}}</b></td>
              <td colspan="2">{{formattedDate(confList.schedule)}}</td>
              <td>{{confList.price}}</td>
              <td colspan="3">{{confList.registered_personnel}} / {{confList.limit_personnel}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { VDialog } from 'vuetensils/src/components'
import axios from 'axios'
import EduModifyComponent from "@/components/EduModifyComponent.vue"
import router from "@/router";

const formData = reactive({
  conference: [],
})

axios.get('/api/conferences').then(response => {
  formData.conference =  response.data.contents

}).catch(error => {
  console.error(error)
})

//날짜 format 수정
const formattedDate = (dateString) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  return `${year}/${month}/${day} ${hours}:${minutes}`
}

//부서 토글
const toggleSiblingsClass = (event) => {
  const clickedElement = event.currentTarget
  const siblings = clickedElement.parentNode.querySelectorAll('.adm_subTable')
  siblings.forEach((sibling) => {
    if (sibling.classList.contains('adm_subTable_tt')) return
    sibling.classList.toggle('hidden')
  })
}

onMounted(() => {
  // url token 삭제
  if( router.currentRoute.value.params.token !== undefined ){
    let path = router.currentRoute.value.path;
    path = path.split('/');
    let newPath = '';
    for (let i = 1; i < (path.length)-1; i++) {
      newPath += '/' + path[i];
    }
    router.push(newPath);
  }

})
</script>

