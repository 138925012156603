<template>
  <div class="lg:flex flex-1" >
    <LNavComponent v-if="pageName !== 'signup_app'" />
    <appNavigator v-if="pageName === 'signup_app'" :page="pageName" />
    <SignupPage class="flex-1"/>
  </div>
</template>

<script>
import SignupPage from "@/pages/SignupPage.vue";
import LNavComponent from "@/components/LNavComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import AppNavigator from "@/components/appNavigator.vue";

export default {
  name: 'SignupView',
  components: {
    AppNavigator,
    SignupPage,
    LNavComponent,
    FooterComponent
  },
  data() {
    return {
      pageName: ''
    }
  },
  mounted() {
    this.pageName = this.$route.name;
  }
}
</script>
