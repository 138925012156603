<template>
  <div id="member-management">
    <div class="adm_box_wrapper">
      <section class="flex justify-between items-baseline p-5 border-b border-gray-200">
        <div>
          <h3 class="font-medium">컨퍼런스 취소 신청 목록</h3>
          <p class="text-neutral-500">Total : {{state.list.length}} 건</p>
        </div>
      </section>
      <!--<ul>
        <li v-for="(member, idx) in state.members" :key="idx">{{ member }}</li>
      </ul>-->
      <div>

        <table class="w-full table-auto table_wrapper">
          <thead class="text-center">
            <tr>
              <th>주문번호</th>
              <th>선택옵션</th>
              <th>신청자</th>
              <th>교회명</th>
              <th>취소인원(전체)</th>
              <th>취소수수료(원)</th>
              <th>환불금액(원)</th>
              <th>취소신청일</th>
              <th>취소완료일</th>
              <th>[현재상태]</th>
              <th>승인</th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr v-for="item in confesOnCurrentPage">
              <td> {{item.registerConference.oid}} ({{item.idx}}) </td>
              <td> {{item.registerConference.conferenceDetail.dept}} </td>
              <td> {{item.registerConference.name}} </td>
              <td> {{item.registerConference.church}} </td>
              <td>
                  <span v-if="item.type === '전체'"> 전체 </span>
                  <span v-else>부분 - {{item.count}}</span>
              </td>
              <td> 0 </td>
              <td> {{item.price.toLocaleString('ko-kr')}} </td>
              <td> {{item.created_date}} </td>
              <td>
                  <span v-if="item.confirm_date!=null"> {{item.confirm_date}} </span>
              </td>
              <td> {{item.status}} </td>
              <td>
                <span v-if="item.status == '취소신청'">
                  <button class="btn btn-primary" @click="handleConfirm(item.idx)">승인</button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="flex justify-center py-5">
        <v-pagination
          v-model="state.currentPage"
          :pages="totalPages"
          :range-size="1"
          @update:modelValue="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, reactive} from "vue";
import axios from "axios";
import VPagination from "@hennge/vue3-pagination";

export default {
  name: "ConfCancelPage",
	components: {VPagination},
  setup () {
    const state = reactive ({
      status: "all",
      list: [],

      currentPage: 1,
      pageSize: 20, //페이지에 8개씩
    })

    const getCancelList = async () => {
      const url = `/api/payment/cancels?status=${state.status}`;
      const {data} = await axios.get(url);
			if( data.err_code === 0 ){
        state.list = data.contents;
				state.list.map( item => {
					item.created_date = formattedDate(new Date(item.created_date));
          if( item.confirm_date != null ){
            item.confirm_date = formattedDate(new Date(item.confirm_date));
          }
				})
      }
			else {
				alert(data.err_msg);
				return false;
      }
			/*const res = await axios.get(url);
	    console.log(res);*/
      // state.list = data
    }

		const handleConfirm = async (idx) => {
			if( confirm('취소를 승인 하시겠습니까?') === true ){
        const url = `/api/payment/confirmCancels/${idx}`;
        const {data} = await axios.put(url);
        if( data.err_code === 0 ){
          alert("승인되었습니다.");
          await getCancelList();
        }
        else {
          alert(data.err_msg);
          return false;
        }
			}
    }

		const formattedDate = ( date ) => {
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')

      return `${year}/${month}/${day} ${hours}:${minutes}`
    }

		// 총 페이지수 계산
    const totalPages = computed(() =>
      Math.ceil(state.list.length / state.pageSize)
    )

    //회원 목록 slice
    const confesOnCurrentPage = computed(() => {
      const start = (state.currentPage - 1) * state.pageSize
      const end = start + state.pageSize
      return state.list.slice(start, end)
    })

    //페이지 변경
    const handlePageChange = (newPage) => {
      state.currentPage = newPage
    }

    onMounted(getCancelList)

    return {
      state,
      totalPages,
      confesOnCurrentPage,
      handlePageChange,
      handleConfirm
    }
  }

}

</script>