<template>
  <img src="@/assets/img/community_banner.jpg" class="w-full max-h-[25rem] min-h-[11.25rem] object-cover"/>

  <main class="max-w-[1200px] mx-auto mb-20 px-5">
    <header class="header_w_search">
      <div></div>
      <h1 class="font-bold">
        {{ postDetail.boardTitle }}
      </h1>
    </header>

    <!-- 본문 -->
    <section class="flex items-center flex-col px-4 pb-6 mb-6 border-b border-neutral-300">
      <h3 class="text-center">
        {{ postDetail.postTitle }}
      </h3>
      <div class="text-neutral-600" style="font-size: clamp(0.5rem, 3.5vw, 1rem);">
        {{postDetail.postAuthor}} | {{formDate(postDetail.createdDateTime)}} | 조회수 {{postDetail.postHit}}
      </div>
      <div class="flex w-full justify-end mt-4 gap-2" v-if="$store.state.account.id == postDetail.postAuthorId || $store.state.level.id == 10">
        <router-link :to="'/community/write/' + postDetail.postId"
          class="inline-flex h-fit gap-1 bg-white border border-black px-2 py-1 items-center text-sm">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><path d="M12.0808 4.10666C12.3083 3.87916 12.3083 3.49999 12.0808 3.28416L10.7158 1.91916C10.5 1.69166 10.1208 1.69166 9.89333 1.91916L8.82 2.98666L11.0075 5.17416M1.75 10.0625V12.25H3.9375L10.3892 5.79249L8.20167 3.60499L1.75 10.0625Z" fill="#1B1D21"></path></svg>
          수정
        </router-link>
        <button type="button" @click="deletePost" class="inline-flex h-fit gap-1 bg-white border border-black px-2 py-1 items-center text-sm">
          X 삭제
        </button>
      </div>
    </section>

    <div class="w-full px-4 pb-6 mb-6 border-b border-neutral-300">
      <section v-html="postDetail.postContent"></section>
    </div>
      
    <div class="flex w-full justify-end mb-4 gap-2 px-3" v-if="$store.state.account.id == postDetail.postAuthorId || $store.state.level.id == 10">
      <router-link :to="'/community/write/' + postDetail.postId"
        class="inline-flex h-fit gap-1 bg-white border border-black px-2 py-1 items-center text-sm">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><path d="M12.0808 4.10666C12.3083 3.87916 12.3083 3.49999 12.0808 3.28416L10.7158 1.91916C10.5 1.69166 10.1208 1.69166 9.89333 1.91916L8.82 2.98666L11.0075 5.17416M1.75 10.0625V12.25H3.9375L10.3892 5.79249L8.20167 3.60499L1.75 10.0625Z" fill="#1B1D21"></path></svg>
        수정
      </router-link>
      <button type="button" @click="deletePost" class="inline-flex h-fit gap-1 bg-white border border-black px-2 py-1 items-center text-sm">
        X 삭제
      </button>
    </div>

    <!-- 댓글 -->
    <div class="px-5">
      <div class="mb-3">
        <p class="text-sm text-gray-700">댓글 {{postDetail.commentCount}}</p>
        <ul v-for="com in postComments" :key="com.commentId" class="py-3">
          <li v-if="com.isDel !== 'Y'">
            <div class="flex gap-2 items-center">
              <p class="text-lg">{{com.memberName}}</p>
              <span class="text-sm text-gray-500">
                {{formDate(com.createdDateTime)}}
              </span>
              <button 
                class="sec_btn text-sm px-1"
                @click="deleteComments(com.commentId)"
                v-if="$store.state.account.id == com.memberIdx || $store.state.level.id == 10">
                X 삭제
              </button>
            </div>
            <div v-html="convertComments(com.commentContent)"></div>
          </li>
        </ul>
      </div>

      <div class="flex flex-col border" v-if="$store.state.account.id !== null && $store.state.level.id > 1 ">
        <textarea
          name="comment"
          v-model="state.commentContent"
          rows="3"
          class="w-full p-4"
          placeholder="댓글을 입력해주세요."
        ></textarea>
        <div class="w-full flex justify-end border-t">
          <button type="button" @click="writeComments" class="pri_btn_bg px-6 py-3">작성</button>
        </div>
      </div> 

      <div class="flex flex-col border" v-else>
        <p class="p-4">로그인 후 이용 가능합니다.</p>
      </div> 
    </div>

    <div class="mx-auto my-10 flex justify-center items-center gap-2">
      <router-link :to="'/community/' + boardId" class="sec_btn px-6 py-4 text-lg">목록으로</router-link>
    </div>
  </main>
</template>

<script setup>
  import { ref, onMounted } from 'vue'
  import axios from 'axios';
  import { formDate } from '@/assets/js/util.js';
  import { useRoute, useRouter } from 'vue-router';
  const route = useRoute();
  const router = useRouter();

  const postDetail = ref([]);
  const postComments = ref([]);

  const postId = route.params.postId;
  const boardId = route.params.boardId;

  const state = ref({
    postId: postId,
    commentContent: null
  })

  const getPost = async () => {
    try {
      if (postId) {
        const { data } = await axios.get(`/new_api/post/${postId}`);
        console.log(data);

        if(data.code === "032") {
          alert('열람 권한이 없습니다.');
          router.go(-1);
        }

        postDetail.value = data.data.post;
        postComments.value = data.data.comments;
      } else {
        console.error('올바르지 않은 접근입니다.');
      }
    } catch (err) {
      console.error(err);
    }
  }

  const deletePost = async () => {
    const isConfirmed = window.confirm('정말 삭제하시겠습니까?');

    if (isConfirmed) {
      try {
        const res = await axios.delete(`/new_api/post/delete/${postId}`);
        console.log(res.data);

        if (res.data.message === "OK") {
          alert('삭제되었습니다.');          
          router.push('/community/' + boardId);
        }

      } catch (error) {
        console.error(error);
      }
    }
  };

  onMounted(async () => {
    await getPost();
  });

  // 댓글 줄바꿈
  const convertComments = (content) => {
    return content.replace(/\n/g, '<br>');
  };

  const writeComments = async () => {
    try {
      const res = await axios.post('/new_api/comment/new', state.value);
      console.log(res);
      document.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const deleteComments = async (comId) => {
    const isConfirmed = window.confirm('정말 삭제하시겠습니까?');

    if (isConfirmed) {
      try {
        const res = await axios.delete(`/new_api/comment/delete/${comId}`);
        console.log(res.data);

        if (res.data.message === "OK") {
          alert('삭제되었습니다.');          
          document.location.reload();
        } else {
          alert("예기치 못한 에러가 발생했습니다.\n문제 지속시 고객센터로 문의 바랍니다.");
        }

      } catch (error) {
        console.error(error);
      }
    }
  }
</script>