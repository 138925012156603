<template>
  <div class="lg:flex flex-1" >
    <LNavComponent v-if="pageName !== 'conferenceListApp'" />
    <appNavigator v-if="pageName === 'conferenceListApp'" :page="pageName" />

<!--    <LNavComponent v-if=" pageName !== 'memberProfileConferenceApp' " />-->
    <conferencePage />
  </div>
</template>

<script>
import LNavComponent from "@/components/LNavComponent.vue"
import conferencePage from "@/pages/conferencePage.vue";
import appNavigator from "@/components/appNavigator.vue";

export default {
  name: "conferenceList",
  components: {conferencePage, LNavComponent, appNavigator},
  data() {
    return {
      pageName: this.$route.name
    }
  },
  /*watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        window.location.reload();
      }
    }
  }*/
}
</script>

<style scoped>

</style>
