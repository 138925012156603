<template>
  <div id="confirm">
    <!-- PC -->
    <div class="mt-40 mx-auto text-center" v-show="returnCode=='0000'&&payDevice=='pc'">
      <h1 class="text-blue-700">
        <font-awesome-icon icon="fa-solid fa-circle-check"/>
        <br/>신청이 완료되었습니다.
      </h1>
      <p class="mt-5">접수가 마감된 후에는 변경 및 취소가 어려우며</p>
      <p>지도자용 예배/활동 매뉴얼은 컨퍼런스 현장에서 구입가능합니다.</p>

      <!-- 가상계좌 결제일 경우 -->
      <div v-if="this.detail.pay_method == 'VBank'" class="mt-8">
        <h5 class="mb-4 text-blue-700">아래 가상계좌로 입금해 주시면 결제 완료처리 됩니다.</h5>
        <div class="max-w-[500px] mx-auto border m-4 p-4 text-left">
          <div class="mb-4">
            <p>계좌 정보</p>
            <div>
              <h5>{{ detail.paymentOthers.vact_bank_name }} <span class="mont">{{ detail.paymentOthers.vact_num }}</span></h5>
              <h5>예금주 : {{ detail.paymentOthers.vact_name }}</h5>
            </div>
          </div>
          <div class="mb-4">
            <p>결제 금액</p>
            <h5>
              <span class="mont">{{ Number(detail.tot_price).toLocaleString() }}</span>원
            </h5>
          </div>
          <div class="mb-4">
            <p>입금 기한</p>
            <h5>
              <span class="mont">{{ this.formatDate(detail.paymentOthers.vact_date) }}</span> 
              <span class="mont"> 23</span>: <span class="mont">59</span>: <span class="mont">00</span> 까지
            </h5>
          </div>

        </div>
      </div>

      <div class="flex justify-center mt-16 gap-4">
        <a class="sec_filled-blk_btn px-6 py-2" @click="goMyConfList" href="javascript:void(0)"><h5>신청 내역 보기</h5></a>
        <a class="sec_blk_btn px-6 py-2" @click="goList" href="javascript:void(0)"><h5>홈으로</h5></a>


      </div>
    </div>
    <!--승인실패시-->
    <div class="mt-40 mx-auto text-center" v-show="returnCode!='0000'&&payDevice=='pc'">
      <h1 class="text-blue-700">
        <font-awesome-icon icon="fa-solid fa-circle-check"/>
        <br/>결제에 실패하였습니다.
      </h1>
      <p class="mt-5">[사유]</p>
      <p class="mt-5">{{returnMsg}}</p>
    </div>

    <!-- 모바일 -->
    <div class="mt-40 mx-auto text-center" v-show="returnCode=='00'&&payDevice=='mobile'">
      <h1 class="text-blue-700">
        <font-awesome-icon icon="fa-solid fa-circle-check"/>
        <br/>신청이 완료되었습니다.
      </h1>
      <p class="mt-5">접수가 마감된 후에는 변경 및 취소가 어려우며</p>
      <p>지도자용 예배/활동 매뉴얼은 컨퍼런스 현장에서 구입가능합니다.</p>

      <!-- 가상계좌 결제일 경우 -->
      <div v-if="this.detail.pay_method == 'VBank'" class="mt-8">
        <h5 class="mb-4 text-blue-700">아래 가상계좌로 입금해 주시면 결제 완료처리 됩니다.</h5>
        <div class="max-w-[500px] mx-auto border m-4 p-4 text-left">
          <div class="mb-4">
            <p>계좌 정보</p>
            <div>
              <h5>{{ detail.paymentOthers.vact_bank_name }} <span class="mont">{{ detail.paymentOthers.vact_num }}</span></h5>
              <h5>예금주 : {{ detail.paymentOthers.vact_name }}</h5>
            </div>
          </div>
          <div class="mb-4">
            <p>결제 금액</p>
            <h5>
              <span class="mont">{{ Number(detail.tot_price).toLocaleString() }}</span>원
            </h5>
          </div>
          <div class="mb-4">
            <p>입금 기한</p>
            <h5>
              <span class="mont">{{ this.formatDate(detail.paymentOthers.vact_date) }}</span> 
              <span class="mont"> 23</span>: <span class="mont">59</span>: <span class="mont">00</span> 까지
            </h5>
          </div>

        </div>
      </div>

      <div class="flex justify-center mt-16 gap-4">
        <a class="sec_filled-blk_btn px-6 py-2" @click="goMyConfListApp" href="javascript:void(0)"><h5>신청 내역 보기</h5></a>
        <a class="sec_blk_btn px-6 py-2" @click="goList" href="/"><h5>홈으로</h5></a>

<!--        <b @click="reload"> 새로고침 </b>-->
      </div>
    </div>
    <!--승인실패시-->
    <div class="mt-40 mx-auto text-center" v-show="returnCode!='00'&&payDevice=='mobile'">
      <h1 class="text-blue-700">
        <font-awesome-icon icon="fa-solid fa-circle-check"/>
        <br/>결제에 실패하였습니다.
      </h1>
      <p class="mt-5">[사유]</p>
      <p class="mt-5">{{returnMsg}}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";

export default {
  name: "EduRegisterConfirmPage",
  data() {
    return {
      oid: "",
      payDevice: "",
      atPhone: "",
      detail: {
        pay_method: "",
      },
      returnCode : "",
      returnMsg : ""
    }
  },
  methods: {
    goList() {
      opener.location.href = "/"
      self.close()
    },
    goMyConfListApp() {
      const browserInfo = this.detectWebView();
      if( browserInfo == 'android' || browserInfo == 'ios' ){
        // opener.location.href = "/profile/confList/app"
        router.push({path: '/profile/confList/app'});
      }
      else {
        router.push({path: '/profile/confList'});
        // opener.location.href = "/profile/confList"
      }
    },

    goMyConfList() {
      const browserInfo = this.detectWebView();
      if( browserInfo == 'android' || browserInfo == 'ios' ){
        // opener.location.href = "/profile/confList/app"
        router.push({path: '/profile/confList/app'});
      }
      else {
        opener.location.href = "/profile/confList"
      }
      self.close()
    },

    reload() {
      location.reload();
    },

    async sendOkMessage() {
      try {
        // 핸드폰번호 유효성 검사
        const phoneReg = /^[0-9]*$/;
        if( this.atPhone.length > 10 && phoneReg.test(this.atPhone) ){

          const url = '/v2/sender/send';
          const body = [{
            message_type: 'AT',
            phn: this.atPhone,
            profile: '4ece7da6a5cd10889ee20c4048afad3b554e419f',
            msg: '[총회교육개발원]\n' +
                  '총회교육개발원 여름성경학교 컨퍼런스에 등록이 완료되었습니다. 감사합니다.',
            tmplId: 'conference_join_01',
            smsKind: 'S',
            msgSms: '[총회교육원] 컨퍼런스에 등록이 완료되었습니다. 감사합니다.',
            smsSender: '025595648',
          }];
          const response = await axios.post(url, body, {
            headers: {
              "Content-Type":"application/json",
              "userId": "eduwind"
            }
          });
          console.log(response.data);

        }
      } catch (error) {
        console.error(error);
      }
    },

    async getPaymentResult() {
      try {
        const url = `/api/payment/result?oid=${this.oid}&device=${this.payDevice}`;
        const response = await axios.get(url);
        console.log(response.data);
        if( response.data.err_code === 0 ){
          this.atPhone = response.data.contents.buyer_tel;
          // this.atPhone = '01020083033';
          if( this.atPhone ){
            await this.sendOkMessage();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async payResult() {


      try {
        // const response = await axios.get(`/api/payment/result?oid=${this.oid}&device=${this.payDevice}`);
        // console.log(response);
        /*console.log(response.data);
        console.log(response.data.contents);
        // const contents = JSON.stringify(response.data);
        const contents = JSON.parse(response.data);
        console.log(contents.contents);

        this.detail = response.data.contents.detail;

        console.log(this.detail)*/
      } catch (error) {
        console.error(error);
      }
    },

    formatDate(date) {
      const year = date.slice(0, 4);
      const month = date.slice(4, 6);
      const day = date.slice(6, 8);
      return `${year}-${month}-${day}`;
    },

    detectWebView() {
      // 사용자 에이전트 문자열을 가져옴
      const mobileType = navigator.userAgent.toLowerCase();
      let detect = "PC";
      if (mobileType.indexOf('android') > -1) {
        detect = "android";
      }
      else if (mobileType.indexOf('iphone') > -1 || mobileType.indexOf('ipad') > -1 || mobileType.indexOf('ipod') > -1) {
        detect = "ios";
      }

      return detect;
    }
  },

  created() {
    this.oid = this.$route.query.oid;
    this.payDevice = this.$route.query.payDevice;
    this.returnCode = this.$route.query.resultCode;
    this.returnMsg = this.$route.query.resultMsg;

    if( this.oid && this.payDevice ){
        // this.getPaymentResult();
    }

    if(this.returnCode == "0000" || this.returnCode == "00"){ //승인성공 시
      this.payResult();

      if(this.payDevice == "pc"){
        window.opener.location.replace('/profile/confList');
      }
    }
  },

  mounted() {


  }
}

</script>

<style scoped>
  .mont {
    font-weight: 500;
    letter-spacing: 0.05em;
  }
</style>