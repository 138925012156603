<template>
  <footer>
    <div class="max-w-[1350px] mx-auto text-xs font-normal leading-5">
      <div class="flex gap-7 mb-5">
        <router-link to="/policy/info" class="text-sm font-bold">개인정보 취급방침</router-link>
        <router-link to="/policy/guide" class="text-sm">이용약관</router-link>
        <router-link to="" class="text-sm">오시는 길</router-link>
      </div>
      <div class="">
        <div>
          (06177) 서울특별시 강남구 영동대로 330(대치동) 총회회관 6층 총회교육개발원&nbsp; |&nbsp;
          <svg class="inline" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <g clip-path="url(#clip0_131_3343)">
              <path d="M7.63675 9.37482C7.2555 9.37482 6.71996 9.23693 5.918 8.78888C4.94281 8.24201 4.18851 7.73712 3.21859 6.76974C2.28343 5.83517 1.82836 5.23009 1.19144 4.07111C0.47191 2.76251 0.594566 2.07658 0.731676 1.78341C0.894957 1.43302 1.13597 1.22345 1.4475 1.01544C1.62444 0.899513 1.81169 0.800135 2.00687 0.718569C2.0264 0.71017 2.04457 0.702163 2.06078 0.694936C2.15746 0.651381 2.30394 0.585561 2.48949 0.655873C2.61332 0.702358 2.72386 0.797475 2.89691 0.968373C3.25179 1.31837 3.73675 2.09787 3.91566 2.48068C4.03578 2.73869 4.11527 2.909 4.11546 3.10001C4.11546 3.32365 4.00296 3.49611 3.86644 3.68224C3.84086 3.7172 3.81546 3.7506 3.79086 3.78302C3.64222 3.97833 3.60961 4.03478 3.63109 4.13556C3.67464 4.3381 3.99945 4.94103 4.53324 5.47365C5.06703 6.00626 5.65257 6.31056 5.85589 6.35392C5.96097 6.37638 6.01859 6.3424 6.22015 6.18849C6.24906 6.16642 6.27875 6.14357 6.3098 6.12072C6.518 5.96583 6.68246 5.85626 6.90082 5.85626H6.90199C7.09203 5.85626 7.25472 5.93869 7.52425 6.07462C7.87582 6.25197 8.67875 6.73068 9.03089 7.08595C9.20218 7.25861 9.29769 7.36876 9.34437 7.4924C9.41468 7.67853 9.34847 7.82443 9.30531 7.92208C9.29808 7.9383 9.29007 7.95607 9.28168 7.9758C9.19946 8.17063 9.0995 8.35749 8.98304 8.534C8.77543 8.84455 8.56507 9.08498 8.2139 9.24845C8.03357 9.33375 7.83623 9.37696 7.63675 9.37482Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_131_3343">
                <rect width="10" height="10" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          02)559-5643&nbsp; |&nbsp; 
          <svg class="inline" xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
            <path d="M1.83317 9.66634C1.58109 9.66634 1.36521 9.57651 1.18555 9.39684C1.00588 9.21718 0.916199 9.00145 0.916505 8.74967V3.24968C0.916505 2.99759 1.00634 2.78172 1.186 2.60205C1.36567 2.42238 1.58139 2.3327 1.83317 2.33301H9.1665C9.41859 2.33301 9.63446 2.42284 9.81413 2.60251C9.9938 2.78218 10.0835 2.9979 10.0832 3.24968V8.74967C10.0832 9.00176 9.99334 9.21763 9.81367 9.3973C9.634 9.57697 9.41828 9.66665 9.1665 9.66634H1.83317ZM5.49984 6.45801L9.1665 4.16634V3.24968L5.49984 5.54134L1.83317 3.24968V4.16634L5.49984 6.45801Z" fill="white"/>
          </svg>
          eduwind.org@gmail.com
        </div>
        <div>
          (재)대한예수교장로회총회&nbsp; |&nbsp; 대표자 배광식&nbsp; |&nbsp; 사업자등록번호 120-82-00479&nbsp; |&nbsp; 통신판매업신고번호 강남-1505
        </div>
        <div>
          Copyright © http://총회교육.com. All rights reserved.
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent"
}
</script>

