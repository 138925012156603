<template>
  <div id="member-management">
    <div class="adm_box_wrapper">
      <section class="flex justify-between items-baseline p-5 border-b border-gray-200">
        <div>
          <h3 class="font-medium">회원 목록</h3>
          <p class="text-neutral-500">Total : {{ state.members.length }}</p>
        </div>

        <AdminFilterComponent/>
      </section>
      <!--<ul>
        <li v-for="(member, idx) in state.members" :key="idx">{{ member }}</li>
      </ul>-->
  
      <table class="w-full table-auto table_wrapper">
        <thead class="text-left">
          <tr>
            <th>이름(아이디)</th>
            <th>전화번호</th>
            <th>이메일</th>
            <th>교회명</th>
            <th>가입날짜</th>
            <th>회원구분</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(member, idx) in membersOnCurrentPage" :key="idx">
            <td>{{ member.name }}({{ member.uid }})</td>
            <td v-if="member.phone">{{ formattedPhone(member.phone) }}</td>
            <td v-else> {{member.phone}} </td>
            <td>{{ member.email }}</td>
            <td><!-- 교회명 --></td>
            <td><!-- 가입날짜 --></td>
            <td><!-- 회원구분(카카오,네이버,일반) --></td>
            <td>
              <VDialog :classes="{ bg: 'bg-black/40' }">
                <template #default="{ close }">
                  <!-- 참석자 목록 -->
                  <div class="color-black bg-white min-w-[500px] max-h-[80vh] overflow-y-auto">
                    <section class="flex justify-between p-4 border-b border-gray-200">
                      <h5>회원정보 편집</h5>
                      <button @click="close">
                        <h4><font-awesome-icon icon="fa-solid fa-circle-xmark" /></h4>
                      </button>
                    </section>
                    <div class="signup_form field_input mb-5 p-4">
                      <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0">
                        <label class="inline-block w-1/5">이름<span></span></label>
                        <input type="text" v-model="member.name" class="px-4 py-2 border">
                      </div>
              
                      <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0">
                        <label class="inline-block w-1/5">아이디<span></span></label>
                        <input type="text" v-model="member.uid" disabled class="px-4 py-2 border">
                      </div>

                      <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0">
                        <label class="inline-block w-1/5">연락처<span></span></label>
                        <input type="text" v-model="member.phone" class="px-4 py-2 border">
                      </div>
              
                      <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0">
                        <label class="inline-block w-1/5">이메일<span></span></label>
                        <input type="text" v-model="member.email" class="px-4 py-2 border">
                      </div>

                      <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0">
                        <label class="inline-block w-1/5">교회명<span></span></label>
                        <input type="text"  class="px-4 py-2 border">
                      </div>
                    </div>
                    <button type="button" class="sec_blk_btn lg:px-5 p-2 flex mx-auto mb-4" @click="memberInfoUpdate(member)" >정보수정</button>
                  </div>
                </template>

                <template #toggle="{ bind, on }">
                  <button v-bind="bind" v-on="on">[편집]</button>
                </template>
              </VDialog>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="flex justify-center py-5">
        <v-pagination
          v-model="state.currentPage"
          :pages="totalPages"
          :range-size="2"
          @update:modelValue="handlePageChange"
        />
      </div>

    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue"
import axios from "axios"
import { VDialog } from 'vuetensils/src/components'
import AdminFilterComponent from "@/components/AdminFilterComponent.vue"

import VPagination  from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"

export default {
  name: "MemberManagementPage",
  components: { AdminFilterComponent, VDialog, VPagination  },

  setup () {
    const state = reactive({
      members: [],
      currentPage: 1,
      pageSize: 30, //페이지에 30명씩
    })

    axios.get('/api/members').then(response => {
      state.members = response.data.contents;
    }).catch(error => {
      console.error(error)
    })

    // 총 페이지수 계산
    const totalPages = computed(() =>
      Math.ceil(state.members.length / state.pageSize)
    )

    //회원 목록 slice
    const membersOnCurrentPage = computed(() => {
      const start = (state.currentPage - 1) * state.pageSize
      const end = start + state.pageSize
      return state.members.slice(start, end)
    })

    //페이지 변경
    const handlePageChange = (newPage) => {
      state.currentPage = newPage
    }

    //회원 정보 수정
    const memberInfoUpdate = (member) => {
      console.log(member);

      // 핸드폰번호 숫자만 추출
      const phone = member.phone.replace(/[^0-9]/g, '');
      const body = {
          idx: member.idx,
          name: member.name,
          phone: phone,
          email: member.email
      }
      axios.put('/api/member/update', body).then(response => {
        if( response.data.err_code == 0 ){
          alert('회원정보가 수정되었습니다.');
          // document.location.href = '/management';
        }
      }).catch(error => {
        console.error(error)
      })

      /*axios.put('/api/members', member).then(response => {
        console.log(response)
      }).catch(error => {
        console.error(error)
      })*/
    }

    const formattedPhone = (phone) => {
      return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1 - $2 - $3');
    }

  

    return { 
      state, formattedPhone, memberInfoUpdate,
      totalPages, membersOnCurrentPage, handlePageChange
    }
  }
}
</script>


