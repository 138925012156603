import axios from 'axios';
import { ref } from 'vue';

export const editCart = (itemId, quantity, isSelected, command, optionId) => {
  const Data = {
    itemId: ref(itemId).value,
    quantity: ref(quantity).value, // 상품 수량
    isSelected: ref(isSelected).value, // 선택 여부
    command: ref(command).value, // 추가(CREATE) 삭제(DELETE)
    optionId: ref(optionId).value // 이미지 라이센스 아이디
  };

  return axios.post('/new_api/customer/cart/edit', Data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  })
  .then(res => {
    console.log(res.data.code);
    console.log(res.data.message);

    if (res.data.code === '401') {
      // 비회원인 경우
      alert('로그인 후 이용 가능합니다.'); // 로그인 팝업 띄우도록 추가할 것
      return;
    }
    
    return res;
  })
  .catch(error => {
    alert('시스템 오류입니다. 잠시 후 다시 시도해주세요.');
    console.error(error);
  });
};
