<template>
  <appNavigator :page="pageName" v-if="pageName === 'helpPageApp'" />
  <div class="lg:flex flex-1" >
    <helpPage class="flex-1"/>
  </div>
</template>

<script>
import helpPage from "@/pages/helpPage.vue";
import AppNavigator from "@/components/appNavigator.vue";

export default {
  name: 'helpView',
  components: {
    AppNavigator,
    helpPage,
  },
  data() {
    return {
      pageName: this.$route.name
    }
  },
  mounted() {
  }
}
</script>
