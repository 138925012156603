<template>
  <div class="lg:flex flex-1" >
    <LNavComponent />
      <div class="lg:w-[1000px] p-5 mx-auto mt-8">
        <div>
            <component :is="currentComponent"/>
          </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { shallowRef } from 'vue'
  
  import PolicyInfoPage from "@/pages/PolicyInfoPage.vue"
  import PolicyGuidePage from "@/pages/PolicyGuidePage.vue";
  import { watchEffect } from 'vue'
  import { useRoute } from 'vue-router'
  import LNavComponent from '@/components/LNavComponent.vue';
  import FooterComponent from "@/components/FooterComponent.vue";
  
  const currentComponent = shallowRef(PolicyInfoPage)
  
  const route = useRoute()
  
  watchEffect(() => {
    if (route.name === "PolicyInfo") {
      currentComponent.value = PolicyInfoPage;
    } else {
      currentComponent.value = PolicyGuidePage;
    }
  })
  </script>