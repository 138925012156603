const address = {

	execDaumPostcode(callback) {
		new window.daum.Postcode({
			oncomplete: (data) => {
				// console.log(data);
				let extraAddress = "";
				let address = "";
				let postcode = "";
				if (extraAddress !== "") {
					extraAddress = "";
				}
				if (data.userSelectedType === "R") {
					// 사용자가 도로명 주소를 선택했을 경우
					address = data.roadAddress;
				} else {
					// 사용자가 지번 주소를 선택했을 경우(J)
					address = data.jibunAddress;
				}

				// 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
				if (data.userSelectedType === "R") {
					// 법정동명이 있을 경우 추가한다. (법정리는 제외)
					// 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
					if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
						extraAddress += data.bname;
					}
					// 건물명이 있고, 공동주택일 경우 추가한다.
					if (data.buildingName !== "" && data.apartment === "Y") {
						extraAddress +=
							extraAddress !== ""
								? `, ${data.buildingName}`
								: data.buildingName;
					}
					// 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
					if (extraAddress !== "") {
						extraAddress = `(${extraAddress})`;
					}
				} else {
					extraAddress = "";
				}
				// 우편번호를 입력한다.
				postcode = data.zonecode;

				const result = {
					postcode: postcode,
					address: address,
					extraAddress: extraAddress
				}
				callback(result);
			},
		}).open();
	},

};

export default address;