<template>
  <div class="lg:flex flex-1" >
    <LNavComponentTest/>

      테스트 페이지 입니다.

  </div>
</template>

<script>
import LNavComponentTest from "@/components/LNavComponentTest.vue"
import EduListRegisterPage from "@/pages/EduListRegisterPage.vue"
//import EduListRegisterPage2 from "@/pages/EduListRegisterPage2.vue"

export default {
  name: "test",
  components: {EduListRegisterPage, LNavComponentTest}
}
</script>

<style scoped>

</style>
