<template>
  <div id="member-management">
    <div class="adm_box_wrapper">
      <section class="flex justify-between items-baseline p-5 border-b border-gray-200">
        <div>
          <h3 class="font-medium">컨퍼런스 취소 신청 목록</h3>
          <p class="text-neutral-500">Total : {{state.items.length}} 건</p>
        </div>
      </section>
      <!--<ul>
        <li v-for="(member, idx) in state.members" :key="idx">{{ member }}</li>
      </ul>-->

      <div>
        <div class="p-2 flex justify-end">
          <select v-model="state.status" @change="getCancelList" class="border p-1 text-sm">
            <option value="all">전체분류</option>
            <option value="취소신청">취소신청</option>
            <option value="처리완료">처리완료</option>
          </select>
          <select v-model="state.searchField" class="border text-sm mx-1">
            <option value="name"> 이름(아이디) </option>
            <option value="oid"> 주문번호 </option>
            <option value="church"> 교회명 </option>
          </select>
          <input type="text" v-model="state.searchValue" class="border text-sm px-2" placeholder="검색어 입력">
        </div>
        <div class="text-center">
          <Vue3EasyDataTable
            :headers="headers"
            :items="state.items"
            :search-field="state.searchField"
            :search-value="state.searchValue"
            alternating
          >
            <template #item-type="{idx, type, count}">
              <span v-if="type === '전체'"> 전체 </span>
              <span v-else>부분 - <b class="cursor-pointer" @click="openPersonList(idx)">{{count}}</b></span>
            </template>
            <template #item-status="{status, statusColor}">
              <span :class="statusColor">{{status}}</span>
            </template>
            <template #item-button="{idx, status}">
              <span v-if="status === '취소신청'" class="cursor-pointer" @click="handleConfirm(idx)"> 승인 </span>
            </template>
          </Vue3EasyDataTable>
        </div>

      </div>

    </div>

    <div class="modal" v-show="state.personListModal === true">
      <div class="overlay" @click="state.personListModal = false"></div>
      <div class="modal-card">
        <div id="AdmModal">
          <div class="flex justify-center mt-5">
            <h5> 취소 신청 인원 </h5>
  <!--          <div><img src="@/assets/img/my_pop_close.svg" class="w-11 max-w-[8vw] cursor-pointer" alt="닫기"></div>-->
          </div>
          <div class="p-5">
<!--            <table class="table-auto w-full">-->
            <table class="min-w-full text-left text-sm font-light">
              <thead class="border-b font-medium dark:border-neutral-500">
                <tr>
                  <th scope="col" class="px-6 py-3 text-center">idx</th>
                  <th scope="col" class="px-6 py-3 text-center">이름</th>
                  <th scope="col" class="px-6 py-3 text-center">교회명</th>
                  <th scope="col" class="px-6 py-3 text-center">상태</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="person in state.personList" class="text-center" :class="{
                  'text-red-500': person.is_cancel === 'Y'
                }">
                  <td class="whitespace-nowrap px-6 py-3 border-b">{{person.idx}}</td>
                  <td class="whitespace-nowrap px-6 py-3 border-b">{{person.name}}</td>
                  <td class="whitespace-nowrap px-6 py-3 border-b">{{person.church}}</td>
                  <td class="whitespace-nowrap px-6 py-3 border-b">
                    <span v-if="person.is_cancel == 'Y'">취소</span>
                    <span v-else-if="person.is_cancel == 'N'">등록</span>
                    <span v-else> - </span>
                    <span v-if="person.is_cancel">({{person.is_cancel}})</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex justify-center">
            <button class="sec_filled-blk_btn block w-20 p-2" @click="state.personListModal = false">닫기</button>
          </div>
        </div>
      </div>
    </div>

  </div>


</template>

<script setup>
import { reactive } from "vue"
import axios from "axios"

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

const state = reactive({
  status: 'all',
  searchField: 'name',
  searchValue: '',
  items: [],
  personListModal: false,
  personList: []
})

const headers = reactive([
  { text: "주문번호", value: "oid", sortable: true },
  { text: "컨퍼런스", value: "conference" },
  { text: "부서", value: "dept" },
  { text: "신청자", value: "name" },
  { text: "교회명", value: "church" },
  { text: "취소인원", value: "type" },
  { text: "환불금액(원)", value: "price" },
  { text: "취소신청일", value: "created_date", sortable: true },
  { text: "취소완료일", value: "confirm_date", sortable: true },
  { text: "[현재상태]", value: "status" },
  { text: "승인", value: "button" },
])

// let items = reactive([]);

const setStatusColor = (status) => {
  switch (status) {
    case '처리완료':
      return 'text-blue-600';
    case '등록취소':
      return 'text-orange-600';
    case '취소신청':
      return 'text-red-600';
    default:
      return 'text-gray-600';
  }
}

const getCancelList = async () => {
	state.items = [];
  const url = `/api/payment/cancels?status=${state.status}`;
  const { data } = await axios.get(url);
  const { contents } = data;
	contents.forEach((item) => {
    let statusColor = setStatusColor(item.status);
		let setItem = {
      idx: item.idx,
      oid: item.registerConference.oid + " (" + item.idx + ")",
      conference: item.registerConference.conferenceDetail.place,
      dept: item.registerConference.conferenceDetail.dept,
      name: item.registerConference.name,
      church: item.registerConference.church,
      type: item.type,
      count: item.count,
      price: item.price.toLocaleString('ko-kr'),
      created_date: formattedDate(item.created_date),
      confirm_date: formattedDate(item.confirm_date),
      status: item.status,
      statusColor: statusColor,
      button: '승인'
    };
    state.items.push(setItem);
	})
}

getCancelList();

const formattedPhone = (phone) => {
  if (phone.length === 11) {
    return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1 - $2 - $3');
  } else {
    return phone;
  }
}

const formattedDate = ( date ) => {
  if (date) {
    date = new Date(date);
    return date.toLocaleString();
  }
  else {
    return '-';
  }
}

const handleConfirm = async (idx) => {
  if( confirm('취소를 승인 하시겠습니까?') === true ){
    const url = `/api/payment/confirmCancels/${idx}`;
    const {data} = await axios.put(url);
    if( data.err_code === 0 ){
      alert("승인되었습니다.");
      await getCancelList();
    }
    else {
      alert(data.err_msg);
      return false;
    }
  }
}

const openPersonList = (idx) => {
  getCancelPersonList(idx);
  state.personListModal = true;
}

const getCancelPersonList = async (idx) => {
  state.personList = [];
  const url = `/api/payment/cancel/regmember/list/${idx}`;
  const {data} = await axios.get(url);
  console.log(data);
  if( data.err_code === 0 ){
    state.personList = data.contents;
  }
  else {
    alert(data.err_msg);
    return false;
  }
}

</script>

<style scoped>
/* Modal */
.modal,
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  z-index: 2;
}
.overlay {
  opacity: 0.5;
  background-color: black;
}
.modal-card {
  position: relative;
  margin: auto;
  width: 500px;
  background-color: white;
  z-index: 10;
  opacity: 1;
}
#AdmModal {
  margin-bottom: 15px;
}
.close {
  cursor: pointer;
}
.btn-black-100 {
  cursor: pointer;
}
</style>