<template>
<!--  <RouterView />-->
  <router-view :key="$route.fullPath" />
</template>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
<script>
import axios from "axios";
import store from "@/store";
import {useRoute} from "vue-router";
import {watch, onMounted } from "vue";

export default {
  name: 'App',
  setup () {
    /*const check = () => {
      axios.get('/api/account/check').then(({data}) => {
        console.log(data.contents);
        const idx = data.contents
        store.commit('setAccount', idx || 0);
        getMember(idx)
      })
    }
    const route = useRoute();

    const getMember = (idx) => {
      if(idx) {
        axios.get(`/api/member/${idx}`).then(({data}) => {
          if(data.err_code) {
            alert(data.err_msg)
          } else {
            store.commit('setLevel', data.contents.mlevel)
            store.commit('setUid', data.contents.uid)
          }
        })
      }
      return
    }

    watch(route, () => {
      check()
    })*/

    // 페이지 이동시 스크롤 위치 초기화
    onMounted(() => {
      window.scrollTo(0, 0)
    })
  }
}
</script>
