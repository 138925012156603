<template>
  <button class="naver_btn rounded-full p-4" @click="naverLogin">
    <img src="@/assets/img/login_naver.jpg" alt="네이버로그인"/>
  </button>
</template>

<script>

export default {
    name: "naverLoginComponent",
    methods: {
      naverLogin() {
        // alert('네이버 로그인 준비중 입니다.');
        const client_id = "4Fohm70rxjaHecEZLAiH";
        const callback_url = "https://nodeblack.net/api/naver/login";
        //const callback_url = "http://localhost:8080/api/naver/login";
        //const callback_url = "https://eduwind.node-www.com/naverLoginCheck";
        const states = "1234";

        const url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${client_id}&redirect_uri=${callback_url}&state=${states}`;

        console.log("==================url====================");
        console.log(url);

        window.location.href=url;
      }

    }

}
</script>

<style scoped>

</style>