<script setup>
import productRequest from '@/components/productRequest.vue';
import addPayment from '@/components/addPayment.vue';

import axios from 'axios';
import {computed, onMounted, ref} from "vue";
import {CARD_TYPE, formatOrderStatus, numberWithCommas, returnTypeTxt, paymentRefundTypeTxt, paymentRefundStatusTxt, status} from "@/assets/js/util"
import { useAppProductRequest } from '@/stores/appProductRequest';
const store = useAppProductRequest();
import { useRoute } from 'vue-router';
const route = useRoute();

const orderInfo = ref({});
const deliveryList = ref({});
const orderContentList = ref({});
const paymentInfo = ref({});
const paymentList = ref({});

const orderContentPrice = ref(0);


const toggleAddPayment = ( itemOid ) => {
  console.log(itemOid);

  store.addPaymentItemInfo = itemOid;
  store.addPaymentInfo.orderExtraOid = itemOid.oid;

  store.setDisplayAddPayment(true);
}

const getListDetail = async () => {
  try {
    // customer/order/v2/order-result/
    // const url = `/new_api/customer/order/detail/${route.params.id}`;
    const url = `/new_api/customer/order/v2/order-result/${route.params.id}`;
    const { status, data } = await axios.get(url);
    if( status === 200 ){
      if( data.code === '200' ){
        const getData = data.data;
        // 항목별 분리
        ({
          deliveryList: deliveryList.value,
          contentList: orderContentList.value,
          paymentInfo: paymentInfo.value,
          paymentList: paymentList.value
        } = getData);

        // getData 에서 deliveryList, contentList, paymentInfo 를 삭제
        delete getData.deliveryList;
        delete getData.contentList;
        delete getData.paymentInfo;
        delete getData.paymentList;

        orderInfo.value = getData;
        orderInfo.value.confirm_date = orderInfo.value.confirm_date.replace('T', ' ').replace('Z', '');

        contentPrice();
      }
    }
  } catch (err) {
    console.error(err);
  }
};

// 콘텐츠 가격
const contentPrice = () => {
  orderContentPrice.value = orderContentList.value.reduce((acc, cur) => acc + cur.regularPrice, 0);
};

const toggleProductRequest = () => {
  store.requestOID = orderInfo.value.oid;
  store.requestOrderId = orderInfo.value.orderId;
  store.getOrderItemList();
  store.setSwitchProductRequest(true);
  // store.switchProductRequest = !store.switchProductRequest;
};

onMounted(async () => {
  await getListDetail();
  store.requestOID = orderInfo.value.oid;
  await store.getProductRequestList();
});
</script>

<template>
  <main class="w-full max-w-[1200px] mx-auto mb-20">
    <h1 class="font-bold text-center my-8">주문 상세정보</h1>
    <div class="mt-10 max-lg:px-5 max-lg:mt-10">
      <div class="flex justify-between w-full items-center">
        <div>
          <p>주문번호 : {{ orderInfo.oid }}</p>
        </div>
        <div>
          <p>주문일 : {{ orderInfo.confirm_date }}</p>
        </div>
<!--        <div v-if="orderInfo.tid">
          <a :href="'https://iniweb.inicis.com/receipt/iniReceipt.jsp?noTid=' + orderInfo.tid" type="button" class="sec_blk_btn px-2">영수증 조회</a>
        </div>-->
      </div>

      <h2 class="font-bold mt-10">상품</h2>
      <div class="cart_grid_container order">

        <div class="cart_grid_container" v-for="(warehouse, wareKey) in deliveryList">
          <!-- 상품 목록 -->
          <h3 class="font-bold text-2xl"> - {{ warehouse.brandName }} </h3>
          <div class="cart_grid_wrapper mt-2">
            <div class="cart_grid_header2">
              <!-- 선택 체크/체크해제 저장되도록 수정 필요 -->
              <div></div>
              <div>상품정보</div>
              <div>옵션</div>
              <!-- <div>수량</div> -->
              <div>주문 금액</div>
              <div>상태</div>
              <div>배송</div>
            </div>

            <!-- {{ warehouse.orderProductList }} -->

            <div class="cart_grid_body2" v-for="product in warehouse.orderProductList" :key="product.orderItemId">
              <div class="w-20 h-14 flex items-center justify-center border border-neutral-200 rounded-xl overflow-hidden">
                <router-link :to="'/mall/view/' + product.itemId" class="flex justify-center items-center w-full h-full">
                  <img v-if="product.thumbnailLink" :src="'https://eduwind-api.nodeblack.net/' + product.thumbnailLink" class="w-full h-full object-cover"/>
                  <img v-else src="@/assets/img/null_img.jpg"/>
                </router-link>
              </div>
              <div>
                <div class="text-left">
                  <router-link :to="'/mall/view/' + product.orderItemId"  class="text-xl max-lg:text-sm">
                    {{product.orderItemTitle}}
                  </router-link>
                </div>
              </div>
              <div>
                <div class="justify-center">
                  <!-- 옵션 -->
                  <div v-if=" product.optionList.length > 0 ">
                    <ul>
                      <li v-for="option in product.optionList" class="productOptionList">
                        <div>
                          <span v-for=" ( item, pathIndex ) in option.infoDetailList ">
                            <span> {{ item.optionName }} > {{ item.elementName }} </span>
  <!--                          <span v-if="pathIndex < (option.optionPathNames.length-1)"> > </span>-->
                          </span>
                        </div>
                        <span class="px-2 text-sm text-gray-300"> / </span>
                        <div> {{ option.quantity }} </div>
                        <span class="px-2 text-sm text-gray-300"> / </span>
                        <div> {{ option.totalOptionPrice }}원 </div>
                      </li>
                    </ul>
                  </div>
                  <div v-else>
                    수량 : {{ product.quantity }}
                  </div>
                </div>
              </div>

              <div class="product_price flex-col">
                <div v-if="product.isFree === true">
                  무료
                </div>
                <div v-else>
                  {{product.sellingPrice.toLocaleString('ko-kr')}}원
                </div>
              </div>
              <div>
                {{ formatOrderStatus(product.orderStatus) }}
              </div>
              <div>
                <div>{{ status.getProductStatus(product.deliveryStatus) }}</div>
                <div class="text-xs" v-if="product.orderStatus === 'ORDER_DONE' && product.invoiceNumber && product.deliveryCompany ">
                  {{ product.deliveryCompany }} - {{ product.invoiceNumber }}
                </div>
              </div>
            </div>

          </div>

          <div class="p-5 bg-neutral-100 mb-3">
            <div class="productPriceInfo">
              <div class="productPriceItem">
                <div class="itemTitle"> 총 상품 금액 </div>
                <div class="itemPrice">
                  <b>{{ warehouse.originalPurchasePrice.toLocaleString('ko-kr') }}</b>  원
                </div>
              </div>

              <div class="productPriceItem">
                <div class="itemTitle"> 할인 금액 </div>
                <div class="itemPrice">
                  <b>{{ warehouse.discountAmount.toLocaleString('ko-kr') }}</b> 원
                </div>
              </div>

              <!--
              <div class="productPriceItem">
                <div class="itemTitle"> 구매가 </div>
                <div class="itemPrice">
                  {{ warehouse.productOrderPurchasePrice.toLocaleString('ko-kr') }} 원
                </div>
              </div>
              -->

              <div class="productPriceItem">
                <div class="itemTitle"> 배송비 </div>
                <div class="itemPrice">
                  <b>{{ warehouse.deliveryFee.toLocaleString('ko-kr') }}</b> 원
                </div>
              </div>

              <div> | </div>

              <div class="w-[200px] flex justify-end items-center">
                <div class="text-sm mr-3"> 결제금액 </div>
                <div class="text-xl">
<!--                    <b class="text-2xl text-red-500"> {{ warehouse.productPaidAmount.toLocaleString('ko-kr') }} </b> 원-->
                  <b class="text-2xl text-red-500">
                    {{ (warehouse.paidAmount + warehouse.deliveryFee).toLocaleString('ko-kr') }}
                  </b> 원
                </div>
              </div>

<!--                <div>
                총 주문금액
                <h4 class="ml-2 font-semibold text-red-500">{{ warehouse.productPaidAmount.toLocaleString('ko-kr') }}</h4>
                <b class="ml-1">원</b>
              </div>-->
            </div>
          </div>

          <div>
            <!-- divider -->
            <div class="border-t border-neutral-200 my-3"></div>
          </div>

        </div>

      </div>


      <div v-if="orderContentList.length > 0">
        <h2 class="font-bold mt-10">콘텐츠</h2>
        <div class="cart_grid_container">
          <div class="cart_grid_wrapper mt-2">
            <div class="cart_grid_header2">
              <!-- 선택 체크/체크해제 저장되도록 수정 필요 -->
              <div></div>
              <div>상품정보</div>
              <div>유형</div>
              <!-- <div>수량</div> -->
              <div>주문 금액</div>
            </div>
            <div class="cart_grid_body2" v-for="item in orderContentList" :key="item.orderItemId">
              <div class="w-20 h-14 flex items-center justify-center border border-neutral-200 rounded-xl overflow-hidden">
                <router-link :to="'/mall/view/' + item.orderItemId" class="flex justify-center items-center w-full h-full">
                  <img v-if="item.thumbnailLink" :src="'https://eduwind-api.nodeblack.net/' + item.thumbnailLink" class="w-full h-full object-cover"/>
                  <img v-else src="@/assets/img/null_img.jpg"/>
                </router-link>
              </div>
              <div>
                <div class="text-left">
                  <!-- <p class="text-sky-500 max-lg:text-sm">2023 겨울성경학교 청소년부</p> -->
                  <router-link :to="'/mall/view/' + item.orderItemId"  class="text-xl max-lg:text-sm">{{item.orderItemTitle}}</router-link>
                </div>
              </div>
              <div>
                <div class="product_tags justify-center">
                  <span>{{item.itemType}}</span>
                </div>
              </div>

              <div class="product_price flex-col">
                <div v-if="item.isFree === true">
                  무료
                </div>
                <div v-else>
                  {{item.regularPrice.toLocaleString('ko-kr')}}원
                </div>
              </div>

            </div>
          </div>
          <div class="p-5 bg-neutral-100">

            <div class="productPriceInfo">
              <!--
              <div class="productPriceItem">
                <div class="itemTitle"> 총 상품 금액 </div>
                <div class="itemPrice">
                  <b> 0 </b>  원
                </div>
              </div>

              <div class="productPriceItem">
                <div class="itemTitle"> 할인 금액 </div>
                <div class="itemPrice">
                  <b> 0 </b> 원
                </div>
              </div>

              <div> | </div>
              -->

              <div class="w-[200px] flex justify-end items-center">
                <div class="text-sm mr-3"> 결제금액 </div>
                <div class="text-xl">
                  <b class="text-2xl text-red-500"> {{ orderContentPrice.toLocaleString('ko-kr') }} </b> 원
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <h3 class="font-bold mt-10">주문자 정보</h3>
      <div class="bg-neutral-100 p-6 flex" v-if="orderInfo.orderBuyer">
        <div class="w-1/2">
          <div class="flex gap-4 py-3">
            <b class="w-12">이름</b>
            <p>{{orderInfo.orderBuyer.buyerName}}</p>
          </div>
          <div class="flex gap-4 py-3">
            <b class="w-12">연락처</b>
            <p>{{orderInfo.orderBuyer.buyerTel}}</p>
          </div>
        </div>
        <div class="w-1/2">
          <div class="flex gap-4 py-3">
            <b class="w-12">이메일</b>
            <p>{{orderInfo.orderBuyer.buyerEmail}}</p>
          </div>
          <div class="flex gap-4 py-3">
            <b class="w-12">주소</b>
            <div>
              <p>[{{orderInfo.orderBuyer.buyerZipCode}}] {{orderInfo.orderBuyer.buyerAddress}} {{orderInfo.orderBuyer.buyerDetailedAddress}}</p>
            </div>
          </div>
        </div>
      </div>

      <h3 class="font-bold mt-10">결제 정보</h3>
      <div class="bg-neutral-100 p-6 flex">
        <div class="flex gap-4 py-3 w-1/2">
          <b>결제방법</b>
          <div
            v-if="orderInfo.gopaymethod==='Card' || orderInfo.gopaymethod==='VCard'"
            class="flex gap-2 items-center"
          >
            <div> 카드결제 </div>
            <div> {{ CARD_TYPE(paymentInfo.card_Code) }} - {{ paymentInfo.card_Num }} </div>
          </div>
          <div v-if="orderInfo.gopaymethod==='DirectBank'"> 실시간계좌이체 </div>
          <div v-if="orderInfo.gopaymethod==='VBank'"> 가상계좌 </div>
        </div>
        <div class="flex gap-4 py-3 w-1/2">
          <b>결제상태</b>
          <p v-if="orderInfo.orderStatus==='ORDER_DONE'"> 결제완료 </p>
        </div>
      </div>

      <h3 class="font-bold mt-10">결제 내역</h3>
      <div>
        <div class="flex gap-4 w-full" v-if="paymentList.length > 0">
          <table class="w-full border p-2 paymentTable">
            <thead>
              <tr>
                <th>결제명</th>
                <th>결제방법</th>
                <th>결제금액(원)</th>
                <th>상태</th>
                <th>영수증</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in paymentList" class="even:bg-neutral-100 odd:bg-white">
                <td class="text-center">{{ (item.goodName)? item.goodName : "추가결제" }}</td>
                <td class="text-center">{{ status.formatPayMethod(item.payMethod) }}</td>
                <td class="text-center">{{ numberWithCommas(item.paidAmount) }}</td>
                <td class="text-center">
                  {{ item.orderStatus }}
                  <div v-if="item.orderStatus === '(추가)결제생성'">
                    <button type="button" class="sec_blk_btn px-2" @click="toggleAddPayment(item)"> 추가 결제 </button>
                  </div>
                </td>
                <td class="text-center">
                  <span v-if="item.orderStatus === '주문완료' || item.orderStatus === '결제완료'">
                    <a class="sec_blk_btn px-2" :href="'https://iniweb.inicis.com/receipt/iniReceipt.jsp?noTid=' + item.tid" target="_blank">영수증 조회</a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else> 등록된 정보가 없습니다. </div>
      </div>

      <!--
      <h3 class="font-bold mt-10">결제 내역</h3>
      <div class="bg-neutral-100 p-6">
        <div class="flex gap-4 py-3">
          <b>결제방법</b>
          <div>
          </div>
        </div>
      </div>
      -->

      <h3 class="font-bold mt-10">반품, 교환, 취소 목록</h3>
      <div>
        <div v-if="store.productRequestList.length > 0">
          <table class="w-full border p-2 paymentTable">
            <tr>
              <th>분류</th>
              <th>결제방법</th>
              <th>제목</th>
              <th>등록일</th>
              <th>상태</th>
            </tr>
            <tr v-for="item in store.productRequestList">
              <td class="text-center">{{ paymentRefundTypeTxt(item.refundType) }} - {{ returnTypeTxt(item.reasonType) }}</td>
              <td class="text-center">{{ (item.method) ? item.method : '-' }}</td>
              <td class="text-center">{{ item.title }}</td>
              <td class="text-center">{{ item.requestDateTime }}</td>
              <td class="text-center">{{ paymentRefundStatusTxt(item.refundStatus) }}</td>
            </tr>
          </table>
        </div>
        <div v-else class="w-full text-center"> 등록된 정보가 없습니다. </div>

        <div class="flex justify-end">
          <button type="button" class="sec_blk_btn px-2" @click="toggleProductRequest"> 반품, 교환, 취소 신청 </button>
        </div>

      </div>
      <!-- <h3 class="font-bold">유의 사항</h3>
      <div class="bg-neutral-100 p-6">

      </div> -->

    </div>

    <div class="flex justify-center mt-20">

      <button
        type="button"
        class="border py-2 px-4 rounded-md hover:bg-gray-300 transition duration-200"
        @click="$router.go(-1)"
      > 주문목록 </button>

    </div>

    <div>
      <product-request v-if="store.switchProductRequest" />
    </div>

  </main>

  <div>
    <add-payment v-if="store.displayAddPayment" />
  </div>
</template>



<style scoped>
.cartBackground {
  background-color: #f9f9f9;
  @apply flex flex-col w-full;
}

.cartDivisionBox {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  padding: 1rem;
  border-radius: 16px;
  margin-bottom: 2rem;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.09);
}


.productOptionList {
  display: flex;
  align-items: baseline;
  padding: 0.5rem 0;
}

.productPriceInfo {
  @apply flex items-center justify-end gap-5;
}

.productPriceInfo .productPriceItem {
  @apply w-[100px];
}

.productPriceInfo .productPriceItem > .itemTitle {
  @apply text-sm;
}

.productPriceInfo .productPriceItem > .itemPrice {
  @apply text-xl;
}

.addressAlign {
  @apply flex items-center;
}
.addressAlign div {
  @apply items-center;
}
.paymentTable {
  @apply mt-1 mb-5;
}
.paymentTable th,
.paymentTable td {
  @apply py-2;
}
.paymentTable th,
.paymentTable td {
  border-bottom: 1px solid #e5e5e5;
}
</style>