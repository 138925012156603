<template>
  <!-- pageInfo : {{ pageInfo }} -->

  <ul class="pagination_wr flex items-center justify-center gap-4 text-lg my-14">
    <li class="flex items-center gap-2">
<!--      <button @click="goPrevPageList" :disabled="pageInfo.page === 1">-->
      <button @click="goPrevPageList" v-if=" pageInfo.prev > 1 ">
        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 19L12.7071 12.7071C12.3166 12.3166 12.3166 11.6834 12.7071 11.2929L19 5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11 19L4.70711 12.7071C4.31658 12.3166 4.31658 11.6834 4.70711 11.2929L11 5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </li>

    <li class="flex mr-2">
      <!-- chevron 버튼 -->
<!--      <button @click="onPageClick(pageInfo.page - 1)" :disabled="pageInfo.page === 1">-->
      <button @click="onPageClick(pageInfo.page - 1)" v-if="pageInfo.page > 1">
<!--        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.69826 18.2942H15.5677C17.6114 18.2942 18.628 17.2776 18.628 15.2636V3.32524C18.628 1.31124 17.6114 0.294647 15.5677 0.294647H3.69826C1.66461 0.294647 0.627968 1.30165 0.627968 3.32524V15.2641C0.627968 17.2876 1.66461 18.2942 3.69826 18.2942ZM3.72746 16.7207C2.75006 16.7207 2.20233 16.2026 2.20233 15.1856V3.4045C2.20233 2.38749 2.75006 1.86939 3.72746 1.86939H15.5385C16.5063 1.86939 17.054 2.38749 17.054 3.4045V15.1848C17.054 16.2018 16.5063 16.7199 15.5385 16.7199L3.72746 16.7207ZM11.9113 14.1198C12.2141 13.8362 12.2141 13.3477 11.9209 13.074L7.90239 9.29047L11.9209 5.51611C12.2242 5.23244 12.2046 4.74396 11.9013 4.46072C11.6176 4.19625 11.1679 4.21585 10.8651 4.48992L6.79775 8.33187C6.24043 8.84079 6.25002 9.73974 6.79775 10.2583L10.8651 14.0906C11.1387 14.3547 11.6472 14.3743 11.9113 14.1198Z" fill="black"/>
        </svg>-->
        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.1795 3.26875C15.7889 2.87823 15.1558 2.87823 14.7652 3.26875L8.12078 9.91322C6.94952 11.0845 6.94916 12.9833 8.11996 14.155L14.6903 20.7304C15.0808 21.121 15.714 21.121 16.1045 20.7304C16.495 20.3399 16.495 19.7067 16.1045 19.3162L9.53246 12.7442C9.14194 12.3536 9.14194 11.7205 9.53246 11.33L16.1795 4.68297C16.57 4.29244 16.57 3.65928 16.1795 3.26875Z" fill="#0F0F0F"/>
        </svg>
      </button>
    </li>

    <li class="flex items-center gap-2">
      <button @click="onPageClick(num)"
        v-for="num in pageRange" :key="num"
        :class="{ 'text-blue-500 underline underline-offset-2': num === pageInfo.page }" class="p-2">
        {{ num }}
      </button>
    </li>

    <!-- chevron 버튼 -->
    <li class="flex ml-2">
      <button @click="onPageClick(pageInfo.page + 1)" :disabled="pageInfo.page >= (pageInfo.total / size)">
        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.82054 20.7313C8.21107 21.1218 8.84423 21.1218 9.23476 20.7313L15.8792 14.0868C17.0505 12.9155 17.0508 11.0167 15.88 9.84497L9.3097 3.26958C8.91918 2.87905 8.28601 2.87905 7.89549 3.26958C7.50497 3.6601 7.50497 4.29327 7.89549 4.68379L14.4675 11.2558C14.8581 11.6464 14.8581 12.2795 14.4675 12.67L7.82054 19.317C7.43002 19.7076 7.43002 20.3407 7.82054 20.7313Z" fill="#0F0F0F"/>
        </svg>
      </button>
    </li>

    <li class="flex items-center gap-2">
      <button @click="goNextPageList" :disabled="pageInfo.page >= (pageInfo.total / size)">
        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.5 5L11.7929 11.2929C12.1834 11.6834 12.1834 12.3166 11.7929 12.7071L5.5 19" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.5 5L19.7929 11.2929C20.1834 11.6834 20.1834 12.3166 19.7929 12.7071L13.5 19" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </li>
  </ul>
</template>

<script setup>
import { ref, toRefs, watchEffect, defineEmits } from 'vue';

const props = defineProps(['pageInfo', 'page', 'size']);
const { page, size } = toRefs(props);
const { pageInfo } = toRefs(props);

const emit = defineEmits();
const pageRange = ref([]);

const createRange = (start, length) => Array.from({ length }, (_, index) => start + index);

const updateRange = () => {
  pageRange.value = createRange(pageInfo.value.start, (pageInfo.value.end - pageInfo.value.start + 1));
};

const goPrevPageList = () => {
  // const prevPage =
  emit('updatePage', pageInfo.value.prev);
};

const goNextPageList = () => {
  // const nextPage =
  emit('updatePage', pageInfo.value.next);
};

watchEffect(() => {
  updateRange();
});

const onPageClick = (num) => {
  emit('updatePage', num);
};

</script>
