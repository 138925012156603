<template>
  <main class="max-w-[1200px] w-full mx-auto mb-20 px-5">
    <h1 class="font-bold text-center my-8">작성한 글</h1>

    <div class="w-full overflow-auto lg:my-4 py-4">
      <div class="flex w-fit gap-x-2">
        <router-link to="/mypage/post" active-class="pri_btn"
          class="sec_btn py-2 px-4 rounded-full whitespace-nowrap max-lg:text-sm">
          내가 작성한 글
        </router-link>
        <router-link to="/mypage/review" active-class="pri_btn"
          class="sec_btn py-2 px-4 rounded-full whitespace-nowrap max-lg:text-sm">
          나의 후기
        </router-link>
      </div>
    </div>

    <div class="mt-10 max-lg:px-5 max-lg:overflow-auto" style="max-width:calc(100vw - 2.5rem)">
      <div class="table_grid_container">
        <table class="table_grid_wrapper max-lg:min-w-min" style="max-width: calc(100vw - 5rem);">
          <tr class="table_grid_header max-lg:!hidden">
            <th>상품</th>
            <th>리뷰</th>
          </tr>

          <tr class="table_grid_body max-lg:grid" v-for="item in myReviewList" :key="item.oid">
            <td>
              <div class="text-left">
                <p class="text-neutral-500">구매일자 : {{formDate(item.orderDateTime)}}</p>
                <b class="text-base">{{ item.itemTitle }}</b>
              </div>
            </td>
            <td class="max-lg:!p-0 flex flex-col items-center">
              <p class="text-base truncate2 max-w-xs">{{ item.reviewTitle }}</p>
              <div class="flex gap-2 max-lg:flex-col mx-auto mt-2">
                <button class="sec_btn px-3 py-1" @click="openModal(item)">수정</button>
                <!-- <button class="sec_btn px-3 py-1">보기</button> -->
              </div>
            </td>
          </tr>
        </table>
      </div>

      <pagination v-if="pageInfo.page"
        :pageInfo="pageInfo"
        :page="page"
        :size="size"
        @updatePage="updatePage"
      />
    </div>

    <ReviewWriteComponent v-if="modalVisible" @close="modalVisible = false" :content="modalData" />
  </main>
</template>

<script setup>
  import axios from 'axios';
  import store from '@/store'
  import { onMounted, ref, computed } from 'vue';
  import { formDate } from '@/assets/js/util.js';
  import Pagination from '@/components/Pagination.vue';
  import ReviewWriteComponent from "@/components/ReviewWriteComponent.vue";

  const myReviewList = ref({});

  const memberIdx = store.state.account.id;

  const getmyReviewList = async (num) => {
  const pageValue = num !== undefined ? 1 : page.value;

    try {
      const res = await axios.get(
        `/new_api/review/list?page=${pageValue}&size=${size.value}&memberIdx=${memberIdx}`
        );
      
        myReviewList.value = res.data.data.reviewList;
        pageInfo.value = res.data.data.pageInfo;
    } catch (error) {
      console.error(error);
    }
  };

  onMounted(async () => {
    await getmyReviewList();
  });

  const pageInfo = ref({});

  const page = ref(1);
  const size = ref(10);

  const updatePage = (num) => {
    page.value = num;
    getmyReviewList();
  };

  const modalVisible = ref(false);
  const modalData = ref(null);

  const openModal = (content) => {
    modalData.value = content;
    modalVisible.value = true;
  };
</script>