<template>
  <div class="flex flex-1 bg-white">
    <div class="w-[1000px] p-5 mx-auto">
      <EduRegisterDonePage/>
    </div>
  </div>
</template>

<script>
// import LNavComponent from "@/components/LNavComponent.vue";
import EduRegisterDonePage from "@/pages/EduRegisterConfirmPage.vue";

export default {
  name: "EduRegisterConfirmView",
  components: {EduRegisterDonePage}
}
</script>

<style scoped>

</style>
