<script setup>
  import { VDialog } from 'vuetensils/src/components';
  import router from "@/router";

  const goBack = () => {
    // window.history.back();
    const prevPath = localStorage.getItem('prevPath');
    const prevPathName = localStorage.getItem('prevPathName');
    if( prevPathName === 'undefined' ){
      getData.postMessage('finish');
    }
    else {
      router.go(-1);
    }
  };
</script>
<template>
  <main>
    <div class="back_wr">
      <button @click="goBack()" class="back">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.636 11.293C3.44853 11.4805 3.34322 11.7348 3.34322 12C3.34322 12.2652 3.44853 12.5195 3.636 12.707L9.293 18.364C9.38525 18.4595 9.49559 18.5357 9.6176 18.5881C9.7396 18.6405 9.87082 18.6681 10.0036 18.6692C10.1364 18.6704 10.2681 18.6451 10.391 18.5948C10.5139 18.5445 10.6255 18.4703 10.7194 18.3764C10.8133 18.2825 10.8875 18.1708 10.9378 18.0479C10.9881 17.9251 11.0134 17.7934 11.0123 17.6606C11.0111 17.5278 10.9835 17.3966 10.9311 17.2746C10.8787 17.1526 10.8025 17.0422 10.707 16.95L6.757 13L20 13C20.2652 13 20.5196 12.8946 20.7071 12.7071C20.8946 12.5196 21 12.2652 21 12C21 11.7348 20.8946 11.4804 20.7071 11.2929C20.5196 11.1053 20.2652 11 20 11L6.757 11L10.707 7.04999C10.8892 6.86139 10.99 6.60879 10.9877 6.34659C10.9854 6.08439 10.8802 5.83358 10.6948 5.64817C10.5094 5.46277 10.2586 5.3576 9.9964 5.35532C9.73421 5.35304 9.4816 5.45383 9.293 5.63599L3.636 11.293Z" fill="black"/>
        </svg>
      </button>
    </div>

    <div class="text-center max-w-3xl p-4">
      <img src="@/assets/img/hanaB_logo.jpg" class="max-w-[88vw] mx-auto mt-10"/>
      <p class="mt-4 text-primary-800">The Kingdom of God in the Bible</p>
      <p>하나 바이블은 성경 속에서 하나님 나라를 배우는 책입니다.</p>
      <p>왕관은 하나님의 통치, 길은 하나님 나라 백성의 삶을 의미합니다.</p>
      <div class="my-24">
        <h2 class="text-primary-800 font-semibold">초대합니다!</h2>
        <VDialog>
          <template #toggle="{ bind, on }">
            <button v-bind="bind" v-on="on"><img src="@/assets/img/hanaB_invite1.jpg"/></button>
          </template>

          <template #default="{ close }">
            <div class="flex justify-center items-center w-screen h-screen bg-[#00000078]">
              <div class="relative flex flex-col bg-white max-w-[92vw] max-h-[88vh] overflow-y-auto px-4">
                <button aria-label="close" @click="close"
                  class="place-self-end text-xl p-4 bg-white sticky top-0 rounded-full leading-none">
                  X
                </button>
                <pre class="whitespace-pre-wrap break-keep text-sm">
  성삼위 하나님의 은혜와 평강이 전국 교회와 교회학교 사역자들에게 넘쳐나기를 소원합니다.

  이 땅에 교회학교가 시작된 이후로 하나님은 이를 통해 교회 교육의 큰 부흥을 이루어 주셨습니다. 오늘 한국교회는 다시 한번 새로운 변화를 기대하고 있습니다. 특히 4차 산업혁명과 저출산, 비대면 시대로의 전환 등 새로운 도전에 직면한 교회 교육에 새로운 돌파구가 필요한 현 시점에 총회는 새로운 융합형 성경 공부 교재 「하나 바이블(The Kingdom of God in the Bible)」을 출간하였습니다.

  「하나 바이블」의 ‘하나’는 하나님 나라를 뜻하는 것으로, 성경 속에서 하나님 나라를 배우는 교재입니다. 마태복음 5:13-16 말씀을 주제 성구로 삼아 그리스도인으로서 ‘착한 행실’을 통해 하나님 나라 백성으로서 하나님의 통치와 다스리심을 자신의 삶에 구현하도록 합니다. 그리하여 믿음을 생활에서 적용하는 신앙이 가능하도록 돕습니다. 이러한 토대 위에 바르게 교훈하여 현대 교회와 사회 간의 심각한 분리 현상을 해결하고자 합니다.

  「하나 바이블」은 영유아부, 유치부, 유년부, 초등부, 청소년부까지 이르는 다섯 개 부서의 교재로 구성했으며, 전 부서의 교육 과정이 동일합니다. 같은 성경 본문과 주제를 학습하기 때문에 세대 간 소통을 가능하게 하며 작은 교회의 교회학교 운영에 해법이 될 수 있을 것입니다. 또한 단순히 성경 지식을 주고받는 차원을 뛰어넘어 삶의 현장에서 실제적인 문제 해결 역량을 키우도록 하였으며, 신학과 교리 그리고 세계관을 융합해 기독교 본질을 강화하였습니다. 설교와 성경 공부를 일원화하고, 교회와 가정, 학교를 염두에 둔 원포인트 교육으로 진행하며, 기독교 세계관에 입각해 생활 속의 문제를 신앙으로 해결하도록 함으로써 신앙과 삶의 융합이 가능하도록 구성했습니다.

  4차 산업혁명 시대에는 일방적인 지식의 전달이 아닌 교사와 학생 모두가 능동적으로 교육에 참여하는 패러다임의 변화가 일어나고 있습니다. 일방적으로 성경을 가르치는 방편이 아닌 쌍방향으로 소통하는 교육을 위해 이론과 실제를 융합한 「하나 바이블」이 효과적인 신앙교육으로 이끌 것입니다.

  현재 멀티미디어의 영향은 가히 혁명과 같은 형태로 우리에게 다가오고 있습니다. 따라서 「하나 바이블」은 온라인 교육에 중점을 두고 오프라인 교육의 효율을 극대화하도록 하였습니다. 특별히 총회가 교회 교육의 허브가 되어 스마트 플랫폼을 구축해 학생 교육, 교사 교육, 목회자 교육, 문화 콘텐츠, 교회 행정 시스템, 예배 매뉴얼 등 다양한 정보와 자료를 제공합니다. 이를 통해 교회학교 학생과 교사, 학부모, 지도자들의 신앙 성장이 일어나 기를 기대합니다.

  끝으로 본 교재를 출간할 수 있도록 협력해 주신 교육과정위원과 집필위원, 감수해 주신 교수님과 목사님, 교육개발원 이사 및 전문 자문위원께 감사의 마음을 전합니다. 또한 실무로 수고하신 교육개발원 연구원과 출판국을 비롯한 직원들께도 감사를 표합니다. 앞으로도 총회 성경 공부 교재가 더욱 발전하여 전국 교회와 교회학교 및 성도들의 믿음을 더욱 확고하게 세우는 교재가 되도록 기도해 주시길 바랍니다.

  대한예수교장로회총회
  교육개발원
                </pre>
              </div>
            </div>
          </template>
        </VDialog>
        <VDialog>
          <template #toggle="{ bind, on }">
            <button v-bind="bind" v-on="on"><img src="@/assets/img/hanaB_invite2.jpg"/></button>
          </template>

          <template #default="{ close }">
            <div class="flex justify-center items-center w-screen h-screen bg-[#00000078]">
              <div class="relative flex flex-col bg-white max-w-[92vw] max-h-[88vh] overflow-y-auto px-4">
                <button aria-label="close" @click="close"
                  class="place-self-end text-xl p-4 bg-white sticky top-0 rounded-full leading-none">
                  X
                </button>
                <pre class="whitespace-pre-wrap break-keep text-sm">
「하나 바이블」 교재 개발 개요

대한예수교장로회총회는 1999년 「말씀, 믿음, 삶」이라는 성경 공부 교재를 통해 성경 전체를 관통하는 종합적인 교육 과정을 개발했다. 2010년에는 ‘하나님 중심 · 말씀 중심 · 교회 중심 · 개혁 지향’의 체계적이고 종합적인 계단 성경 공부 교재인 「생명의 빛」을 발간하여 교회학교 교육의 새로운 장을 열었다. 그러나 사회의 급격한 변화와 한국교회 내부의 여러 위기에 대처해야 할 필요성과 Z세대를 넘어 α세대로 불리는 다음 세대를 위한 새로운 차원의 신앙 교육 과정을 새로이 수립해야 한다는 목소리가 제기되었다. 하나님의 말씀은 변하지 않는 속성을 가진 반면, 현대 사회는 인공지능으로 대변 가능한 초지능사회로의 급격한 변화를 겪고 있기 때문이다. 이에, 이러한 불확실한 시대에서 불변하시는 하나님의 말씀으로 교육해야 하는 도전에 응전하고자 새로운 융합형 통합 교재, 「하나 바이블 (The Kingdom of God in the Bible)」을 개발하였다. 여기서 하나는 ‘하나님 나라’의 줄임말이며, 바이블a은 ‘ 성경’을 의미한다. 즉, 성경을 통해 하나님 나라를 배운다.

본 교재의 주제는 바로 ‘하나님 나라’다. 이는 성경에서 매우 중요한 개념이다. 구약 성경의 지향점 또한 신약과의 관계 속에서 볼 때 ‘하나님 나라’를 이야기하며, 하나님이 이스라엘 백성과 맺은 언약 속에는 하나님의 통치와 임재, 즉 하나님 나라의 개념을 명백히 포함한다. 또한 신약에 명확하게 드러난 ‘하나님 나라’는 예수님의 존재와 사역과 밀접히 관련된다. 구약의 언약으로 표현된 하나님 나라의 완성은 곧 예수님이시기 때문이다(막 1:15; 눅 4:43).

또한 하나님 나라는 예수 그리스도의 죽음과 부활로 세우신 교회를 통해 구체화 된다. 그리스도의 피를 통해 세워진 교회는 하나님의 통치와 임재가 현실화된 것으로서(엡 1:22-23), 하나님 나라는 이상 속에 존재하는 개념이 아니라 삶 속에 실재하는 현실이다. 그러므로 우리는 하나님의 통치가 역사 속에서 어떻게 형성되고 진행해 왔으며, 하나님과 그 백성이 어떤 관계를 형성했는지에 대하여 학습자들에게 바로 가르쳐야 한다. 동시에 ‘지금 그리고 여기에서’ 어떻게 하나님 나라를 완성해 갈지를 가르치는 것 또한 필요하다.

따라서 우리는 학습자들의 삶 속에서 하나님의 절대 주권으로 다스림 받는 하나님 나라에 관한 올바른 교육이 이뤄지도록 하는 데 중점을 두었다. 또한 하나님 나라를 이루시고 구원을 주신 예수 그리스도를 믿음으로 하나님의 자녀가 된다는 복음을 가르치기 위해, 삶 속에서 하나님의 말씀을 바로 구현하도록 구성한 교수 학습 과정 및 방법을 모색하였다. 더불어 현시대의 새로운 교육 생태계인 디지털 환경 및 다문화 환경을 고려했을 뿐만 아니라 교회와 가정, 매일의 생활 속에서 하나님의 말씀을 배울 수 있는 교재를 개발하였다. 이에 하나님 나라를 중심으로 한 「하나 바이블」의 교육 이념, 교육 목적, 교육 목표, 교육 과정, 인간상, 주제 성구를 포함한 개요는 다음과 같다.                  
                </pre>
              </div>
            </div>
          </template>
        </VDialog>
        <VDialog>
          <template #toggle="{ bind, on }">
            <button v-bind="bind" v-on="on"><img src="@/assets/img/hanaB_invite3.jpg"/></button>
          </template>

          <template #default="{ close }">
            <div class="flex justify-center items-center w-screen h-screen bg-[#00000078]">
              <div class="relative flex flex-col bg-white max-w-[92vw] max-h-[88vh] overflow-y-auto px-4">
                <button aria-label="close" @click="close"
                  class="place-self-end text-xl p-4 bg-white sticky top-0 rounded-full leading-none">
                  X
                </button>
                <iframe src="https://www.youtube.com/embed/BkpNOmCdcZE?si=5X62X9MnMxQcrCQw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <br/>
              </div>
            </div>
          </template>
        </VDialog>        
      </div>
      <div class="my-24">
        <h2 class="text-primary-800 font-semibold">총회교육의 새로운 패러다임</h2>
        <p class="mb-6">성경 말씀에 근거한 하나님 나라가 삶 속에서 어떻게 실현될 수 있는지를 교육하는 것이 이번 성경 공부 교재의 핵심!</p>
        <img src="@/assets/img/hanaB_int1.jpg" class="max-w-[88vw] mx-auto"/>
      </div>
      <div class="my-24">
        <h2 class="text-primary-800 font-semibold mb-6">스마트 플랫폼 기반 융합형 교육</h2>
        <img src="@/assets/img/hanaB_int2.jpg" class="max-w-[88vw] mx-auto"/>
      </div>
      <div class="my-24">
        <h2 class="text-primary-800 font-semibold mb-6">하나 바이블 교육 체계도</h2>
        <img src="@/assets/img/hanaB_int3.jpg" class="max-w-[88vw] mx-auto"/>
      </div>
      <div class="my-24">
        <h2 class="text-primary-800 font-semibold">Design 으로 Thinking 하라</h2>
        <p class="mb-6">기존의 것을 재해석하여 새롭게 창조하는 주일학교 교육 모델 제시</p>
        <img src="@/assets/img/hanaB_int4.jpg" class="max-w-[88vw] mx-auto"/>
      </div>
      <div class="my-24">
        <h2 class="text-primary-800 font-semibold">하나 바이블 새로운 디자인</h2>
        <p class="mb-6">2021년 1과정 1학기 성경 공부 교재의 새로운 디자인</p>
        <img src="@/assets/img/hanaB_int5.jpg" class="max-w-[88vw] mx-auto"/>
      </div>
      <div class="my-24">
        <h2 class="text-primary-800 font-semibold">하나 바이블 특별한 멀티미디어</h2>
        <p class="mb-6">2021년 예배와 성경 공부를 돕는 맞춤형 콘텐츠</p>
        <img src="@/assets/img/hanaB_int6.jpg" class="max-w-[88vw] mx-auto"/>
      </div>
      <div class="mt-24">
        <h2 class="text-primary-800 font-semibold">하나 바이블 Q&A</h2>
        <p class="mb-6">2021년 예배와 성경 공부를 돕는 맞춤형 콘텐츠</p>
        <img src="@/assets/img/hanaB_int7.jpg" class="max-w-[88vw] mx-auto"/>
      </div>
    </div>
  </main>
</template>