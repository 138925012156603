<template>
  <div id="edu-list" class="flex-1 py-20 px-5">
    <div v-for="(conference, idx) in state.conferences" :key="idx" class="max-w-[1000px] lg:px-5 mx-auto mb-14">
      <!-- 컨퍼런스 타이틀 -->
      <h2 class="font-medium text-center">{{conference.title}}</h2>
      <div v-if="conference.idx == 1">
        <div class="mt-6">
          <img src="@/assets/img/main_confImg.jpg" alt="컨퍼런스 대표 이미지" class="rounded-lg">
        </div>
        <div>
          <img src="@/assets/img/main_confImg_bt.jpg" alt="컨퍼런스 설명 이미지" class="rounded-lg">
        </div>
      </div>

      <div v-else-if="conference.idx == 2">
        <div class="mt-6">
          <img src="@/assets/img/main_confImg_hana.jpg" alt="하나바이블 대표 이미지" class="rounded-lg">
        </div>
        <div>
          <img src="@/assets/img/main_confImg_btHana.jpg" alt="하나바이블 설명 이미지" class="rounded-lg">
        </div>
      </div>

      <div class="list">
        <template v-for="(confList, index) in conference.conferenceDetailList" :key="confList.idx">
<!--            {{confList.number}} / {{conference.conferenceDetailList[index - 1]?.number}}-->
<!--          <div class="w-full" v-if="confList.number !== conference.conferenceDetailList[index - 1]?.number && confList.view !== false">-->
          <div class="w-full" v-if="confList.view !== false">
            <div class="item" @click="toggleItemSubWr(confList.idx)">
              <b class="mont">{{confList.number}}차</b>
              <div class="title flex items-center">
                <h5 class="font-medium">{{confList.local}} {{confList.place}}</h5>
                <span class="bar"></span>
                <h5 class="font-medium">{{ formatDate(confList.schedule) }}</h5>
              </div>
              <button>
                <font-awesome-icon :icon="isSubVisible[confList.idx] ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'"/>
              </button>
            </div>
            <div class="item_sub_wr flex flex-wrap justify-between py-4" :class="{ hidden: isSubVisible[confList.idx] }">
              <div class="item_sub" v-for="filteredConfList in conference.conferenceDetailList.filter(cl => cl.number === confList.number)" :key="filteredConfList.idx">
                <div class="flex items-center gap-2">
                  <h5 class="font-medium">{{filteredConfList.dept}}</h5>
                  <p>(인원: <b>{{filteredConfList.registered_personnel}}</b> / {{filteredConfList.limit_personnel || '무제한'}})</p>
                </div>
                <!-- 접수 기간 내에만 노출 -->
                <div v-if="isAvailable(conference.rsdatetime, conference.redatetime)">
                  <!-- 로그인 유무 -->
                  <a @click="alertLogin()" class="sec_rose_btn block p-4" v-if="!$store.state.account.id">신청</a>
                  <!-- 인원 마감 시 -->
                  <a @click="alertExceed()" class="sec_rose_btn block p-4" v-else-if="filteredConfList.registered_personnel >= filteredConfList.limit_personnel && filteredConfList.limit_personnel != 0">신청</a>
<!--                  <router-link :to="'/register/'+filteredConfList.idx" class="sec_rose_btn block p-4" v-else>신청</router-link>-->
                  <a href="javascript:void(0)" @click="goApply(filteredConfList.idx)" v-else>신청</a>
                </div>
                <a @click="alertAvailable()" class="sec_rose_btn block p-4" v-else>신청</a>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from 'vue'
import axios from 'axios'

export default {
  name: "EduListRegisterPage",

  setup () {
    const state = reactive({
      conferences: [],
    })

    axios.get('/api/conferences').then(response => {
      state.conferences = response.data.contents
    }).catch(error => {
      console.error(error)
    })

    //접수기간 체크
    const isAvailable = (rsdatetime, redatetime) => {
      const today = new Date()
      const startDate = new Date(Date.parse(rsdatetime))
      const endDate = new Date(Date.parse(redatetime))

      // console.log(today)
      // console.log(startDate)
      // console.log(endDate)
      return today >= startDate && today <= endDate
    }

    const formatDate = (date) => {
      date = new Date(date);
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', hour12: true };
      return date.toLocaleString('ko-KR', options).replace(',', '');


      /*const year = date.slice(0,4)
      const month = date.slice(5,7)
      const day = date.slice(8,10)
      const hour = date.slice(11,13)
      const minute = date.slice(14,16)


      // return `${year}년 ${month}월 ${day}일  ${hour}시 ${minute}분`
      return `${year}년 ${month}월 ${day}일`*/
    }

    const isSubVisible = reactive([])

    const toggleItemSubWr = (idx) => {
      isSubVisible[idx] = !isSubVisible[idx]
    }

    const alertLogin = () => { 
      alert('로그인 후 이용해주세요.')
      return
     }

    const alertAvailable = () => { 
      alert('접수 기간이 아닙니다.')
      return
    }

    const alertExceed = () => { 
      alert('접수가 마감되었습니다.')
      return
    }

    const goApply = (idx) => {
      document.location.href = `/register/${idx}`;
    }

    const getConferenceList = async () => {
      await axios.get('/api/conferences').then(response => {
        console.log(response.data.contents);
        state.conferences = response.data.contents;

        if (location.pathname === '/hana') {
          state.conferences.filter(conference => conference.idx === 2)
        }
        else {
          state.conferences.filter(conference => conference.idx === 1)

          state.conferences[0].conferenceDetailList.map((conferenceDetailList) => {
            conferenceDetailList.view = viewCheck(conferenceDetailList.number);
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
    }

    onMounted(() => {
      getConferenceList();
    })

    //성경학교, 하나바이블 분리
    const filteredConferences = computed(() => {
      if (location.pathname === '/hana') {
        return state.conferences.filter(conference => conference.idx === 2)
      } else {
        return state.conferences.filter(conference => conference.idx === 1)
      }
    })


     const viewCheck = (number) => {
        if( number === 5 ){
          //23년 5월 12일 오후 5시 이후부터 보여지도록
          const date = new Date();
          const targetDate = new Date("2023-05-12T17:00:00");
          return date > targetDate;
        }
        else {
            return true;
        }
     }


    return { 
      state,
      // filteredConferences,
      isAvailable,
      formatDate,
      isSubVisible,
      toggleItemSubWr,
      alertLogin, alertAvailable, alertExceed, goApply, viewCheck }
  }
}
</script>

