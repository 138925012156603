<template>
  <main class="w-full mx-auto mb-20 flex-1">
<!--    <h1 class="font-bold text-center my-8">주문 확인</h1>-->


    <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <!-- <div class="max-w-lg w-full bg-white shadow-md rounded-lg p-8"> -->
      <div class="max-w-[800px] w-full bg-white shadow-md rounded-lg p-8">
        <!-- 주문번호 -->
        <h1 class="text-3xl font-semibold text-center mb-6">주문이 완료되었습니다</h1>
        <div class="mb-6">
          <p class="text-lg font-semibold">
            주문번호
            <span class="text-blue-500 ml-3">
              {{ orderInfo.oid }}
            </span>
          </p>
        </div>

        <!-- 주문정보 -->
        <div>
          <div class="mb-6">
            <p class="text-lg font-semibold">
              주문일시
              <span class="ml-3">
                {{ orderInfo.confirm_date }}
              </span>
            </p>
          </div>
          <div class="mb-6">
            <p class="text-lg font-semibold">
              결제금액
              <span class="ml-3">
                {{ orderInfo.totalPaidAmount }}
              </span>
            </p>
          </div>

          <div class="mb-6">
            <p class="text-lg font-semibold">
              배송정보
            </p>
            <div class="text-lg font-semibold ml-3">
              <!-- http://localhost:3000/mypage/order_done/MALL_1712306888591_296 테스트 주소 -->

              <div class="shipping_info"> <div class="text_subject">주문자</div> <div> {{ shippingInfo.buyerName }} </div> </div>
              <div class="shipping_info"> <div class="text_subject">전화번호</div> <div> {{ shippingInfo.buyerTel }} </div> </div>
              <div class="shipping_info"> <div class="text_subject">이메일</div> <div> {{ shippingInfo.buyerEmail }} </div> </div>
              <div class="shipping_info"> <div class="text_subject">주소</div> <div> [{{ shippingInfo.buyerZipCode }}] {{ shippingInfo.buyerAddress }} {{ shippingInfo.buyerDetailedAddress }} </div> </div>
            </div>
          </div>
        </div>

        <!-- 결제정보 -->
        <div class="mb-6">
          <p class="text-lg font-semibold">
            결제정보
          </p>
          <div class="text-lg font-semibold ml-3">
            <div class="shipping_info">
              <div class="text_subject"> 결제수단 </div>
              <div>
                <span v-if="orderInfo.gopaymethod === 'Card' || orderInfo.gopaymethod === 'VCard'">
                  카드결제
                </span>
                <span v-if="orderInfo.gopaymethod === 'VBank'">
                  가상계좌
                </span>
                <span v-if="orderInfo.gopaymethod === 'DirectBank'">
                  실시간계좌이체
                </span>
              </div>
            </div>

            <div v-if="orderInfo.gopaymethod === 'Card' || orderInfo.gopaymethod === 'VCard'">
              <div class="shipping_info">
                <div class="text_subject"> 승인번호 </div>
                <div> {{ orderInfo.paymentInfo.applNum }} </div>
              </div>
              <div class="shipping_info">
                <div class="text_subject"> 카드정보 </div>
                <div> {{ CARD_TYPE(orderInfo.paymentInfo.card_Code) }} {{ orderInfo.paymentInfo.card_Num }} </div>
              </div>
            </div>

            <div v-if="orderInfo.gopaymethod === 'VBank'">

              <div v-if="orderInfo.pay_device === 'pc'">
                <div class="shipping_info">
                  <div class="text_subject"> 입금정보 </div>
                  <div> {{ orderInfo.paymentInfo.vactBankName }} {{ orderInfo.paymentInfo.vact_Num }}  </div>
                </div>

                <div class="shipping_info">
                  <div class="text_subject"> 입금기한 </div>
                  <div> {{ orderInfo.payment_date }}  </div>
                </div>
              </div>
              <div v-else>
                <div class="shipping_info">
                  <div class="text_subject"> 입금정보 </div>
                  <div> {{ orderInfo.paymentInfo.vactBankName }} {{ orderInfo.paymentInfo.VACT_Num }}  </div>
                </div>

                <div class="shipping_info">
                  <div class="text_subject"> 입금기한 </div>
                  <div> {{ orderInfo.paymentInfo.VACT_Date }} {{ orderInfo.paymentInfo.VACT_Time }}  </div>
                </div>

              </div>



            </div>

            <div class="shipping_info" v-if="orderInfo.gopaymethod === 'DirectBank'">
              <div class="shipping_info">
                <div class="text_subject"> 승인번호 </div>
                <div> {{ orderInfo.paymentInfo.applNum }} </div>
              </div>
            </div>

          </div>
        </div>

        <div class="max-lg:px-5 mt-10">
          <div class="flex gap-2 justify-center max-lg:justify-center mt-5">
            <router-link to="/mall" class="sec_btn !border-black p-4 w-1/6 max-lg:w-1/2">
              목록으로
            </router-link>
            <router-link to="/mypage" class="pri_btn pri_btn_bg p-4 w-1/6 max-lg:w-1/2">
              마이페이지
            </router-link>
            <!--
            <router-link to="/mypage/contentsBox" class="pri_btn pri_btn_bg p-4 w-1/6 max-lg:w-1/2"
            v-if="orderStatus.returnCode =='0000' && orderStatus.orderStatus == 'ORDER_DONE'">
              콘텐츠 보관함 바로가기
            </router-link>
            <router-link to="/mypage/order" class="pri_btn pri_btn_bg p-4 w-1/6 max-lg:w-1/2" v-else>
              주문서 작성으로 돌아가기
            </router-link>
            -->
          </div>
        </div>
      </div>
    </div>


    <!--
    <div class="border border-neutral-200 text-center py-10" v-if="orderInfo.orderStatus">

      <h3> {{ orderInfo.oid }} </h3>

      <div v-if="orderInfo.orderStatus == 'ORDER_DONE'">
        <p>구매가 정상적으로 완료되었습니다.</p>
      </div>
      <div v-else-if="orderInfo.orderStatus == 'VBANK_WAIT'">
        구매가 정상적으로 완료되었으며 입금 대기 중입니다.
        <div class="text-xl mt-4">
          <p class="block font-semibold mb-2">
            다음의 계좌로 
            <span class="text-blue-700 font-bold">{{ VBackDeadline(orderDetail.paymentInfo.vact_Date, orderDetail.paymentInfo.vact_Time) }}</span> 까지
            입금 바랍니다.
          </p>
          [ {{ orderDetail.paymentInfo.vactBankName }}
          {{ orderDetail.paymentInfo.vact_Num }}
          {{ orderDetail.paymentInfo.vact_Name }} ]
        </div>
      </div>
      <div v-else>
        <p>결제에 실패하였습니다.</p>
        [{{ orderStatus.returnMsg }}]
      </div>

    </div>
    -->



  </main>
</template>

<script setup>
import axios from 'axios';
import { onMounted, ref, computed } from "vue";
import { useRoute } from 'vue-router';
import {inicisDateFormat, CARD_TYPE} from "@/assets/js/util";

const route = useRoute();

const orderId = route.params.oid;


const getData = async (url, target) => {
  try {
    const res = await axios.get(url);
    target.value = res.data.data;
    console.log(target.value);
  } catch (err) {
    console.error(err);
  }
};

const orderInfo = ref({});
const orderStatus = ref({});

const shippingInfo = ref({});   // 배송지 정보

const getOrderStatus = async () => {
  await getData(`/new_api/customer/order/status/${route.params.oid}`, orderStatus);
};

const getListDetail = async () => {
  await getData(`/new_api/customer/order/detail/${route.params.oid}`, orderDetail);
};

const getOrderInfo = async () => {

  try {
    const url = `/new_api/customer/order/v2/order-result/${orderId}`;
    const { data, status } = await axios.get(url);

    if( status === 200 ){
      if( data.code === "200" ){
        orderInfo.value = data.data;
        orderInfo.value.confirm_date = new Date(orderInfo.value.confirm_date).toLocaleString();
        orderInfo.value.totalPaidAmount = Number(orderInfo.value.totalPaidAmount).toLocaleString();
        orderInfo.value.gopaymethodText = orderInfo.value.gopaymethod === 'Card' ? '카드결제' : orderInfo.value.gopaymethod === 'VBank' ? '가상계좌' : '실시간 계좌이체';

        if( orderInfo.value.pay_device === 'pc' && orderInfo.value.gopaymethod === 'VBank'){
          orderInfo.value.payment_date = inicisDateFormat(orderInfo.value.paymentInfo.vact_Date, orderInfo.value.paymentInfo.vact_Time);
        }
        else {
          // TODO: 모바일 결제일 경우
          orderInfo.value.payment_date = '';
        }

        shippingInfo.value = orderInfo.value.orderBuyer;    // 배송지 정보
      }
    }
    else {
      console.error(data);
    }
  } catch (err) {
    console.error(err);
  }
};

onMounted(async () => {
  await getOrderInfo();
  // await getOrderStatus();
  // await getListDetail();
});


const VBackDeadline = (date, time) => {
  const deadline = new Date(`${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6)}T${time.slice(0, 2)}:${time.slice(2, 4)}:${time.slice(4)}`);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  
  return deadline.toLocaleDateString('ko-KR', options).replace('$1.$2.$3 $4:$5:$6');
};

</script>

<style>
.shipping_info {
  display: flex;
}
.text_subject {
  width: 80px;
  height: 35px;
  text-align: justify;
}
.text_subject:after {
  content: "";
  display: inline-block;
  width: 100%;
}

</style>
