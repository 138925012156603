<template>

  <button class="kakao_btn rounded-full p-4" @click="kakaoLoginBtn">
    <img src="@/assets/img/login_kakao.jpg" alt="카카오로그인"/>
  </button>

</template>

<script>
import axios from "axios";
import router from "@/router";

export default {
    name: "kakaoLoginCheckComponent",
    data() {
      return {
        join_data: {
          uid: "",
          upw: "",
          name: "",
          phone: "",
          email: "",
          is_agree: "Y",
          sns_type: "kakao",
          sns_id: "",
        },
      }
    },
    mounted(){
      if (!window.Kakao.isInitialized()) {
        window.Kakao.init('0aca12fa90bba7a078a5a90de8846816');// Kakao Developers에서 요약 정보 -> JavaScript 키
      };
    },  
    methods: {
      kakaoLoginBtn:function(){

        const me = this;
        
        /*
        console.log(window.Kakao.Auth.getAccessToken());
        if (window.Kakao.Auth.getAccessToken()) {
          window.Kakao.API.request({
            url: '/v1/user/unlink',
            success: function (response) {
              console.log(response)
            },
            fail: function (error) {
              console.log(error)
            },
          })
          window.Kakao.Auth.setAccessToken(undefined)
        }
        */

        window.Kakao.Auth.login({
          success: function () {
            window.Kakao.API.request({
              url: '/v2/user/me',

              success: async function (response) {
                me.snsLoginCheck('kakao', response);
              },
              fail: function (error) {
                console.log(error)
              },
            })
          },
          fail: function (error) {
            console.log(error)
          },
        })
      },

      async snsLoginCheck( sns, sns_data ){
        console.log(sns);
        const kakao_id = sns_data.id;
        const kakao_account = sns_data.kakao_account;
        console.log(` kakao_id : ${kakao_id} `);
        console.log(kakao_account);

        this.join_data.uid = kakao_account.email;
        this.join_data.email = kakao_account.email;
        this.join_data.name = ( kakao_account.name != undefined ) ? kakao_account.name : "" ;
        let ph_num = kakao_account.phone_number;
        // 국가코드 제외
        ph_num = ph_num.replace("+82", "0");
        // 숫자만
        ph_num = ph_num.replace(/[^0-9]/g, "");
        this.join_data.phone = ph_num;
        this.join_data.sns_id = kakao_id;


        const url = `/api/check-sns?sns_id=${this.join_data.sns_id}&sns_type=kakao&phone=${ph_num}`;
        const headers = {"Content-Type":"application/json"};
        const { data } = await axios.get(url,{headers});
        console.log('이메일중복확인 data=>' ,data);

        /**
         * 1. sns_id, sns_type - 일치 -> 로그인 처리
         * 2. sns_id, sns_type - 불일치 -> phone 중복 체크(2-1, 2-2)
         * 2-1. phone 중복 체크 - 일치 -> 해당 가입 정보 전달
         * 2-2. phone 중복 체크 - 불일치 -> 회원가입 페이지로 이동
         */

        if( data.err_code === 0 ){
          const successCode = data.contents.successCode;
          if( successCode === '000' ){
            // 로그인 처리
            await this.kakaoUserLogin('1'); // 로그인 진행
          }
          else if( successCode === '001' ){
            // 가입 기록 있음
            const joinType = data.contents.joinType;// 배열
            // 일반, 카카오
            if( joinType.includes('normal') && joinType.includes('kakao') ){
              alert('\'카카오(간편가입)\'와 \'일반회원가입\'으로 가입된 계정 정보가 있습니다.\n\n\'일반회원가입\' 계정정보가 기억나지 않으신다면 핸드폰 번호로\n\'아이디/비밀번호 찾기\'를 이용 해 주세요.');
            }
            // 일반, 네이버
            else if( joinType.includes('normal') && joinType.includes('naver') ){
              alert('\'네이버(간편가입)\'와 \'일반회원가입\'으로 가입된 계정 정보가 있습니다.\n\n\'일반회원가입\' 계정정보가 기억나지 않으신다면 핸드폰 번호로\n\'아이디/비밀번호 찾기\'를 이용 해 주세요.');
            }
            // 일반
            else if( joinType.includes('normal') ){
              alert('\'일반회원가입\'으로 가입된 계정 정보가 있습니다.\n\n계정정보가 기억나지 않으신다면 핸드폰 번호로\n\'아이디/비밀번호 찾기\'를 이용 해 주세요.');
            }
            // 카카오
            else if( joinType.includes('kakao') ){
              alert('\'카카오(간편가입)\'로 가입된 계정 정보가 있습니다.');
            }
            // 네이버
            else if( joinType.includes('naver') ){
              alert('\'네이버(간편가입)\'로 가입된 계정 정보가 있습니다.');
            }

            router.push({path: '/'});
            return false;
          }
          else if( successCode === '002' ){
            // 회원 가입 페이지로 이동
            localStorage.setItem('uid', this.join_data.uid);
            localStorage.setItem('email', this.join_data.email);
            localStorage.setItem('name', this.join_data.name);
            localStorage.setItem('phone', this.join_data.phone);
            localStorage.setItem('sns_id', this.join_data.sns_id);
            localStorage.setItem('sns_type', this.join_data.sns_type);
            document.location.href = '/signup';
          }
          else {
            // 오류 다시 시도
          }
        }
        else {
          // 오류 다시 시도
        }

        /*
        if(data.contents === true){ //DB에 일치하는 아이디가 존재하는 경우
          await this.kakaoUserLogin('1'); // 로그인 진행
        } else { //DB에 일치하는 아이디가 존재하지 않는 경우

            localStorage.setItem('uid', this.join_data.uid);
            localStorage.setItem('email', this.join_data.email);
            localStorage.setItem('name', this.join_data.name);
            localStorage.setItem('phone', this.join_data.phone);
            localStorage.setItem('sns_id', this.join_data.sns_id);
            localStorage.setItem('sns_type', this.join_data.sns_type);
            document.location.href = '/signup';

            // 회원 가입 페이지로 이동

          // await this.kakaoUserJoin(); // 회원가입
        }
        return false;
        */
      },

      // 회원가입 후 로그인
      async kakaoUserJoin(){
        const url = "/api/member";
        const headers = {"Content-Type":"application/json"};

        const { data } = await axios.post(url, this.join_data, {headers});
        console.log(data);
        if(data.err_code === 0){
          alert('SNS 회원 가입이 완료 되었습니다. \n\n 계속해서 추가 정보를 등록해 주세요.');
          await this.kakaoUserLogin('2');
        }
      },

      // 바로 로그인
      async kakaoUserLogin( check ){
        const url = `/api/account/login`;
        const headers = { "Content-Type": "application/json" };
        const body = { sns_id: this.join_data.sns_id, sns_type: this.join_data.sns_type };
        const { data } = await axios.post(url, body, { headers });
        console.log(data);
        if( data.err_code === 0 ){
          localStorage.setItem('authorization', data.contents.body.accessToken);
          if( check === '1' ){
            document.location.href = '/';
          }
          else if( check === '2' ){
            document.location.href = '/profile';
          }
        }
        else {
          alert(data.err_msg);
          document.location.href = '/';
        }
      },

    },

}
</script>

<style scoped>

</style>