<template>
  <!-- class="lg:flex flex-1" -->
  <TNavComponent/>
  <toTopComponent/>
  <router-view/>
  <FooterComponent/>
</template>

<script>
import TNavComponent from "@/components/TNavComponent.vue"
import toTopComponent from "@/components/toTopComponent.vue"
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "myPage",
  components: {TNavComponent, toTopComponent, FooterComponent}
}
</script>
