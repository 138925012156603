<template>
    <div class="flex table_filter">
        <select class="border border-neutral-200 border-r-0" @change="setFilter">
          <option value="이름">이름</option>
          <option value="아이디">아이디</option>
          <option value="전화번호">전화번호</option>
          <option value="이메일">이메일</option>
        </select>
        <input type="text" class="border border-neutral-200 p-1"/>
        <button class="px-2 border border-l-0 border-neutral-200">
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
        </button>
    </div>
</template>

<script setup>
import {reactive} from "vue"


const filters = reactive({
    이름: true,
    아이디: true,
    전화번호: true,
    이메일: true
})

const setFilter = (e) => {
    const inputValue = e.target.value
    const isActive = e.target.selected
    filters[inputValue] = isActive ? true : false

    console.log(inputValue)
}


</script>