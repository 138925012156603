<template>
      <p> 테스트 페이지 입니다 </p>
      <div class="lg:w-[1000px] p-5 mx-auto mt-10 overflow-x-auto">

        <div class="w-full text-center text-3xl mb-5">
          2023 총회 여름성경학교.수련회 지도자 컨퍼런스
        </div>

        <div class="w-full text-center text-xl mb-5"> ※ 취소 및 변경처리는 19일 오후부터 처리가능합니다 </div>

        <h4 class="font-medium mb-2">신청내역</h4>
        <div class="max-md:hidden grid py-5 gap-2 px-4 border-t border-black sticky top-0" style="grid: 1fr / 1.5fr 0.5fr 1fr 0.5fr 0.3fr 0.5fr">
            <h6>컨퍼런스 제목 및 장소</h6>
            <h6 class="text-center">참가인원</h6>
            <h6 class="text-center">결제금액</h6>
            <h6 class="text-center">결제상태</h6>
            <h6 class="text-center">상세</h6>
            <h6 class="text-center">취소/변경</h6>
        </div>

        <div v-if="state.myConference.registeredConferenceList.length === 0">
          <div class="flex justify-center px-4 py-10 border-t border-neutral-200">
            <h4>내역이 없습니다</h4>
          </div>
        </div>

        <div v-else v-for="(item, index) in state.myConference.registeredConferenceList" :key="index" 
        class="md:grid flex flex-col gap-2 py-3 px-4 border-t border-neutral-200" style="grid: 1fr / 1.5fr 0.5fr 1fr 0.5fr 0.3fr 0.5fr">
          <div>
            <span class="md:hidden font-medium">컨퍼런스 :</span>
            {{ item.conferenceDetail.local }} {{ item.conferenceDetail.place }} {{ item.conferenceDetail.dept }}
          </div>
          <div class="md:text-center">
            <span class="md:hidden font-medium">참가인원 :</span>
            {{ item.headcount }}
          </div>
          <div class="md:text-center " v-if="item">
            <span class="md:hidden font-medium">결제금액 :</span>
            <!-- total_price 가 로딩된 후 toLocaleString() 실행 -->
            {{ item.total_price ? item.total_price.toLocaleString() : '' }}
          </div>
          <div class="md:text-center">
            <span class="md:hidden font-medium">결제상태 :</span>

            <span class="text-gray-500" v-if="item.status === '결제시도'" >
              {{ item.status }}
              <button type="button" class="sec_blue_btn text-sm p-1 mt-0.5" @click="goPayment(item.idx)"> 등록계속 </button>
            </span>
            <span v-else
              :class="{
                'text-red-500': item.status === '결제실패',
                'text-blue-500': item.status === '등록완료',
                'text-yellow-500': item.status === '결제대기',
              }"
            > {{ item.status }} </span>
          </div>
          <!-- 상세보기 -->
          <div>
            <VDialog :classes="{ bg: 'bg-black/40' }" class="p-4">
              <template #default="{ close }">
                <!-- 자세히 -->
                <div class="color-black bg-white max-w-full min-w-[50vw] max-h-[70vh] overflow-y-auto p-4">
                  <section class="flex justify-between border-b border-gray-200 pb-2">
                    <h5>상세보기</h5>
                    <button @click="close">
                      <h4><font-awesome-icon icon="fa-solid fa-circle-xmark" /></h4>
                    </button>
                  </section>
                  <section class="divide-y">
                    <div class="py-2 my-2">
                      <p class="text-sky-600">컨퍼런스</p>
                      <p>{{ item.conferenceDetail.conference.title }} - {{ item.conferenceDetail.local }} {{ item.conferenceDetail.place }} {{ item.conferenceDetail.dept }}</p>
                    </div>
                    <div class="py-2 my-2">
                      <p class="text-sky-600">참가자 (총 {{ item.headcount }}명)</p>
                      <div class="max-h-[40vh] overflow-y-auto">
                        <p v-for="(ppl, idx) in item.registerMemberList" :key="idx">
                          <b class="font-medium">{{ ppl.name }}</b> ({{ ppl.church }})
                        </p>
                      </div>
                    </div>
                    <div class="py-2 my-2">
                      <div>
                        <p class="text-sky-600">결제 금액</p>
                        <p>{{ item.total_price ? item.total_price.toLocaleString() : '' }}({{ item.status }})</p>
                      </div>
                    </div>
                  </section>
                </div>
              </template>

              <template #toggle="{ bind, on }">
                <div v-bind="bind" v-on="on" class="justify-center py-3 bg-neutral-100 flex items-center">
                  <span class="md:hidden font-medium">상세보기</span>
                  <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
                </div>
              </template>
            </VDialog>
          </div>

          <!-- 취소/변경 신청 -->
          <div>
            <VDialog :classes="{ bg: 'bg-black/40' }" class="p-4">
              <template #default="{ close }">
                <div class="color-black bg-white max-w-full min-w-[50vw] max-h-[70vh] overflow-y-auto p-4">
                  <section class="flex justify-between border-b border-gray-200 pb-2">
                    <h5>취소 신청</h5>
                    <button @click="close">
                      <h4><font-awesome-icon icon="fa-solid fa-circle-xmark" /></h4>
                    </button>
                  </section>
                  <section class="divide-y">


                    <div class="py-2 my-2">
                      <p class="text-sky-600">컨퍼런스</p>
                      <div class="p-2">
                        <p>{{item.conferenceDetail.conference.title}}</p>
                        <p>{{ item.conferenceDetail.local }} {{ item.conferenceDetail.place }} {{ item.conferenceDetail.dept }}</p>
                        <p>결제금액 : <b>{{ item.total_price ? item.total_price.toLocaleString() : '' }}</b>원</p>
                      </div>
                    </div>

                    <div class="py-2 my-2">
                      <p class="text-sky-600">요청 구분</p>
                      <div class="flex gap-5">
                        <label>
                          <input type="radio" name="type" v-model="state.cancelApply.type" value="부분" @change="selectType('', item.headcount)" /> 부분취소
                        </label>
                        <label>
                          <input type="radio" name="type" v-model="state.cancelApply.type" value="전체" @change="selectType(item.total_price, item.headcount)" /> 전체취소
                        </label>
                      </div>
                    </div>

                    <div class="py-2 my-2" v-show="state.cancelApply.type === '부분'">
                      <p class="text-sky-600">취소인원선택</p>
                      <!-- 인원 선택 -->
                      <div class="w-full border p-2">
                        <div class="grid mt-3" style="grid: 1fr / repeat(3, auto)">
                          <label v-for="(ppl, idx) in item.registerMemberList" :key="idx">
                            <input type="checkbox" v-model="state.cancelApply.member" :value="ppl.idx" @change="calcCanclePrice('', item.headcount)"/> {{ ppl.name }}
                          </label>
                        </div>
                      </div>
                    </div>

                    <!-- 수수료, 환불금액 -->
                    <div class="py-2 my-2">
                      <div class="mt-4 p-2">
                        <p>취소수수료: 0원</p>
                        <p>환불금액: <b> {{ state.cancelApply.price.toLocaleString('kr-ko') }} </b>원</p>
                      </div>
                    </div>

                    <div class="py-2 my-2">
<!--                      <div>
                        <p class="text-sky-600">사유</p>
                        <textarea class="w-full border p-2"></textarea>
                      </div>-->
                      <div class="flex justify-center">
                        <button type="button" class="mt-4 sec_blk_btn lg:px-5 px-2 py-2" @click="cancelApply( item.idx )">신청하기</button>
                      </div>
                    </div>
                  </section>
                </div>
              </template>

              <template #toggle="{ bind, on }">
                <div v-bind="bind" v-on="on" v-if="item.status == '등록완료'" class="justify-center py-3 bg-neutral-100 flex items-center">
                  <span class="md:hidden font-medium">취소·변경 신청</span>
                  <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                </div>
              </template>
            </VDialog>
          </div>


        </div>

      </div>
  
  </template>
  
  <script setup>
  import { reactive } from 'vue'
  import { VDialog } from 'vuetensils/src/components'
  import axios from 'axios'
  
  const state = reactive({
    myConference: {
      registeredConferenceList: [
        {
          conferenceDetail: {
            conference: {
              title:"",
            }
          },
          registerMemberList: [{

          }]
        }
      ]
    },

    cancelApply: {
      price: 0,
      member: [],
      type: "부분"
    }
  })

  const selectType = ( total, totalPerson ) => {
    if( state.cancelApply.type === "부분" ) {
        state.cancelApply.member = [];
        state.cancelApply.price = 0;
    }
    calcCanclePrice(total, totalPerson);
  }

  const calcCanclePrice = ( total, totalPerson ) => {

    let price = 0
    if( state.cancelApply.type === "전체" ) {
      state.cancelApply.price = total;
    }
    else {
      state.myConference.registeredConferenceList.forEach(item => {
        item.registerMemberList.forEach(ppl => {
          if(state.cancelApply.member.includes(ppl.idx)) {
            price += item.conferenceDetail.price
          }
        })
      })

      if( totalPerson === state.cancelApply.member.length ){
        state.cancelApply.type = "전체";
      }
      console.log(price);
      console.log(state.cancelApply.member);
      state.cancelApply.price = price
    }
  }

   const cancelApply = async ( idx ) => {
    try {
      const count = state.cancelApply.member.length
      const cancel_member_idxs = state.cancelApply.member.join(',');

      const body = {
        "register_conf_idx": idx,
        "type": state.cancelApply.type,
        "price": state.cancelApply.price,
        "count": count,
        "cancel_member_idxs": cancel_member_idxs
      }

      if( body.type === "부분" && body.cancel_member_idxs === "" ) {
        alert("취소할 인원을 선택해주세요.");
        return;
      }

      const response = await axios.post('/api/payment/cancel', body);
      console.log(response);
      if (response.data.err_code === 0) {
        alert('신청이 완료되었습니다. \n\n처리 완료 후 안내드립니다.');
        document.location.reload();
      } else {
        alert(response.data.err_msg)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const goPayment = ( idx ) => {
    document.location.href = '/register_pay/' + idx;
  }


  axios.get('/api/member/me').then(response => {
    state.myConference = response.data.contents
    console.log(state.myConference)
  }).catch(error => {
    console.error(error)
  })


  </script>
  