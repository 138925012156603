<template>
    <div class="lg:bg-sky-50 bg-white lg:border-r border-[#bfdef2] lg:max-w-[440px] sticky top-0 left-0 z-10" style="flex: 1 0 440px">
      <section class="lg:px-5 max-lg:border-b border-[#e0e0e0] overflow-y-auto sticky top-0" :class="{ 'h-screen w-screen': isMenuVisible }">
        <div class="w-full flex justify-between px-4 py-3 items-center lg:pt-8" :class="{ 'bg-sky-50' : isMenuVisible}">
          <router-link to="/" class="max-lg:max-w-[50%]">
            <img src="@/assets/img/logo.png" alt="총회교육개발원">
          </router-link>
          <!-- 모바일 버튼 -->
          <button @click="toggleMenu()" class="lg:hidden px-2 text-[#002356]" type="button">
            <h2><font-awesome-icon :icon="isMenuVisible ? 'fa-solid fa-xmark' : 'fa-solid fa-bars'"/></h2>
          </button>
        </div>

        <!-- 로그인 -->
        <div :class="{ 'max-lg:hidden': !isMenuVisible }">
          <div class="login max-lg:border-b border-neutral-300" :class="{ 'bg-sky-50' : isMenuVisible}" v-if="!$store.state.uid.id">
            <div class="login_inputs max-w-[658px] mx-auto py-5 px-5">
              <div class="rounded-t-lg overflow-hidden outline outline-1 outline-[#bfdef2]">
                <input type="text" class="p-4 w-full" placeholder="아이디" v-model="state.form.uid"/>
              </div>
              <div class="mt-[1px] rounded-b-lg overflow-hidden outline outline-1 outline-[#bfdef2]">
                <input type="password" class="p-4 w-full" placeholder="비밀번호" v-model="state.form.upw"/>
              </div>
        
              <!--
              <div class="flex justify-between mt-2">
                <span class="check_btn check_blk_btn">
                  <input id="rememberMe" type="checkbox" name="rememberMe"/>
                  <label for="rememberMe">아이디 저장</label>
                </span>
              </div>
              -->
        
              <div class="mt-2 mb-5 pb-5">
                <button class="sec_blue_btn !rounded-full w-full p-4 border border-[#0090ec]" @click="submit()">
                  <h6 class="font-medium">로그인</h6>
                </button>
              </div>

              <div class="flex justify-center items-center gap-2 leading-none md:text-sm">
                <a href="" class="hover:underline">아이디/비밀번호 찾기</a>
                <span class="block bg-black w-[1px] h-[0.8em]"></span>
                <router-link to="/signup" class="hover:underline">회원가입</router-link>
              </div>
        
              <!-- 간편 로그인 -->
              <div>
                <p class="lg:mt-6 mt-4 text-center font-medium text-[#0090ec]">간편로그인 및 회원가입</p>
                <div class="flex justify-center pt-4 gap-4">    
  <!--                <button class="naver_btn rounded-full p-4">
                    <img src="@/assets/img/login_naver.jpg" alt="네이버로그인"/>
                  </button>-->
                  <naverLoginComponentTest />
                  <kakaoLoginComponent />
          
                </div>
              </div>
            </div>
          </div>
          
          <!-- 로그인 후 정보 -->
          <div v-else class="py-5 px-5 max-lg:border-b border-neutral-300" :class="{ 'bg-sky-50' : isMenuVisible}">
            <div class="flex items-baseline mb-4 px-2">
              <h3 class="font-medium truncate">{{$store.state.uid.id}}</h3>
              <h4 class="font-light" style="flex: 1 0 auto">님 안녕하세요</h4>
              <div class="text-end ml-4" style="flex: 1 0 auto">
                
                <!-- 로그아웃 -->
                <button type="button" @click="logout()" class="text-neutral-700 hover:text-neutral-500">
                  로그아웃 <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
                </button>
              </div>
            </div>

            <div v-if="$store.state.level.id === 10" class="flex justify-center items-center sec_blue_btn p-4 border border-[#0090ec] !rounded-full">
              <router-link to="/profile/confList" class="w-1/3 text-center"><h6>신청내역</h6></router-link>
              <span class="block bg-white w-[1px] h-[0.8em]"></span>
              <router-link to="/profile" class="w-1/3 text-center"><h6>회원정보</h6></router-link>
              <span class="block bg-white w-[1px] h-[0.8em]"></span>
              <router-link to="/management" class="w-1/3 text-center"><h6>관리자</h6></router-link>
            </div>

            <div v-else class="flex justify-center items-center sec_blue_btn p-4 border border-[#0090ec] !rounded-full">
              <router-link to="/profile/confList" class="w-1/2 text-center"><h6>신청내역</h6></router-link>
              <span class="block bg-white w-[1px] h-[0.8em]"></span>
              <router-link to="/profile" class="w-1/2 text-center"><h6>회원정보</h6></router-link>
            </div>
          </div>

          <div class="links mt-6 mb-10 px-5">
            <router-link to="/" active-class="flex items-center text-[#039dff] font-bold" class="block mb-5">
              <h4 class="mr-4">컨퍼런스 신청</h4>
              <template v-if="$route.path === '/'">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14.5px" height="20.5px">
                  <path fill-rule="evenodd"  stroke="rgb(3, 157, 255)" stroke-width="3px" stroke-linecap="round" stroke-linejoin="round" fill="none"
                  d="M3.058,3.058 L9.261,9.261 L3.058,15.464 "/>
                </svg>
              </template>
            </router-link>

            <router-link to="/intro" active-class="flex items-center text-[#039dff] font-bold" class="block mb-5">
              <h4 class="mr-4">2023 여름성경학교 소개</h4>
              <template v-if="$route.path === '/intro'">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14.5px" height="20.5px">
                  <path fill-rule="evenodd"  stroke="rgb(3, 157, 255)" stroke-width="3px" stroke-linecap="round" stroke-linejoin="round" fill="none"
                  d="M3.058,3.058 L9.261,9.261 L3.058,15.464 "/>
                </svg>
              </template>
            </router-link>

            <a href="https://eduwindmall.com/shop/eduwind/index.php" target="_blank" class="block mb-5">
              <h4>성경학교 용품 · 데코용품</h4>
            </a>
            <a href="https://www.holyonebook.com/book/book_list.aspx?lcode=&mcode=&cateid=09&page=1&ordertype=&searchstring=&listtype=gallery" target="_blank" class="block mb-5">
              <h4>교재 구입</h4>
            </a>

            <span class="block bg-[#bfdef2] w-full h-[1px] mb-5"></span>

            <a class="block mb-5" @click="alert_msg(1)">
              <h4 class="text-[#002356]">하나바이블 컨퍼런스 신청</h4>
            </a>
          </div>

          <div class="px-5 text-[#566975] font-light">
            <div class="flex items-center gap-2 leading-none text-sm">
              <router-link to="/policy/info" class="hover:underline">개인정보취급방침</router-link>
              <span class="block bg-black w-[1px] h-[0.8em]"></span>
              <router-link to="/policy/guide" class="hover:underline">이용약관</router-link>
              <span @click="logout()" v-if="$store.state.account.id" class="block bg-black w-[1px] h-[1em]"></span>
              <a @click="logout()" v-if="$store.state.account.id" class="cursor-pointer hover:underline">로그아웃</a>
            </div>

            <div class="lg:text-sm text-xs mt-3">
              <p>문의 : 02)559-5643, eduwind.org@gmail.com</p>
              <p>상호 : (재)대한예수교장로회총회</p>
              <p class="my-3">Copyright © http://총회교육.com. All rights reserved.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  
  </template>
  
  <script>
  import {reactive, ref} from "vue"
  import axios from "axios"
  import { onBeforeRouteLeave } from 'vue-router'
  
  import store from "@/store"
  import router from "@/router"

  import naverLoginComponentTest from "@/components/naverLoginComponentTest.vue";
  import kakaoLoginComponent from "@/components/kakaoLoginComponent.vue";
  export default {
    name: "NavComponent",
    components: {
      naverLoginComponentTest,
      kakaoLoginComponent
    },
    setup () {
      const state = reactive({
        form: {
          uid: "",
          upw: ""
        }
      })

      //메뉴 토글
      const isMenuVisible = ref(false)

      const toggleMenu = () => {
        isMenuVisible.value = !isMenuVisible.value
        document.body.classList.toggle('fixed', isMenuVisible.value)
      }
      onBeforeRouteLeave(() => {
        //페이지 이동시 isMenuVisible, body fixed 초기화
        isMenuVisible.value = false
        document.body.classList.remove('fixed');
      })
  
      //로그인,아웃
      const submit = () => {
  
        if (!state.form.uid) {
            alert("아이디를 입력하세요.")
            return;
        }
        else if (!state.form.upw) {
          alert("비밀번호를 입력하세요.")
          return;
        }
        else{
          axios.post('/api/account/login', state.form).then((res) => {
            console.log(res.data);
            if(res.data.err_code == 0){ //로그인 성공일 경우
              // store.commit('setAccount', state.form.uid)
              localStorage.setItem('authorization', res.data.contents.body.accessToken)
              router.go({path: '/'})
            }else{
              alert(res.data.err_msg);
            }
          }).catch(() => {
            alert('아이디 또는 비밀번호를 확인하세요.')
          })
        }
      }
  
      const logout = () => {
        localStorage.removeItem('authorization')
        store.commit('setAccount', 0)
        store.commit('setLevel', 0)
        store.commit('setUid', '')
        router.push({path: '/'});
      }
  
      return { state, isMenuVisible, toggleMenu, submit, logout }
    },
    methods : {
      alert_msg(v){
        if(v==1){
          alert("5월2일 화요일 12시에 오픈됩니다!");
        }
      }
    }

  }
  </script>
  
  <style scoped>
  input:focus {
    border: none;
    outline: none;
  }

  input::placeholder {
    color: #77aed2;
  }

  .links a {
    width: fit-content;
  }

  .links a:hover:not(.router-link-exact-active) { 
    font-weight: 500;
    cursor: pointer;
  }
  
  </style>
  