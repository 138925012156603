<script setup>
  import router from "@/router";

  const goBack = () => {
    // window.history.back();
    const prevPath = localStorage.getItem('prevPath');
    const prevPathName = localStorage.getItem('prevPathName');
    if( prevPathName === 'undefined' ){
      getData.postMessage('finish');
    }
    else {
      router.go(-1);
    }
  };
</script>

<template>
  <div class="back_wr">
    <button @click="goBack()" class="back">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.636 11.293C3.44853 11.4805 3.34322 11.7348 3.34322 12C3.34322 12.2652 3.44853 12.5195 3.636 12.707L9.293 18.364C9.38525 18.4595 9.49559 18.5357 9.6176 18.5881C9.7396 18.6405 9.87082 18.6681 10.0036 18.6692C10.1364 18.6704 10.2681 18.6451 10.391 18.5948C10.5139 18.5445 10.6255 18.4703 10.7194 18.3764C10.8133 18.2825 10.8875 18.1708 10.9378 18.0479C10.9881 17.9251 11.0134 17.7934 11.0123 17.6606C11.0111 17.5278 10.9835 17.3966 10.9311 17.2746C10.8787 17.1526 10.8025 17.0422 10.707 16.95L6.757 13L20 13C20.2652 13 20.5196 12.8946 20.7071 12.7071C20.8946 12.5196 21 12.2652 21 12C21 11.7348 20.8946 11.4804 20.7071 11.2929C20.5196 11.1053 20.2652 11 20 11L6.757 11L10.707 7.04999C10.8892 6.86139 10.99 6.60879 10.9877 6.34659C10.9854 6.08439 10.8802 5.83358 10.6948 5.64817C10.5094 5.46277 10.2586 5.3576 9.9964 5.35532C9.73421 5.35304 9.4816 5.45383 9.293 5.63599L3.636 11.293Z" fill="black"/>
      </svg>
    </button>
  </div>

  <main class="mx-auto max-w-3xl">
    <h2 class="p-5">하나 바이블 과정 소개</h2>
    <ul class="grid grid-cols-2 p-5 gap-x-3 gap-y-6">
      <li>
        <a href="http://hana.xn--9d0bp30cjhe9zk.com/" target="_blank" class="text-center">
          <img src="@/assets/img/hana_2_3P2.jpg"/>
          <div class="mt-2 bg-black rounded-full text-white w-fit py-1 px-3 mx-auto text-sm">1과정2학기</div>
          <h4 class="font-medium">하나님 나라의 역사</h4>
        </a>
      </li>
      <li>
        <a href="http://hana.xn--9d0bp30cjhe9zk.com/2-1/" target="_blank" class="text-center">
          <img src="@/assets/img/hana_2_3P3.jpg"/>
          <div class="mt-2 bg-black rounded-full text-white w-fit py-1 px-3 mx-auto text-sm">2과정1학기</div>
          <h4 class="font-medium">하나님 나라의 확장</h4>
        </a>
      </li>
      <li>
        <a href="http://hana.xn--9d0bp30cjhe9zk.com/2-2/" target="_blank" class="text-center">
          <img src="@/assets/img/hana_2_3P4.jpg"/>
          <div class="mt-2 bg-black rounded-full text-white w-fit py-1 px-3 mx-auto text-sm">2과정2학기</div>
          <h4 class="font-medium">하나님 나라의 실현</h4>
        </a>
      </li>
      <li>
        <a href="http://hana.xn--9d0bp30cjhe9zk.com/3-1/" target="_blank" class="text-center">
          <img src="@/assets/img/hana_2_3P5.jpg"/>
          <div class="mt-2 bg-black rounded-full text-white w-fit py-1 px-3 mx-auto text-sm">3과정1학기</div>
          <h4 class="font-medium">하나님 나라의 전파</h4>
        </a>
      </li>
      <li>
        <a href="http://hana.xn--9d0bp30cjhe9zk.com/3-2/" target="_blank" class="text-center">
          <img src="@/assets/img/hana_2_3P6.jpg"/>
          <div class="mt-2 bg-black rounded-full text-white w-fit py-1 px-3 mx-auto text-sm">3과정2학기</div>
          <h4 class="font-medium">하나님 나라의 완성</h4>
        </a>
      </li>
      <li>
        <a href="http://hana.xn--9d0bp30cjhe9zk.com/4-1/" target="_blank" class="text-center">
          <img src="@/assets/img/hana_2_3P7.jpg"/>
          <div class="mt-2 bg-black rounded-full text-white w-fit py-1 px-3 mx-auto text-sm">4과정1학기</div>
          <h4 class="font-medium">교리1 하나님 나라 백성의 뿌리</h4>
        </a>
      </li>
    </ul>
  </main>
</template>

<style>
	.back_wr {
    display: none;
    position: sticky;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 99999;
	}
	.back {
    display: flex;
    background: #fff;
    border-radius: 50%;
		margin: 0.5rem;
    padding: 0.375rem;
    border: none;
    justify-content: center;
    align-items: center;
	}

	@media screen and (max-width: 768px) {
		.back_wr {
			display: flex;
		}
	}
</style>