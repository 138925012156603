<template>
  <main class="w-full max-w-[800px] mx-auto mb-20">
    <h1 class="font-bold text-center my-8">주문 상세정보</h1>
    <div class="mt-20 max-lg:px-5 max-lg:mt-10">
      <div class="flex justify-between w-full items-center">
        <div>
          <p>주문번호 : {{orderDetail.oid}}</p>
          <span class="text-gray-400">2024.02.02 18:11:07</span>
        </div>
        <div v-if="orderDetail.tid">
          <a :href="'https://iniweb.inicis.com/receipt/iniReceipt.jsp?noTid=' + orderDetail.tid" type="button" class="sec_blk_btn px-2">영수증 조회</a>
        </div>
      </div>

      {{ orderItemList }}

      <!-- 상품 정보 -->
      <h3 class="font-bold mt-10">상품 정보</h3>
      <div class="cart_grid_container order">
        <div class="cart_grid_wrapper mt-2">
          <div class="cart_grid_header">
            <div></div>
            <div>상품정보</div>
            <div>옵션</div>
            <div>주문 금액</div>
          </div>
          <div v-for="item in orderDetail.details" :key="item.itemId" class="cart_grid_body" style="grid-template-columns: 5rem 2fr .5fr .5fr;">
            <div class="w-20 h-14 flex items-center justify-center border border-neutral-200 rounded-xl overflow-hidden">
              <img v-if="item.thumbnailLink" :src="'https://eduwind-api.nodeblack.net/' + item.thumbnailLink" class="w-full h-full object-cover"/>
              <img v-else src="@/assets/img/null_img.jpg"/>
            </div>
            <div>
              <div class="text-left">
                <p class="max-lg:text-sm">{{item.orderContentTitle}}</p>
              </div>
            </div>
            <div class="product_price flex-col">
              <div v-if="item.isFree === true">
                무료
              </div>
              <div v-else>
                {{(item.sellingPrice * item.quantity).toLocaleString('ko-kr')}}원
              </div>
            </div>
            <div v-if="item.orderStatus === 'ORDER_DONE'">
              <router-link :to="'/mypage/contentsBox/' + item.orderContentId" class="block w-fit mx-auto">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="20" height="20" fill="white"/>
                  <path d="M18 12.6667V16.2222C18 16.6937 17.8127 17.1459 17.4793 17.4793C17.1459 17.8127 16.6937 18 16.2222 18H3.77778C3.30628 18 2.8541 17.8127 2.5207 17.4793C2.1873 17.1459 2 16.6937 2 16.2222V12.6667M5.55556 8.22222L10 12.6667M10 12.6667L14.4444 8.22222M10 12.6667V2" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </router-link>
            </div>

          </div>
        </div>
        <div class="p-5 bg-neutral-100">
          <div class="flex items-center justify-end">
            총 주문금액
            <div v-if="orderDetail.totalPaidAmount > 0" class="flex items-center gap-1">
              <h4 class="ml-2 font-semibold" v-if="orderDetail.totalPaidAmount">
                {{orderDetail.totalPaidAmount.toLocaleString('ko-kr')}}
              </h4>
              <b>원</b>
            </div>
            <div v-else>
              <h4 class="ml-2">무료</h4>
            </div>
          </div>
        </div>
      </div>

      <!-- 콘텐츠 정보 -->

      <h3 class="font-bold mt-10">주문자 정보</h3>
      <div class="bg-neutral-100 p-6" v-if="orderDetail.orderBuyer">
        <div class="flex gap-4 py-3">
          <b class="w-12">이름</b>
          <p>{{orderDetail.orderBuyer.buyerName}}</p>
        </div>
        <div class="flex gap-4 py-3">
          <b class="w-12">연락처</b>
          <p>{{orderDetail.orderBuyer.buyerTel}}</p>
        </div>
        <div class="flex gap-4 py-3">
          <b class="w-12">이메일</b>
          <p>{{orderDetail.orderBuyer.buyerEmail}}</p>
        </div>
        <div class="flex gap-4 py-3">
          <b class="w-12">주소</b>
          <div>
            <p>[{{orderDetail.orderBuyer.buyerZipCode}}] {{orderDetail.orderBuyer.buyerAddress}} {{orderDetail.orderBuyer.buyerDetailedAddress}}</p>
          </div>
        </div>
      </div>

      <h3 class="font-bold mt-10">결제 정보</h3>
      <div class="bg-neutral-100 p-6">
        <div class="flex gap-4 py-3">
          <b>결제방법</b>
          <p v-if="orderDetail.gopaymethod==='Card' || orderDetail.gopaymethod==='VCard'"> 카드결제 </p>
          <p v-if="orderDetail.gopaymethod==='DirectBank'"> 실시간계좌이체 </p>
          <p v-if="orderDetail.gopaymethod==='VBank'"> 가상계좌 </p>
        </div>
        <div class="flex gap-4 py-3">
          <b>결제상태</b>
          <p v-if="orderDetail.orderStatus==='ORDER_DONE'"> 결제완료 </p>
        </div>
      </div>

      <!-- <h3 class="font-bold">유의 사항</h3>
      <div class="bg-neutral-100 p-6">

      </div> -->

    </div>
  </main>
</template>

<script setup>
import axios from 'axios';
import { onMounted, ref } from "vue";
import { useRoute } from 'vue-router';
const route = useRoute();
console.log(route.params);

const orderDetail = ref({});
const orderItemList = ref({});

const getListDetail = async () => {
  try {
    // const url = `/new_api/customer/order/detail/${route.params.id}`;
    const url = `/new_api/customer/order/v2/order-result/${route.params.id}`;
    const { status, data } = await axios.get(url);
    if( status === 200 ){
      if( data.code === '200' ){
        console.log(data.data);
        orderDetail.value = data.data;
      }
    }
  } catch (err) {
    console.error(err);
  }
};

const getOrderItemDetail = async () => {
  try {
    const url = `/new_api/customer/order/item/detail/${orderDetail.value.orderId}`;
    const { status, data } = await axios.get(url);
    if( status === 200 ){
      if( data.code === '200' ){
        console.log(data.data);
        orderItemList.value = data.data;
        console.log(orderItemList.value);
      }
    }
  } catch (err) {
    console.error(err);
  }

}

onMounted(async () => {
  await getListDetail();
  // await getOrderItemDetail();
});
</script>