<template>
  <!-- class="lg:flex flex-1" -->
  <div>
    <TNavComponent style="width: 100%; max-width: 100%; max-height: 3.75rem; overflow: hidden;"/>
    <toTopComponent/>
    <contentsDetailPage />
    <FooterComponent/>
  </div>
</template>

<script>
import TNavComponent from "@/components/TNavComponent.vue"
import toTopComponent from "@/components/toTopComponent.vue"
import contentsDetailPage from "@/pages/contentsMall/contentsDetailPage.vue"
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "contentsDetail",
  components: { contentsDetailPage, TNavComponent, toTopComponent, FooterComponent },

};
</script>
