<template>
  <section class="flex-1 my-10 pb-20">
    <div class="text-center px-5">
      <h1 class="font-medium mb-3 pt-20">로그인</h1>
      <p class="font-light text-neutral-400 break-keep">총회교육개발원 홈페이지에 오신 것을 환영합니다</p>
    </div>

    <div class="field_inputs login_inputs max-w-[658px] mx-auto my-10 px-5">
      <div>
        <input type="text" class="regi_inputs w-full" placeholder="아이디" v-model="state.form.uid"/>
      </div>
      <div>
        <input type="text" class="regi_inputs w-full" placeholder="비밀번호" v-model="state.form.upw"/>
      </div>

      <div class="flex justify-between mt-4">
        <span class="check_btn check_blk_btn">
          <input id="rememberMe" type="checkbox" name="rememberMe"/>
          <label for="rememberMe">아이디 저장</label>
        </span>
        <div class="flex gap-2">
          <a href="" class="text-neutral-400">아이디/비밀번호 찾기</a>
          <router-link to="/signup" class="underline ml-2">회원가입</router-link>
        </div>
      </div>

      <div class="mt-8 pb-5">
        <button class="sec_filled-blk_btn w-full p-4" @click="submit()">로그인</button>
      </div>

      <div class="mt-8 pb-10">
        <button class="naver_btn w-full p-4 mb-2">
          <img src="@/assets/img/login_naver.jpg" alt="네이버로그인"/> 네이버 로그인
        </button>

        <button class="kakao_btn w-full p-4">
          <img src="@/assets/img/login_kakao.jpg" alt="카카오로그인"/> 카카오 로그인
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import {reactive} from "vue";
import axios from "axios";
// import store from "@/store";
import router from "@/router";

export default {
  name: "LoginPage",
  setup () {
    const state = reactive({
      form: {
        uid: "",
        upw: ""
      }
    })

    const submit = () => {

      if (!state.form.uid) {
          alert("아이디를 입력하세요.")
          return;
      }
      else if (!state.form.upw) {
        alert("비밀번호를 입력하세요.")
        return;
      }
      else{
        axios.post('/api/account/login', state.form).then((res) => {
          console.log(res.data);
          if(res.data.err_code == 0){ //로그인 성공일 경우
            // store.commit('setAccount', state.form.uid)
            localStorage.setItem('authorization', res.data.contents.body.accessToken)
            router.push({path: '/'})
          }else{
            alert(res.data.err_msg);
          }
        }).catch(() => {
          alert('아이디 또는 비밀번호를 확인하세요.')
        })
      }
    }

    return { state, submit }
  }
}
</script>

<style scoped>

</style>
