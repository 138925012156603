<script setup>
import {computed, defineEmits, onMounted, ref} from "vue";
import axios from "axios";
import router from "@/router";
import { numberWithCommas } from '@/assets/js/util.js';

const emit = defineEmits();

const closeModal = () => {
  emit('close');
};
// 업데이트 성공 - 부모 함수 호출
const updateComplete = () => {
  emit('updateComplete');
};

const { cartIdx } = defineProps(['cartIdx']);

const productIdx = ref(null);
onMounted(async () => {
  // await getItemInfo();
  await getOptionInfo();
  await getItemInfo();

  selectedOption.value = new Array(requiredOptionSetting.value.length).fill('');
  normalOption.value = new Array(normalOptionSetting.value.length).fill('');
});

const itemId = ref(null);
const requiredOptionSetting = ref([]);    // 필수 옵션 목록
// const requiredWriteSetting = ref([]);    // 옵션 목록
const writeSetting = ref([]);    // 옵션 목록
const optionSetting = ref([]);    // 옵션 목록
const normalOptionSetting = ref([]);    // 일반 선택 옵션 예시
const normalOption = ref([]);
const selectedOption = ref([]);
const selectedOptionList = ref([]);
const selectedOptionTitle = ref(null);

const cartOptionList = ref([]);   // 현재 장바구니에 담겨져 있는 옵션 목록

// 옵션 업는 상풍 관련 변수들
const noneOptionItem = ref(false);  // 옵션 있는 상품인지 아닌지
const noneOptionItemInfo = ref({
  itemId : null,
  isSelected : true,
  quantity : null,
  licenseId : null,
  productStock : 1,  // 옵션 없는 상품 보낼때 사용
  command : null
});  // 옵션 없는 상품 정보
const noneOptionItemPrice = ref(0);   // 옵션 없는 상품 가격
const noneOptionItemTotalPrice = ref(0);    // 옵션 없는 상품 총 가격


const getOptionInfo = async () => {
  try {
    const url = `/new_api/customer/cart/v2/${cartIdx}/options`;
    const { data, status } = await axios.get(url);
    console.log(status);
    const info = data.data;
    console.log(info);
    productIdx.value = info.itemId;
    cartOptionList.value = info.cartOptionList;

    console.log(cartOptionList.value);

    info.cartOptionList.forEach((option, index) => {
      const item = {
        optionIdx: option.optionId,
        race: 'combination',
        type: option.type,
        title: option.optionPathNames.join(' > '),
        option: option.combinationId,
        count: option.quantity,
        price: option.price,
        totalPrice: option.price * option.quantity,
        value: (option.optionMessage) ? option.optionMessage : null
      };

      selectedOptionList.value.push(item);
    });
    // selectedOptionList 목록에 cartOptionList 를 넣어 줘야 함 - 목록 생성
    // optionSetting.value = option;
  } catch (err) {
    console.error(err);
  }
};

const getItemInfo = async () => {
  try {
    if( productIdx.value > 0 && productIdx.value !== null ){
      const url = `/new_api/customer/item/${productIdx.value}`;
      const { data, status } = await axios.get(url);
      if( status === 200 ){
        const item = data.data;
        console.log(item);
        item.itemType = (item.code.startsWith('P')) ? 'PRODUCT' : 'CONTENT';
        item.itemId = (item.code.startsWith('P')) ? item.productId : item.contentId ;
        if( item.itemType === 'PRODUCT' ){
          // 필수 옵션
          requiredOptionSetting.value = item.optionList.filter(option => (option.required === true && option.optionType === 'TREE'));
          /*requiredOptionSetting.value.map((option, index) => {
            option.required = true;
          });*/
          // 선택 옵션
          normalOptionSetting.value = item.optionList.filter(option => (option.required === false && option.optionType === 'TREE'));
          // 입력 옵션
          writeSetting.value = item.optionList.filter(option => (option.optionType === 'WRITING'));
          // 출고지 정보
          // warehouseInfo.value = item.warehouse;

          noneOptionItem.value = (requiredOptionSetting.value.length === 0 && normalOptionSetting.value.length === 0 && writeSetting.value.length === 0);
          if( noneOptionItem.value === true ){
            noneOptionItemInfo.itemId = item.itemId;
            noneOptionItemPrice.value = item.sellingPrice;
            noneOptionItemTotalPrice.value = item.sellingPrice;
          }
        }
      }
      else {
        alert('상품 정보를 가져오는데 실패하였습니다.');
        document.location.reload();
      }
    }

  } catch (err) {
    console.error(err);
  }
};


const combinationSelectOption = async (option, idx, require) => {
  console.group(` combinationSelectOption ------------------------------------------- `);

  console.log(option);
  const selectOption = (require === 'R') ? requiredOptionSetting.value : optionSetting.value;
  console.log(selectOption);

  console.log(selectedOption.value);
  console.log(selectedOption.value[idx]);

  const optionCombined = contentDetail.value.optionCombined;
  selectedOption.value[idx].optionCombined = optionCombined;

  // 조합형
  if( optionCombined === true ){
    const optionCount = selectOption.length - 1;
    console.log(optionCount, idx);
    if (optionCount <= idx) {
      addOption(selectedOption.value[idx], option.optionId, require, optionCombined);
    } else {
      getOptionList(idx);
    }
  }
  // 비조합형
  else {
    selectedOption.value[`combinationId${idx + 1}`] = selectedOption.value.combinationId;
    selectedOption.value.combinationId = null;

    // selectedOption.value[idx][`combinationId${idx + 1}`] = option.combinationId;
    console.log(selectedOption.value[`combinationId${idx + 1}`]);
    console.log(selectedOption.value);

    const optionCount = selectOption.length - 1;
    console.log(optionCount, idx);

    console.groupEnd();
    if (optionCount <= idx) {
      addOption(selectedOption.value, option.optionId, require, optionCombined);
    }
    // addOption(selectedOption.value[idx], option.optionId, require, optionCombined);
  }


}
/*
const addOption = (option, optionId) => {
  console.group(` addOption ------------------------------------------- `);
  console.log(option);
  console.log(option.title);
  const item = {
    optionIdx: optionId,
    race: 'combination',
    type: option.type,
    title: (option.title) ? option.title : null,
    option: option.combinationId,
    count: 1,
    price: option.price,
    totalPrice: option.price * 1,
    value: null
  };
  if( option.value ){
    item.value = option.value;
  }
  console.log(item.title);
  // selectedOptionList 중복 확인
  let overlap = false;
  console.log(selectedOptionList.value);
  if( selectedOptionList.value.length > 0 ) {
    selectedOptionList.value.forEach((val, index) => {
      if (JSON.stringify(val.option) === JSON.stringify(item.option)) {
        alert('이미 선택된 옵션입니다.');
        overlap = true;
        return false;
      }
    });
  }

  if( overlap === false ){
    console.log(item);
    console.log(item.title);
    console.log(`selectedOptionTitle.value : ${selectedOptionTitle.value}`);
    if( item.title === null ) {
      if( selectedOptionTitle.value !== null ){
        item.title = selectedOptionTitle.value + ' > ' + option.elementName;
      }
      else {
        item.title = option.elementName;
      }
    }
    selectedOptionList.value.push(item);
    selectedOptionTitle.value = null;   // 상품명 초기화
  }
  // console.log(combinationOptionSetting.value);
  // selectedOption.value = new Array(combinationOptionSetting.value.length).fill('');

  console.groupEnd();
}
*/
/**
 * 옵션 추가
 * @param option
 * @param optionId
 * @param require - 필수항목
 * @param optionCombined - 조합, 비조합 판단
 */
const addOption = (option, optionId, require=null, optionCombined) => {
  console.group(` addOption ------------------------------------------- `);
  console.log(`++++++++++++++++++++++++++++++++++++++++`);
  console.log(optionCombined);
  console.log(option);
  console.log(`++++++++++++++++++++++++++++++++++++++++`);
  let item;
  if( optionCombined === false ){
    const title = option.map((val) => val.elementName).join(' > ');
    const price = option.reduce((acc, cur) => acc + cur.price, 0);
    // optionIdx: optionId,
    item = {
      optionIdx: null,
      race: ( optionCombined === 'Y' ) ? 'combination' : 'noneCombination',
      type: option.type,
      title: title,
      option: ( option.combinationId ),
      count: 1,
      price: price,
      totalPrice: price * 1,
      value: ( option.value ),
      optionMessage: ( option.optionMessage ) ? option.optionMessage : null,
      require: require,
      combinationId1: option[0].combinationId,
      combinationId2: option[1].combinationId,
      combinationId3: option[2].combinationId,
    };

    console.log(`item +++++++++++++++++++++++++++++++++++++++++++++ `);
    console.log(item);
    console.log(`item +++++++++++++++++++++++++++++++++++++++++++++ `);
  }
  else {
    item = {
      optionIdx: optionId,
      race: ( optionCombined === 'Y' ) ? 'combination' : 'noneCombination',
      type: option.type,
      title: (option.title) ? option.title : null,
      option: option.combinationId,
      count: 1,
      price: option.price,
      totalPrice: option.price * 1,
      value: option.value,
      optionMessage: ( option.optionMessage ) ? option.optionMessage : null,
      require: require,
      combinationId1: ( option.combinationId1 ),
      combinationId2: ( option.combinationId2 ),
      combinationId3: ( option.combinationId3 ),
    };
    if( option.value ){
      item.value = option.value;
    }
  }

  console.log(item);

  // selectedOptionList 중복 확인
  let overlap = false;
  selectedOptionList.value.forEach((val, index) => {
    if (
      JSON.stringify(val.option) === JSON.stringify(item.option) &&
      JSON.stringify(val.combinationId1) === JSON.stringify(item.combinationId1) &&
      JSON.stringify(val.combinationId2) === JSON.stringify(item.combinationId2) &&
      JSON.stringify(val.combinationId3) === JSON.stringify(item.combinationId3)
    ) {
      alert('이미 선택된 옵션입니다..');
      overlap = true;
      return false;
    }
  });

  if( overlap === false ){
    /*console.log(item);
    console.log(item.title);
    console.log(`selectedOptionTitle.value : ${selectedOptionTitle.value}`);*/
    if( item.title === null ) {
      if( selectedOptionTitle.value !== null ){
        item.title = selectedOptionTitle.value + ' > ' + option.elementName;
      }
      else {
        item.title = option.elementName;
      }
    }
    selectedOptionList.value.push(item);
    selectedOptionTitle.value = null;   // 상품명 초기화
  }
  selectedOption.value = new Array(combinationOptionSetting.value.length).fill('');

  console.groupEnd();
}
// 옵션가져오기 - 완료
const getOptionList = ( idx ) => {
  console.log('getOptionList');
  console.log(selectedOption.value[idx]);
  let elements = "";
  selectedOption.value.forEach((val, index) => {
    if( val !== '' && val !== undefined && val !== null){
      elements += (( index === 0 )? '?' : '&' ) + `elements=${val.elementName}`;
    }
  });
  console.log(elements);

  // const url = `/api/product/${itemId}/options?elements=A&elements=1`;
  const url = `/new_api/product/${itemId}/options${elements}`;
  axios.get(url).then((res) => {
    console.log(res.data.data.paths);
    requiredOptionSetting.value[idx+1].optionNodeList = res.data.data.paths
    selectedOptionTitle.value = ( selectedOptionTitle.value !== null ) ? selectedOptionTitle.value + ' > ' + selectedOption.value[idx].elementName : selectedOption.value[idx].elementName;
        // selectedOptionTitle.value + ' > ' + selectedOption.value[idx].elementName;
  })
  .catch((error) => {
    console.error(error);
  });
}
// 완료
const makeWriteOption = ( option ) => {
  console.log(option.inputData);
  // console.log(option.optionType);

  const writeOption = {
    type: option.optionType,
    title: option.inputData,
    combinationId: option.optionNodeList[0].combinationId,
    price: option.optionNodeList[0].price,
    value: null,
    optionMessage: option.inputData,
  }
  option.inputData = "";   // 초기화
  addOption(writeOption, option.optionId);
}
// 완료
const normalSelectOption = ( option, idx, require ) => {
  console.group(` normalSelectOption ------------------------------------------- `);
  console.log(option);
  // const selectedOption = event.target.value;
  const selectedOption = normalOption.value[idx];
  if( selectedOption !== '' ) {
    const item = {
      optionIdx: selectedOption.optionId,
      race: 'normal',
      type: option.type,
      title: selectedOption.elementName,
      option: selectedOption.combinationId,
      count: 1,
      price: selectedOption.price,
      totalPrice: selectedOption.price * 1,
      value: null
    };

    console.log(item);
    // selectedOptionList 중복 확인
    let overlap = false;

    if( item.type === 'input' ){
      selectedOptionList.value.forEach((val, index) => {
        if (val.optionIdx === item.optionIdx) {
          alert(' .');
          overlap = true;
          return false;
        }
      });

    }
    else {
      selectedOptionList.value.forEach((val, index) => {
        if (JSON.stringify(val.option) === JSON.stringify(item.option)) {
          alert('이미 선택된 옵션입니다.');
          overlap = true;
          return false;
        }
      });
    }

    if( overlap === false ){
      selectedOptionList.value.push(item);
    }

    normalOption.value[idx] = "";   // 초기화
  }

  console.groupEnd();
}
// 완료
const optionDecreaseQuantity = ( item, index ) => {
  // selectedOptionList.value[index].count--;
  const option = selectedOptionList.value[index];
  if( option.count > 1 ){
    option.count--;
    option.totalPrice = option.price * option.count;
  }
  else {
    alert('수량은 1 이상이어야 합니다.');
  }

  /*if (quantity.value > 1) {
    quantity.value--;
  } else {
    alert('수량은 1 이상이어야 합니다.')
  }*/
};
// 완료
const optionIncreaseQuantity = ( item, index ) => {
  const option = selectedOptionList.value[index];
  option.count++;
  option.totalPrice = option.price * option.count;
};

const optionDecreaseQuantityNone = () => {
  // selectedOptionList.value[index].count--;

  if( noneOptionItemInfo.value.productStock > 1 ){
    noneOptionItemInfo.value.productStock--;
    noneOptionItemTotalPrice.value = noneOptionItemPrice.value * noneOptionItemInfo.value.productStock;
  }
  else {
    alert('수량은 1 이상이어야 합니다.');
  }
};

const optionIncreaseQuantityNone = () => {
  noneOptionItemInfo.value.productStock++;
  noneOptionItemTotalPrice.value = noneOptionItemPrice.value * noneOptionItemInfo.value.productStock;
};

const cartTotalPrice = computed(() => {
  let totalPrice = 0;
  if (selectedOptionList.value.length > 0) {
    console.log(selectedOptionList);
    console.log(selectedOptionList.value);
    console.log(selectedOptionList.value[0]);
    totalPrice = selectedOptionList.value.reduce((acc, cur) => acc + cur.totalPrice, 0);
  }
  console.log(totalPrice)
  return totalPrice;
});

const deleteCart = ( item ) => {
  const orgSelectedOptionList = JSON.parse(JSON.stringify(selectedOptionList.value));
  orgSelectedOptionList.forEach((val, index) => {
    if (JSON.stringify(val) === JSON.stringify(item)) {
      orgSelectedOptionList.splice(index, 1);
    }
  });
  selectedOptionList.value = orgSelectedOptionList;

  // 배열로 초기화
  if( selectedOptionList.value.length === 0 ){
    selectedOptionList.value = [];
  }

}

const deleteCartItem = ( cartIdx ) => {
  console.log('deleteCartItem');
  if( confirm('해당 상품을 장바구니에서 삭제하시겠습니까?') ){
    const url = `/new_api/customer/cart/v2/delete-option/${cartIdx}`;
    axios.delete(url).then((res) => {
      console.log(res);
      getOptionInfo();
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

const updateCart = async () => {
  console.log('updateCart');
  console.log(selectedOptionList.value);

    // isSelected : true,
  const param = {
    itemId : productIdx.value,
    quantity : null,
    licenseId : null,
    productStock : null,
    productOptionList : [],
    command : null,
  }

  if( noneOptionItem.value === true ){
    param.productStock = noneOptionItemInfo.value.productStock;
  }
  else {

    // param.productStock = ""; // 옵션이 없는 상품 들
    // param.productOptionList = "";
    console.log(selectedOptionList.value);
    let productOptionList = [];
    selectedOptionList.value.forEach((item, index) => {
      productOptionList.push({
        require: (item.require === 'R'),
        optionId: item.optionIdx,
        combinationId: item.option,
        quantity: item.count,
        optionMessage: item.optionMessage,    // 입력형 옵션
        // 비조합형 필수 옵션 조합 아이디
        combinationId1: item.combinationId1,
        combinationId2: item.combinationId2,
        combinationId3: item.combinationId3
      });
    });

    // 필수 항목 체크
    // require 1개 이상 체크
    let requireCheck = false;
    requiredOptionSetting.value.forEach((item, index) => {
      if (productOptionList.filter(option => option.require === true).length > 0) {
        requireCheck = true;
      }
    });

    if (requireCheck === false) {
      alert('필수 옵션을 하나 이상 선택해 주세요.');
      return false;
    }


    console.log(productOptionList);
    if (productOptionList.length > 0) {
      param.productOptionList = productOptionList;
    } else {
      alert('옵션을 선택해 주세요.');
      return false;

    }
  }

  try {
    await axios.post('/new_api/customer/cart/v2/edit', param).then((res) => {
      if (res.status === 200) {
        if (res.data.code === '200') {
          alert('장바구니에 담겼습니다.');
          updateComplete();
        }
        else if (res.data.code === '031') {
          alert('상품 재고 부족');
        }
        else if (res.data.code === '032') {
          // TODO: 상품 불러올 때 판매중지 여부 체크 필요
          alert('판매중지된 상품이므로 구매하실 수 없습니다.');
          window.location.reload();
        }
        else {

        }
      } else {
        console.log(res);
      }
    });
  } catch (e) {
    console.log(e);
  }

}
</script>

<template>

  <div class="modal_container">
    <div class="reviewWrite_wrapper">
      <div class="flex justify-between items-center p-2 border-b border-black">
        <h4> 상품명 옵션 변경 </h4>
        <button class="close" @click="closeModal">X</button>
      </div>

      <div class="optionBody">
        <div class="p-2">

          <div class="mb-3" v-if="noneOptionItem === false">
<!--            {{ requiredOptionSetting }}-->
            <div v-for="(option, index) in requiredOptionSetting" :key="index" class="mb-2">
              <!-- {{ option }} -->
              <div>
                <div> 필수 옵션 </div>
                <select
                  v-model="selectedOption[index]"
                  class="selectClass"
                  @change="combinationSelectOption( option, index, 'R' )"
                >
                  <option value=""> {{ option.optionName }} </option>
                  <option v-if="option.optionNodeList.length > 0" :value="item" v-for="item in option.optionNodeList">
                    {{item.elementName}}(+{{numberWithCommas(item.price)}})
                  </option>
                  <option v-if="option.optionNodeList.length <= 0 && index > 0" value="" >
                    {{ requiredOptionSetting[index-1].optionName }} 옵션을 먼저 선택해 주세요.
                  </option>
                </select>
              </div>
            </div>

<!--            {{ normalOptionSetting }}-->
            <div v-for="(option, index) in normalOptionSetting" :key="index">
              <div>
                <div> 선택 옵션 </div>
                <select
                  v-model="normalOption[index]"
                  class="selectClass"
                  @change="normalSelectOption( option, index, 'N' )"
                >
                  <option value=""> {{ option.optionName }} </option>
                  <option v-if="option.optionNodeList.length > 0" :value="item" v-for="item in option.optionNodeList">
                    {{item.elementName}} {{ item.price > 0 ? ' - ' + item.price + '원' : ''}}
                  </option>
                </select>
              </div>
            </div>

<!--            {{ writeSetting }}-->
            <div v-for="(option, index) in writeSetting" :key="index">
              <div>
                <div> {{ option.optionName }} </div>
                <div class="flex justify-between gap-1">
                  <input type="text" class="w-10/12 border border-neutral-800 p-2" v-model="option.inputData" @keyup.enter="makeWriteOption(option)">
                  <button type="button" class="border w-2/12" @click="makeWriteOption(option)">추가</button>
                </div>
              </div>
            </div>

<!--            {{ selectedOptionList }}-->
            <div v-if="selectedOptionList.length>0">
              <!-- {{ selectedOptionList }} -->
              <div v-for=" (item, index) in selectedOptionList " class="flex-col mt-5">

                <div class="flex mb-2">
                  <span> {{ item.title }} </span>
                  <!--
                  <span v-for="(title, opIndex) in item.option" v-if="item.race === 'combination'">
                    <span>{{ title }}</span>
                    <span v-if="opIndex < item.option.length-1 "> /&nbsp;</span>
                  </span>
                  <span v-else>
                    <span>{{ item.option }}</span>
                  </span>
                  -->
                </div>

                <div class="flex justify-between items-center">

                  <div class="countBox" data-hs-input-number>
                    <div class="flex items-center gap-x-1.5">
                      <button
                        type="button"
                        class="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        data-hs-input-number-decrement
                        @click="optionDecreaseQuantity(item, index)"
                      >
                        <svg class="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14"/></svg>
                      </button>

                      <input
                        class="p-0 w-6 bg-transparent border-0 text-gray-800 text-center focus:ring-0 dark:text-white"
                        type="text"
                        v-model="item.count"
                        data-hs-input-number-input
                      >

                      <button
                        type="button"
                        class="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        data-hs-input-number-increment
                        @click="optionIncreaseQuantity(item, index)"
                      >
                        <svg class="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14"/><path d="M12 5v14"/></svg>
                      </button>
                    </div>
                  </div>

                  <div class="flex items-center">
                    <div class="mr-3">
                      <span class="text-xl font-bold"> {{ item.totalPrice }} </span> 원
                    </div>
                    <div @click="deleteCart(item)">
                      X
                    </div>
                  </div>

                </div>

              </div>


              <div class="flex justify-between items-baseline mt-3 pt-3 border-t">
                <div> 총 상품 가격 </div>
                <div class="text-xl">
                  <span class="text-2xl">
                    {{ selectedOptionList.reduce((acc, cur) => acc + cur.totalPrice, 0) }}
                  </span> 원
                </div>
              </div>

            </div>


          </div>

          <div class="mb-3" v-else>

            <div class="countBox" data-hs-input-number>
              <div class="flex items-center gap-x-1.5">
                <button
                  type="button"
                  class="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  data-hs-input-number-decrement
                  @click="optionDecreaseQuantityNone()"
                >
                  <svg class="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14"/></svg>
                </button>

                <input
                  class="p-0 w-6 bg-transparent border-0 text-gray-800 text-center focus:ring-0 dark:text-white"
                  type="text"
                  v-model="noneOptionItemInfo.productStock"
                  data-hs-input-number-input
                >

                <button
                  type="button"
                  class="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  data-hs-input-number-increment
                  @click="optionIncreaseQuantityNone()"
                >
                  <svg class="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14"/><path d="M12 5v14"/></svg>
                </button>
              </div>
            </div>

            <div class="flex justify-between items-baseline mt-3 pt-3 border-t">
              <div> 총 상품 가격 </div>
              <div class="text-xl">
                <span class="text-2xl">
                  {{ noneOptionItemTotalPrice }}
                </span> 원
              </div>
            </div>

          </div>

        </div>

        <!--
        <div class="p-2">

          {{ cartOptionList }}
          <ul>
            <li v-for="option in cartOptionList" class="productOptionList">
              <div>
                <span v-for=" ( pathName, pathIndex ) in option.optionPathNames ">
                  <span> {{ pathName }} </span>
                  <span v-if="pathIndex < (option.optionPathNames.length-1)"> > </span>
                </span>
              </div>
              <span class="px-2"> / </span>
              <div> {{ option.quantity }}개 </div>
              <div v-if="option.price>0"> ({{ option.price }}원) </div>

              <div class="ml-50">
                <button @click="deleteCartItem(option)">X</button>
              </div>
            </li>
          </ul>

        </div>
        -->
      </div>

      <div class="optionFooter">
<!--        {{ selectedOptionList }}-->
        <button type="button" class="mt-4 sec_blk_btn lg:px-5 px-2 py-2" @click="closeModal">닫기</button>
        <button type="button" class="mt-4 sec_blk_btn lg:px-5 px-2 py-2" @click="updateCart"> 저장 </button>
      </div>

    </div>
  </div>

</template>

<style scoped>
  .modal_container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .reviewWrite_wrapper {
    width: 88%;
    max-width: 36rem;
    min-height: 88vh;
    background-color: #fefefe;
    padding: 1rem;
    border: 1px solid #888;
    max-height: 90vh;
  }

  .close {
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .productOptionList {
    display: flex;
    align-items: baseline;
    padding: 0.5rem 0;
  }

  .optionBody {
    overflow-y: auto;
    max-height: 70vh;
  }
  .optionFooter {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .selectClass {
    @apply border border-neutral-800 p-2 col-span-4 w-full;
  }
  .countBox {
    @apply py-2 px-3 inline-block bg-white rounded-lg dark:bg-slate-900 dark:border-gray-700;
  }
</style>