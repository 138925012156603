<template>
  <div id="member-management">
    <div class="adm_box_wrapper">
      <section class="flex justify-between items-baseline p-5 border-b border-gray-200">
        <div>
          <h3 class="font-medium">회원 목록</h3>
          <p class="text-neutral-500">Total : {{ state.items.length }}</p>
        </div>
      </section>
      <!--<ul>
        <li v-for="(member, idx) in state.members" :key="idx">{{ member }}</li>
      </ul>-->
  
      <div>
        <div class="p-2 flex justify-end">
          <select v-model="state.searchField" class="border text-sm mx-1">
            <option value="name"> 이름(아이디) </option>
            <option value="phone"> 전화번호 </option>
            <option value="email"> 이메일 </option>
          </select>
          <input type="text" v-model="state.searchValue" class="border text-sm px-2" placeholder="검색어 입력">
        </div>
        <div class="text-center">
          <Vue3EasyDataTable
            :headers="headers"
            :items="state.items"
            :search-field="state.searchField"
            :search-value="state.searchValue"
            alternating
          >
            <template #item-join_type="{kakao, naver, apple, item, password}">
              <div class="flex w-full justify-center items-center">
                <div>
                  <img src="@/assets/img/naver_icon.svg" alt="네이버" v-if="naver===true"><span class="w-2 mr-1" v-else>-</span>
                </div>
                <div class="w-1 mx-0.5 text-center text-gray-300">|</div>
                <div>
                  <img src="@/assets/img/kakao_icon.svg" alt="카카오" v-if="kakao===true"><span v-else>-</span>
                </div>
                <div class="w-1 mx-0.5 text-center text-gray-300">|</div>
                <div>
                  <img src="@/assets/img/apple_icon.svg" alt="애플" v-if="apple===true"><span v-else>-</span>
                </div>
                <div class="w-1 mx-0.5 text-center text-gray-300">|</div>
                <div>
                  <span v-if="password===true" class="w-fit">일반</span><span class="w-8 ml-1" v-else>-</span>
                </div>
              </div>
            </template>
            <template #item-edit="{item}">
              <span class="cursor-pointer" @click="openMemberEdit(item)"> [수정] </span>
            </template>

          </Vue3EasyDataTable>
        </div>

      </div>



    </div>

    <div class="modal" v-show="state.memberViewModal === true">
      <div class="overlay" @click="state.memberViewModal = false"></div>
      <div class="modal-card">
        <div id="AdmModal">
          <div class="flex justify-center mt-5">
            <h5> 회원 정보 수정 </h5>
  <!--          <div><img src="@/assets/img/my_pop_close.svg" class="w-11 max-w-[8vw] cursor-pointer" alt="닫기"></div>-->
          </div>
          <div class="p-5">
            <div class="signup_form field_input mb-5 p-4">
              <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0">
                <label class="inline-block w-1/5">아이디<span></span></label>
                <p> {{state.memberData.uid}} </p>
              </div>

              <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0">
                <label class="inline-block w-1/5">이름<span></span></label>
                <input type="text" v-model="state.memberData.name" class="px-4 py-2 border">
              </div>

              <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0">
                <label class="inline-block w-1/5">연락처<span></span></label>
                <input type="text" v-model="state.memberData.phone" class="px-4 py-2 border">
              </div>

              <div class="flex items-center border-b border-neutral-200 max-md:!pl-4 max-md:!mb-0">
                <label class="inline-block w-1/5">이메일<span></span></label>
                <input type="text" v-model="state.memberData.email" class="px-4 py-2 border">
              </div>
            </div>
          </div>
          <div class="flex justify-center">
            <button class="sec_blk_btn block w-20 p-2 mr-2" @click="state.memberViewModal = false">닫기</button>
            <button class="sec_filled-blk_btn block w-20 p-2" @click="memberInfoUpdate">수정</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { reactive } from "vue"
import axios from "axios"

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

const state = reactive({
  searchField: 'name',
  searchValue: '',
  items: [],
  memberViewModal: false,
  memberData: {},
})

const headers = reactive([
  { text: "이름(아이디)", value: "name", sortable: true },
  { text: "전화번호", value: "phone" },
  { text: "이메일", value: "email" },
  // { text: "교회명", value: "church" },
  { text: "가입날짜", value: "created_date" },
  { text: "최근접속일", value: "last_date" },
  { text: "가입여부", value: "join_type" },
  { text: "수정", value: "edit" },
])

const getMemberList = async () => {
	state.items = [];
  const url = `/api/members`;
  const { data } = await axios.get(url);
  const { contents } = data;
	contents.forEach((item) => {
		let setItem = {
      phone: formattedPhone(item.phone),
      email: item.email,
      name: item.name + " (" + item.uid + ")",
      status: item.status,
      is_agree: item.is_agree,
      created_date: formattedDate(item.created_date),
      updated_date: formattedDate(item.updated_date),
      last_date: formattedDate(item.last_logindate),
      kakao: item.kakao,
      naver: item.naver,
      apple: item.apple,
      password: item.password,
      join_type: "",
      edit: "",
      item:{
        idx: item.idx,
        uid: item.uid,
        name: item.name,
        phone: item.phone,
        email: item.email
      }
    };
    state.items.push(setItem);
	})
}

getMemberList();

const formattedPhone = (phone) => {
  if( phone ){
    if (phone.length === 11) {
      return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1 - $2 - $3');
    } else {
      return phone;
    }
  }
  else {
    return phone;
  }
}

const formattedDate = ( date ) => {
  if (date) {
    date = new Date(date);
    return date.toLocaleString();
  }
  else {
    return '-';
  }
}

const openMemberEdit = (item) => {
  state.memberData = item;
  state.memberViewModal = true;
  // document.location.href = '/management/member/edit/' + item.idx;
}

const memberInfoUpdate = () => {
  alert('준비 중 입니다.');
  return false;
  // 핸드폰번호 숫자만 추출
  /*const phone = state.memberData.phone.replace(/[^0-9]/g, '');
  const body = {
      idx: state.memberData.idx,
      name: state.memberData.name,
      phone: phone,
      email: state.memberData.email,
  }
  axios.put('/api/member/update', body).then(response => {
    if( response.data.err_code == 0 ){
      alert('회원정보가 수정되었습니다.');
      getMemberList();
    }
    else {
      alert(response.data.err_msg);
    }
  }).catch(error => {
    console.error(error)
  })*/
}


</script>

<style scoped>
/* Modal */
.modal,
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  z-index: 2;
}
.overlay {
  opacity: 0.5;
  background-color: black;
}
.modal-card {
  position: relative;
  margin: auto;
  width: 500px;
  background-color: white;
  z-index: 10;
  opacity: 1;
}
#AdmModal {
  margin-bottom: 15px;
}
.close {
  cursor: pointer;
}
.btn-black-100 {
  cursor: pointer;
}
</style>
