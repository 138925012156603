<template>
  <div class="lg:flex flex-1" >
    <LNavComponent v-if="pageName !== 'memberProfileConferenceApp' && pageName !== 'memberProfileConferenceApp2' " />
    <appNavigator v-if="pageName === 'memberProfileConferenceApp' || pageName === 'memberProfileConferenceApp2'" :page="pageName" />
    <component :is="currentComponent" class="max-lg:w-screen flex-1"/>
  </div>
</template>

<script setup>
import { shallowRef } from 'vue'

// import TNavComponent from "@/components/TNavComponent.vue"
import LNavComponent from "@/components/LNavComponent.vue"
import MemberProfilePage from "@/pages/MemberProfilePage.vue"
import MemberProfileConfPage from "@/pages/MemberProfileConfPage.vue"
import MemberProfileConfPageTest from "@/pages/MemberProfileConfPageTest.vue"
import FooterComponent from "@/components/FooterComponent.vue";

import { watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import appNavigator from "@/components/appNavigator.vue";

const currentComponent = shallowRef(MemberProfileConfPage)

const route = useRoute()

const pageName = route.name;

watchEffect(() => {
  console.log(route.name);
  if (route.name === "memberProfileConference" || route.name === "memberProfileConferenceApp" || route.name === "memberProfileConferenceApp2") {
    currentComponent.value = MemberProfileConfPage;
  } else if (route.name === "memberProfileConferenceTest") {
    currentComponent.value = MemberProfileConfPageTest;
  } else {
    currentComponent.value = MemberProfilePage;
  }
})
</script>

