<template>
  <div class="w-full mx-auto">
    <h1 class="font-bold text-center mt-8 mb-3">장바구니</h1>
    <div class="flex justify-center gap-3 mb-5 text-2xl">
      <div class="cursor-pointer" v-if="productCount > 0">
        <a href="#goodsCart">상품 - <b>{{ productCount }}</b> 건</a>
      </div>
      <div v-if="productCount > 0 && contentCount > 0"> | </div>
      <div class="cursor-pointer" v-if="contentCount > 0">
        <a href="#contentsCart">콘텐츠 - <b>{{ contentCount }}</b> 건</a>
      </div>
    </div>
  </div>

  <main class="w-full cartBackground">

    <div class="w-full max-w-[1200px] mx-auto mb-[70px]">

      <!-- 상품 장바구니 -->
      <div id="goodsCart" class="max-lg:px-5 mt-5" v-if="productCount > 0">
        <h3 class="mb-2 pl-2">상품 장바구니</h3>

        <div class="cartDivisionBox" v-for="(warehouse, wareKey) in productCartInfo.warehouses">
          <div class="flex justify-between items-center">
            <div class="flex items-center">
              <label for="cartChkAll" class="w-fit check_btn font-bold text-2xl">
                <input type="checkbox" id="selectedAllProduct" @click="selectedAllProduct(wareKey, $event)"
                  :checked="warehouse.products.map(product => product.isSelected).every(Boolean)"
                />
                &nbsp;&nbsp;&nbsp;{{ warehouse.name ?? "출고지" }}
              </label>
              <span class="text-gray-600 text-sm ml-2" v-if="warehouse.deliveryFreeOption>0">
                ( {{ warehouse.deliveryFreeOption.toLocaleString('ko-kr') }}원 이상 무료 배송 )
              </span>
            </div>
            <button class="flex gap-1 items-center p-2 sec_btn" @click="deleteCartItem('product', warehouse.warehouseId )">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M11.8125 2.625H9.625V2.1875C9.625 1.8394 9.48672 1.50556 9.24058 1.25942C8.99444 1.01328 8.6606 0.875 8.3125 0.875H5.6875C5.3394 0.875 5.00556 1.01328 4.75942 1.25942C4.51328 1.50556 4.375 1.8394 4.375 2.1875V2.625H2.1875C2.07147 2.625 1.96019 2.67109 1.87814 2.75314C1.79609 2.83519 1.75 2.94647 1.75 3.0625C1.75 3.17853 1.79609 3.28981 1.87814 3.37186C1.96019 3.45391 2.07147 3.5 2.1875 3.5H2.625V11.375C2.625 11.6071 2.71719 11.8296 2.88128 11.9937C3.04538 12.1578 3.26794 12.25 3.5 12.25H10.5C10.7321 12.25 10.9546 12.1578 11.1187 11.9937C11.2828 11.8296 11.375 11.6071 11.375 11.375V3.5H11.8125C11.9285 3.5 12.0398 3.45391 12.1219 3.37186C12.2039 3.28981 12.25 3.17853 12.25 3.0625C12.25 2.94647 12.2039 2.83519 12.1219 2.75314C12.0398 2.67109 11.9285 2.625 11.8125 2.625ZM6.125 9.1875C6.125 9.30353 6.07891 9.41481 5.99686 9.49686C5.91481 9.57891 5.80353 9.625 5.6875 9.625C5.57147 9.625 5.46019 9.57891 5.37814 9.49686C5.29609 9.41481 5.25 9.30353 5.25 9.1875V5.6875C5.25 5.57147 5.29609 5.46019 5.37814 5.37814C5.46019 5.29609 5.57147 5.25 5.6875 5.25C5.80353 5.25 5.91481 5.29609 5.99686 5.37814C6.07891 5.46019 6.125 5.57147 6.125 5.6875V9.1875ZM8.75 9.1875C8.75 9.30353 8.70391 9.41481 8.62186 9.49686C8.53981 9.57891 8.42853 9.625 8.3125 9.625C8.19647 9.625 8.08519 9.57891 8.00314 9.49686C7.92109 9.41481 7.875 9.30353 7.875 9.1875V5.6875C7.875 5.57147 7.92109 5.46019 8.00314 5.37814C8.08519 5.29609 8.19647 5.25 8.3125 5.25C8.42853 5.25 8.53981 5.29609 8.62186 5.37814C8.70391 5.46019 8.75 5.57147 8.75 5.6875V9.1875ZM8.75 2.625H5.25V2.1875C5.25 2.07147 5.29609 1.96019 5.37814 1.87814C5.46019 1.79609 5.57147 1.75 5.6875 1.75H8.3125C8.42853 1.75 8.53981 1.79609 8.62186 1.87814C8.70391 1.96019 8.75 2.07147 8.75 2.1875V2.625Z" fill="#555961"/>
              </svg>
              <span class="text-sm">선택 삭제</span>
            </button>
          </div>

          <div class="cart_grid_container">

            <!-- 상품 목록 -->
            <div class="cart_grid_wrapper mt-2">
              <div class="cart_grid_header">
                <!-- 선택 체크/체크해제 저장되도록 수정 필요 -->
                <div>선택</div>
                <div></div>
                <div>상품정보</div>
                <div>정가</div>
                <div>옵션</div>
                <!-- <div>수량</div> -->
                <div>주문 금액</div>
              </div>

              <div class="cart_grid_body" v-for="product in warehouse.products" :key="product.itemId">
                <div class="px-2 mx-auto">
                  <label for="cartChk" class="w-fit check_btn">
                    <input type="checkbox" id="cartChk" v-model="product.isSelected"/>
                  </label>
                </div>
                <div class="w-20 h-14 flex items-center justify-center border border-neutral-200 rounded-xl overflow-hidden">
                  <router-link :to="'/mall/view/' + product.itemId" class="flex justify-center items-center w-full h-full">
                    <img v-if="product.thumbnailLink" :src="'https://eduwind-api.nodeblack.net/' + product.thumbnailLink" class="w-full h-full object-cover"/>
                    <img v-else src="@/assets/img/null_img.jpg"/>
                  </router-link>
                </div>
                <div>
                  <div class="text-left">
                    <!-- <p class="text-sky-500 max-lg:text-sm">2023 겨울성경학교 청소년부</p> -->
                    <router-link :to="'/mall/view/' + product.itemId"  class="text-xl max-lg:text-sm">{{product.title}}</router-link>
                  </div>
                </div>
                <div>
                  {{ product.regularPrice }}
                </div>
                <div>
                  <div class="justify-center">
                    <!-- 옵션 -->
                    <div v-if="product.cartOptionList.length > 0">
                      <ul>
                        <li v-for="option in product.cartOptionList" class="productOptionList">
                          <div>
                            <span v-for=" ( pathName, pathIndex ) in option.optionPathNames ">
                              <span> {{ pathName }} </span>
                              <span v-if="pathIndex < (option.optionPathNames.length-1)"> > </span>
                            </span>
                            <span v-if="option.optionMessage"> - {{ option.optionMessage }} </span>
                          </div>
                          <span class="px-2"> / </span>
                          <div> {{ option.quantity }}개 </div>
                          <div v-if="option.price>0"> ({{ option.price }}원) </div>
                        </li>
                      </ul>
                    </div>
                    <div v-else>
                      수량 : {{ product.productStock }}
                    </div>
                  </div>

                  <div class="flex justify-end">
                    <button
                      @click="openOptionModal(product.cartId)"
                      class="text-sm text-sky-500 underline cursor-pointer w-fit"
                    >
                      옵션 수정
                    </button>
                  </div>
                </div>

                <div class="product_price flex-col">
                  <div v-if="product.isFree === true">
                    무료
                  </div>
                  <div v-else>
                    {{product.purchasePrice.toLocaleString('ko-kr')}}원
                  </div>
                </div>

              </div>
            </div>


            <div class="p-5 bg-neutral-100">
              <div class="productPriceInfo">
                <div class="productPriceItem">
                  <div class="itemTitle"> 총 상품 금액 </div>
                  <div class="itemPrice">
                    <b>{{ warehousePirceInfo[wareKey].warehouseProductPrice.toLocaleString('ko-kr') }}</b>  원
                  </div>
                </div>

                <div class="productPriceItem">
                  <div class="itemTitle"> 할인 금액 </div>
                  <div class="itemPrice">
                    <b>{{ warehousePirceInfo[wareKey].warehouseDiscountAmount.toLocaleString('ko-kr') }}</b> 원
                  </div>
                </div>

                <div class="productPriceItem">
                  <div class="itemTitle"> 배송비 </div>
                  <div class="itemPrice">
                    <b>{{ warehousePirceInfo[wareKey].deliveryFee.toLocaleString('ko-kr') }}</b> 원
                  </div>
                </div>

                <div> | </div>

                <div class="w-[200px] flex justify-end items-center">
                  <div class="text-sm mr-3"> 결제금액 </div>
                  <div class="text-xl">
                    <b class="text-2xl text-red-500"> {{ warehousePirceInfo[wareKey].warehouseTotalPrice.toLocaleString('ko-kr') }} </b> 원
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      <!-- 콘텐츠 장바구니 -->
      <div id="contentsCart" class="max-lg:px-5 mt-10" v-if="contentCount > 0">
        <h3 class="mb-2 pl-2">콘텐츠 장바구니</h3>

        <div class="cartDivisionBox">
          <div class="cart_grid_container mb-12" v-if="contentUnavailableCartList.length != 0">
            <h4>
              <b class="text-rose-600">판매중지</b>되어 제외된 콘텐츠가 있습니다.
            </h4>
            <div class="cart_grid_wrapper mt-2">
              <div class="cart_grid_body" style="grid-template-columns: 5rem 2fr .25fr .5fr auto;"
                v-for="(item, idx) in contentUnavailableCartList" :key="item.cartId">
                <div class="w-20 h-14 flex items-center justify-center border border-neutral-200 rounded-xl overflow-hidden">
                  <router-link :to="'/mall/view/' + item.itemId" class="flex justify-center items-center w-full h-full">
                    <img v-if="item.thumbnailLink" :src="'https://eduwind-api.nodeblack.net/' + item.thumbnailLink" class="w-full h-full object-cover"/>
                    <img v-else src="@/assets/img/null_img.jpg"/>
                  </router-link>
                </div>
                <div>
                  <div class="text-left">
                    <!-- <p class="text-sky-500 max-lg:text-sm">2023 겨울성경학교 청소년부</p> -->
                    <router-link :to="'/mall/view/' + item.itemId"  class="text-xl max-lg:text-sm">{{item.title}}</router-link>
                  </div>
                </div>
                <div>
                  <div class="product_tags justify-center">
                    <span>{{item.itemKind}}</span>
                  </div>
                </div>
                <div class="product_price flex-col">
                  <div v-if="item.isFree === true">
                    무료
                  </div>
                  <div v-else>
                    {{(item.sellingPrice * item.quantity).toLocaleString('ko-kr')}}원
                  </div>
                </div>
                <div class="flex justify-center items-center">
                  <button class="flex gap-1 items-center p-2 sec_btn" @click="removeUnavailable(idx)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M11.8125 2.625H9.625V2.1875C9.625 1.8394 9.48672 1.50556 9.24058 1.25942C8.99444 1.01328 8.6606 0.875 8.3125 0.875H5.6875C5.3394 0.875 5.00556 1.01328 4.75942 1.25942C4.51328 1.50556 4.375 1.8394 4.375 2.1875V2.625H2.1875C2.07147 2.625 1.96019 2.67109 1.87814 2.75314C1.79609 2.83519 1.75 2.94647 1.75 3.0625C1.75 3.17853 1.79609 3.28981 1.87814 3.37186C1.96019 3.45391 2.07147 3.5 2.1875 3.5H2.625V11.375C2.625 11.6071 2.71719 11.8296 2.88128 11.9937C3.04538 12.1578 3.26794 12.25 3.5 12.25H10.5C10.7321 12.25 10.9546 12.1578 11.1187 11.9937C11.2828 11.8296 11.375 11.6071 11.375 11.375V3.5H11.8125C11.9285 3.5 12.0398 3.45391 12.1219 3.37186C12.2039 3.28981 12.25 3.17853 12.25 3.0625C12.25 2.94647 12.2039 2.83519 12.1219 2.75314C12.0398 2.67109 11.9285 2.625 11.8125 2.625ZM6.125 9.1875C6.125 9.30353 6.07891 9.41481 5.99686 9.49686C5.91481 9.57891 5.80353 9.625 5.6875 9.625C5.57147 9.625 5.46019 9.57891 5.37814 9.49686C5.29609 9.41481 5.25 9.30353 5.25 9.1875V5.6875C5.25 5.57147 5.29609 5.46019 5.37814 5.37814C5.46019 5.29609 5.57147 5.25 5.6875 5.25C5.80353 5.25 5.91481 5.29609 5.99686 5.37814C6.07891 5.46019 6.125 5.57147 6.125 5.6875V9.1875ZM8.75 9.1875C8.75 9.30353 8.70391 9.41481 8.62186 9.49686C8.53981 9.57891 8.42853 9.625 8.3125 9.625C8.19647 9.625 8.08519 9.57891 8.00314 9.49686C7.92109 9.41481 7.875 9.30353 7.875 9.1875V5.6875C7.875 5.57147 7.92109 5.46019 8.00314 5.37814C8.08519 5.29609 8.19647 5.25 8.3125 5.25C8.42853 5.25 8.53981 5.29609 8.62186 5.37814C8.70391 5.46019 8.75 5.57147 8.75 5.6875V9.1875ZM8.75 2.625H5.25V2.1875C5.25 2.07147 5.29609 1.96019 5.37814 1.87814C5.46019 1.79609 5.57147 1.75 5.6875 1.75H8.3125C8.42853 1.75 8.53981 1.79609 8.62186 1.87814C8.70391 1.96019 8.75 2.07147 8.75 2.1875V2.625Z" fill="#555961"/>
                    </svg>
                    <span class="text-sm">삭제</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-between items-center mt-4">
            <label for="cartChkAll" class="w-fit check_btn">
              <input type="checkbox" id="selectedAllContent" @click="selectedAllContent"
                :checked="contentCartInfo.contents.map(content => content.isSelected).every(Boolean)"
              />
              &nbsp;전체선택&nbsp;
            </label>
            <button class="flex gap-1 items-center p-2 sec_btn"  @click="deleteCartItem('content' )">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M11.8125 2.625H9.625V2.1875C9.625 1.8394 9.48672 1.50556 9.24058 1.25942C8.99444 1.01328 8.6606 0.875 8.3125 0.875H5.6875C5.3394 0.875 5.00556 1.01328 4.75942 1.25942C4.51328 1.50556 4.375 1.8394 4.375 2.1875V2.625H2.1875C2.07147 2.625 1.96019 2.67109 1.87814 2.75314C1.79609 2.83519 1.75 2.94647 1.75 3.0625C1.75 3.17853 1.79609 3.28981 1.87814 3.37186C1.96019 3.45391 2.07147 3.5 2.1875 3.5H2.625V11.375C2.625 11.6071 2.71719 11.8296 2.88128 11.9937C3.04538 12.1578 3.26794 12.25 3.5 12.25H10.5C10.7321 12.25 10.9546 12.1578 11.1187 11.9937C11.2828 11.8296 11.375 11.6071 11.375 11.375V3.5H11.8125C11.9285 3.5 12.0398 3.45391 12.1219 3.37186C12.2039 3.28981 12.25 3.17853 12.25 3.0625C12.25 2.94647 12.2039 2.83519 12.1219 2.75314C12.0398 2.67109 11.9285 2.625 11.8125 2.625ZM6.125 9.1875C6.125 9.30353 6.07891 9.41481 5.99686 9.49686C5.91481 9.57891 5.80353 9.625 5.6875 9.625C5.57147 9.625 5.46019 9.57891 5.37814 9.49686C5.29609 9.41481 5.25 9.30353 5.25 9.1875V5.6875C5.25 5.57147 5.29609 5.46019 5.37814 5.37814C5.46019 5.29609 5.57147 5.25 5.6875 5.25C5.80353 5.25 5.91481 5.29609 5.99686 5.37814C6.07891 5.46019 6.125 5.57147 6.125 5.6875V9.1875ZM8.75 9.1875C8.75 9.30353 8.70391 9.41481 8.62186 9.49686C8.53981 9.57891 8.42853 9.625 8.3125 9.625C8.19647 9.625 8.08519 9.57891 8.00314 9.49686C7.92109 9.41481 7.875 9.30353 7.875 9.1875V5.6875C7.875 5.57147 7.92109 5.46019 8.00314 5.37814C8.08519 5.29609 8.19647 5.25 8.3125 5.25C8.42853 5.25 8.53981 5.29609 8.62186 5.37814C8.70391 5.46019 8.75 5.57147 8.75 5.6875V9.1875ZM8.75 2.625H5.25V2.1875C5.25 2.07147 5.29609 1.96019 5.37814 1.87814C5.46019 1.79609 5.57147 1.75 5.6875 1.75H8.3125C8.42853 1.75 8.53981 1.79609 8.62186 1.87814C8.70391 1.96019 8.75 2.07147 8.75 2.1875V2.625Z" fill="#555961"/>
              </svg>
              <span class="text-sm">선택 삭제</span>
            </button>
          </div>

          <div class="cart_grid_container">
            <div class="cart_grid_wrapper mt-2">
              <div class="cart_grid_header">
                <!-- 선택 체크/체크해제 저장되도록 수정 필요 -->
                <div>선택</div>
                <div></div>
                <div>상품정보</div>
                <div>유형</div>
                <!-- <div>수량</div> -->
                <div>주문 금액</div>
              </div>
              <div class="cart_grid_body" v-for="item in contentAvailableCartList" :key="item.cartId">
                <div class="px-2 mx-auto">
                  <label for="cartChk" class="w-fit check_btn">
                    <input type="checkbox" id="cartChk" v-model="item.isSelected"/>
                  </label>
                </div>
                <div class="w-20 h-14 flex items-center justify-center border border-neutral-200 rounded-xl overflow-hidden">
                  <router-link :to="'/mall/view/' + item.itemId" class="flex justify-center items-center w-full h-full">
                    <img v-if="item.thumbnailLink" :src="'https://eduwind-api.nodeblack.net/' + item.thumbnailLink" class="w-full h-full object-cover"/>
                    <img v-else src="@/assets/img/null_img.jpg"/>
                  </router-link>
                </div>
                <div>
                  <div class="text-left">
                    <!-- <p class="text-sky-500 max-lg:text-sm">2023 겨울성경학교 청소년부</p> -->
                    <router-link :to="'/mall/view/' + item.itemId"  class="text-xl max-lg:text-sm">{{item.title}}</router-link>
                  </div>
                </div>
                <div>
                  <div class="product_tags justify-center">
                    <span>{{item.itemKind}}</span>
                  </div>
                </div>
                <!-- <div>
                  <div class="flex items-center justify-center">
                    <span  class="w-6"> {{item.quantity }} </span>
                    <button @click="increaseQuantity(item)" class="mr-1">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="white" stroke="#DEE0E3"/>
                        <path d="M9.00024 3.85718V14.1429" stroke="#1B1D21" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M3.85742 9L14.1431 9" stroke="#1B1D21" stroke-width="1.5" stroke-linecap="round"/>
                      </svg>
                    </button>
                    <button @click="decreaseQuantity(item)">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="white" stroke="#DEE0E3"/>
                        <path d="M3.85718 9H14.1429" stroke="#898C92" stroke-width="1.5" stroke-linecap="round"/>
                      </svg>
                    </button>
                  </div>
                </div> -->

                <div class="product_price flex-col">
                  <div v-if="item.isFree === true">
                    무료
                  </div>
                  <div v-else>
                    {{item.purchasePrice.toLocaleString('ko-kr')}}
                  </div>
                </div>

              </div>
            </div>
            <div class="p-5 bg-neutral-100">

              <div class="productPriceInfo">
                <div class="productPriceItem">
                  <div class="itemTitle"> 총 상품 금액 </div>
                  <div class="itemPrice">
                    <b>{{ contentPriceInfo.contentPurchasePrice.toLocaleString('ko-kr') }}</b>  원
                  </div>
                </div>

                <div class="productPriceItem">
                  <div class="itemTitle"> 할인 금액 </div>
                  <div class="itemPrice">
                    <b>{{ contentPriceInfo.contentDiscountAmount.toLocaleString('ko-kr') }}</b> 원
                  </div>
                </div>

                <div> | </div>

                <div class="w-[200px] flex justify-end items-center">
                  <div class="text-sm mr-3"> 결제금액 </div>
                  <div class="text-xl">
                    <b class="text-2xl text-red-500"> {{ contentPriceInfo.contentPurchasePrice.toLocaleString('ko-kr') }} </b> 원
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div v-if="productCount <= 0 && contentCount <= 0">
        <div class="flex justify-center items-center h-96">
          <p class="text-2xl">
            장바구니가 비어있습니다.
          </p>
        </div>
      </div>

      <!-- 주문 버튼 -->
      <!--
      <div class="flex gap-2 justify-end max-lg:justify-center mt-5" v-if="productCount > 0 || contentCount > 0">

      </div>
      -->


    </div>
  </main>

  <div class="fixed bottom-0 w-full bg-black py-5 text-white">
    <div class="w-full max-w-[1200px] mx-auto">
      <div class="flex justify-end items-center">

        <div class="flex text-xl items-baseline mr-5">
          <div class="mr-3" v-if="selectedProductCount > 0"> 상품 <b class="text-3xl">{{ selectedProductCount }}</b> 건 </div>
          <div class="mr-3" v-if="selectedContentCount > 0"> 콘텐츠 <b class="text-3xl">{{ selectedContentCount }}</b> 건 </div>
          <div> 주문금액 <b class="text-3xl">{{ paymentPrice.toLocaleString('ko-kr') }}</b> 원 </div>
        </div>

        <button type="button" @click="checkout(true)" class="sec_btn !border-black p-4 w-1/6 max-lg:w-1/2 mr-3">
          선택 주문하기
        </button>
        <button type="button" @click="checkout()" class="pri_btn pri_btn_bg p-4 w-1/6 max-lg:w-1/2">
          전체 주문하기
        </button>
      </div>
    </div>
  </div>

  <!-- 옵션 모달 창 -->
  <productOptionModal
    v-if = "modalVisible"
    @close = "modalVisible = false"
    @updateComplete = "updateCompleteCart"
    :cartIdx="modalData"
  ></productOptionModal>
</template>

<script setup>
import axios from 'axios';
import router from "@/router";
import {ref, onMounted, computed, watch} from 'vue';
import { editCart } from '@/assets/js/cart.js';

import productOptionModal from "@/components/productOptionModify.vue";    // 옵션 수정 모달

const cartList = ref([]);

const cartInfo = ref({});           // 장바구니 가격 정보
const productCartInfo = ref([]);    // 상품 장바구니
const productCount = ref(0);        // 상품 장바구니 수량
const warehousePirceInfo = ref([]); // 출고지별 금액 정보
const contentCartInfo = ref([]);    // 콘텐츠 장바구니
const contentCount = ref(0);        // 콘텐츠 장바구니 수량
const contentPriceInfo = ref({})    // 콘텐츠 금액 정보



// 판매중지 상품 필터
const contentAvailableCartList = ref([]);
const contentUnavailableCartList = ref([]);

const paymentPrice = ref(0);        // 상품 가격

// 장바구니 GET
const getCart = async () => {
  try {
    const url = "/new_api/customer/cart/v2/list";
    const { data, status } = await axios.get(url);
    if( status === 200 ){
      if( data.code === '200' ){
        // 출고지 갯수에 맞게 비용 저장되는 array 크기 지정해서 생성
        console.log('data.data: ', data.data);
        warehousePirceInfo.value = Array.from({ length: data.data.productInfo.warehouses.length }, () => ({}));

        // 상품 장바구니 체크
        cartInfo.value = {
          totalOrderRegularPrice: data.data.totalOrderRegularPrice,
          totalOrderDiscountAmount: data.data.totalOrderDiscountAmount,
          totalOrderPurchasePrice: data.data.totalOrderPurchasePrice,
          totalOrderDeliveryFee: data.data.totalOrderDeliveryFee,
          totalPaidAmount: data.data.totalPaidAmount
        };
        ({productInfo: productCartInfo.value, contentInfo: contentCartInfo.value} = data.data);   // 상품, 콘텐츠 장바구니
        productCount.value = productCartInfo.value.warehouses.flatMap(warehouse => warehouse.products).length;  // 상품 장바구니 수량
        contentCount.value = contentCartInfo.value.contents.length;     // 콘텐츠 장바구니 수량

        productCartInfo.value.warehouses.forEach(warehouse => {
          let productPrice = 0;
          warehouse.products.forEach(products => {
            products.cartOptionList.forEach(option => {
              productPrice += option.price * option.quantity;
              });
              products.sellingPrice = productPrice;
              });
              });
              
              
              console.log('productCartInfo: ', productCartInfo);

        contentAvailableCartList.value = contentCartInfo.value.contents.filter(item => item.itemStatus !== "STOP");
        contentUnavailableCartList.value = contentCartInfo.value.contents.filter(item => item.itemStatus === "STOP");

        // productPrice();
      }
      else {
        alert('장바구니를 불러오는데 실패했습니다.\n\n새로고침 후 다시 시도해 주세요.');
        document.location.reload();
      }
    }
    else {
      alert('장바구니를 불러오는데 실패했습니다.\n\n새로고침 후 다시 시도해 주세요.');
      document.location.reload();
    }
    /*cartList.value = res.data.data.cartList;
    console.log(cartList.value);*/
  } catch (err) {
    console.error(err);
  }
};

// 출고지 전체 상품 선택
const selectedAllProduct = (wareKey, $event) => {
  productCartInfo.value.warehouses[wareKey].products.forEach(product => {
    product.isSelected = $event.target.checked;
  });
}

const selectedAllContent = ($event) => {
  contentAvailableCartList.value.forEach(item => {
    item.isSelected = $event.target.checked;
  });
}

watch([productCartInfo, contentCartInfo], () => {
  paymentPriceCalc();
}, { deep: true });
// 상품 가격


const paymentPriceCalc = () => {
  let totalProductPrice = 0;
  productCartInfo.value.warehouses.forEach((warehouse, index) => {

    // 출고지별 선택된 상품의 총 상품 금액 계산
    const warehouseProductPrice = warehouse.products
    .filter((item) => item.isSelected)
    .reduce((total, item) => total + item.purchasePrice, 0);

    // 출고지별 선택된 상품의 할인 금액 계산
    const warehouseDiscountAmount = warehouse.products
    .filter((item) => item.isSelected)
    .reduce((total, item) => total + item.discountAmount, 0);
    
    const selectedProductCount = warehouse.products
    .filter((item) => item.isSelected).length
    
    // 출고지별 배송비 계산
    const deliveryFee = warehouseProductPrice >= warehouse.deliveryFreeOption || selectedProductCount == 0 ? 0 : warehouse.deliveryFee;  

    // 출고지별 선택된 상품의 결제금액 계산
    const warehouseTotalPrice = warehouseProductPrice + deliveryFee;

    warehousePirceInfo.value[index] = {
      warehouseProductPrice : warehouseProductPrice,
      warehouseDiscountAmount : warehouseDiscountAmount,
      deliveryFee : deliveryFee,
      warehouseTotalPrice : warehouseTotalPrice
    }

    totalProductPrice += warehouseTotalPrice;
  });


  // 콘텐츠 총 상품 금액
  const contentPurchasePrice = contentCartInfo.value.contents
  .filter((item) => item.isSelected)
  .reduce((total, item) => total + item.purchasePrice, 0);
  
  // 콘텐츠 할인 금액 
  const contentDiscountAmount = contentCartInfo.value.contents
  .filter((item) => item.isSelected)
  .reduce((total, item) => total + item.discountAmount, 0);

  contentPriceInfo.value = {
    contentPurchasePrice: contentPurchasePrice,
    contentDiscountAmount: contentDiscountAmount
  }

  paymentPrice.value = totalProductPrice + contentPurchasePrice;
};
// 콘텐츠 가격
const contentPrice = computed(() => {
  return contentAvailableCartList.value
      .filter((item) => item.isSelected)
      .reduce((total, item) => total + item.purchasePrice, 0);
});

// 선택 상품 갯수
const selectedProductCount = computed(() => {
  if( productCartInfo.value.warehouses !== undefined ){
    return productCartInfo.value.warehouses.flatMap(warehouse => warehouse.products).filter((item) => item.isSelected).length;
  }
});
// 선택 콘텐츠 개수
const selectedContentCount = computed(() => {
  return contentAvailableCartList.value.filter((item) => item.isSelected).length;
});
// 선택 상품 + 선택 콘텐츠 가격
const selectedTotalPrice = computed(() => {
  return productPrice.value + contentPrice.value;
});


const modalData = ref(null);
const modalVisible = ref(false);
// 옵션 변경 열기
const openOptionModal = (item) => {
  console.log(item);
  modalData.value = item;
  modalVisible.value = true;
};
// 장바구니 변경 성공시
const updateCompleteCart = async () => {
  await getCart();
  modalVisible.value = false;
}


onMounted(async () => {
  await getCart();
  paymentPriceCalc();
});



// 총 주문 금액 계산
const getTotalPrice = computed(() => {
  return cartList.value
    .filter((item) => item.isSelected)
    .reduce((total, item) => total + item.sellingPrice * item.quantity, 0);
});

// 장바구니 전체 선택
const isChkAll = ref(true);

const toggleChkAll = () => {
  cartList.value.forEach(item => item.isSelected = !isChkAll.value);
};

/*
const updateChkAll = () => {
  isChkAll.value = cartList.value.every(item => item.isSelected);
};

// Watch for changes in cartList and update isChkAll accordingly그럭ㄹㄺㄺㄺㄺㄹ
watch(cartList, updateChkAll, { deep: true });
*/

// 장바구니 삭제
const removeCart = () => {
  const selectedItems = cartList.value.filter((item) => item.isSelected);
  selectedItems.forEach((item, index, array) => {
    editCart(
      item.itemId,
      item.quantity,
      true,
      'DELETE'
    ).then((res) => {
      console.log(res)
      if (res.data.code === '200') {
        if (index === array.length - 1) {
          alert('삭제되었습니다.');
          window.location.reload();
        }
      }
    });
  });
};

const deleteCartItem = async (type, warehouseId = null) => {
  let cartItemList = [];
  if (type === 'product') {
    productCartInfo.value.warehouses.forEach(warehouse => {
      if (warehouse.warehouseId === warehouseId) {
        cartItemList = cartItemList.concat(warehouse.products.filter((item) => item.isSelected).map((item) => item.cartId));
      }
    });
  } else if (type === 'content') {
    cartItemList = cartItemList.concat(contentAvailableCartList.value.filter((item) => item.isSelected).map((item) => item.cartId));
  }

  if (cartItemList.length > 0) {
    const url = "/new_api/customer/cart/v2/delete-cart-all";
    const {data, status} = await axios.delete(url, { data: cartItemList });
    if (status === 200) {
      if (data.code === '200') {
        await getCart();
      }
    }
  }
  else {
    alert("삭제할 항목을 선택해 주세요.");
    return false;
  }
};

const removeUnavailable = (idx) => {
  const item = unavailableCartList.value[idx];
  
  editCart(
      item.itemId,
      item.quantity,
      true,
      'DELETE'
    ).then((res) => {
      console.log(res)
      if (res.data.code === '200') {
        alert('삭제되었습니다.');
        window.location.reload();
      }
    });
};

// 수량 증감
const increaseQuantity = (item) => {
  item.quantity++;

  editCart(
    item.itemId,
    item.quantity,
    true,
    'CREATE'
  );
};

const decreaseQuantity = (item) => {
  if (item.quantity > 1) {
    item.quantity--;
    
    editCart(
      item.itemId,
      item.quantity,
      true,
      'CREATE'
    );
  } else {
    alert('수량은 1 이상이어야 합니다.')
  }
};

//주문하기
const checkout = (selected = false) => {

  let cartItemList = [];
  // 전제 상품, 콘텐츠의 isSelected 값을 true 로 변경
  if( selected === false ){
    productCartInfo.value.warehouses.forEach(warehouse => {
      warehouse.products.forEach(product => {
        product.isSelected = true;
      });
    });
    contentAvailableCartList.value.forEach(item => {
      item.isSelected = true;
    });
  }
  cartItemList.push(
    productCartInfo.value.warehouses.flatMap(warehouse => warehouse.products).filter((item) => item.isSelected).map((item) => item.cartId),
    cartItemList.concat(contentAvailableCartList.value.filter((item) => item.isSelected).map((item) => item.cartId))
  );


  const cartList = cartItemList.flat();
  console.log('cartList.length: ', cartList.length);
  if( cartList.length > 0 ){
    localStorage.setItem('cartIdSet', JSON.stringify({ cartList }));
    router.push({ name: 'order' });
  }
  else {
    alert('주문할 상품이나 콘텐츠를 선택해 주세요.');
    return false;
  }
};

</script>

<style scoped>
.cartBackground {
  background-color: #f9f9f9;
  @apply flex flex-col w-full;
}

.cartDivisionBox {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  padding: 1rem;
  border-radius: 16px;
  margin-bottom: 2rem;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.09);
}


.productOptionList {
  display: flex;
  align-items: baseline;
  padding: 0.5rem 0;
}

.productPriceInfo {
  @apply flex items-center justify-end gap-5;
}

.productPriceInfo .productPriceItem {
  @apply w-[100px];
}

.productPriceInfo .productPriceItem > .itemTitle {
  @apply text-sm;
}

.productPriceInfo .productPriceItem > .itemPrice {
  @apply text-xl;
}
</style>