<template>
    <div class="text-sm my-4">
        <h2 class="font-medium text-center mb-10">이용약관</h2>

        <div class="p-4">
            <h4 class="mt-5 font-medium text-center border-b-2 border-black pb-4">총 칙</h4>
            <h6 class="font-medium mt-5">제1조 [목적]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                본 약관은 총회교육개발원(이하 "회사")가 이용 고객(이하 '회원')간에 회사가 제공하는 웹서비스(이하 "서비스")의 가입조건 및 이용과 관련하여 회사와 회원 사이에 권리ㆍ의무 및 책임사항 등을 규정함을 목적으로 합니다.
            </pre>

            <h6 class="font-medium mt-5">제2조 [정의]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                1."이용자"라 함은 "회사"의 웹사이트에 접속하여 본 약관에 따라 "회사"가 제공하는 "콘텐츠" 및 제반서비스를 이용하는 "회원" 및 "비회원"을 말합니다.
                2."회원"이라 함은 회사의 웹사이트에 접속하여 본 약관에 동의 함으로써 회사와 이용계약을 체결하고 아이디(ID)를 부여받은 자로서 회사가 제공하는 정보와 서비스를 지속적으로 이용할 수 있는 자를 말합니다.
                3."아이디(ID)"라 함은 회원의 식별 및 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 문자 또는 숫자의 조합을 말합니다.
                4."비밀번호(PASSWORD)"라 함은 서비스 이용 시, 아이디와 일치되는 회원임을 확인하고, 회원 개인정보 보호를 위하여, 회원 자신이 정한 문자 또는 숫자의 조합을 말합니다.
                5."전자우편(Email)"이라 함은 인터넷을 통한 우편 혹은 전기적 매체를 이용한 우편을 말합니다.
                6."운영자(관리자)"라 함은 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 사람 또는 회사를 말합니다.
                7."회원의 게시물"이라 함은 회사의 서비스가 제공되는 웹사이트에 회원이 올린 글, 이미지, 각종 파일 및 링크, 각종 덧글 등의 정보를 의미합니다.
                ② 전항 각호에 해당하는 정의 이외의, 기타 용어의 정의에 대하여는 거래 관행 및 관계 법령에 따릅니다.
                
            </pre>

            <h6 class="font-medium mt-5">제3조 [회사 신원정보 등의 제공]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                회사는 회사의 상호, 대표자 성명, 주소, 대표전화, 팩스전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 웹사이트 초기화면에 게시합니다.
            </pre>

            <h6 class="font-medium mt-5">제4조 [약관의 효력 및 변경]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 이 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을 발생합니다. ② 이 약관은 회사의 웹사이트(www.총회교육.com 이하 '총회교육개발원 서비스')에 온라인으로 공시됨으로써 효력이 발생되고, 합리적인 사유가 발생할 경우 회사는 관계법령에 위배되지 않는 범위에서 이 약관을 변경할 수 있습니다.
                ③ 개정약관도 총회교육개발원 서비스에 온라인으로 공시됨으로써 효력이 발생됩니다. 회사는 약관을 변경할 경우 지체 없이 이를 공시하여야 하고, 회원의 권리나 의무 등에 관한 중요사항을 개정할 경우에는 사전에 공시하여야 합니다.
                ④ 이 약관에 동의하는 것은 정기적으로 총회교육개발원 서비스를 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관을 알지 못해 발생하는 이용자의 피해는 회사에서 책임지지 않습니다.
                ⑤ 약관에 동의하지 않는 회원은 탈퇴(해지)를 요청할 수 있으며, 약관의 효력이 발생된 날로부터 7일 이후까지 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우는 약관에 동의한 것으로 간주됩니다.
            </pre>

            <h6 class="font-medium mt-5">제5조 [약관과 기타 준칙]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 이 약관은 회사가 제공하는 개별서비스에 관한 이용안내(이하 서비스별 안내라 함)와 함께 적용됩니다.
                ② 본 약관에 명시되지 않은 사항에 대해서는 "약관의 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률", "전자상거래 등에서의 소비자보호에 관한 법률" 및 기타 관련법령의 규정에 따릅니다.                
            </pre>

            <h6 class="font-medium mt-5">제6조 [회원가입]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회원으로 가입하여 회사 서비스의 이용을 희망하는 자는 약관의 내용을 숙지한 후 동의함을 표시하고, 회사가 제시하는 소정의 회원가입 양식에 관련사항을 기재하여 회원가입을 신청하여야 합니다.
                ② 회사는 전항에 따라 회원이 온라인 회원가입 신청양식에 기재하는 모든 회원정보를 실제 데이터인 것으로 간주합니다.
                ③ 실명이나 실제 정보를 입력하지 않은 회원은 법적인 보호를 받을 수 없으며, 본 약관의 관련 규정에 따라 서비스 사용에 제한을 받을 수 있습니다.
                ④ 회사는 본조 제1항에 따른 이용자의 신청에 대하여 회원가입을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있으며, 승낙 이후라도 취소할 수 있습니다.
                1. 이용자의 귀책사유로 인하여 승인이 불가능한 경우
                2. 실명을 사용하지 않은 경우
                3. 타인의 명의 또는 개인정보를 도용하는 경우
                4. 허위의 정보를 제공하는 경우
                5. 중복된 아이디를 사용하는 경우
                6. 회사가 제시하는 회원가입 신청양식에 관련 내용을 기재하지 않은 경우
                7. 이전에 회사 이용약관 또는 관계법령을 위반하여 회원자격이 상실되었던 경우
                8. 기타 본 약관 및 관계법령을 위반하는 경우
                9. 회원가입 신청양식에 기재되어 회사에 제공되는 개인정보(ID, 비밀번호, 주소 등)가 선량한 풍속 기타 사회질서에 위배되거나 타인을 모욕하는 경우                              
            </pre>

            <h6 class="font-medium mt-5">제7조 [서비스의 제공 및 변경]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회사는 회원에게 아래와 같은 서비스를 제공합니다.
                1. 회원을 위한 커뮤니티 서비스, 쇼핑몰, 온라인교육 컨텐츠 서비스, 맞춤 서비스
                2. 기타 회사가 자체 개발하거나 다른 회사와의 협력계약을 통해 회원들에게 제공할 일체의 서비스
                ② 회사는 변경될 서비스의 내용 및 제공일자를 홈페이지에 공지하고 서비스를 변경하여 제공할 수 있습니다.                              
            </pre>

            <h6 class="font-medium mt-5">제8조 [개인정보의 보호 및 사용]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회사는 고객의 개인정보를 보호하고 존중합니다.
                ② 회사는 이용신청 시 고객이 제공하는 정보, 각종 이벤트 참가를 위하여 고객이 제공하는 정보, 기타 서비스 이용 과정에서 수집되는 정보 등을 통해 고객에 관한 정보를 수집하며, 수집된 고객의 정보는 본 이용계약의 이행과 본 이용계약상의 서비스 제공을 위한 목적으로 사용됩니다.
                ③ 회사는 서비스 제공과 관련하여 지득한 고객의 신상정보를 본인의 승낙 없이 제3자에게 누설할 수 없습니다. 다만, 다음의 각 호의 경우에는 그러하지 아니합니다.
                1. 서비스의 제공에 따른 요금 정산을 위하여 필요한 경우
                2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우
                3. 관계 법령에 의하여 수사상 목적으로 정해진 절차와 방법에 따라 관계기관의 요구가 있는 경우
                4. 다른 법률에 특별한 규정이 있는 경우
                5. 정보통신윤리위원회가 관계법령에 의하여 요청 경우
                6. 회원에게 보다 전문적이고 다양한 서비스를 제공하기 위한 경우
                ④ 회사는 회원에게 제3항의 전문적이고 다양한 서비스를 제공하기 위해 자체적으로 TM을 하거나, 외부전문 사업자와 제휴하여 공동으로 서비스(TM, SMS, 메일 등 광고성 정보 발송)를 제공할 수 있습니다.
                ⑤ 전문적인 지식, 경험과 상담이 요구되는 서비스의 경우 회원의 동의를 받아 외부전문사업자와 공동으로 서비스를 제공하며 이때 해당전문사업자의 상호와 공유목적, 공유 정보를 명시합니다.
                ⑥ 외부전문사업자와의 공동서비스를 제공함에 있어 회원의 성명, 연락처 등 공동서비스에 필요한 최소한의 정보가 공유될 수 있고, 공유되는 정보는 아래 각호와 같이 엄격히 보호 관리됩니다.
                1. 공유되는 정보는 해당 전문서비스 이외 어떠한 다른 용도로도 사용되지 않습니다.
                2. 서비스 제공 과정에서 해당 전문서비스에 대해 회원이 동의의사를 밝히지 않거나 사전에 거부의사를 밝힐 경우 최소한의 정보도 전문 사업자와 공유하지 않습니다.
                ⑦ 제4항의 개인정보 이용에 관한 회원의 동의는 본 약관에 동의하는 것으로 갈음할 수 있습니다.
                ⑧ 회원은 언제든 원할 경우 회사에 제공한 개인정보의 수집과 이용에 관한 동의를 철회할 수 있고, 위 동의의 철회는 해지 신청을 하는 것으로 이루어 집니다.
                ⑨ 개인정보보호와 관련된 보다 자세한 사항은 개인정보취급방침을 참조하시기 바랍니다.                                            
            </pre>

            <h6 class="font-medium mt-5">제9조 [회원정보의 변경]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회원은 회사 웹사이트 "정보수정" 페이지에서 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다.
                ② 회원이 전항의 변경사항을 수정하지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.                                           
            </pre>

            <h6 class="font-medium mt-5">제10조 [회원의 ‘아이디’ 및 ‘비밀번호’의 관리에 대한 의무]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회원은 아이디와 비밀번호에 대한 관리책임이 있으며, 이를 타인에게 공개하여 제3자가 이용하도록 하여서는 안됩니다.
                ② 회원은 자신의 아이디 및 비밀번호가 유출되어 제3자에 의해 사용되고 있음을 인지한 경우, 즉시 회사에 알려야 합니다.
                ③ 회사는 전항의 경우 회원의 개인정보보호 및 기타 서비스 부정이용행위 방지 등을 위하여 회원에게 비밀번호의 변경 등 필요한 조치를 요구할 수 있으며, 회원은 회사의 요구가 있는 즉시 회사의 요청에 성실히 응해야 합니다.
                ④ 회사는 회원이 본 조 제2항 및 제3항에 따른 의무를 성실히 이행하지 않아 발생한 불이익에 대하여 책임지지 않습니다.                                                        
            </pre>

            <h6 class="font-medium mt-5">제11조 [회원탈퇴 및 자격 상실 등]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회원은 언제든지 서비스 이용을 중단하고 탈퇴할 수 있습니다.
                ② 회사는 회원이 본 약관 또는 관계법령을 위반하는 경우, 서비스 이용을 제한하거나 회원자격을 상실 시킬 수 있습니다.
                ③ 본 조항에 따른 해지 시 회원은 이용계약 종료(회원 탈퇴) 이전에 포인트, 할인 쿠폰은 소멸됩니다.                                                           
            </pre>

            <h6 class="font-medium mt-5">제12조 [회사의 의무]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회사는 특별한 사정이 없는 한 회원이 희망한 서비스 이용 개시일에 서비스를 제공하고 계속적이고 안정적으로 서비스를 제공해야 합니다.
                ② 회사는 개인정보 보호를 위한 보안시스템을 구축하고 개인정보취급방침을 공시하고 준수합니다.
                ③ 회사는 이용고객으로부터 제기되는 의견이나 불만이 정당하다고 인정될 경우 적절한 조치를 취해야 하고, 즉시 처리하기 곤란한 경우에는 이용자에게 그 사유와 처리일정을 통보해야 합니다.                                                           
            </pre>

            <h6 class="font-medium mt-5">제13조 [회원의 의무]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회사의 명시적 동의가 없는 한 회원의 이용권한은 회원 개인에 한정되며, 타인에게 양도, 증여하거나 이를 담보로 제공할 수 없습니다.
                ② 회원은 주소, 연락처, 전자우편주소 등 이용계약사항의 변경이 있을 경우 해당 절차를 거쳐 즉시 이를 회사에 알려야 합니다.
                ③ 회원은 본 약관과 관계법령 등 제반 규정 및 회사의 공지사항을 준수하여야 하며, 회사의 업무를 방해하거나 회사의 명예를 손상시키는 행위를 해서는 안됩니다.
                ④ 회원은 회사의 사전 승낙 없이 서비스를 이용하여 영업활동을 할 수 없으며, 회사는 그 영업활동에 대한 책임을 부담하지 않습니다. 또한 회원은 위와 같은 영업활동으로 회사에 손해를 입힐 경우 손해배상책임을 부담합니다.
                ⑤ 회원은 회사의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여, 담보제공 할 수 없습니다.
                ⑥ 회원은 회사 및 제 3자의 지적재산권을 침해해서는 안됩니다.
                ⑦ 회사는 회원이 다음 각 호의 행위를 할 경우 당해 회원의 서비스 이용제한 등 적절한 제한조치를 할 수 있습니다.
                1. 회원이 이용신청 또는 변경 시, 허위사실을 기재하거나, 자신 또는 다른 회원의 ID 및 개인정보를 이용ㆍ공유하는 경우
                2. 회원이 기타 불법적인 방법으로 회원가입을 한 사실이 확인된 경우
                3. 회원이 회사 또는 제3자의 권리나 저작권을 침해하는 경우
                4. 회원이 회사에서 제공하는 서비스를 이용하여 상품 또는 용역을 판매하는 영업활동 등의 상행위를 하는 경우
                5. 회원이 다른 이용자의 서비스 이용을 방해하거나, 회사의 운영진, 직원 또는 관계자를 사칭하는 경우
                6. 공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
                7. 회사의 서비스 운영을 고의로 방해하거나 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송하는 경우
                8. 모욕적이거나 개인신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
                9. 회사의 승인을 받지 않고 다른 사용자의 개인정보를 수집 또는 저장하는 행위
                10. 회사 및 타인의 명예를 훼손하거나 모욕하는 경우11. 관계법령 및 기타 본 약관에서 규정한 사항을 위반한 경우                                                     
            </pre>

            <h6 class="font-medium mt-5">제14조 [부정이용 금지 및 차단]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 부정이용 식별방법 및 차단
                1. 회사는 회원의 서비스 이용 중에 수집ㆍ확인된 IP정보, 맥어드레스 등의 자료를 토대로, 서버를 통하여 부정이용 여부를 분류ㆍ확인합니다.
                2. 회사는 회원이 서비스 이용 중에 복제프로그램을 실행시키거나 동일한 ID로 동시 접속을 하는 경우, 서비스 이용 접속을 강제로 종료시킵니다.
                ② 회사는 다음 각호에 해당하는 경우를 부정 이용행위로 봅니다.
                1. 동일한 ID로 2대 이상의 PC에서 동시접속이 발생하는 경우
                2. 동일한 ID로 다수의 PC 또는 IP에서 서비스를 이용하는 경우
                3. 자신의 ID 및 강좌 등의 서비스를 타인이 이용하도록 하는 경우
                4. 자신의 ID 및 강좌 등의 서비스를 타인에게 판매, 대여, 양도하는 행위 및 이를 광고하는 행위
                5. 서비스 이용 중, 복제프로그램을 실행하는 경우 또는 녹화를 하거나 시도하는 경우
                ③ 회사는 전항에 따른 부정 이용자가 발견되었을 경우, 회사가 운영하는 홈페이지에 기재된 바에 따라 부정 이용자에 대해 재제 조치하며, 회원은 해당 조치를 이유로 서비스 이용 기간의 연장을 요구할 수 없습니다. 회원이 회사의 본 조치에 이의가 있는 경우, 해당 사실에 대하여 소명할 수 있으며 그에 따라 이용에 관한 별도의 조치를 받을 수 있습니다.
                ④ 회사가 식별하여 부정사용자로 최종 확인된 회원이 회원의 귀책사유가 없음을 입증하지 못하는 경우, 회원은 이로 인해 발생한 모든 민, 형사상 책임을 부담합니다.                                                                        
            </pre>

            <h6 class="font-medium mt-5">제15조 [교재의 구입 및 배송 등]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 교재 구입은 회사 웹사이트에서만 신청 가능합니다.
                ② 교재의 판매는 온라인 강좌를 수강 신청한 회원만을 대상으로 하고, 교재만 별도로 판매하지는 아니합니다.
                ③ 교재는 회원의 구매결제가 완료된 시점으로부터 7일 이내에 배송하는 것을 원칙으로 하고 있으며, 천재지변, 교재의 재고 부족 등으로 지연될 수 있습니다.
                ④ 파손, 파본, 배송오류 등의 문제가 발생할 경우 회사는 추가 배송비용 없이 재 발송하거나 환불해 드립니다. 이때, 회원은 반드시 배송받은 교재를 회사에 반송해야 합니다.
                ⑤ 전항에 해당하지 않는 교환, 환불의 경우 반환에 필요한 비용은 회원이 부담합니다.
                ⑥ 회원이 교재를 구매하는 경우, 회사는 개인정보취급방침에 따라 회원의 최소한의 개인정보를 제3자(배송업체 등)에게 제공할 수 있습니다.                                                                        
            </pre>

            <h6 class="font-medium mt-5">제16조 [게시물의 관리]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회사는 다음 각 호에 해당하는 게시물이나 자료를 사전통지 없이 삭제, 이동하거나 등록 거부할 수 있습니다.
                1. 다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우
                2. 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우
                3. 불법복제 또는 해킹을 조장하는 내용인 경우
                4. 영리를 목적으로 하는 광고일 경우
                5. 범죄와 결부된다고 객관적으로 인정되는 내용일 경우
                6. 다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우
                7. 회사에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 경우
                8. 기타 관계법령에 위배된다고 판단되는 경우                                                                         
            </pre>

            <h6 class="font-medium mt-5">제17조 [게시물에 대한 저작권]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 서비스 화면 내에 게시한 게시물의 저작권은 게시한 회원에게 귀속됩니다. 만일 위 게시물이 제3자의 지적재산권을 침해할 경우의 모든 책임은 게시물을 게시한 회원이 부담하며, 이로 인해 회사가 타인으로부터 손해배상청구 등을 받게 될 경우 회원은 회사의 면책을 위해 노력해야 하고, 회사가 면책되지 않을 경우 회사의 모든 손해를 배상하여야 합니다.
                ② 회사는 게시자의 동의 없이 게시물을 상업적으로 이용할 수 없습니다. 다만 서비스 내에 이를 게시할 권리를 갖습니다.
                ③ 회원은 서비스를 이용하여 취득한 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 영리목적으로 이용하거나 제3자에게 이용하게 할 수 없습니다.
                ④ 회사는 회원이 게시하거나 등록하는 서비스 내의 내용물, 게시물이 제15조 각 호에 해당된다고 판단할 경우 이를 삭제하거나 등록 거부할 수 있습니다.                                                                                
            </pre>

            <h6 class="font-medium mt-5">제18조 [정보의 제공]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회사는 서비스를 운영함에 있어 각종 정보를 서비스 화면에 게재하거나 전자우편이나 서신우편 등의 방법으로 회원에게 제공할 수 있습니다.
                ② 회사는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원의 동의 하에 추가적인 개인 정보를 요구할 수 있습니다.                                                                                       
            </pre>

            <h6 class="font-medium mt-5">제19조 [광고게재 및 광고주와의 거래]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회사가 회원에게 제공하는 서비스 투자기반의 일부는 광고게재를 통한 수익으로부터 나옵니다. 회사는 서비스 운영과 관련하여 홈페이지, 서비스 화면, 전자우편 등에 광고를 게재할 수 있고, 회원은 서비스 이용 시 노출되는 광고게재에 동의합니다.
                ② 회원이 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는 등의 방법으로 교신 또는 거래를 하는 것은 전적으로 회원과 광고주 간의 문제이므로 이로 인해 발생하는 손실과 손해에 대해 회사는 어떠한 책임도 지지 않습니다.                                                                                                    
            </pre>

            <h6 class="font-medium mt-5">제20조 [계약 변경 및 해지]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 홈페이지 회원탈퇴 메뉴를 이용해 가입 해지를 해야 합니다.                                                                                    
            </pre>

            <h6 class="font-medium mt-5">제21조 [서비스 이용제한]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회사는 회원이 서비스 이용내용에 있어서 본 약관 제 12조 내용을 위반하거나, 다음 각 호에 해당하는 경우 서비스 이용을 제한할 수 있습니다.
                1. 미풍양속을 저해하는 비속한 ID 및 별명 사용
                2. 타 이용자에게 심한 모욕을 주거나, 서비스 이용을 방해한 경우
                3. 정보통신 윤리위원회 등 관련 공공기관의 시정 요구가 있는 경우
                4. 3개월 이상 서비스를 이용한 적이 없는 경우
                5. 불법 홈페이지인 경우
                - 상용소프트웨어나 크랙 파일을 올린 경우
                - 정보통신윤리 위원회의 심의 세칙 제 7조에 어긋나는 음란물을 게재한 경우
                - 반국가적 행위의 수행을 목적으로 하는 내용을 포함한 경우
                - 저작권이 있는 글을 무단 복제하거나 mp3를 불법 유통한 경우
                6. 기타 정상적인 서비스운영에 방해가 될 경우
                7. 상기 이용제한 규정에 따라 서비스를 이용하는 회원에게 서비스 이용에 대하여 별도 공지 없이 서비스 이용의 일시 정지, 초기화, 이용계약 해지 등을 불량이용자 처리규정에 따라 취할 수 있습니다.                                                                                                       
            </pre>

            <h6 class="font-medium mt-5">제22조 [관계법령상 환불정책]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회사의 본 약관상 환불규정 등은 "약관의 규제에 관한 법률", "전자상거래 등에서의 소비자보호에 관한 법률" 등을 반영하였으며 위 관계법령에 위배되는 부분이 있는 경우 위 관계법령의 내용에 따릅니다.
                ② 회사는 회원에게 대금 등을 환불할 때는 원칙적으로 회원이 대금을 결제한 때와 동일한 수단으로 환급합니다. 다만 동일한 수단으로 환급이 불가능할 경우에는 그 사유와 다른 환급수단을 사전에 고지합니다.                                                                                                     
            </pre>

            <h6 class="font-medium mt-5">제23조 [회사의 환불규정]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                1. 구매자는 작품 수령 후 7일 이내에 전자상거래등에서의소비자보호에관한법률 등 관련법령에 의거하여 판매자에게 환불 내지 교환을 신청할 수 있습니다.
                2. 반품에 소요되는 비용은 환불 내지 교환에 대한 귀책사유가 있는 자에게 귀속됩니다.
                
                ※ 환불 규정 적용 범위는 과정마다 다를 수가 있습니다.
                ※ 환불시, 카드수수료는 본인부담.                                                                                                       
            </pre>

            <h6 class="font-medium mt-5">제24조 [손해배상]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회사가 제공하는 서비스로 인하여 회원에게 손해가 발생하는 경우 회사는 그 손해가 회사의 고의 또는 중과실에 의한 경우에 한하여 통상손해의 범위에서 손해배상책임을 부담합니다.                                                                                       
            </pre>

            <h6 class="font-medium mt-5">제25조 [면책조항]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회사는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.
                ② 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
                ③ 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.
                ④ 회사는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.
                ⑤ 회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.
                ⑥ 회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.
                ⑦ 회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한 회사는 회원이 서비스를 이용하며 타 회원으로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.
                ⑧ 회사는 회원이 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않습니다.
                ⑨ 회사는 이용자 상호간 및 이용자와 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.                                                                                                    
            </pre>

            <h6 class="font-medium mt-5">제26조 [재판권 및 준거법]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 이 약관에 명시되지 않은 사항은 전기통신사업법 등 관계법령과 상관습에 따릅니다.
                회사의 정액 서비스 회원 및 기타 유료 서비스 이용 회원의 경우 회사가 별도로 정한 약관 및 정책에 따릅니다.
                ② 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.                                                                                                      
            </pre>

            <h6 class="font-medium mt-5">제24조 [손해배상]</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                ① 회사가 제공하는 서비스로 인하여 회원에게 손해가 발생하는 경우 회사는 그 손해가 회사의 고의 또는 중과실에 의한 경우에 한하여 통상손해의 범위에서 손해배상책임을 부담합니다.                                                                                       
            </pre>


            <h4 class="mt-5 font-medium text-center border-b-2 border-black pb-4">부 칙</h4>

            <h6 class="font-medium mt-5">제1조 적용일자</h6>
            <pre class="font-light my-2 whitespace-pre-line" style="font-family: inherit">
                이 약관은 2023년 4월 1일부터 적용됩니다.                                                                                    
            </pre>
        </div>
    </div>
</template>

<script>
</script>