<template>
    <div class="w-full h-full flex">
        <div class="flex-1 flex items-center justify-center w-full h-full ">
            <div class="h-[350px]">
                <div class="border-b border-black pl-4 w-fit m-auto">
                    <input type="text" placeholder="바코드 입력" v-model="barcode" class="outline-0 tracking-wide text-xl" @keyup.enter="search" ref="cursor"/>
                    <button type="button" class="p-4" @click="search">▼</button>
                </div>
                <div v-if="result" class="text-center mt-4">
                    <div v-if="err_code==0">
                        <h3 class="text-sky-500">신청 확인되었습니다</h3>

                        <!-- 프린트 영역 시작 -->
                        <div class="text-center m-6 p-6 border border-black w-[500px]" id="printArea">
                            <h3>교환권:</h3>
                            <h3>{{conf_detail.local }} {{ conf_detail.place }}</h3>
                            <h3 class="mb-2">{{ conf_detail.dept }} {{ reg_detail.headcount }}명</h3>
                            <h3>{{ reg_detail.church }} ({{ reg_detail.name }})</h3>
                            <h3><span v-for="mInfo in reg_detail.registerMemberList" :key="mInfo.idx" v-show="mInfo.is_cancel!='Y'" style="padding-right:10px;">{{ mInfo.name }}</span></h3>
                            <p style="height:30px;">&nbsp;</p>
                        </div>
                        <!-- 프린트 영역 끝 -->
                        <button class="bg-black text-white px-6 py-2" @click="print"><h2>프린트</h2></button>
                    </div>
                    <h4 class="text-rose-500" v-else>{{err_msg}}</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import printJS from 'print-js'
export default {
    name: "BarcodeVerifyPage",

    data() {
        return {
            barcode : "",
            result: false,
            err_msg : "",
            err_code : "",
            conf_detail : {},
            reg_detail : {}
        }
    },

    mounted() {
        this.$refs.cursor.focus();
    },
    
    methods: {
        search(){
            if(this.barcode == ""){
                alert("바코드를 입력해주세요");
            }else{
                axios.get('/api/register/conference/barcode/' + this.barcode).then(response => {
                    if(response.data.err_code==0){
                        this.is_reg = true;
                        this.err_code = response.data.err_code;
                        this.conf_detail = response.data.conferenceDetail;
                        this.reg_detail = response.data.registerConference;
                    }else{
                        this.err_msg = response.data.err_msg;
                        this.err_code = response.data.err_code;
                        //alert(response.data.err_msg);
                    }
                    this.showMsg();
                    
                }).catch(error => {
                    console.error(error)
                })
            }
        },
        showMsg() {
            this.result = true;
        },
        print(){
            
            axios.post('/api/register/conference/barcode/' + this.barcode).then(response => {
                if(response.data.err_code==0){
                    printJS({ printable: 'printArea', type: 'html', font_size : '20pt'});
                    setTimeout(() => window.location.reload(), 2000);
                }else{
                    alert(response.data.err_msg);
                }
            }).catch(error => {
                console.error(error)
            })
        }
    }
}

</script>