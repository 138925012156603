<template>
  <!-- class="lg:flex flex-1" -->
  <div>
    <TNavComponent/>
    <toTopComponent/>
    <introducePage />
    <FooterComponent/>
  </div>
</template>

<script>
import TNavComponent from "@/components/TNavComponent.vue"
import toTopComponent from "@/components/toTopComponent.vue"
import introducePage from "@/pages/introducePage.vue"
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "introView",
  components: {introducePage, TNavComponent, toTopComponent, FooterComponent}
}
</script>
