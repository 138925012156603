<template>
  <main class="w-full max-w-[1200px] mx-auto mb-20 flex-1" id="order_page">
    <h1 class="font-bold text-center my-8">주문서</h1>

    <div class="max-lg:px-5 mt-10">
      <h3 class="font-bold">구매할 콘텐츠</h3>
      <div class="cart_grid_container order">
        <div class="cart_grid_wrapper mt-2">
          <div class="cart_grid_header">
            <div></div>
            <div>상품정보</div>
            <!-- <div>수량</div> -->
            <div>주문 금액</div>
          </div>
          <div v-for="item in checkoutList" :key="item.itemId" class="cart_grid_body">
            <div class="w-20 h-14 flex items-center justify-center border border-neutral-200 rounded-xl overflow-hidden">
              <img v-if="item.thumbnailLink" :src="'https://eduwind-api.nodeblack.net/' + item.thumbnailLink" class="w-full h-full object-cover"/>
              <img v-else src="@/assets/img/null_img.jpg"/>
            </div>
            <div>
              <div class="text-left">
                <p class="text-xl max-lg:text-sm">{{item.title}}</p>
              </div>
            </div>
            <!-- <div>{{item.quantity}}</div> -->
            <div class="product_price flex-col">
              <div v-if="item.isFree === true">
                무료
              </div>
              <div v-else>
                {{(item.sellingPrice * item.quantity).toLocaleString('ko-kr')}}원
              </div>
            </div>
          </div>
        </div>
        <div class="p-5 bg-neutral-100">
          <div class="flex items-center justify-end">
            총 주문금액
            <div v-if="checkoutInfo.totalPaidAmount > 0" class="flex items-center gap-1">
              <h4 class="ml-2 font-semibold text-red-500" v-if="checkoutInfo.totalPaidAmount">
                {{checkoutInfo.totalPaidAmount.toLocaleString('ko-kr')}}
              </h4>
              <b>원</b>
            </div>
            <div v-else>
              <h4 class="ml-2">무료</h4>
            </div>
          </div>
        </div>
      </div>

      <h3 class="font-bold mt-20 mb-4">주문자 정보</h3>
      <div class="bg-neutral-100 p-6">
        <div class="flex items-center mb-2">
          <b class="w-20">이름</b>
          <input type="text" class="regi_inputs" v-model="checkoutInfo.orderName"/>
        </div>

        <div class="flex items-center mb-2">
          <b class="w-20">연락처</b>
          <input type="text" class="regi_inputs" v-model="checkoutInfo.orderPhone"/>
        </div>

        <div class="flex items-center">
          <b class="w-20">이메일</b>
          <input type="text" class="regi_inputs" v-model="checkoutInfo.orderEmail"/>
        </div>
      </div>
      
      <!-- 실제 상품일 경우 -->
      <!-- <h3 class="font-bold mt-20 mb-4">배송지 정보</h3>
      <div class="bg-neutral-100 p-6">
        <div class="flex items-center mb-2">
          <b class="w-20">이름</b>
          <input type="text" class="regi_inputs"/>
        </div>

        <div class="flex items-center mb-2">
          <b class="w-20">연락처</b>
          <input type="text" class="regi_inputs"/>
        </div>

        <div class="flex items-baseline">
          <b class="w-20">주소</b>
          <div>
            <input type="text" class="regi_inputs w-[35rem]"/>
            <input type="text" placeholder="상세주소를 입력해주세요"  class="regi_inputs block my-2 w-[35rem]"/>
            <label class="check_btn gap-1">
              <input type="checkbox"/>
              기본 배송지로 설정
            </label>
          </div>
        </div>

      </div> -->

      <div v-if="checkoutInfo.totalPaidAmount > 0">
        <h3 class="font-bold mt-20 mb-4">결제 정보</h3>
        <div class="bg-neutral-100 p-6">
          <div class="flex gap-4 py-3">
            <b>주문금액</b>
            <p v-if="checkoutInfo.totalPaidAmount">{{checkoutInfo.totalPaidAmount.toLocaleString('ko-kr')}}원</p>
          </div>
          <div class="flex gap-4 py-3">
            <b>결제방법</b>
            <select v-model="gopaymethod">
              <option value="Card">신용카드</option>
              <option value="DirectBank">실시간계좌이체</option>
              <option value="VBank">가상계좌</option>
            </select>
          </div>
        </div>
      </div>


      
      <div class="flex gap-2 justify-end max-lg:justify-center mt-5">
        <!-- /***************/ -->
        <!-- confirm 모달로 변경 ; 주문을 취소하시겠습니까? 작성된 내용은 저장되지 않습니다. -->
        <!-- /***************/ -->
        <router-link to="/mypage/cart" class="sec_btn !border-black p-4 w-1/6 max-lg:w-1/2">
          취소
        </router-link>
        
        <button @click="pcPaySubmit" v-if="checkoutList.length > 0" class="max-md:hidden pri_btn pri_btn_bg p-4 w-1/6 max-lg:w-1/2">
          <p v-if="checkoutInfo.totalPaidAmount > 0">결제하기</p>
          <p v-else>주문하기</p>
        </button>
  
        <!-- mobile -->
        <button @click="mPaySubmit" v-if="checkoutList.length > 0" class="md:hidden pri_btn pri_btn_bg p-4 w-1/6 max-lg:w-1/2">
          <p v-if="checkoutInfo.totalPaidAmount > 0">결제하기</p>
          <p v-else>주문하기</p>
        </button>
      </div>
    </div>
  </main>

  <!-- pc -->
  <div v-if="checkoutInfo">
    <form method="post" action="https://nodeblack.net/api/pay/mall/pc" target="PayForm" name="pcPayForm">
      <input id="buyername" name="buyername" type="hidden" v-model="checkoutInfo.orderName"/>
      <input id="buyertel" name="buyertel" type="hidden" v-model="checkoutInfo.orderPhone"/>
      <input id="buyeremail" name="buyeremail" type="hidden" v-model="checkoutInfo.orderEmail"/>
      <input id="buyerAddress" name="buyerAddress" type="hidden" value=""/>
      <input id="buyerDetailedAddress" name="buyerDetailedAddress" type="hidden" value=""/>
      <input id="buyerZipCode" name="buyerZipCode" type="hidden" value=""/>
      <input id="orderItemJson" name="orderItemJson" type="hidden" :value="JSON.stringify(orderItemJson)"/>
      <input id="gopaymethod" name="gopaymethod" type="hidden" v-model="gopaymethod"/>
      <!-- token 은 submit 할 때 localstorage auth 값 받아서 보내기 -->
      <input id="token" name="token" type="hidden" v-model="token"/>
    </form>
  
    <!-- mobile -->
    <form method="post" action="https://nodeblack.net/api/pay/mall/mobile" id="mPayForm" name="mPayForm">
      <input id="buyername" name="buyername" type="hidden" v-model="checkoutInfo.orderName"/>
      <input id="buyertel" name="buyertel" type="hidden" v-model="checkoutInfo.orderPhone"/>
      <input id="buyeremail" name="buyeremail" type="hidden" v-model="checkoutInfo.orderEmail"/>
      <input id="buyerAddress" name="buyerAddress" type="hidden" value=""/>
      <input id="buyerDetailedAddress" name="buyerDetailedAddress" type="hidden" value=""/>
      <input id="buyerZipCode" name="buyerZipCode" type="hidden" value=""/>
      <input id="orderItemJson" name="orderItemJson" type="hidden" :value="JSON.stringify(orderItemJson)"/>
      <input id="P_INI_PAYMENT" name="P_INI_PAYMENT" type="hidden" v-model="gopaymethod"/>
      <!-- token 은 submit 할 때 localstorage auth 값 받아서 보내기 -->
      <input id="token" name="token" type="hidden" v-model="token"/>
    </form>
  </div>

  <iframe
    id="PayForm"
    name="PayForm"
    src="about:blank"
    style="display:none; width:100vw; height: 100vh;position: fixed; top: 0; left: 0; z-index: 9999;"
  />

<!--
src="about:blank"
style="display:none; width:100vw; height: 100vh;position: fixed; top: 0; left: 0; z-index: 9999;"
-->

<!--  <span @click="closeIFrame"> 호출 </span>-->

</template>

<script setup>
import axios from 'axios';
import {onMounted, ref} from 'vue';
import router from "@/router";

// import { useStore } from 'vuex';
// const store = useStore();


const checkoutList = ref([]);
const checkoutInfo = ref([]);

let orderItemJson = [];
const gopaymethod = ref("Card");
const token = ref("");


const payForm = ref(null);


const closeIFrame = () => {
  alert('사용자 요청에 의해 결제가 취소 되었습니다.');
  // document.location.reload();
  router.go(0);
}

const freeContent = (data) => {
  try {
    if (data.moid) {
      const moid = data.moid;
      router.push(`/mypage/order_done/${moid}`);
    }
  } catch (error) {
    console.error(error);
  }
}

// 메세지 감지 - iframe으로부터의 메시지 수신
window.addEventListener('message', (event) => {
  // console.log('message', event.data);
  if( event.data && typeof event.data === 'object' ){
    if( event.data.mod === 'close' || event.data.mod === 'free' || event.data.mod === 'exception' ){
      console.log(event.data.mod );
      // string 데이터 JSON으로 변환
      const eventData = JSON.parse(event.data);

      if( eventData.mod === 'close' ){ // 이니시스 닫힘
        closeIFrame();
      } else if (eventData.mod === 'free') { // 무료결제
        freeContent(eventData);
      } else if (eventData.mod === 'exception') { // 실패
        alert(eventData.returnMsg);
        document.location.reload();
      }
    }
  }
});

// 주문 목록
onMounted(async () => {
  try {
    const { cartList } = JSON.parse(localStorage.getItem('cartIdSet')) || {cartIdSet: []};
    console.log(cartList);
    if( cartList.length > 0 ){
      const { data, status } = await axios.post('/new_api/customer/order/v2/form', {cartIdSet: cartList});
      console.log(data);
      console.log(status);

      /*checkoutList.value = res.data.data.itemList;
      checkoutInfo.value = res.data.data;

      orderItemJson = checkoutList.value.map(item => ({itemId: item.itemId, quantity: item.quantity, optionId: item.optionId}));*/
    }
    else {
      alert('주문할 상품이 없습니다.');
      router.push('/mypage/cart');
    }

  } catch (err) {
    console.error(err);
  }


});


// 결제 요청 - pc
const pcPaySubmit = async () => {
  try {
    const localStorageToken = localStorage.getItem('authorization');

    if (localStorageToken) {
      token.value = localStorageToken;

      //token 값을 가져온 이후 실행되도록 처리
      await new Promise((resolve) => {
        document.querySelector('#PayForm').style.display = 'block';
        // window.open('', 'fPayPopForm', 'width=900,height=700');
        setTimeout(resolve, 500);
      });

      document.pcPayForm.submit();
    } else {
      console.error("토큰 값이 일치하지 않습니다.");
      alert("잘못된 요청입니다.");
      window.location.reload();
    }
  } catch (error) {
    console.error(error);
  }
};

// 결제 요청 - mobile
const mPaySubmit = async () => {
  try {
    const localStorageToken = localStorage.getItem('authorization');

    if (localStorageToken) {
      token.value = localStorageToken;

      //token 값을 가져온 이후 실행되도록 처리
      await new Promise((resolve) => {
        document.querySelector('#PayForm').style.display = 'block';
        // window.open('', 'fPayPopForm', 'width=900,height=700');
        setTimeout(resolve, 500);
      });

      console.log(document.mPayForm);
      document.querySelector('#mPayForm').submit();
      // document.mPayForm.submit();
    } else {
      console.error("토큰 값이 일치하지 않습니다.");
      alert("잘못된 요청입니다.");
      window.location.reload();
    }
  } catch (error) {
    console.error(error);
  }
};


</script>