<template>

  <div wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden opacity-75 flex flex-col items-center justify-center">
    <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    <h2 class="text-center  text-xl font-semibold">로그인 중...</h2>
    <p class="w-1/3 text-center "> 잠시만 기다려 주세요 </p>
  </div>

</template>

<!-- 참고 URL : https://velog.io/@yeoonnii/%EB%84%A4%EC%9D%B4%EB%B2%84-API-%EB%A1%9C%EA%B7%B8%EC%9D%B8 -->

<script>
import { onMounted } from '@vue/runtime-core';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import router from "@/router";

export default {
    name: "naverLoginCheckComponent",
    setup () {
      const route = useRoute();

      const state = ({
        naverClientId : '4Fohm70rxjaHecEZLAiH',
        clientSecret : 'thJKZeP1No',
        callbackUrl : 'https://eduwind.node-www.com/naverLoginCheck',
        code : route.query.code,
        states : route.query.state, // csrf 공격을 방지하기 위해 애플리케이션에서 생성한 상태 토큰값으로 URL 인코딩을 적용한 값을 사용
        access_token : route.query.access_token, // 발급받은 access_token 저장을 위한 변수
        refresh_token : route.query.refresh_token, // 발급받은 refresh_token 저장을 위한 변수
        userid    :'',
        userpw    :'',
        role      : 'CUSTOMER',
        birth     :'', // DB에 넣을 생일
        birthday  :'', // 가공할 생일 정보
        birthyear :'', // 가공할 연도 정보
        nickname  :'',
        phone     :'',
        apiimageurl : ''
      })

      const join_data  = ({
        uid: "",
        upw: "",
        name: "",
        phone: "",
        email: "",
        is_agree: "Y",
        sns_type: "apple",
        sns_id: "",
      })

      // callback url
      const callback = async() => {

        console.log(route.query);
        if(route.query.resultcode == "00"){
          join_data.sns_id = route.query.id;
          /*join_data.uid = route.query.email;
          join_data.email = route.query.email;
          join_data.name = route.query.name;

          if(route.query.mobile){
            var mobile = route.query.mobile.split('-');
            join_data.phone = mobile.join('');
          }*/
          console.log(join_data);

          await naverUseridCheck();

        }else{
          alert(route.query.message);
          document.location.href = '/';
        }
      }

      // 회원가입시 아이디(이메일) 중복여부 확인
      const naverUseridCheck = async() => {
        // const url = `/ROOT/api/member/idcheck.json?userid=${state.userid}`;
        const url = `/api/check-sns?sns_id=${join_data.sns_id}&sns_type=apple&phone=`;
        const headers = {"Content-Type":"application/json"};
        const { data } = await axios.get(url,{headers});
        console.log(data);
        console.log('이메일중복확인 data=>' ,data);

        /**
         * 1. sns_id, sns_type - 일치 -> 로그인 처리
         * 2. sns_id, sns_type - 불일치 -> phone 중복 체크(2-1, 2-2)
         * 2-1. phone 중복 체크 - 일치 -> 해당 가입 정보 전달
         * 2-2. phone 중복 체크 - 불일치 -> 회원가입 페이지로 이동
         */

        if( data.err_code === 0 ){
          const successCode = data.contents.successCode;
          if( successCode === '000' ){
            // 로그인 처리
            await appleUserLogin(); // 로그인 진행
          }
          else if( successCode === '001' ){
            // 가입 기록 있음
            const joinType = data.contents.joinType;// 배열
            // 일반, 카카오
            if( joinType.includes('normal') && joinType.includes('kakao') ){
              alert('\'카카오(간편가입)\'와 \'일반회원가입\'으로 가입된 계정 정보가 있습니다.\n\n\'일반회원가입\' 계정정보가 기억나지 않으신다면 핸드폰 번호로\n\'아이디/비밀번호 찾기\'를 이용 해 주세요.');
            }
            // 일반, 네이버
            else if( joinType.includes('normal') && joinType.includes('naver') ){
              alert('\'네이버(간편가입)\'와 \'일반회원가입\'으로 가입된 계정 정보가 있습니다.\n\n\'일반회원가입\' 계정정보가 기억나지 않으신다면 핸드폰 번호로\n\'아이디/비밀번호 찾기\'를 이용 해 주세요.');
            }
            // 일반, 네이버
            else if( joinType.includes('normal') && joinType.includes('apple') ){
              alert('\'애플(간편가입)\'와 \'일반회원가입\'으로 가입된 계정 정보가 있습니다.\n\n\'일반회원가입\' 계정정보가 기억나지 않으신다면 핸드폰 번호로\n\'아이디/비밀번호 찾기\'를 이용 해 주세요.');
            }
            // 일반
            else if( joinType.includes('normal') ){
              alert('\'일반회원가입\'으로 가입된 계정 정보가 있습니다.\n\n계정정보가 기억나지 않으신다면 핸드폰 번호로\n\'아이디/비밀번호 찾기\'를 이용 해 주세요.');
            }
            // 카카오
            else if( joinType.includes('kakao') ){
              alert('\'카카오(간편가입)\'로 가입된 계정 정보가 있습니다.');
            }
            // 네이버
            else if( joinType.includes('naver') ){
              alert('\'네이버(간편가입)\'로 가입된 계정 정보가 있습니다.');
            }
            // 네이버
            else if( joinType.includes('apple') ){
              alert('\'애플(간편가입)\'로 가입된 계정 정보가 있습니다.');
            }

            router.push({path: '/'});
            return false;
          }
          else if( successCode === '002' ){
            // 회원 가입 페이지로 이동
            localStorage.setItem('sns_id', join_data.sns_id);
            localStorage.setItem('sns_type', join_data.sns_type);
            document.location.href = '/signup';
          }
          else {
            // 오류 다시 시도
          }
        }
        else {
          // 오류 다시 시도
        }

        /*
        if(data.contents === true){ //DB에 일치하는 아이디가 존재하는 경우
          console.log('로그인');
          await naverUserLogin() // 로그인 진행
        } else { //DB에 일치하는 아이디가 존재하지 않는 경우
          console.log('회원가입');
          // await naverUserJoin(); // 회원가입

          localStorage.setItem('uid', join_data.uid);
          localStorage.setItem('email', join_data.email);
          localStorage.setItem('name', join_data.name);
          localStorage.setItem('phone', join_data.phone);
          localStorage.setItem('sns_id', join_data.sns_id);
          localStorage.setItem('sns_type', join_data.sns_type);
          document.location.href = '/signup';
        }
        */
      }

      // 회원가입
      const naverUserJoin = async() => {
        // 변수에 값 넣기
        const url = "/api/member";
        const headers = {"Content-Type":"application/json"};

        console.log(join_data);
        const { data } = await axios.post(url, join_data, {headers});
        if (data.err_code) {
          alert(data.err_msg)
          return
        }
        else {
          alert('회원 가입이 완료되었습니다.');
          await naverUserLogin();
        }
      }

      // 로그인처리 + 토큰발행
      const appleUserLogin = async() => {
        const url = `/api/account/login`;
        const headers = { "Content-Type": "application/json" };
        const body = { sns_id: join_data.sns_id, sns_type: join_data.sns_type };
        // const { data } = await axios.post(url, body, { headers });
        const { data } = await axios.post(url, body, { headers });
        console.log(data);
        if( data.err_code === 0 ){
          localStorage.setItem('authorization', data.contents.body.accessToken);
          // return false;
          document.location.href = '/';
          // router.go({path: '/'})
        }
        else {
          alert(data.err_msg);
          document.location.href = '/';
          // router.go({path: '/'})
        }
      }

      onMounted(async () => {
        await callback();
      })
      return {state, callback}
    }
}

</script>

<style scoped>
.loader {
	border-top-color: #3498db;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

</style>