<template>
  <div id="register" class="flex-1 mt-10 pt-10 max-lg:px-5">
    <div class="eduRegi_wrapper max-w-[1000px] lg:px-5 mx-auto">
<!--          <img src="@/assets/img/conf_detailDesc.jpg" class="mb-5" v-if="state.conferences.conferenceDetail.conference.idx == 1"/>
          <img src="@/assets/img/hana_conf_detailDesc.jpg" class="mb-5" v-else-if="state.conferences.conferenceDetail.conference.idx == 2"/>-->
<!--      <section>
        <div :class="viewDetails.value ? 'max-h-auto overflow-auto' : 'max-h-[300px] overflow-hidden max-lg:h-[20vh]'" class="lg:p-5">

          <img src="@/assets/img/2023_conference.png" class="mb-5" v-if="state.conferences.conferenceDetail.conference.idx == 3 || state.conferences.conferenceDetail.conference.idx == 4 || state.conferences.conferenceDetail.conference.idx == 5"/>
          <img src="@/assets/img/2023_winter.png" class="mb-5" v-else-if="state.conferences.conferenceDetail.conference.idx == 6"/>
        </div>
        <div class="detail_view text-center border-b-2 py-4 border-black">
          <button @click="toggleView" class="sec_blk_btn lg:px-5 px-2 py-2" v-if=!viewDetails.value>내용보기</button>
          <button @click="toggleView" class="sec_blk_btn lg:px-5 px-2 py-2" v-else>내용접기</button>
        </div>
      </section>-->

      <section class="eduRegi_header">
        <!--
        <a href="/" class="mr-10">
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25.51" viewBox="0 0 35 25.51"> <polygon points="35 13.83 4.12 13.83 13.51 23.22 14.28 23.98 12.75 25.51 1.08 13.83 0 12.75 1.08 11.68 12.75 0 14.28 1.52 13.51 2.28 4.12 11.68 35 11.68 35 13.83" style="fill: #212121;"/> </svg>
        </a>
        -->
        <div class="flex font-medium">
          <div class="flex items-center">
            <!-- 컨퍼런스 제목 -->
            <h4>{{ state.conferences.conferenceDetail.conference.title }} <b>{{ state.conferences.conferenceDetail.dept }}</b></h4>
            <span class="bar"></span>
            <!-- 지역, 장소, 날짜 -->
            <h4>{{ state.conferences.conferenceDetail.local }} {{ state.conferences.conferenceDetail.place }} ({{ formattedDate }})</h4>
          </div>
        </div>
      </section>

      <section class="eduRegi_conts">
        <div class="flex items-center">
          <label class="inline-block w-1/5">신청자 이름</label>
          <input type="text" class="regi_inputs w-4/5" v-model="state.conferences.member.name" disabled />
        </div>

        <div class="flex">
          <label class="inline-block w-1/5">연락처</label>
          <div>
            <div class="flex gap-2 mont font-medium">
<!--                <input type="text" :value="state.conferences.member.phone.slice(0, 3)" maxlength="3" class="w-24 regi_inputs" />
                <input type="text" :value="state.conferences.member.phone.slice(3, 7)" maxlength="4" class="w-24 regi_inputs" />
                <input type="text" :value="state.conferences.member.phone.slice(7, 11)" maxlength="4" class="w-24 regi_inputs" />-->

                <input type="text" v-model="state.conferences.member.phone1" maxlength="3" class="w-24 regi_inputs" :disabled="state.appleJoinCheck===false" />
                <input type="text" v-model="state.conferences.member.phone2" maxlength="4" class="w-24 regi_inputs" :disabled="state.appleJoinCheck===false" />
                <input type="text" v-model="state.conferences.member.phone3" maxlength="4" class="w-24 regi_inputs" :disabled="state.appleJoinCheck===false" />
            </div>
            <p class="text-red-500 text-sm my-2">
<!--              위 연락처로 중요 공지 문자가 전송되오니 정확히 기재바랍니다.<br>
              이름, 연락처 수정은 <router-link to="/profile" class="underline">마이페이지</router-link>에서 가능합니다.-->
              위 연락처로 중요 공지문자가 전송되오니 정확히 기재바랍니다.<br>
              입력한 연락처는 개인연락처와 동기화됩니다. <br>
              만약 본인연락처가 아니신경우 <router-link to="/profile" class="underline">마이페이지</router-link>에서 본인의 연락처로 업데이트를 부탁드립니다. <br>
            </p>
          </div>
        </div>

        <div class="flex items-center">
          <label class="inline-block w-1/5">이메일 </label>
          <div class="flex items-center gap-2">
              <input type="text" class="w-72 regi_inputs" v-model="state.conferences.member.email" disabled />
          </div>
        </div>

        <!--
        <div class="flex items-center">
          <label class="inline-block w-1/5">부서 선택</label>
          <div class="flex gap-2">
            <select name="dept" class="w-36 regi_inputs" v-model="formData.registerConference.dept">
              <option value="" hidden>선택하세요</option>
              <option value="영유아부">영유아부</option>
              <option value="유치부">유치부</option>
              <option value="유년부">유년부</option>
              <option value="초등부">초등부</option>
              <option value="청소년부">청소년부</option>
              <option value="">없음</option>
            </select>
          </div>
        </div>
        -->
        
        <div class="flex items-center">
          <label class="inline-block w-1/5">교회명</label>
          <input type="text" class="regi_inputs w-4/5" v-model="formData.registerConference.church" />
        </div>

        <div class="flex items-center" v-if="state.conferences.conferenceDetail.conference.isAddress==='Y'">
          <label class="inline-block w-1/5">주소</label>
          <div>
            <div class="mb-2">
              <input type="text" class="regi_inputs w-1/5" placeholder="우편번호" maxlength="5" v-model="formData.zipCode" readonly @focus="searchAddress" />
              <button type="button" class="sec_filled-blk_btn lg:px-5 px-2 py-2 ml-3" @click="searchAddress"> 주소</button>
            </div>
            <div class="flex">
              <input type="text" class="regi_inputs w-3/5" placeholder="기본주소" v-model="formData.address" readonly @focus="searchAddress" />
              <input type="text" class="regi_inputs w-3/5" placeholder="상세주소" v-model="formData.detailedAddress" />
            </div>
          </div>


        </div>
      </section>

      <div class="border-t border-neutral-300 border-dashed w-full"></div>

      <section class="eduRegi_conts">

        <div class="flex items-center">
          <label class="inline-block w-1/5">등록비</label>
          <input type="text" class="w-32 regi_inputs" v-model="state.conferences.conferenceDetail.price" disabled/>
        </div>

        <div class="flex items-center">
          <label class="inline-block w-1/5"><span>등록비 합계</span></label>
          <input type="text" class="w-32 regi_inputs" v-model="formData.registerConference.total_price" disabled/>
        </div>

        <div class="flex items-center">
          <label class="inline-block w-1/5">참석자 정보 <span></span></label>
          <div class="max-h-[750px] overflow-y-auto">
            <div class="flex items-center lg:gap-2 mb-2" v-for="(item, index) in formData.registerMemberList" :key="index">
              <span class="w-6">{{ index + 1 }}</span>
              <input type="text" class="w-32 regi_inputs" placeholder="이름" v-model="item.name"/>
              <input type="text" class="w-32 regi_inputs" placeholder="교회명" v-model="item.church"/>

              <div v-if="index == 0 && formData.registerMemberList.length == 1">
                <button type="button" @click="addItem" class="sec_filled-blk_btn lg:px-5 px-2 py-2 ml-3">추가</button>
              </div>

              <div v-else-if="!index == 0 && index == formData.registerMemberList.length - 1">
                <button type="button" @click="removeItem(index)" class="sec_blk_btn lg:px-5 px-2 py-2 ml-3">삭제</button>
                <button type="button" @click="addItem" class="sec_filled-blk_btn lg:px-5 px-2 py-2 lg:ml-3">추가</button>
              </div>
              <div v-else>
                <button type="button" @click="removeItem(index)" class="sec_blk_btn lg:px-5 px-2 py-2 ml-3">삭제</button>
              </div>
            </div>
            <div>
              ※ <b>신청자</b>는 <b>참석자</b>에 <b>포함 되지 않습니다.</b><br />
                <b>신청자 참석시 참석자 정보에 입력(추가) 해 주세요.</b>
            </div>
          </div>

        </div>

        <!--
        <div class="flex items-center">
          <label class="inline-block w-1/5">추가 참석자</label>
          <div>
            <div>
              <button type="button" @click="addItem" class="sec_filled-blk_btn px-5 py-2 mb-2">추가</button>
            </div>
            <div class="max-h-[500px] overflow-y-auto">
              <div class="flex items-center mb-2" v-for="(item, index) in formData.registerMemberList" :key="index">
                <div class="flex items-center gap-2">
                  <span class="w-6">{{ index + 1 }}</span>
                  <input type="text" class="w-32 regi_inputs" placeholder="이름" v-model="item.name"/>
                  <input type="text" class="w-32 regi_inputs" placeholder="교회명" v-model="item.church"/>
                  <select name="dept" class="w-32 regi_inputs" v-model="item.dept">
                    <option value="" hidden>부서 선택</option>
                    <option value="영유아부">영유아부</option>
                    <option value="유치부">유치부</option>
                    <option value="유년부">유년부</option>
                    <option value="초등부">초등부</option>
                    <option value="청소년부">청소년부</option>
                    <option value="">없음</option>
                  </select>
                </div>
                <button type="button" @click="removeItem(index)" class="sec_blk_btn px-5 py-2 ml-3">삭제</button>
              </div>
            </div>
          </div>
        </div>
        -->

        <div class="eduRegi_policy">
          <pre>
            [개인정보의 수집에 대한 사항]
            대한예수교장로회총회 총회교육개발원은 이용자의 개인정보를 중요시하며, 『개인정보보호법』 제15조 및 제 22조에 따라 다음과 같은 내용의 개인정보 수집 및 이용을 하고자 합니다.  총회교육개발원은 이 「정보통신망법」을 비롯하여 개인정보와 관련된 법령 상의 개인정보보호규정 및 방송통신위원회가 제정한 "개인정보보호지침"을 준수하고 있습니다.
            
            <b>▶︎ 개인정보의 수집·이용 목적 : 컨퍼런스 신청모집 및 안내</b>
            <b>▶︎ 수집하는 개인정보의 항목 : 성함, 연락처, 이메일, 교회명</b>
            <b>▶︎ 개인정보의 보유·이용 기간 : 모집 종료 후 1년</b>
            
            [동의여부]
            개인정보의 수집 및 이용에 대한 동의를 거부할 수 있으며, 이 경우 신청이 제한됩니다.
          </pre>


        </div>

        <div class="flex">
          <span class="check_btn check_blk_btn">
            <input id="policy" type="checkbox" name="policy" v-model="state.agreeCheck"/>
            <label for="policy">약관 동의</label>
          </span>
        </div>
      </section>

      <section class="w-1/2 mx-auto my-10 pb-20 text-center !border-0">
        <a @click="handleSubmit" class="sec_rose_btn block p-4 cursor-pointer">신청하기</a>
      </section>
    </div>
  </div>

  <div id="addressFrame" style="display: none;">
    <img src="//t1.daumcdn.net/zipCode/resource/images/close.png" id="btnFoldWrap" style="cursor:pointer;position:absolute;right:0px;top:-1px;z-index:1" @click="foldAddress()" alt="접기 버튼">
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { reactive, computed, onMounted, watchEffect } from "vue"
import axios from "axios"
import store from "@/store"



export default {
  name: "EduRegisterPage",


  setup () {
    const route = useRoute()
    const router = useRouter()

    const state = reactive({
      conferences: {
        member: {
          phone: "",
          phone1: "",
          phone2: "",
          phone3: "",
        },
        conferenceDetail: {
          price: 0,
          local: "",
          place: "",
          limit_personnel: 0,
          registered_personnel: 0,

          conference: {
            idx: 0,
            title: "",
            rsdatetime: "",
            redatetime: ""
          }

        }
      },

      agreeCheck: false,
      appleJoinCheck: false
    })

    const viewDetails = reactive({
      value: false,
    })

    const toggleView = () => {
      viewDetails.value = !viewDetails.value
    }

    const formData = reactive ({
      zipCode: "",
      address: "",
      detailedAddress: "",
      registerConference: {
        name: "",
        phone: "",
        email: "",
        church: "",
        total_price: 0,

      },
      registerMemberList:[
        {
          name: "",
          church: "",
        }
      ]
    })

    // 컨퍼런스 세부 idx
    const idx = route.params.idx

    //신청자 기본 정보 get
    onMounted(() => {
      axios.get('/api/register/conference/'+idx).then((response) => {
        if(response.data.err_code == 0){
          isAvailable(state.conferences)
          state.conferences = response.data.contents

          console.log(state.conferences.member);
          // state.appleJoinCheck = state.conferences.member.apple === true;
          state.appleJoinCheck = ( state.conferences.member.phone === '' || state.conferences.member.phone === null) && state.conferences.member.apple === true
          /*let price = parseInt(state.conferences.conferenceDetail.price);
          price = price.toLocaleString('ko-KR');
          state.conferences.conferenceDetail.price_txt = price;*/
          state.conferences.member.phone1 = state.conferences.member.phone.slice(0, 3)
          state.conferences.member.phone2 = state.conferences.member.phone.slice(3, 7)
          state.conferences.member.phone3 = state.conferences.member.phone.slice(7, 11)

          formData.registerConference.name = state.conferences.member.name
          formData.registerConference.phone = state.conferences.member.phone
          formData.registerConference.email = state.conferences.member.email
        } else {
          alert(response.data.err_msg);
          router.push({path: '/'});
        }
      }).catch(error => {
        //로그인 안 되어있을 시 
        if(!store.state.account.id) {
          alert('로그인 후 이용해주세요.')
          router.push({path: '/'})
        }
        else {
          console.error(error);
          router.push({path: '/'})
          // document.location.href = '/';
        }
      })
    })

    //접수기간 체크
    const isAvailable = (conferences) => {
      const { rsdatetime, redatetime } = conferences.conferenceDetail.conference
      const today = new Date()

      const startDate = new Date(Date.parse(rsdatetime))
      const endDate = new Date(Date.parse(redatetime))

      if (today < startDate || today > endDate) {
        alert('접수 기간이 아닙니다. 이전 페이지로 돌아갑니다.')
        window.history.back()
      }
    }


    //타이틀 날짜 format
    const formattedDate = computed(() => {
      const date = new Date(state.conferences.conferenceDetail.schedule)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')

      // return `${year}/${month}/${day} ${hours}:${minutes}`
      return `${year}/${month}/${day}`
    })

    const searchAddress = () => {
      const addressFrame = document.querySelector('#addressFrame');

      new window.daum.Postcode({
        oncomplete: (data) => {
          console.log(data);
          formData.zipCode = data.zonecode;
          formData.address = data.roadAddress;
          addressFrame.style.display = 'none';
        },
      // }).open();
      }).embed(addressFrame);

      addressFrame.style.display = 'block';

      initLayerPosition();

    }

    const foldAddress = () => {
        document.querySelector('#addressFrame').style.display = 'none';
    }

    const initLayerPosition = () => {
      const width = 510;
      const height = 510;
      const borderWidth = 5;
      const addressFrame = document.querySelector('#addressFrame');
      const layerWidth = width + borderWidth * 2;
      const layerHeight = height + borderWidth * 2;
      const offsetLeft = (window.innerWidth - layerWidth) / 2;
      const offsetTop = (window.innerHeight - layerHeight) / 2;

      addressFrame.style.position = 'absolute';
      addressFrame.style.width = width + 'px';
      addressFrame.style.height = height + 'px';
      addressFrame.style.border = borderWidth + 'px solid';
      addressFrame.style.left = offsetLeft + 'px';
      addressFrame.style.top = offsetTop + 'px';
    }


    const handleSubmit = async () => {
      /*console.log(formData);
      return false;*/
      formData.registerConference.name = state.conferences.member.name;
      formData.registerConference.phone = state.conferences.member.phone1 + "" + state.conferences.member.phone2 + "" + state.conferences.member.phone3;
      formData.registerConference.email = state.conferences.member.email;
      const { registerConference, registerMemberList } = formData;
      const { conferenceDetail, member } = state.conferences;
      const { limit_personnel, registered_personnel } = conferenceDetail;
      
      if (!registerConference.name) {
        alert("신청자 이름을 입력해 주세요.");
        return;
      }

      // 핸드폰 번호 유효성 검사
      const phoneReg = /^0[0-9]{2}[0-9]{4}[0-9]{4}$/;
      if (!phoneReg.test(registerConference.phone)) {
        alert("올바른 핸드폰 번호를 입력해주세요.")
        return
      }

      if (!registerConference.church) {
        alert("교회명을 기입해주세요.")
        return
      }

      for (const attendee of registerMemberList) {
        if (!attendee.name || !attendee.church) {
          alert("참석자 정보를 모두 기입해주세요.")
          return
        }
      }

      if (!state.agreeCheck) {
        alert("약관을 동의해주세요.")
        return
      }

      /*console.log(limit_personnel);
      console.log(registered_personnel);
      console.log(registerMemberList.length);
      console.log( limit_personnel - registered_personnel )
      console.log(limit_personnel - registered_personnel < registerMemberList.length)*/


      if (limit_personnel - registered_personnel < registerMemberList.length && limit_personnel != 0) {
        alert("신청 가능 인원이 초과되었습니다.")
        return
      }

      const url = `/api/payment/check-limit/${state.conferences.conferenceDetail.idx}?headcount=${registerMemberList.length}`;
      const response = await axios.get(url);
      if( response.data.err_code === 0 ){
        try {
          const response = await axios.post('/api/register/conference/' + idx, formData);
          if (response.data.err_code === 0) {
            const appCheck = ( thisPageName === 'eduRegister2App' ) ? "/app" : "" ;
            await router.push(appCheck + '/register_pay/' + response.data.contents);
          } else {
            alert(response.data.err_msg)
          }
        } catch (error) {
          console.error(error)
        }
      }
      else if( response.data.err_code === 403 ){
        alert(response.data.err_msg);
        return false;
      }
      else {
        alert(response.data.err_msg);
        return false;
      }
    }

    // 참석자 추가,삭제 & 인원수 체크
    const addItem = () => {
      const { limit_personnel, registered_personnel } = state.conferences.conferenceDetail
      if( limit_personnel != 0 && limit_personnel - registered_personnel <= formData.registerMemberList.length ) {
        alert("신청 가능 인원이 초과되었습니다.")
      } else {
        formData.registerMemberList.push({
          name: "",
          church: "",
        })
      }
      console.log(limit_personnel - registered_personnel)
      console.log(formData.registerMemberList.length)
    }

    const removeItem = (index) => {
      formData.registerMemberList.splice(index, 1)
    }

    // 등록비 합계
    // const updateTotalPrice = () => {
    //   formData.registerConference.total_price = state.conferences.conferenceDetail.price * (formData.registerMemberList.length + 1)

    //   formData.registerConference.total_price = Number(
    //     formData.registerConference.total_price
    //   )

    //   console.log(state.conferences.conferenceDetail.price)
    //   console.log(formData.registerMemberList)
    //   console.log(formData.registerConference.total_price)
    // }

    const updateTotalPrice = () => {
      formData.registerConference.total_price = state.conferences.conferenceDetail.price * formData.registerMemberList.length;

      formData.registerConference.total_price = Number(
        formData.registerConference.total_price
      )
      // console.log(state.conferences.conferenceDetail.price)
      // console.log(formData.registerMemberList.length)
      // console.log(formData.registerConference.total_price)
    }

    watchEffect(() => {
      updateTotalPrice()
    })

    const thisPageName = route.name;


    return {
      state,
      viewDetails, toggleView,
      handleSubmit, formData, formattedDate,
      addItem, removeItem,
      updateTotalPrice, searchAddress, foldAddress, thisPageName }
  }

}

</script>

<style scoped>
.detail_view {
  position: relative
}
.detail_view::before {
  content: '';
  display: block;
  width: 100%;
  height: 50px;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  position: absolute;
  top: -50px;
}
</style>
