<template>

  <div class="flex justify-center items-center h-screen" v-if="state.pageLoadComplete === false">
    <span class="loader"></span>
  </div>

  <div v-else class="lg:w-[1200px] p-5 mx-auto mt-10 overflow-x-auto">

    <h2 class="w-full text-center mb-5">
<!--      2023 총회 여름성경학교.수련회 지도자 컨퍼런스-->
      컨퍼런스 신청내역
    </h2>

    <!-- <div class="w-full text-center text-xl mb-5"> ※ 취소 및 변경처리는 19일 오후부터 처리가능합니다 </div> -->

    <h4 class="font-medium mb-2">신청내역</h4>
    <div
      class="max-md:hidden grid py-5 gap-2 px-4 border-t border-black sticky top-0"
      style="grid: 1fr / 1fr 0.5fr 1fr 1fr 0.3fr 0.4fr 0.4fr 0.4fr">
      <p>컨퍼런스 장소 및 부서</p>
      <p class="text-center">참가인원</p>
      <p class="text-center">결제금액</p>
      <p class="text-center">결제상태</p>
      <p class="text-center">상세</p>
      <p class="text-center">바코드</p>
      <p class="text-center">영수증출력</p>
      <p class="text-center">취소/변경</p>
    </div>

    <div v-if="state.myConference.registeredConferenceList.length === 0">
      <div class="flex justify-center px-4 py-10 border-t border-neutral-200">
        <h4>내역이 없습니다</h4>
      </div>
    </div>

    <div v-else v-for="(item, index) in confesOnCurrentPage" :key="index"
      class="md:grid flex flex-col gap-2 py-3 px-4 border-t border-neutral-200 md:items-center"
      style="grid: 1fr / 1fr 0.5fr 1fr 1fr 0.3fr 0.4fr 0.4fr 0.4fr">
      <div>
        <span class="md:hidden font-medium">컨퍼런스 :</span>
        {{item.conferenceDetail.conference.title}}<br /> {{ item.conferenceDetail.local }} {{ item.conferenceDetail.place }} <b>{{ item.conferenceDetail.dept }}</b>
      </div>
      <div class="md:text-center">
        <span class="md:hidden font-medium">참가인원 :</span>
        {{ item.headcount }}
      </div>
      <div class="md:text-center " v-if="item">
        <span class="md:hidden font-medium">결제금액 :</span>
        <!-- total_price 가 로딩된 후 toLocaleString() 실행 -->
        {{ item.total_price ? item.total_price.toLocaleString() : '' }}
      </div>
      <div class="md:text-center">
        <span class="md:hidden font-medium">결제상태 :</span>

        <span class="text-gray-500" v-if="item.status === '결제시도'" >
          {{ item.status }}
          <button type="button" class="sec_blue_btn md:block mx-auto text-sm p-1 mt-0.5" v-if="viewCheck(item.conferenceDetail.redatetime) === false" @click="goPayment(item.idx, item.conferenceDetail.redatetime)"> 등록계속 </button>
        </span>
        <span v-else
          :class="{
            'text-red-500': item.status === '결제실패',
            'text-blue-500': item.status === '등록완료',
            'text-yellow-500': item.status === '결제대기',
          }"
        > {{ item.status }} </span>
      </div>

      <!-- 상세보기 -->
      <div class="w-full max-md:mt-4">
        <VDialog :classes="{ bg: 'bg-black/40' }" class="p-4">
          <template #default="{ close }">
            <!-- 자세히 -->
            <div class="color-black bg-white max-w-full md:min-w-[500px] max-md:min-w-[80vw] max-h-[70vh] overflow-y-auto p-4">
              <section class="flex justify-between border-b border-gray-200 pb-2">
                <h5>상세보기</h5>
                <button @click="close">
                  <h4><font-awesome-icon icon="fa-solid fa-circle-xmark" /></h4>
                </button>
              </section>
              <section class="divide-y">
                <div class="p-2 my-2">
                  <p class="text-sky-600">컨퍼런스</p>
                  <p>{{ item.conferenceDetail.conference.title }} - {{ item.conferenceDetail.local }} {{ item.conferenceDetail.place }} {{ item.conferenceDetail.dept }}</p>
                </div>
                <div class="p-2 my-2">
                  <p class="text-sky-600">참가자 (총 {{ item.headcount }}명)</p>
                  <div class="max-h-[40vh] overflow-y-auto">
                    <p v-for="(ppl, idx) in item.registerMemberList" :key="idx" v-show="ppl.is_cancel == 'N'">
                      <b class="font-medium">{{ ppl.name }}</b> ({{ ppl.church }})
                    </p>
                  </div>
                </div>
                <div class="p-2 my-2">
                  <div>
                    <p class="text-sky-600">결제 금액</p>
                    <p>{{ item.total_price ? item.total_price.toLocaleString() : '' }}({{ item.status }})</p>
                  </div>
                </div>
              </section>
            </div>
          </template>

          <template #toggle="{ bind, on }">
            <div v-bind="bind" v-on="on" class="justify-center py-3 bg-neutral-100 hover:bg-sky-100 flex items-center ">
              <span class="md:hidden font-medium">상세보기</span>
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </div>
          </template>
        </VDialog>
      </div>

      <!-- 바코드 -->
      <div v-if="item.barcode !== null">
        <VDialog :classes="{ bg: 'bg-black/40' }" class="p-4">
          <template #default="{ close }">
            <div class="color-black bg-white max-w-full md:min-w-[500px] max-md:min-w-[80vw] max-h-[70vh] overflow-y-auto p-4">
              <section class="flex justify-between border-b border-gray-200 pb-2">
                <h5> 바코드 보기 </h5>
                <button @click="close">
                  <h4><font-awesome-icon icon="fa-solid fa-circle-xmark" /></h4>
                </button>
              </section>
              <section class="divide-y">
                <div>
                  <vue3-barcode :value="item.barcode" :height="50" class="flex justify-center"/>
                </div>
              </section>
            </div>
          </template>

          <template #toggle="{ bind, on }">
            <div v-bind="bind" v-on="on" class="justify-center py-3 bg-neutral-100 hover:bg-sky-100 flex items-center ">
              <span class="md:hidden font-medium">바코드 보기</span>
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </div>
          </template>
        </VDialog>
      </div>
      <div v-else class="noneBarcode"> - </div>


      <!-- 영수증 출력 -->
      <div>
<!--            <a href="https://www.inicis.com/payment-view" target="_blank" v-if="item.status == '등록완료'" class="justify-center py-3 bg-neutral-100 hover:bg-sky-100 flex items-center">-->
          <div @click="openModal(item.tid)" v-if="item.status == '등록완료'" class="justify-center py-3 bg-neutral-100 hover:bg-sky-100 flex items-center">
            <span class="md:hidden font-medium">영수증출력</span>
            <font-awesome-icon icon="fa-solid fa-receipt" />
          </div>
<!--        <a :href="'https://iniweb.inicis.com/receipt/iniReceipt.jsp?noTid='+item.tid" target="_blank" v-if="item.status == '등록완료'" class="justify-center py-3 bg-neutral-100 hover:bg-sky-100 flex items-center">-->
<!--        </a>-->
      </div>

      <!-- 취소/변경 신청 -->
      <div class="w-full">
        <VDialog :classes="{ bg: 'bg-black/40' }" class="p-4">
          <template #default="{ close }">
            <div class="color-black bg-white max-w-full md:min-w-[500px] max-md:min-w-[80vw] max-h-[70vh] overflow-y-auto p-4">
              <section class="flex justify-between border-b border-gray-200 pb-2">
                <h5>취소 신청</h5>
                <button @click="close">
                  <h4><font-awesome-icon icon="fa-solid fa-circle-xmark" /></h4>
                </button>
              </section>
              <section class="divide-y">


                <div class="py-2 my-2">
                  <p class="text-sky-600">컨퍼런스</p>
                  <div class="p-2">
                    <p>{{item.conferenceDetail.conference.title}}</p>
                    <p>{{ item.conferenceDetail.local }} {{ item.conferenceDetail.place }} {{ item.conferenceDetail.dept }}</p>
                    <p class="mt-2">결제금액 : <b>{{ item.total_price ? item.total_price.toLocaleString() : '' }}</b>원</p>
                  </div>
                </div>

                <div class="py-2 my-2">
                  <p class="text-sky-600">요청 구분</p>
                  <div class="flex gap-5 p-2">
                    <label>
                      <input type="radio" name="type" v-model="state.cancelApply.type" value="부분" @change="selectType('', item.headcount)" /> 부분취소
                    </label>
                    <label>
                      <input type="radio" name="type" v-model="state.cancelApply.type" value="전체" @change="selectType(item.total_price, item.headcount)" /> 전체취소
                    </label>
                  </div>
                </div>

                <div class="py-2 my-2" v-show="state.cancelApply.type === '부분'">
                  <p class="text-sky-600">취소인원선택</p>
                  <!-- 인원 선택 -->
                  <div class="w-full border p-2">
                    <div class="grid gap-2" style="grid: 1fr / repeat(3, auto)">
                      <label v-for="(ppl, idx) in item.registerMemberList" :key="idx" class="hover:bg-sky-100" v-show="ppl.is_cancel == 'N'">
                        <input type="checkbox" v-model="state.cancelApply.member" :value="ppl.idx" @change="calcCanclePrice('', item.headcount)"/> {{ ppl.name }}
                      </label>
                    </div>
                  </div>
                </div>

                <!-- 수수료, 환불금액 -->
                <div class="py-2 my-2">
                  <div class="mt-4 p-2">
                    <p>취소수수료: 0원</p>
                    <p>환불금액: <b> {{ state.cancelApply.price.toLocaleString('kr-ko') }} </b>원</p>
                  </div>
                </div>

                <div class="py-2 my-2">
<!--                      <div>
                    <p class="text-sky-600">사유</p>
                    <textarea class="w-full border p-2"></textarea>
                  </div>-->
                  <div class="flex justify-center">
                    <button type="button" class="mt-4 sec_blk_btn lg:px-5 px-2 py-2" @click="cancelApply( item.idx )">신청하기</button>
                  </div>
                </div>
              </section>
            </div>
          </template>

          <template #toggle="{ bind, on }">
            <div v-bind="bind" v-on="on" v-if="item.status == '등록완료' && item.paymentCancelListLatest.status != '취소신청' " class="justify-center py-3 bg-neutral-100 hover:bg-sky-100 flex items-center" @click="setCancleInit">
              <span class="md:hidden font-medium">취소·변경 신청</span>
              <font-awesome-icon icon="fa-solid fa-pen-to-square" />
            </div>
          </template>
        </VDialog>
        <div class="text-center" v-if="item.paymentCancelListLatest.status === '취소신청'"> 확인중 </div>
        <div class="text-center" v-if="item.paymentCancelListLatest.status === '처리완료'"> 처리완료 </div>
      </div>
    </div>

    <div
      v-if="state.myConference.registeredConferenceList.length > 0"
      class="flex justify-center py-5"
    >
      <v-pagination
        v-model="state.currentPage"
        :pages="totalPages"
        :range-size="1"
        @update:modelValue="handlePageChange"
      />
    </div>

<!--    <div>
      <router-link to="/myCancelList" class="sec_blk_btn lg:px-5 px-2 py-2">취소·변경 내역</router-link>
    </div>-->
  </div>



  <ini-receipt  v-if="modalVisible" @close="modalVisible = false" :it_id="modalData" ></ini-receipt>



</template>
  
<script setup>
  import {reactive, computed, ref} from 'vue'
  import { VDialog } from 'vuetensils/src/components'
  import axios from 'axios'

  import VPagination  from "@hennge/vue3-pagination"
  import "@hennge/vue3-pagination/dist/vue3-pagination.css"

  import iniReceipt from './iniReceipt.vue'
  import ReviewWriteComponent from "@/components/ReviewWriteComponent.vue";

  import Vue3Barcode from 'vue3-barcode'
  
  const state = reactive({
    myConference: {
      registeredConferenceList: [
        {
          conferenceDetail: {
            conference: {
              title:"",
            }
          },
          registerMemberList: [{

          }],
	        paymentCancelList: [],
          paymentCancelListLatest: {
						idx: 0,
            status: "",
            type: "",
            price: 0,
            count: 0,
            cancel_member_idxs: "0",
            created_date: "",
            confirm_date: null,
            member_idx: 0
					}
        }
      ]
    },

    cancelApply: {
      price: 0,
      member: [],
      type: "부분"
    },

    currentPage: 1,
    pageSize: 8, //페이지에 8개씩

    pageLoadComplete: false
  })

  const selectType = ( total, totalPerson ) => {
    if( state.cancelApply.type === "부분" ) {
        state.cancelApply.member = [];
        state.cancelApply.price = 0;
    }
    calcCanclePrice(total, totalPerson);
  }

  const calcCanclePrice = ( total, totalPerson ) => {

    let price = 0
    if( state.cancelApply.type === "전체" ) {
      state.cancelApply.price = total;
    }
    else {
      state.myConference.registeredConferenceList.forEach(item => {
        item.registerMemberList.forEach(ppl => {
          if(state.cancelApply.member.includes(ppl.idx)) {
            price += item.conferenceDetail.price
          }
        })
      })

      if( totalPerson === state.cancelApply.member.length ){
        state.cancelApply.type = "전체";
      }
      console.log(price);
      console.log(state.cancelApply.member);
      state.cancelApply.price = price
    }
  }

   const cancelApply = async ( idx ) => {
    try {
      // const count = (state.cancelApply.type == "전체") ? 0 : state.cancelApply.member.length;
      const count = state.cancelApply.member.length;
      const cancel_member_idxs = state.cancelApply.member.join(',');

      const body = {
        "register_conf_idx": idx,
        "type": state.cancelApply.type,
        "price": state.cancelApply.price,
        "count": count,
        "cancel_member_idxs": cancel_member_idxs
      }

      if( body.type === "부분" && body.cancel_member_idxs === "" ) {
        alert("취소할 인원을 선택해주세요.");
        return;
      }

      const response = await axios.post('/api/payment/cancel', body);
      console.log(response);
      if (response.data.err_code === 0) {
        alert('신청이 완료되었습니다. \n\n처리 완료 후 안내드립니다.');
        document.location.reload();
      } else {
        alert(response.data.err_msg)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const goPayment = ( idx, endDate ) => {

    const date = new Date();
    const targetDate = new Date(endDate);
    if( date <= targetDate ) {
      if( window.location.pathname === '/profile/confList/app' ){
        document.location.href = '/app/register_pay/' + idx;
      }
      else {
        document.location.href = '/register_pay/' + idx;
      }
    }
    else {
      alert('접수 기간이 아닙니다.');
    }
  }

  const setCancleInit = () => {
    state.cancelApply = {
      price: 0,
      member: [],
      type: "부분"
    }
  }


  setTimeout(() => {

    // 오늘 날짜 yyyymmdd 형식으로
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1. padStart ensures it's always 2 digits
    const day = String(today.getDate()).padStart(2, '0'); // padStart ensures it's always 2 digits
    const formattedDate = `${year}-${month}-${day}`;

    console.log(formattedDate);



    const url = `/api/member/me?today=${formattedDate}`
    axios.get(url).then(response => {
      state.myConference = response.data.contents;
      state.myConference.registeredConferenceList.map(item => {
        item.paymentCancelListLatest = ( item.paymentCancelList.length > 0 ) ? item.paymentCancelList[item.paymentCancelList.length - 1] : {};
      })
      state.pageLoadComplete = true;
    }).catch(error => {
      if( window.location.pathname === '/profile/confList/app' ){
        alert('로그인이 필요합니다.');
        getData.postMessage('login');
      }
      console.error(error)
    })
  }, 1000)


  // 총 페이지수 계산
  const totalPages = computed(() =>
    Math.ceil(state.myConference.registeredConferenceList.length / state.pageSize)
  )

  //회원 목록 slice
  const confesOnCurrentPage = computed(() => {
    const start = (state.currentPage - 1) * state.pageSize
    const end = start + state.pageSize
    return state.myConference.registeredConferenceList.slice(start, end)
  })

  //페이지 변경
  const handlePageChange = (newPage) => {
    state.currentPage = newPage
  }

  const viewCheck = ( endDate ) => {
    const date = new Date();
    const targetDate = new Date(endDate);
    return date > targetDate;
  }

  const modalVisible = ref(false);
  const modalData = ref(null);

  const openModal = (content) => {
    modalData.value = content;
    modalVisible.value = true;
  };

</script>

<style scoped>
.noneBarcode {
  text-align: center;
}
@media (max-width: 768px) {
  .noneBarcode {
    display: none;
  }
}

</style>
