<template>
  <div>
    <TNavComponent/>
    <toTopComponent/>
    <router-view/>
    <FooterComponent/>
  </div>
</template>

<script>
import TNavComponent from "@/components/TNavComponent.vue"
import toTopComponent from "@/components/toTopComponent.vue"
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "communityView",
  components: {TNavComponent, toTopComponent, FooterComponent}
}
</script>
