<script setup>
import router from "@/router";

const { page } = defineProps(['page']);
const moveBack = () => {
  // alert(page);
  const prevPath = localStorage.getItem('prevPath');
  // alert(prevPath);
  const prevPathName = localStorage.getItem('prevPathName');
  // alert(prevPathName);
  /*if( page === 'undefined' ){
    alert('/app_main');
    router.push({path: '/app_main'});
  }*/
  if( page === 'eduRegisterApp' || page === 'eduRegister2App' ){
    // router.go(-1);
    // history.back();
    router.push({path: prevPath});
  }
  else if( page === 'conferenceListApp' ){
    // router.go(-1);
    router.push({path: '/app_main'});
  }
  else {
    if( prevPathName === 'undefined' ){
      getData.postMessage('finish');
    }
    else {
      router.go(-1);
      // history.back();
      // router.push({path: prevPath});
    }
  }
}
</script>

<template>
  <div
    class="lg:bg-sky-50 bg-white lg:border-r border-[#bfdef2] lg:max-w-[440px] sticky top-0 left-0 z-10"
  >
    <section class="lg:px-5 max-lg:border-b border-[#e0e0e0] overflow-y-auto sticky top-0">
<!--      <div class="w-full flex justify-between px-4 py-3 items-center lg:pt-8">-->
      <div class="w-full flex justify-between items-center lg:pt-8">
<!--        <button type="button" @click="moveBack">
          <svg enable-background="new 0 0 15 26" height="26px" id="Layer_1" version="1.1" viewBox="0 0 15 26" width="15px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <polygon fill="#231F20" points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5 "/>
          </svg>
        </button>-->

        <button @click="moveBack()" class="back">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.636 11.293C3.44853 11.4805 3.34322 11.7348 3.34322 12C3.34322 12.2652 3.44853 12.5195 3.636 12.707L9.293 18.364C9.38525 18.4595 9.49559 18.5357 9.6176 18.5881C9.7396 18.6405 9.87082 18.6681 10.0036 18.6692C10.1364 18.6704 10.2681 18.6451 10.391 18.5948C10.5139 18.5445 10.6255 18.4703 10.7194 18.3764C10.8133 18.2825 10.8875 18.1708 10.9378 18.0479C10.9881 17.9251 11.0134 17.7934 11.0123 17.6606C11.0111 17.5278 10.9835 17.3966 10.9311 17.2746C10.8787 17.1526 10.8025 17.0422 10.707 16.95L6.757 13L20 13C20.2652 13 20.5196 12.8946 20.7071 12.7071C20.8946 12.5196 21 12.2652 21 12C21 11.7348 20.8946 11.4804 20.7071 11.2929C20.5196 11.1053 20.2652 11 20 11L6.757 11L10.707 7.04999C10.8892 6.86139 10.99 6.60879 10.9877 6.34659C10.9854 6.08439 10.8802 5.83358 10.6948 5.64817C10.5094 5.46277 10.2586 5.3576 9.9964 5.35532C9.73421 5.35304 9.4816 5.45383 9.293 5.63599L3.636 11.293Z" fill="black"/>
          </svg>
        </button>
      </div>
    </section>
  </div>

</template>

<style scoped>

</style>