<template>
  <div class="flex flex-col w-full justify-center items-center flex-1" id="parallax">
    <div class="max-w-[1340px] w-full px-5">
      <div class="w-fit mx-auto pt-10 pb-20 text-center">
        <img src="@/assets/img/home_title.svg" style="width: clamp(240px, 40vw, 340px);"/>
        <router-link to="/introduce/1" class="flex w-fit gap-2 items-center rounded-full text-sm mx-auto mt-6 px-3 py-1 bg-black text-white ">
          총회교육닷컴 소개
          <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0 0 6 9" fill="none">
            <path d="M1 0.5L5 4.5L1 8.5" stroke="white"/>
          </svg>
        </router-link>
      </div>

      <div class="w-full flex max-md:flex-col gap-12 pb-16">
        <router-link to="/" class="w-full main_shortcut">
          <div class="aspect-[4/3] rounded-2xl overflow-hidden bg-slate-300 mb-7">
            <img src="@/assets/img/tmp1.jpg" class="w-full"/>
          </div>
          <div>
            <div class="flex justify-between items-center">
              <h3 class="aggro leading-none">컨퍼런스</h3>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none">
                <path d="M15 2L27 14L15 26" stroke="#1B1D21" stroke-width="3"/>
                <path d="M0 14H27" stroke="#1B1D21" stroke-width="3"/>
              </svg>
            </div>
            <p class="text-sm font-light text-gray-700 mt-3">컨퍼런스 소개, 접수 및 용품 구입 바로가기</p>
          </div>
        </router-link>
        <router-link to="/mall" class="w-full main_shortcut">
          <div class="aspect-[4/3] rounded-2xl overflow-hidden bg-slate-300 mb-7">
            <img src="@/assets/img/tmp1.jpg" class="w-full"/>
          </div>
          <div>
            <div class="flex justify-between items-center">
              <h3 class="aggro leading-none">콘텐츠 몰</h3>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none">
                <path d="M15 2L27 14L15 26" stroke="#1B1D21" stroke-width="3"/>
                <path d="M0 14H27" stroke="#1B1D21" stroke-width="3"/>
              </svg>
            </div>
            <p class="text-sm font-light text-gray-700 mt-3">총회교육닷컴에서 만나는 다양한 콘텐츠</p>
          </div>
        </router-link>
        <router-link to="/" class="w-full main_shortcut">
          <div class="aspect-[4/3] rounded-2xl overflow-hidden bg-slate-300 mb-7">
            <img src="@/assets/img/tmp1.jpg" class="w-full"/>
          </div>
          <div>
            <div class="flex justify-between items-center">
              <h3 class="aggro leading-none">커뮤니티</h3>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none">
                <path d="M15 2L27 14L15 26" stroke="#1B1D21" stroke-width="3"/>
                <path d="M0 14H27" stroke="#1B1D21" stroke-width="3"/>
              </svg>
            </div>
            <p class="text-sm font-light text-gray-700 mt-3">공지사항, 자유게시판 등 다양한 이야기를 나누는 공간</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  methods: {
    parallax(event) {
      const elem = document.querySelector("#parallax");

      let _w = window.innerWidth / 3;
      let _h = window.innerHeight / 3;

      let _mouseX = event.clientX;
      let _mouseY = event.clientY;

      let _depth1 = `${10 - (_mouseX - _w) * 0.0015}% ${45 - (_mouseY - _h) * 0.0015}%`;
      let _depth2 = `${95 - (_mouseX - _w) * 0.008}% ${130 - (_mouseY - _h) * 0.008}%`;

      let x = `${_depth1}, ${_depth2}`;
      elem.style.backgroundPosition = x;
    },
  },
}
</script>

<style scoped>
  .main_shortcut {
    transition: transform .6s, color .4s ;
  }
  .main_shortcut:hover {
    transform: translateY(-2.5rem);
    color: var(--main-navy);
    
  }

  .main_shortcut:hover svg path{
    stroke: var(--main-navy);
  }

  #parallax {
    background:
      10% 45% / auto no-repeat url(@/assets/img/home_bg_obj.svg),
      95% 130% / 25% no-repeat url(@/assets/img/home_bg_obj.svg);
  }

  @media screen and (max-width: 1200px) {
    #parallax {
      background: none;
    }
  }
</style>