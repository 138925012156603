<template>

  <button type="button" class="btn btn-primary" @click="sendNotification">알림톡 보내기</button>

</template>

<script>
import axios from 'axios';

export default {
    name: "alimtalkComponent",

    data() {
      return {
				alimtalk_data: [{
          message_type: 'AT',
          phn: '01020083033',
          profile: '4ece7da6a5cd10889ee20c4048afad3b554e419f',
          msg: '\t[총회교육개발원]\n' +
		          '총회교육개발원 가입을 위한 인증번호는 아래와 같습니다.\n' +
		          '\n' +
		          '인증번호 : 123456\n' +
		          '\n' +
		          '감사합니다.',
          tmplId: 'member_reg_passcode',
          smsKind: 'S',
          msgSms: '[총회교육원] 인증번호 발송 : 123456',
          smsSender: '01020083033',
        }],
        /*alimtalk_data: [{
          message_type: 'AT',
          phn: '01020083033',
          profile: '4ece7da6a5cd10889ee20c4048afad3b554e419f',
          msg: '[총회교육개발원]\n' +
              '총회교육개발원 컨퍼런스 홈페이지에 회원가입 해주셔서 감사합니다.\n' +
              '앞으로 다양한 소식과 혜택/정보를 홈페이지에서 보실 수 있습니다.',
          tmplId: 'member_join_01',
          smsKind: 'S',
          msgSms: '[총회교육원] 회원가입 되었습니다. 감사합니다.',
          smsSender: '01020083033',
        }],*/
      }
    },
    methods: {
      async sendNotification() {
        try {
          const response = await axios.post('/v2/sender/send', this.alimtalk_data, {
            headers: {
              "Content-Type":"application/json",
              "userId": "eduwind"
            }
          });
          console.log(response.data);
        } catch (error) {
          console.error(error);
        }
      }

    }

}
</script>

<style scoped>

</style>