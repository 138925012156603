<template>
  <img src="@/assets/img/community_banner.jpg" class="w-full max-h-[25rem] min-h-[11.25rem] object-cover"/>

  <main class="max-w-[1200px] mx-auto mb-20">
    <header class="header_w_search">
      <div></div>
      <h1 class="font-bold">커뮤니티</h1>
    </header>

    <div class="max-lg:px-5">
      <div class="flex gap-2 items-center mb-4 max-lg:flex-col">
        <select v-model="state.post.boardId" :disabled="state.post.postId !== null">
          <option value="null" disabled hidden>게시판 선택</option>
          <option value="1" v-if="$store.state.level.id === 10">공지사항</option>
          <option value="2">자유게시판</option>
          <option value="3">Q&A</option>
          <option value="4" v-if="$store.state.level.id === 10">웹진</option>
        </select>
        <input type="text" placeholder="제목을 입력해 주세요." v-model="state.post.postTitle" class="w-full p-4 border border-neutral-300"/>
      </div>
      
      <div class="flex justify-between items-center mb-2 text-sm">
        <!-- 썸네일 등록 -->
        <div class="flex items-center gap-2">
          <label class="flex items-center gap-2" v-if="state.post.boardId == 4">
            <div id="imagePreview" class="relative w-20 h-20 border border-gray-300 overflow-hidden">
              <img :src="'https://eduwind-api.nodeblack.net/' + state.post.postThumbnail" v-if="state.post.postThumbnail">
              <img id="img" v-else/>
            </div>
            <input id="thumbnailFile" type="file" @change="readInputFile" class="hidden"/>
            <p class="cursor-pointer sec_btn_black px-2 py-1">썸네일 등록</p>
          </label>

          <!-- 파일 인풋에 등록된 썸네일 지우기 -->
          <button v-if="state.post.boardId == 4" type="button" @click="clearThumbnail">
            <p class="cursor-pointer sec_btn px-2 py-1">썸네일 <span class="underline">삭제</span></p> 
          </button>

          <!-- 이미 등록된 썸네일 지우기 -->
          <label v-else-if="state.post.postThumbnail && state.delToggle === false">
            <input type="checkbox" v-model="state.delToggle" class="hidden">
            <p class="cursor-pointer sec_btn px-2 py-1">썸네일 <span class="underline">삭제</span></p> 
          </label>    
        </div>

        <div class="flex items-end gap-2 max-lg:flex-col">
          <label class="check_btn gap-1 cursor-pointer">
            <input type="checkbox" v-model="state.post.isSecret"/>
            비밀글
          </label>

          <label class="check_btn gap-1 cursor-pointer" v-if="$store.state.level.id === 10">
            <input type="checkbox" v-model="state.post.isPin"/>
            상단 고정
          </label>
        </div>
      </div>

      <div>
        <quillEditor
          v-model:value="state.post.postContent"
          contentType="html"
          toolbar="full"
          style="min-height: 50vh;"
          :options="editor.editorOption"
          :modules="modules"
        />
      </div>
    </div>

    <div class="mx-auto my-10 flex justify-center items-center gap-2">
      <button type="button" class="pri_btn pri_btn_bg px-6 py-4 text-lg" @click="postCreate">작성 완료</button>
      <button type="button" class="sec_btn_black px-6 py-4 text-lg" @click="writeCancel">
        취소
      </button>
    </div>
  </main>
</template>

<script setup>
  import { ref, reactive, onMounted, watchEffect } from 'vue'
  import axios from 'axios';
  import { useRouter, useRoute } from 'vue-router';
  import { quillEditor, Quill } from 'vue3-quill'

  import imageUploader from "quill-image-uploader";
  import 'quill-image-uploader/dist/quill.imageUploader.min.css';

  // import Emoji from 'quill-emoji';

  Quill.register({
    'modules/imageUploader': imageUploader,
    // 'modules/emoji': Emoji,
  });

  const router = useRouter();
  const route = useRoute();

  const state = reactive({
    post: {
      postId: null,
      boardId: null,
      postTitle: '',
      postContent: '',
      isSecret: false,
      isPin: false,
      postThumbnail: null
    },
    delToggle: false
  })

  // const emojiList = ref([]);

  // emojiList.value = Emoji.ShortNameEmoji.DEFAULTS.emojiList;

  // const emojiMap = {};
  // emojiList.value.forEach((emoji) => {
  //   emojiMap[emoji.name] = emoji;
  // });

  // console.log(emojiMap);
  
  const editor = reactive({
    editorOption: {
      placeholder: '내용을 입력해 주세요.',
      modules: {
        imageUploader: {
          upload: (file) => {
            return new Promise(async(resolve, reject) => {
              console.log(resolve)
              console.log(reject)
              console.log(file)

              const formData = new FormData();
              formData.append("image", file);

              const url = '/new_api/post/desc-image/new';
              await axios.post(url, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then(res => {
                if( res.status === 200 ) {
                  if (res.data.code === "200") {
                    console.log(res.data.data);
                    const uploadPath = 'http://118.67.134.110:18080' + res.data.data.uploadPath
                    resolve(uploadPath)
                  }
                  else {
                    console.log('등록 실패');
                  }
                }
                else {
                  console.log('등록 실패');
                }

              })
              .catch(err => {
                reject("Upload failed");
                console.error("Error:", err)
              })
            })
          },
        }
      }
    },
    
  })

  const postId = route.params.postId;

  const getPost = async () => {
    try {
      if (postId) {
        const { data } = await axios.get(`/new_api/post/${postId}`);
        state.post = data.data.post;
      } else {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  }

  onMounted(async () => {
    await getPost();
  });

  const postCreate = async () => {
    let postImageCommand = 'CREATE'; // 썸네일 생성

    if (state.post.postThumbnail) { // 썸네일이 존재함
      const thumbnailInput = document.getElementById('thumbnailFile');

      if (thumbnailInput && thumbnailInput.files[0]) { // 썸네일 변경
        postImageCommand = 'UPDATE';
      } else if (state.delToggle === true) { // 썸네일 삭제
        postImageCommand = 'DELETE';
      } else {
        postImageCommand = null; // 썸네일 조작 X
      }
    }

    const jsonBody = {
      postId: state.post.postId !== null ? state.post.postId : null,
      boardId: state.post.boardId,
      postTitle: state.post.postTitle,
      postContent: state.post.postContent,
      isSecret: state.post.isSecret,
      isPin: state.post.isPin,
      postImageCommand,
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(jsonBody));

    if (postImageCommand !== null) {
      const thumbnailInput = document.getElementById('thumbnailFile');

      if (thumbnailInput) {
        const thumbnailFile = thumbnailInput.files[0];
        formData.append('thumbnail', thumbnailFile);
      }
    }

    try {
      const res = await axios.post('/new_api/post/edit', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(res.data)

      if (res.data.code === "200") {
        const { postId } = res.data.data;
        alert('게시글이 등록되었습니다.');
        
        router.push(`/community/${state.post.boardId}/${postId}`);
      } else {
        res.data.reasons.forEach(reason => {
          switch (res.data.reasons) {
            case "NotNull.boardId":
              alert("게시판을 선택해주세요.");
              break;
            case "NotNull.postTitle":
              alert("게시글 제목을 입력해 주세요.");
              break;
            case "NotNull.postContent":
              alert("게시글 내용을 입력해 주세요.");
              break;
            default:
              // 예상치 못한 이유
              alert("예기치 못한 에러가 발생했습니다.\n문제 지속시 고객센터로 문의 바랍니다.");
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  
  // 썸네일 미리보기
  const readInputFile = (e) => {
    const imagePreview = document.getElementById('imagePreview');
    imagePreview.innerHTML = '';

    const files = Array.from(e.target.files);

    files.forEach((file) => {
      if (!file.type.match('image/.*')) {
        alert('이미지 확장자만 업로드 가능합니다.');
        e.target.value = ''; // 파일 등록 취소
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        const img = document.createElement('img');
        img.src = event.target.result;
        imagePreview.appendChild(img);
      };

      reader.readAsDataURL(file);
    });
  };

  const clearThumbnail = () => {
    // 인풋 초기화
    const thumbnailInput = document.getElementById('thumbnailFile');
    thumbnailInput.value = '';
    // 썸네일 초기화
    state.post.postThumbnail = null;
  };

  const writeCancel = () => {
    if (confirm('작성을 취소하시겠습니까? 작성 내용은 저장되지 않습니다.')) {
      router.go(-1); // 이전 페이지로
    }
  };

  watchEffect(() => {
    // 썸네일 삭제 클릭
    if (state.delToggle) {
      const confirmed = confirm("썸네일을 삭제하시겠습니까?");

      if (confirmed) {
        alert("삭제되었습니다. 게시글 등록 시 최종 적용됩니다.")
        
        const imagePreview = document.getElementById('imagePreview');
        imagePreview.innerHTML = '';
      } else {
        state.delToggle = false;
      }
    }
  });
</script>

<style>
  .ql-editor {
    min-height: inherit;
  }
</style>