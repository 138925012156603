<template>
  <div id="create">
    <div class="adm_box_wrapper">
      <form>
        <h3 class="p-5 border-b border-gray-200 font-medium">컨퍼런스 등록</h3>

        <div class="field_inputs p-5">
          <!-- 제목 -->
          <div class="md:flex">
            <label class="inline-block w-1/6 mb-2">제목<span></span></label>
            <div class="flex w-full">
              <input type="text" placeholder="예: 2023 총회여름성경학교‧수련회" class="regi_inputs w-full" v-model="formData.conference.title"/>
            </div>
          </div>

          <!-- 접수기간 -->
          <div class="md:flex">
            <label class="inline-block w-1/6 mb-2">예약접수기간<span></span></label>
            <div class="flex w-full gap-2">
              <div class="flex w-1/4">
                <!--
                <input type="text" class="regi_inputs w-full" v-model="formData.conference.rsdatetime"/>
                <button class="px-3 border border-l-0 border-neutral-200">
                  <font-awesome-icon icon="fa-solid fa-calendar" />
                </button>
                -->
                <VDatePicker v-model.string="formData.conference.rsdatetime" :popover="false" mode="dateTime" is24hr locale="ko" :masks="masks">
                  <template #default="{ togglePopover, inputValue, inputEvents }">
                    <input type="text" class="regi_inputs w-full" :value="inputValue" v-on="inputEvents" placeholder="2023.01.01 18:00"/>
                    <button type="button" class="px-3 border border-l-0 border-neutral-200" @click="togglePopover">
                      <font-awesome-icon icon="fa-solid fa-calendar" />
                    </button>
                  </template>
                </VDatePicker>
              </div>
              &#65343;
              <div class="flex w-1/4">
                <VDatePicker v-model.string="formData.conference.redatetime" :popover="false" mode="dateTime" is24hr locale="ko" :masks="masks">
                  <template #default="{ togglePopover, inputValue, inputEvents }">
                    <input type="text" class="regi_inputs w-full" :value="inputValue" v-on="inputEvents" placeholder="2023.02.01 00:00"/>
                    <button type="button" class="px-3 border border-l-0 border-neutral-200" @click="togglePopover">
                      <font-awesome-icon icon="fa-solid fa-calendar" />
                    </button>
                  </template>
                </VDatePicker>
              </div>
              <div class="ml-5 flex items-center">
                취소기간: 
                <input type="number" placeholder="1" class="w-16 mx-2 regi_inputs" v-model="formData.conference.cancel_day" style="padding: 0.5rem">
                일 전까지 가능
              </div>
            </div>
          </div>

          <!-- 일시 / 장소 -->
          <div class="md:flex">
            <label class="inline-block w-1/6 mb-2">일시 및 장소<span></span></label>
            <div class="w-full">
              <div class="mb-6" v-for="(item, index) in formData.conferenceDetailList" :key="index">
                <div class="flex gap-2 mb-2">
                  <div class="flex w-1/3">
                    <input type="text" class="flex items-center w-14" v-model="item.common.number" disabled>
                    <!-- 시간 선택하기 전에 팝업 닫히는 부분 수정 필요 -->
                    <VDatePicker v-model.string="item.common.schedule" :popover="false" mode="dateTime" is24hr locale="ko" :masks="masks">
                      <template #default="{ togglePopover, inputValue, inputEvents }">
                        <input type="text" class="regi_inputs w-full" :value="inputValue" v-on="inputEvents" placeholder="2023.03.01 14:00"/>
                        <button type="button" class="px-3 border border-l-0 border-neutral-200" @click="togglePopover">
                          <font-awesome-icon icon="fa-solid fa-calendar" />
                        </button>
                      </template>
                    </VDatePicker>
                  </div>
                  <input type="text" placeholder="지역명" class="regi_inputs w-1/6" v-model="item.common.local"/>
                  <input type="text" placeholder="장소명" class="regi_inputs w-1/4" v-model="item.common.place"/>
                  
                  <div class="flex gap-3">
                    <button type="button" @click="removeItem(index)" v-if="formData.conferenceDetailList.length > 1 || index > 0">
                      <h4 class="text-red-500"><font-awesome-icon icon="fa-solid fa-circle-xmark" /></h4>
                    </button>
                    <button type="button" @click="addItem" v-if="index == formData.conferenceDetailList.length - 1">
                      <h4 class="text-sky-500"><font-awesome-icon icon="fa-solid fa-circle-plus" /></h4>
                    </button>
                  </div>
                </div>

                <div class="flex gap-2 items-center mb-2" v-for="(detail, idx) in item.details" :key="detail.id">
                  <div class="whitespace-nowrap">└ 부서: </div>
                  <div class="flex gap-2 items-center">
                    <input type="text" v-model="detail.dept" class="regi_inputs w-1/4" placeholder="부서명"/>
                    <input type="number" :value="detail.price === 0 ? '' : detail.price" v-on:input="detail.price = $event.target.value" placeholder="강의비" class="regi_inputs w-1/4"/>
                    <input type="number" :value="detail.limit_personnel === 0 ? '' : detail.limit_personnel" v-on:input="detail.limit_personnel = $event.target.value" placeholder="최대 인원" class="regi_inputs w-1/6"/>

                    <div class="flex gap-3">
                      <button type="button" @click="removeDetail(index, idx)" v-if="item.details.length > 1 || idx > 0">
                        <h4 class="text-red-500"><font-awesome-icon icon="fa-solid fa-xmark" /></h4>
                      </button>
                      <button type="button" @click="addDetail(index)" v-if="idx == item.details.length - 1">
                        <h4 class="text-sky-500"><font-awesome-icon icon="fa-solid fa-plus" /></h4>
                      </button>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

          <!-- 내용 -->
          <div class="md:flex">
            <label class="inline-block w-1/6 mb-2">내용<span></span></label>
            <div class="w-full mb-20">
              <QuillEditor v-model:content="formData.conference.contents" contentType="html" toolbar="full"></QuillEditor>
            </div>
          </div>

          <!-- 주소 입력 요청 -->
          <div class="md:flex">
            <label class="inline-block w-1/6 mb-2"> 주소 입력 요청 <span></span></label>
            <div class="w-full mb-20">
              <input type="checkbox" id="isAddress" v-model="formData.conference.isAddress" true-value="Y" false-value="N">
              <label for="isAddress"> 체크시 주소 입력 사용 </label>
            </div>
          </div>
          
          <!-- 등록자 // 값 1 고정 -->
          <div class="md:flex">
            <div class="flex w-full">
              <input type="number" placeholder="등록자" class="regi_inputs w-full" hidden v-model="formData.conference.created_member"/>
            </div>
          </div>
        </div>
  
        <div class="flex justify-center gap-2 p-5 border-t border-gray-200">
          <section class="text-center !border-0">
            <button type="button" @click="handleSubmit" class="sec_filled-blk_btn block w-40 p-4">등록하기</button>
          </section>
          <section class="text-center !border-0">
            <router-link to="/signup" class="sec_blk_btn block w-40 p-4">목록으로</router-link>
          </section>
        </div>
      </form>
    </div>

  </div>
</template>

<script setup>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import {reactive, ref} from "vue"
import axios from "axios"

const formData = reactive ({
  conference: {
    title: "",
    rsdatetime: "",
    redatetime: "",
    cancel_day: 0,
    contents: "",
    isAddress: "N",
    created_member: 1,
  },
  conferenceDetailList:[
    {
      common: {
        number: 1,
        local: "",
        place: "",
        schedule: ""
      },

      details: [
        {
          dept: "",
          price: 0,
          limit_personnel: 0
        }
      ]
    }
  ]
})

//일시 value format
const masks = ref({
  modelValue: "YYYY-MM-DDTHH:mm",
  title: "YYYY MMM"
})

const handleSubmit = () => {
  const response = axios.post('/api/conference', formData).then((res) => {
    if(res.data.err_code == 0){
      console.log(response)
      alert("등록이 완료되었습니다.")
      location.reload()
    } else {
      alert(res.data.err_msg)
    }
  }).catch(() => {
    alert("모든 영역에 값을 입력해주세요.")
  })
}


//일시 및 장소 추가/제거
const addItem = () => {
  console.log(formData.conferenceDetailList);
  formData.conferenceDetailList.push({
    common: {
      number: formData.conferenceDetailList.length + 1,
      local: "",
      place: "",
      schedule: ""
    },
    details: [
      {
        dept: "",
        price: 0
      }
    ]
  })
}

const removeItem = (index) => {
  formData.conferenceDetailList.splice(index, 1)
  //number 업데이트
  formData.conferenceDetailList.forEach((item, idx) => {
    item.common.number = idx + 1;
  })
}

//부서 추가/제거
const addDetail = (index) => {
  formData.conferenceDetailList[index].details.push({
    dept: "",
    price: 0
  });
}

const removeDetail = (index, idx) => {
  formData.conferenceDetailList[index].details.splice(idx, 1)
}

</script>

