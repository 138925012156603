<template>
  <div>
    <div class="adm_box_wrapper">
      <section class="flex justify-between items-baseline p-5 border-b border-gray-200">
        <div>
          <h3 class="font-medium">컨퍼런스 신청자 목록</h3>
          <p class="text-neutral-500">Total : {{ state.registered.length }}</p>
        </div>
        <div>
          <select v-model="state.status" @change="getRegistered" class="text-lg border p-1">
            <option value="all">전체</option>
            <option value="결제시도">결제시도</option>
            <option value="등록완료">등록완료</option>
            <option value="입금대기">입금대기</option>
            <option value="등록취소">등록취소</option>
            <option value="결제실패">결제실패</option>
          </select>
        </div>
      </section>
      <div>
        <table class="w-full table-auto table_wrapper_s">
          <thead class="text-left">
            <tr>
              <th>이름(아이디)</th>
              <th>전화번호</th>
              <!--<th>이메일</th>-->
              <th>교회명</th>
              <th>신청한 컨퍼런스</th>
              <th>결제상태</th>
              <th>결제수단</th>
              <th>결제금액</th>
              <th>주문번호</th>
              <th>등록일</th>
              <th>참석자 목록</th>
              <th>바코드</th>
            </tr>
          </thead>
          <tbody class="text-left">
            <tr v-for="item in membersOnCurrentPage" :key="item">
              <td>{{ item.member.name }}({{ item.member.uid }})</td>
              <td>{{ formattedPhone(item.member.phone) }}</td>
              <!--<td>{{ item.member.email }}</td>-->
              <td>{{ item.church }} {{ item.dept }}</td>
<!--              <td>{{ item.conferenceDetail.conference.title }} - {{ item.conferenceDetail.number }}차 {{ item.conferenceDetail.local }} {{ item.conferenceDetail.place }} {{ item.conferenceDetail.dept }}</td>-->
              <td>{{ item.conferenceDetail.number }}차 {{ item.conferenceDetail.local }} {{ item.conferenceDetail.place }} {{ item.conferenceDetail.dept }}</td>
              <td class="text-center">
                <span
                  :class="{
                    'text-gray-600': item.status === '결제시도',
                    'text-blue-600': item.status === '등록완료',
                    'text-yellow-600': item.status === '입금대기',
                    'text-orange-600': item.status === '등록취소',
                    'text-red-600': item.status === '결제실패'
                  }"
                >
                  {{ item.status }}
                </span>
              </td>
              <td class="text-center"> - <!--결제수단--> </td>
              <td class="text-center"> {{ item.total_price.toLocaleString('kr-ko') }} </td>
              <td class="text-center"> - <!--주문번호(TID)--> </td>
              <td class="text-center"> - <!--등록일--> </td>
              <td class="text-center">
                <VDialog :classes="{ bg: 'bg-black/40' }">
                  <template #default="{ close }">
                    <!-- 참석자 목록 -->
                    <div class="color-black bg-white min-w-[500px] max-h-[70vh] overflow-y-auto">
                      <section class="flex justify-between p-4 border-b border-gray-200">
                        <h5>참석자 목록</h5>
                        <button @click="close">
                          <h4><font-awesome-icon icon="fa-solid fa-circle-xmark" /></h4>
                        </button>
                      </section>
                      <table class="w-full table-auto table_wrapper">
                        <thead class="text-center sticky top-0 bg-white">
                          <tr>
                            <th>이름</th>
                            <th>교회명</th>
                            <!--<th>부서</th>-->
                          </tr>
                        </thead>
                        <tbody class="text-center">
                          <tr v-for="registermember in item.registerMemberList" :key="registermember">
                            <td>{{ registermember.name }}</td>
                            <td>{{ registermember.church }}</td>
                            <!--<td>{{ registermember.dept }}</td>-->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>

                  <template #toggle="{ bind, on }">
                    <button v-bind="bind" v-on="on" v-if="'item.registerMemberList.length > 0'">[{{ item.registerMemberList.length }}명]</button>
                  </template>
                </VDialog>
              </td>
              <td> {{ item.barcode }} </td>
            </tr>
          </tbody>
        </table>

        <div class="flex justify-center py-5">
          <v-pagination
            v-model="state.currentPage"
            :pages="totalPages"
            :range-size="2"
            @update:modelValue="handlePageChange"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import { reactive, computed } from "vue"
import { VDialog } from 'vuetensils/src/components'
import axios from "axios"
import AdminFilterComponent from "@/components/AdminFilterComponent.vue"

import VPagination  from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"

const state = reactive({
  registered: [],
  status: 'all',
  currentPage: 1,
  pageSize: 30, //페이지에 30명씩
})

const getRegistered = () => {
  axios.get(`/api/registered/conferences?status=${state.status}`).then(response => {
    state.registered = response.data.contents
    console.log(state.registered)
  }).catch(error => {
    console.error(error)
  })
}

getRegistered();

// 총 페이지수 계산
const totalPages = computed(() =>
  Math.ceil(state.registered.length / state.pageSize)
)

//회원 목록 slice
const membersOnCurrentPage = computed(() => {
  const start = (state.currentPage - 1) * state.pageSize
  const end = start + state.pageSize
  return state.registered.slice(start, end)
})

//페이지 변경
const handlePageChange = (newPage) => {
  state.currentPage = newPage
}


const formattedPhone = (phone) => {
  if (phone.length === 11) {
    return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1 - $2 - $3');
  } else {
    return phone;
  }
}


</script>
