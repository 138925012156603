<template>
  <div class="modal_container">
    <div class="reviewWrite_wrapper">
      <div class="flex justify-between items-center p-2 border-b border-black">
        <h2>리뷰 작성</h2>
        <button class="close" @click="closeModal">X</button>
      </div>

      <div class="p-2">
        <ul>
          <li class="flex flex-col">
            <div class="py-4 border-b">
              <b class="text-lg">{{ content.orderContentTitle }}</b>
              <div class="flex flex-col">
                <input
                  type="text"
                  v-model="reviewTitle"
                  class="regi_inputs w-full my-2"
                  placeholder="리뷰 제목을 입력해 주세요."
                >
                <div>
                  <quillEditor
                    v-model:value="reviewDescription"
                    contentType="html"
                    style="min-height: 30vh;"
                    :options="editor.editorOption"
                    :modules="modules"
                  />
                </div>
              </div>
            </div>
            <div class="flex gap-2 mx-auto">
              <button v-if="content.orderContentId" @click="submitReview(content.orderContentId)" class="pri_btn_bg mt-4 mx-auto px-3 py-1 text-lg">
                작성 완료
              </button>

              <button v-else @click="submitReview(content.orderItemId)" class="pri_btn_bg mt-4 mx-auto px-3 py-1 text-lg">
                수정 완료
              </button>
              <button v-if="myReview && myReview.reviewId" @click="delReview(myReview.reviewId)" class="sec_btn_black mt-4 mx-auto px-3 py-1 text-lg">
                삭제
              </button>
            </div>
          </li>
        </ul>
      </div>
      
      
    </div>
  </div>
</template>

<script setup>
  import axios from 'axios';
  import { ref, reactive, defineEmits, onMounted, computed } from 'vue';
  import { quillEditor, Quill } from 'vue3-quill'

  import imageUploader from "quill-image-uploader";
  import 'quill-image-uploader/dist/quill.imageUploader.min.css';

  Quill.register("modules/imageUploader", imageUploader);

  const emit = defineEmits();

  const closeModal = () => {
    emit('close');
  };

  const { content } = defineProps(['content']);

  const reviewTitle = ref('');
  const reviewDescription = ref('');
  const myReview = ref({});

  const editor = reactive({
    editorOption: {
      placeholder: '내용을 입력해 주세요.',
      modules: {
        toolbar: [
          ['bold', 'underline', 'strike'],
          // [{ color: [] }, { background: [] }],
          ['image']
        ],
        imageUploader: {
          upload: (file) => {
            return new Promise(async(resolve, reject) => {
              console.log(resolve)
              console.log(reject)
              console.log(file)

              const formData = new FormData();
              formData.append("image", file);

              const url = '/new_api/review/desc-image/new';
              await axios.post(url, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then(res => {
                if( res.status === 200 ) {
                  if (res.data.code === "200") {
                    console.log(res.data.data);
                    const uploadPath = 'http://118.67.134.110:18080' + res.data.data.uploadPath
                    resolve(uploadPath)
                  }
                  else {
                    console.log('등록 실패');
                  }
                }
                else {
                  console.log('등록 실패');
                }

              })
              .catch(err => {
                reject("Upload failed");
                console.error("Error:", err)
              })
            })
          },
        }
      }
    },
    
  })

  // 리뷰 작성
  const submitReview = (orderItemId) => {
    const data = {
      reviewId: null,
      reviewTitle: reviewTitle.value,
      reviewDescription: reviewDescription.value,
      orderItemId: orderItemId,
    };

    axios.post('/new_api/review/edit', data).then(res => {
      console.log(res);

      if (res.data.code === "200") {
        alert('리뷰가 등록되었습니다.');
        window.location.reload();
      } else {
        res.data.reasons.forEach(reason => {
          switch (res.data.reasons) {
            case "NotBlank.reviewTitle":
              alert("리뷰 제목을 입력해 주세요.");
              break;
            case "NotBlank.reviewDescription":
              alert("리뷰 내용을 입력해 주세요.");
              break;
            default:
              // 그 외
              alert("예기치 못한 에러가 발생했습니다.\n문제 지속시 고객센터로 문의 바랍니다.");
          }
        });
      }
    })
    .catch(err => {
      console.error(err);
    });
  };

  // 기존 리뷰 get
  const getReview = async () => {
    if(content.reviewId) {
      try {
        const res = await axios.get(`/new_api/review/${content.reviewId}`);   

        myReview.value = res.data.data;
        console.log(myReview.value)

        reviewTitle.value = myReview.value.reviewTitle;
        reviewDescription.value = myReview.value.reviewDescription;
      } catch (err) {
        console.error(err);
      }
    } else {
      return
    }
  }

  // 리뷰 삭제
  const delReview = async (reviewId) => {
    const isConfirmed = window.confirm('삭제하시겠습니까? 삭제된 내용은 복구되지 않습니다.');

    if (isConfirmed) {
      try {
        const { status, data } = await axios.delete(`/new_api/review/delete/${reviewId}`);
        if (status === 200 && data.code === "200") {
          alert('리뷰가 삭제되었습니다.');
          // 닫기
          closeModal();
        } else {
          alert('리뷰 삭제에 실패했습니다.');
        }
        console.log(res.data);
      } catch (err) {
        console.error(err);
      }
    };
  }
  
  onMounted(async () => {
    await getReview();
  });

  // 포토리뷰 미리보기
  const readInputFile = (e) => {
    const imagePreview = document.getElementById('imagePreview');
    imagePreview.innerHTML = '';

    const files = Array.from(e.target.files);

    files.forEach((file) => {
      if (!file.type.match('image/.*')) {
        alert('이미지 확장자만 업로드 가능합니다.');
        e.target.value = ''; // 파일 등록 취소
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        const img = document.createElement('img');
        img.src = event.target.result;
        imagePreview.appendChild(img);
      };

      reader.readAsDataURL(file);
    });
  };

  const clearThumbnail = () => {
    // 인풋 초기화
    const thumbnailInput = document.getElementById('thumbnailFile');
    thumbnailInput.value = '';
    // 썸네일 초기화
    state.post.postThumbnail = null;
  };
</script>

<style scoped>
  .modal_container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .reviewWrite_wrapper {
    width: 88%;
    max-width: 46rem;
    max-height: 88vh;
    background-color: #fefefe;
    padding: 1rem;
    border: 1px solid #888;
  }

  .close {
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
</style>