<template>
    <div class="w-full border-b border-neutral-200 shadow-lg shadow-neutral-200/50">
      <nav id="nav" class="h-[4.5rem] adm_nav">
        <div>
          <router-link to="/management" class="flex gap-3 items-center">
            <img src="@/assets/img/logo_symOnly.png" style="width: 24px; height: 30px"/>
            <h4 class="font-medium text-gray-700">총회교육개발원 컨퍼런스 관리자</h4>
          </router-link>
        </div>
        <div class="link">
          <router-link to="/">홈으로</router-link>
        </div>
      </nav>
    </div>
  
  </template>
  
  <script>
  export default {
    name: "AdminNavComponent"
  }
  </script>
  
