<template>
  <div id="qna">
    <div class="adm_box_wrapper">
      <section class="flex justify-between items-baseline p-5 border-b border-gray-200">
        <div>
          <h3 class="font-medium">문의 목록</h3>
          <p class="text-neutral-500">Total : {{ state.items.length }}</p>
        </div>
      </section>

      <div>
        <div class="text-center">
          <Vue3EasyDataTable
            :headers="headers"
            :items="state.items"
            alternating
          >
            <template #item-writer="{uid, name}">
              {{name}}({{uid}})
            </template>
            <template #expand="items">
              <p class="text-left p-4" style="white-space: break-spaces">{{ items.contents }}</p>
            </template>

            <template #item-status_update="{idx, status}">
              <button @click="confirmQna(idx)" v-if="status==='문의신청'" class="sec_blk_btn px-2 py-1">확인완료</button>
            </template>
          </Vue3EasyDataTable>
        </div>
  
      </div>
    </div>
  </div>

</template>

<script setup>
import { reactive } from "vue"
import axios from "axios"

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

const state = reactive({
  items: [],
  memberViewModal: false,
  memberData: {},
})

const headers = reactive([
  { text: "제목", value: "title", sortable: true },
  // { text: "내용", value: "contents" },
  { text: "작성자", value: "writer" },
  { text: "연락처", value: "phone" },
  { text: "이메일", value: "email" },
  { text: "작성일", value: "created_date" },
  { text: "상태", value: "status" },
  { text: "처리완료일", value: "confirmed_date" },
  { text: "상태변경", value: "status_update" },
])

const getQnaList = async () => {
	state.items = [];
  const url = `/api/qnas`;
  const { data } = await axios.get(url);
  const { contents } = data;

	contents.forEach((item) => {
		let setItem = {
      idx: item.idx,
      title: item.title,
      contents: item.contents,
      writer: "",
      contact: "",
      status: item.status,
      created_date: formattedDate(item.created_date),
      updated_date: formattedDate(item.updated_date),
      confirmed_date: formattedDate(item.confirmed_date),
      uid: item.member.uid,
      name: item.member.name,
      email: item.member.email,
      phone: formattedPhone(item.member.phone)
    };
    state.items.push(setItem);
	})
}

getQnaList();

const confirmQna = async (idx) => {
  if( confirm("처리 완료로 변경하시겠습니까?") ){
    const url = `/api/qna/confirm/${idx}`;
    await axios.put(url);

    alert("처리 완료로 변경되었습니다.");
    await getQnaList();
  }
}

const formattedPhone = (phone) => {
  if( phone ){
    if (phone.length === 11) {
      return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1 - $2 - $3');
    } else {
      return phone;
    }
  }
  else {
    return phone;
  }
}

const formattedDate = ( date ) => {
  if (date) {
    date = new Date(date);
    return date.toLocaleString();
  }
  else {
    return '-';
  }
}
</script>

<style scoped>

</style>