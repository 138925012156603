<template>
  <main class="max-w-[1200px] w-full mx-auto mb-20 px-5">
    <h1 class="font-bold text-center my-8">내 콘텐츠</h1>

    <div class="w-full overflow-auto lg:my-4 py-4">
      <div class="flex w-fit gap-x-2">
        <!-- <router-link to="" 
          class="sec_btn py-2 px-4 rounded-full whitespace-nowrap max-lg:text-sm">
          온라인 강의실
        </router-link> -->
        <router-link to="/mypage/contentsBox" active-class="pri_btn"
          class="sec_btn py-2 px-4 rounded-full whitespace-nowrap max-lg:text-sm">
          콘텐츠 보관함
        </router-link>
        <router-link to="/mypage/downloadHistory" active-class="pri_btn"
          class="sec_btn py-2 px-4 rounded-full whitespace-nowrap max-lg:text-sm">
          콘텐츠 다운로드 내역
        </router-link>
        <router-link to="/mypage/cart" active-class="pri_btn"
          class="sec_btn py-2 px-4 rounded-full whitespace-nowrap max-lg:text-sm">
          콘텐츠 장바구니
        </router-link>
      </div>
    </div>

    <div class="text-sm">
      <div class="bg-neutral-100 border border-neutral-200 p-4 mt-2">
        <div class="lg:flex items-center">
          <span class="w-20">기간조회</span>
          <VDatePicker v-model.range="range" locale="ko" :max-date='new Date()'>
            <template #default="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center">
                <input type="text" class="regi_inputs w-full" :value="inputValue.start" v-on="inputEvents.start" />
                <span class="mx-2">~</span>
                <input type="text" class="regi_inputs w-full" :value="inputValue.end" v-on="inputEvents.end" />
                <button class="ml-2 sec_filled-blk_btn p-2 flex-1" type="button" @click="getdownloadHistory(1)">조회</button>
              </div>
            </template>
          </VDatePicker>
        </div>
      </div>

      <div class="mt-10">
        <div v-if="downloadHistory.length != 0">
          <div class="table_grid_container">
            <table class="w-full">
              <thead class="max-lg:hidden">
                <tr class="border-b border-gray-[#dee0e3] text-gray-400">
                  <th class="p-4 text-left text-sm font-normal">다운로드 일시 및 콘텐츠 정보</th>
                  <!-- <th class="p-4 text-left text-sm font-normal">다운로드</th> -->
                </tr>
              </thead>
              <tbody>
                <tr class="table_grid_body max-lg:grid max-lg:pb-6 mb-6"
                  v-for="item in downloadHistory" :key="item.downloadHistoryId">
                  <td class="align-top p-4">
                    <div class="flex flex-col gap-2">
                      <p class="text-gray-600">
                        {{ formDownloadDate(item.downloadDateTime) }} <span class="lg:hidden">에 다운로드</span>              
                      </p>                    
                      <div>
                        <div>
                          {{ item.downloadHistoryId }}.
                          <span class="font-semibold">{{item.orderContentTitle}}</span>
                          ({{ item.downloadType }})
                        </div>
                      </div>
                    </div>
                  </td>
                  <!-- <td>
                    <div>

                    </div>
                    <div v-if="item.orderContentStatus == 'DOWNLOAD_FIN'">
                      만료
                    </div>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-if="pageInfo.page"
            :pageInfo="pageInfo"
            :page="page"
            :size="size"
            @updatePage="updatePage"
          />
        </div>

        <div v-else class="text-center py-10">
          내역이 비었습니다.
        </div>

      </div>
    </div>
  </main>
</template>

<script setup>
import axios from 'axios';
import { onMounted, ref, computed } from "vue";
import { useRoute } from 'vue-router';
const route = useRoute();
import Pagination from '@/components/Pagination.vue';

const formatDate = (date) => date.toISOString().split('T')[0];

const start = new Date();
const end = new Date();
start.setDate(end.getDate() - 14);

const range = ref({ start, end });

const formStartDate = computed(() => formatDate(range.value.start));
const formEndDate = computed(() => formatDate(range.value.end));
  
const downloadHistory = ref({});

const getdownloadHistory = async (num) => {
  try {
    const pageValue = num !== undefined ? 1 : page.value;

    const { data } = await axios.get(`/new_api/download/history/list?startDate=${formStartDate.value}&endDate=${formEndDate.value}&page=${pageValue}&size=${size.value}`);
    downloadHistory.value = data.data.downloadHistoryList;
    pageInfo.value = data.data.pageInfo;
  } catch (err) {
    console.error(err);
  }
}

onMounted(async () => {
  await getdownloadHistory();
});

// 다운로드 기한 만료 체크
const today = new Date();
const base = new Date('2000-01-01');

const isExpired = (date) => {
  const expirationDate = new Date(date);
  return expirationDate < today;
}

const formDownloadDate = (d) => {
const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  const date = new Date(d);
  return date.toLocaleDateString('ko-KR', options).replace(/(\d+)\D(\d+)\D(\d+)\D(\d+)\D(\d+)/, '$1.$2.$3 $4:$5:$6');
};

const pageInfo = ref({});

const page = ref(1);
const size = ref(10);

const updatePage = (num) => {
  page.value = num;
  getdownloadHistory();
};

</script>