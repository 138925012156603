<template>
  <div class="lg:w-[1000px] p-5 mx-auto mt-10">
    <!-- 회원정보 -->
    <div class="my-5 w-full">
      <h4 class="font-medium mb-2"> 취소 신청 내역 </h4>
      <div class="signup_form field_input mb-5">

      <table class="w-full table-auto table_wrapper">
        <thead class="text-center">
          <tr>
            <th>주문번호</th>
            <th>선택옵션</th>
            <th>신청자</th>
            <th>교회명</th>
            <th>취소인원(전체)</th>
            <th>취소수수료(원)</th>
            <th>환불금액(원)</th>
            <th>취소신청일</th>
            <th>취소완료일</th>
            <th>[현재상태]</th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr v-for="item in confesOnCurrentPage">
            <td> 주문번호 </td>
            <td> {{item.dept}} </td>
            <td> {{item.name}} </td>
            <td> {{item.church}} </td>
            <td>
                <span v-if="item.type === '전체'"> 전체 </span>
                <span v-else>부분 - {{item.count}}</span>
            </td>
            <td> 0 </td>
<!--            <td> {{item.price.toLocaleString('ko-kr')}} </td>-->
            <td> {{item.price}} </td>
            <td> {{item.created_date}} </td>
            <td>
                <span v-if="item.confirm_date!=null"> {{item.confirm_date}} </span>
            </td>
            <td> {{item.status}} </td>
          </tr>
        </tbody>
      </table>

      </div>

    </div>
  </div>

</template>

<script>
import {computed, onMounted, reactive} from "vue"
import axios from "axios"
import VPagination from "@hennge/vue3-pagination";

export default {
  name: "myCancelListPage",
  components: {VPagination},
  setup () {
    const state = reactive ({
      list: [],
      currentPage: 1,
      pageSize: 20, //페이지에 8개씩
    })

    const getCancelList = async () => {
			const url = `/api/member/me`;
			axios.get(url).then(response => {
        /*state.myConference = response.data.contents;

        state.myConference.registeredConferenceList.map(item => {
          item.paymentCancelListLatest = ( item.paymentCancelList.length > 0 ) ? item.paymentCancelList[item.paymentCancelList.length - 1] : {};
        })
        console.log(state.myConference)*/

        const contents = response.data.contents;
        console.log(contents);
        const conferenceList = contents.registeredConferenceList
        console.log(conferenceList);
        let cancelList = [];
        conferenceList.filter( item => {
	        console.log(item);
					if( item.paymentCancelList.length > 0 ){

					}

          /*if( item.paymentCancelList.length > 0 ){
            item.paymentCancelList.map( cancelItem => {
              cancelItem.dept = item.conferenceDetail.dept;
              cancelItem.name = contents.name;
              cancelItem.church = item.church;
              cancelItem.count = item.count;
              cancelItem.price = item.price;
              cancelItem.created_date = item.created_date;
              cancelItem.confirm_date = item.confirm_date;
              cancelItem.status = item.status;
              cancelList.push(cancelItem)
            })
          }*/
        })
        state.list = cancelList;
        console.log(cancelList);

      }).catch(error => {
        console.error(error)
      })

      /*const url = `/api/member/me`;
      const {data} = await axios.get(url);*/

	    const contents = data.contents;
	    console.log(contents);
			const conferenceList = contents.registeredConferenceList
	    console.log(conferenceList);
	    let cancelList = [];
      conferenceList.filter( item => {
				if( item.paymentCancelList.length > 0 ){
          item.paymentCancelList.map( cancelItem => {
	          cancelItem.dept = item.conferenceDetail.dept;
	          cancelItem.name = contents.name;
	          cancelItem.church = item.church;
	          cancelItem.count = item.count;
	          cancelItem.price = item.price;
	          cancelItem.created_date = item.created_date;
	          cancelItem.confirm_date = item.confirm_date;
	          cancelItem.status = item.status;
            cancelList.push(cancelItem)
          })
        }
      })
      state.list = cancelList;
	    console.log(cancelList);

			// data.registeredConferenceList


	    // console.log(data);

			/*if( data.err_code === 0 ){
        state.list = data.contents;
				state.list.map( item => {
					item.created_date = formattedDate(new Date(item.created_date));
          if( item.confirm_date != null ){
            item.confirm_date = formattedDate(new Date(item.confirm_date));
          }
				})
      }
			else {
				alert(data.err_msg);
				return false;
      }*/

			/*const res = await axios.get(url);
	    console.log(res);*/
      // state.list = data
    }

		const formattedDate = ( date ) => {
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')

      return `${year}/${month}/${day} ${hours}:${minutes}`
    }

		// 총 페이지수 계산
    const totalPages = computed(() =>
      Math.ceil(state.list.length / state.pageSize)
    )

    //회원 목록 slice
    const confesOnCurrentPage = computed(() => {
      const start = (state.currentPage - 1) * state.pageSize
      const end = start + state.pageSize
      return state.list.slice(start, end)
    })

    //페이지 변경
    const handlePageChange = (newPage) => {
      state.currentPage = newPage
    }

    onMounted(getCancelList)

    return {
      state,
      totalPages,
      confesOnCurrentPage,
      handlePageChange
    }
  }


}
</script>
