<template>
  <NavComponent/>
  <LoginPage/>
  <FooterComponent/>
</template>

<script>
// @ is an alias to /src
import LoginPage from "@/pages/LoginPage.vue";
import NavComponent from "@/components/NavComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: 'LoginView',
  components: {
    LoginPage,
    NavComponent,
    FooterComponent
  }
}
</script>

<style scoped>
</style>
