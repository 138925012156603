<template>
  <div class="lg:flex flex-1">
    <LNavComponent v-if="pageName !== 'eduRegisterConfirmApp'" />
    <appNavigator v-if="pageName === 'eduRegisterConfirmApp'" :page="pageName" />
    <div class="max-w-[1000px] w-full p-5 mx-auto">
      <EduRegisterPayPage/>
    </div>
  </div>
</template>

<script>
import LNavComponent from "@/components/LNavComponent.vue";
import EduRegisterPayPage from "@/pages/EduRegisterPayPage.vue";
import AppNavigator from "@/components/appNavigator.vue";

export default {
  name: "EduRegisterConfirmView",
  components: {AppNavigator, LNavComponent, EduRegisterPayPage},
  data() {
    return {
      pageName: this.$route.name
    }
  },
}
</script>

<style scoped>

</style>